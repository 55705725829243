import './QuickLink.scss';
import * as g from './QuickLink.generated';
import React, { memo } from 'react';
import { useNav } from "../../hooks";
import { AppLink } from "../Link";

export interface QuickLinkProps extends g.QuickLinkFragment {
}

export const QuickLink = memo(function QuickLink(props: QuickLinkProps) {

  // const navigate = useNavigate();
  // const { navigateTo } = useNav();

  // const pageId = props.pageId
  //   ?? props.link?.pageId
  //   ?? props.link?.pageKey
  //   ?? ''
  // ;

  return (
    <AppLink {...props.navLink} className="ux-quick-link">
      <div className="image" style={
        // dynamic background-image
        props.imageUrl ? { backgroundImage: `url("${props.imageUrl}")` } : {}
      }></div>
      <div className="title">
        {/*<WorkInProgressTag reason={ "Link / navigation not yet working" } />*/}
        { props.title }
      </div>
      <span className="description">{ props.description }</span>
    </AppLink>
  );
});
