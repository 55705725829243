// import "./Column.scss";
import * as g from "./Column.generated";
import { MouseEvent, useRef } from "react";
import { ENDPOINTS } from "@arq-apps/util";
import { BaseColumnProps } from "./BaseColumn";

export interface LinkColumnProps extends g.TextColumnFragment, BaseColumnProps {
  // TODO ...
}

export function LinkColumn(props: LinkColumnProps) {
  const classes = [
    props.className,
    'text',
    'link',
  ].filter(Boolean).join(' ');

  const downloadRef = useRef<HTMLAnchorElement | null>(null);

  // const handleDownload = (event: MouseEvent<HTMLElement>) => {
  //   const fileTarget: string = event.currentTarget.dataset.target || "";
  //   const bearer_token: string = sessionStorage.token;
  //   const url = `${ ENDPOINTS.DOWNLOAD }?${ fileTarget }&query_token=${ bearer_token }`;
  //   if (downloadRef.current) {
  //     downloadRef.current.href = url;
  //     downloadRef.current.click();
  //     downloadRef.current.href = "";
  //   }
  // };

  return (
    <div className={ classes }>
      <div className="title">{ props.title?.at(0) ?? props.id }</div>
      { props.textValues?.map((it, idx) => {
        const blob = JSON.parse(it || "{}");
        return (
          <div key={ idx }>
            <div style={ {cursor: "pointer"} }>
              <a
                // onClick={ handleDownload }
                data-target={ blob?.url }
                rel="noreferrer"
                target="_blank"
              >
                { blob?.metadata?.name }
              </a>
            </div>
          </div>
        );
      }) }
      <div className="footer">
        <a ref={ downloadRef } href="" target="_blank" download
           style={ {display: "hidden"} }
        ></a>
      </div>
    </div>
  );
}
