
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ActionLinkNavLinkExternalLink": [
      "ActionLink",
      "ExternalLink",
      "NavLink"
    ],
    "AlignableColumnI": [
      "BooleanColumn",
      "DateColumn",
      "DateTimeColumn",
      "FormulaColumn",
      "NumberColumn",
      "TextColumn"
    ],
    "Column": [
      "BooleanColumn",
      "DateColumn",
      "DateTimeColumn",
      "FormulaColumn",
      "NumberColumn",
      "TextColumn"
    ],
    "ColumnI": [
      "BooleanColumn",
      "ColumnFormula",
      "DateColumn",
      "DateTimeColumn",
      "FormulaColumn",
      "NumberColumn",
      "TextColumn"
    ],
    "ComponentI": [
      "ActivityLog",
      "App",
      "AppInfo",
      "Chart",
      "DataTemplate",
      "DataTemplateAction",
      "Form",
      "HierarchicalFilter",
      "Metric",
      "Page",
      "ProjectInfo",
      "Support",
      "Table",
      "TemplateAction",
      "VegaLiteChart"
    ],
    "Content": [
      "Chart",
      "DataTemplate",
      "Form",
      "Metric",
      "Table",
      "VegaLiteChart"
    ],
    "ContentI": [
      "ActivityLog",
      "Chart",
      "DataTemplate",
      "DataTemplateAction",
      "Form",
      "Metric",
      "Table",
      "TemplateAction",
      "VegaLiteChart"
    ],
    "EditableColumnI": [
      "BooleanColumn",
      "DateColumn",
      "DateTimeColumn",
      "NumberColumn",
      "TextColumn"
    ],
    "Field": [
      "BooleanField",
      "DateField",
      "DateTimeField",
      "DownloadFileField",
      "MultiSelectField",
      "NumberField",
      "NumberRangeField",
      "SelectField",
      "TextField",
      "UploadFileField"
    ],
    "FieldI": [
      "BooleanField",
      "DateField",
      "DateTimeField",
      "DownloadFileField",
      "MultiSelectField",
      "NumberField",
      "NumberRangeField",
      "SelectField",
      "TextField",
      "UploadFileField"
    ],
    "LoginResultLoginError": [
      "LoginError",
      "LoginResult"
    ]
  }
};
      export default result;
    