import "./Column.scss";
import * as g from "./Column.generated";
import { BaseColumnProps } from "./BaseColumn";
import { dateToString } from "@arq-apps/util"
import DatePicker from "../DatePicker/DatePicker"
export interface DateColumnProps extends g.DateColumnFragment, BaseColumnProps {
  className?: string,
}

export function DateColumn(props: DateColumnProps) {
  const classes = [
    props.className,
    'date',
    props.editable ? 'editable' : '',
    props.align ? props.align : '',
  ].filter(Boolean).join(' ');

  const onChange = (date: Date | null | undefined, row: number) => {
    if (!date) {
      props.onEditCell({
        __typename: "DateColumn",
        value: null,
        columnId: props.id,
        dataRowIdx: row,  // extra contains dataRowIdx
      });
      return
    }

    const dateString = dateToString(date)
    props.onEditCell({
      __typename: "DateColumn",
      value: dateString,
      columnId: props.id,
      dataRowIdx: row,  // extra contains dataRowIdx
    });
  }

  return (
    <div className={ classes }>
      <div className="title">{ props.title?.at(0) ?? props.id }</div>
      { !props.editable && props.dataRowIndex?.map((row) => <div>{props.dateValues[row]}</div>)}
      { props.editable && props.dataRowIndex?.map((row) => {
        const localDate = props.localDateValues ? props.localDateValues[row] : null
        const date = props.dateValues ? props.dateValues[row] : null
        const value = new Date(localDate ?? date ?? null)

        return <DatePicker
          value={value}
          onSelectDate={(date) => onChange(date, row)}
          size="small"
        />
      })}
      { props.showTotalsRow && <div className="footer-totals"></div> }
    </div>
  );
}