import "./Dialog.scss";
import * as D from '@radix-ui/react-dialog';
import { Button, Icon } from '@arq-apps/ui';
import { ReactNode } from "react"

interface DialogProps {
	title: string
	children: ReactNode | ReactNode[]
	content: ReactNode | ReactNode[]
  enabled?: boolean
	onConfirm: () => void
}

export const Dialog = (props: DialogProps) => (
  <D.Root>
    <D.Trigger asChild>
      {props.children}
    </D.Trigger>
    <D.Portal>
      <D.Overlay className="DialogOverlay">
        <D.Content className="DialogContent">
					<div className='dialog-header'>
						<D.Title className="DialogTitle">{props.title}</D.Title>
						<D.Close asChild>
							<button className="IconButton" aria-label="Close">
								<Icon icon="close-outline"/>
							</button>
						</D.Close>
					</div>
					<div className='dialog-scrollable-content'>
						{props.content}
					</div>
          <div className='dialog-footer' style={{ display: 'flex', marginTop: 25, justifyContent: 'flex-end' }}>
            <D.Close asChild>
              <Button text="Confirm" theme="orange" disabled={!props.enabled} onClick={props.onConfirm}/>
            </D.Close>
          </div>
        </D.Content>
      </D.Overlay>
    </D.Portal>
  </D.Root>
);