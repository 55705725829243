import { baseSchema } from "./base";
import { contextSchema, contextTypePolicies } from "./context";
import * as types from "@arq-apps/generated";
import { fieldsSchema } from "./fields";
import { sessionSchema, sessionTypePolicies } from "./session";
import { tableSchema, tableTypePolicies } from "./table";

export const localSchema = [
  baseSchema,
  contextSchema,
  fieldsSchema,
  sessionSchema,
  tableSchema,
];

export const typePolicies: types.StrictTypedTypePolicies = {
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
  // TODO specify cache keys OR empty lists if singletons
  Query: {
    ...contextTypePolicies.Query,
    ...sessionTypePolicies.Query,
    ...tableTypePolicies.Query,
  },
  ...contextTypePolicies,
  ...sessionTypePolicies,
  ...tableTypePolicies,
}
