import { ENDPOINTS } from '@arq-apps/util';
import './Card.scss';
import { MessagesLandingProjectCard } from '@arq-apps/dotnet'

interface ProjectCard extends MessagesLandingProjectCard {
  onClick?: () => void;
}

export function ProjectCard(props: ProjectCard) {
  const classes = [
    'ux-card', "project-card"
  ].filter(Boolean).join(' ');

  return (
    <div className={classes} onClick={props.onClick}>
      <div className="image" style={{ backgroundImage: `url("${ENDPOINTS.DOTNET}/rest/support/getImage?imageKey=${props.card?.image}")` }}/>
      <div className="content">
        <div className="title">{ props.card?.title && props.card.title }</div>
        <div className="rows">
          <div className="row">
            <div className="label">Organisation:</div>
            <div className="value">{props.organisation && props.organisation}</div>
          </div>
          {/* <div className="row">
            <div className="label">Last used:</div>
            <div className="value">{props.last_used && props.last_used}</div>
          </div> */}
          <div className="row">
            <div className="label">Apps:</div>
            <div className="value">{props.card?.description && props.card.description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};