import { MessagesLandingMenu, MessagesUiAccessPageItem, MessagesUiRoleItem } from "../dotnet-client";
import { createContext, useContext, useEffect, useState } from "react";
import { useLandingContext } from "./LandingContext"
import { useDotnetClient } from "./DotnetClientContext"
import { toast } from "react-toastify"

interface EditableMenuContext {
  menu: MessagesLandingMenu | undefined
  setMenu: React.Dispatch<React.SetStateAction<MessagesLandingMenu | undefined>>
  pages: MessagesUiAccessPageItem[]
  roles: MessagesUiRoleItem[]
  postMenu: () => void
  indexArray: number[]
  setIndexArray: React.Dispatch<React.SetStateAction<number[]>>
}

const DEFAULT_CONTEXT: EditableMenuContext = {
  menu: undefined,
  setMenu: () => {},
  pages: [],
  roles: [],
  postMenu: () => {},
  indexArray: [],
  setIndexArray: () => {}
}

const EditableMenuContext = createContext<EditableMenuContext>(DEFAULT_CONTEXT)

export const EditableMenuProvider = (props: { children: React.ReactNode }) => {
  const { appId } = useLandingContext();
  const [menu, setMenu] = useState<MessagesLandingMenu>();
  const [pages, setPages] = useState<MessagesUiAccessPageItem[]>([]);
  const [roles, setRoles] = useState<MessagesUiRoleItem[]>([]);
  const [indexArray, setIndexArray] = useState<number[]>([]);
  
  const {dmpClient} = useDotnetClient();

  useEffect(() => {
    appId && dmpClient?.uiApi.getUiApiGetMenu(appId, "menu")
      .then(result => {
        const _menu = result.definition ? JSON.parse(result.definition) : []
        setMenu(_menu)
      })
      .catch(err => console.warn(err))
  }, [])

  useEffect(() => {
    appId && dmpClient?.uiApi.getUiApiGetPages(appId)
      .then(result => {
        setPages(result)
        // console.log(result)
      })
      .catch(err => console.warn(err))
  }, [])

  useEffect(() => {
    appId && dmpClient?.uiApi.getUiApiGetRoles(appId)
      .then(result => {
        setRoles(result)
        // console.log(result)
      })
      .catch(err => console.warn(err))
  }, [])

  const postMenu = () => {
    if (!appId || !menu) return
    dmpClient?.uiApi.postUiApiUpdateMenu(appId, "menu", {
      definition: JSON.stringify(menu)
    }).then(() => {
      toast.success("Successfully uploaded menu")
    }).catch((err) => {
      console.warn(err)
      toast.error("Failed to upload menu")
    })
  }

  return (
    <EditableMenuContext.Provider value={{menu, setMenu, pages, roles, postMenu, indexArray, setIndexArray}}>
      { props.children }
    </EditableMenuContext.Provider>
  )
}

export const useEditableMenu = () => {
  return useContext(EditableMenuContext);
}