/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesGlossaryGlossary } from '../models/MessagesGlossaryGlossary';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GlossaryApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get a whole collection of terms for an application
     * @param applicationId
     * @returns MessagesGlossaryGlossary OK
     * @throws ApiError
     */
    public getGlossaryApiGetGlossary(
        applicationId: number,
    ): CancelablePromise<Array<MessagesGlossaryGlossary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/glossary/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
        });
    }

    /**
     * PUT a new term on an application
     * @param applicationId
     * @param requestBody
     * @returns MessagesGlossaryGlossary OK
     * @throws ApiError
     */
    public putGlossaryApiAddGlossary(
        applicationId: number,
        requestBody?: MessagesGlossaryGlossary,
    ): CancelablePromise<Array<MessagesGlossaryGlossary>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/glossary/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * POST a whole glossary to update the entire collection, eg. for sort orders
     * @param applicationId
     * @param requestBody
     * @returns MessagesGlossaryGlossary OK
     * @throws ApiError
     */
    public postGlossaryApiPostGlossary(
        applicationId: number,
        requestBody?: Array<MessagesGlossaryGlossary>,
    ): CancelablePromise<Array<MessagesGlossaryGlossary>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/glossary/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Get a single term in an application
     * @param applicationId
     * @param termId
     * @returns MessagesGlossaryGlossary OK
     * @throws ApiError
     */
    public getGlossaryApiGetGlossaryItem(
        applicationId: number,
        termId: number,
    ): CancelablePromise<MessagesGlossaryGlossary> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/glossary/{applicationId}/{termId}',
            path: {
                'applicationId': applicationId,
                'termId': termId,
            },
        });
    }

    /**
     * Update a single term in an application
     * @param applicationId
     * @param termId
     * @param requestBody
     * @returns MessagesGlossaryGlossary OK
     * @throws ApiError
     */
    public patchGlossaryApiUpdateGlossaryItem(
        applicationId: number,
        termId: number,
        requestBody?: MessagesGlossaryGlossary,
    ): CancelablePromise<MessagesGlossaryGlossary> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/rest/glossary/{applicationId}/{termId}',
            path: {
                'applicationId': applicationId,
                'termId': termId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Delete a single term in an application
     * @param applicationId
     * @param termId
     * @returns MessagesGlossaryGlossary OK
     * @throws ApiError
     */
    public deleteGlossaryApiDeleteGlossaryItem(
        applicationId: number,
        termId: number,
    ): CancelablePromise<MessagesGlossaryGlossary> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/glossary/{applicationId}/{termId}',
            path: {
                'applicationId': applicationId,
                'termId': termId,
            },
        });
    }

}
