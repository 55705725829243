import { IconButton } from '../../ui/IconButton';
import { useNavigate } from 'react-router-dom';
import { TermsDialog } from '../Dialog/TermsDialog';
import { useLandingContext } from 'src/contexts/LandingContext';
import { MessagesLandingApplicationProjectCard } from '@arq-apps/dotnet';
import { ENDPOINTS } from "@arq-apps/util";

export function LaunchCard(props: MessagesLandingApplicationProjectCard) {
  const {updateLandingContext} = useLandingContext()
  const navigate = useNavigate();
  
  const onConfirm = () => {
    updateLandingContext({
      appId: props.app_id,
      appTitle: props.application,
      projectId: props.project_id,
      projectName: props.project,
      projectOrg: props.organisation,
      privacyUrl: props.privacy_statement_url
    })
    navigate(`/${props.app_key}/home?pid=${props.project_id}`)
  }

  const classes = [
    'ux-card', "launch-card"
  ].filter(Boolean).join(' ');

  return (
    <TermsDialog 
      onConfirm={onConfirm}
      termsAccepted={props.terms_and_conditions_accepted}
      termsUrl={props.terms_and_conditions_url}
      privacyUrl={props.privacy_statement_url ?? undefined}
      appId={props.app_id}
      >
      <div className={classes} key={`${props.app_key}-${props.project_id}`}>
        <div className="image" style={{ backgroundImage: `url("${ENDPOINTS.DOTNET}/rest/support/getImage?imageKey=${props.card?.image}")` }}/>
        <div className="content">
          <div className="header-row">
            <IconButton icon="star-outline" scale={1.5}/>
            <div className="title"> {props.card?.title && props.card.title} </div>
          </div>
          <div className="rows">
            <div className="row">
              <div className="label">Organisation:</div>
              <div className="value">{props.organisation && props.organisation}</div>
            </div>
            <div className="row">
              <div className="label">Project:</div>
              <div className="value">{props.project && props.project}</div>
            </div>
          </div>
        </div>
      </div>
    </TermsDialog>
  )
}