import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date (isoformat) */
  Date: any;
  /** Date with time (isoformat) */
  DateTime: any;
};

export enum Action {
  Logout = 'logout',
  Refresh = 'refresh'
}

export type ActionLink = {
  __typename?: 'ActionLink';
  action?: Maybe<Action>;
  label?: Maybe<Scalars['String']>;
};

export type ActionLinkNavLinkExternalLink = ActionLink | ExternalLink | NavLink;

export type ActiveUser = {
  __typename?: 'ActiveUser';
  name: Scalars['String'];
};

export type ActivityLog = ComponentI & ContentI & {
  __typename?: 'ActivityLog';
  blurb?: Maybe<Scalars['String']>;
  completedBy: Scalars['String'];
  date: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['ID'];
  meta: Meta;
  relatedFilters: Array<Field>;
  state: Scalars['String'];
  url: Scalars['String'];
};

export type AlignableColumnI = {
  /** align values to the start/center/end of the cell */
  align?: Maybe<ColumnAlignment>;
};

export type App = ComponentI & {
  __typename?: 'App';
  appMenu: AppMenu;
  application: AppInfo;
  chartById?: Maybe<Chart>;
  charts: Array<Chart>;
  chartsByIds: Array<Maybe<Chart>>;
  description?: Maybe<Scalars['String']>;
  filterById?: Maybe<Field>;
  filters: Array<Field>;
  filtersByIds: Array<Maybe<Field>>;
  formById?: Maybe<Form>;
  forms: Array<Form>;
  formsByIds: Array<Maybe<Form>>;
  hierarchicalFiltersByIds: Array<Maybe<HierarchicalFilter>>;
  homePageId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meta: Meta;
  metricById?: Maybe<Metric>;
  metrics: Array<Metric>;
  metricsByIds: Array<Maybe<Metric>>;
  pageById?: Maybe<Page>;
  pages: Array<Page>;
  pagesByIds: Array<Maybe<Page>>;
  project: ProjectInfo;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tableById?: Maybe<Table>;
  tables: Array<Table>;
  tablesByIds: Array<Maybe<Table>>;
  title?: Maybe<Scalars['String']>;
};


export type AppChartByIdArgs = {
  id: Scalars['ID'];
};


export type AppChartsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type AppFilterByIdArgs = {
  id: Scalars['ID'];
};


export type AppFiltersByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type AppFormByIdArgs = {
  id: Scalars['ID'];
};


export type AppFormsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type AppHierarchicalFiltersByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type AppMetricByIdArgs = {
  id: Scalars['ID'];
};


export type AppMetricsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type AppPageByIdArgs = {
  id: Scalars['ID'];
};


export type AppPagesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type AppTableByIdArgs = {
  id: Scalars['ID'];
};


export type AppTablesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type AppInfo = ComponentI & {
  __typename?: 'AppInfo';
  appId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meta: Meta;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AppMenu = {
  __typename?: 'AppMenu';
  items: Array<AppMenuItem>;
};

export type AppMenuItem = {
  __typename?: 'AppMenuItem';
  children?: Maybe<Array<AppMenuItem>>;
  link: NavLink;
};

export type Background = {
  __typename?: 'Background';
  colour?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type Banner = {
  __typename?: 'Banner';
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BooleanColumn = AlignableColumnI & ColumnI & EditableColumnI & {
  __typename?: 'BooleanColumn';
  /** align values to the start/center/end of the cell */
  align?: Maybe<ColumnAlignment>;
  /** ordered boolean (true/false or null) row values contained within this column */
  boolValues: Array<Maybe<Scalars['Boolean']>>;
  /** enables editing of this column's values */
  editable?: Maybe<Scalars['Boolean']>;
  /** hides the column from view */
  hide?: Maybe<Scalars['Boolean']>;
  /** highlight a column to make it stand out from the rest */
  highlight?: Maybe<Scalars['Boolean']>;
  /** the unique identifier for this column */
  id: Scalars['ID'];
  length: Scalars['Int'];
  localBoolValues: Array<Maybe<Scalars['Boolean']>>;
  /** pins the column to the start of the table (will not scroll) */
  pin?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the end of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinEnd?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the start of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinStart?: Maybe<Scalars['Boolean']>;
  /** Show Total for column */
  showTotals?: Maybe<Scalars['Boolean']>;
  /** the width of the column (generic units; try-and-see) */
  size?: Maybe<Scalars['Float']>;
  /** the column title (possibly multiple levels) to be shown */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A checkbox / toggle providing yes-no input */
export type BooleanField = FieldI & {
  __typename?: 'BooleanField';
  /** A brief description of this field */
  description?: Maybe<Scalars['String']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** An information tooltip to be shown for the field */
  information?: Maybe<Scalars['String']>;
  /** A short, human-readable label describing this field */
  label?: Maybe<Scalars['String']>;
  localEdited?: Maybe<Scalars['Boolean']>;
  /** The local/client-edited boolean value */
  localState?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['Boolean']>;
};

export type Chart = ComponentI & ContentI & {
  __typename?: 'Chart';
  config?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  meta: Meta;
  relatedFilters: Array<Field>;
  spec?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Column = BooleanColumn | DateColumn | DateTimeColumn | FormulaColumn | NumberColumn | TextColumn;

export enum ColumnAlignment {
  Center = 'center',
  End = 'end',
  Start = 'start'
}

export type ColumnFormula = ColumnI & {
  __typename?: 'ColumnFormula';
  columnIds?: Maybe<Array<Scalars['ID']>>;
  /** hides the column from view */
  hide?: Maybe<Scalars['Boolean']>;
  /** highlight a column to make it stand out from the rest */
  highlight?: Maybe<Scalars['Boolean']>;
  /** the unique identifier for this column */
  id: Scalars['ID'];
  lowerBound?: Maybe<Scalars['Float']>;
  operation?: Maybe<FormulaOperation>;
  /** pins the column to the start of the table (will not scroll) */
  pin?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the end of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinEnd?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the start of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinStart?: Maybe<Scalars['Boolean']>;
  /** Show Total for column */
  showTotals?: Maybe<Scalars['Boolean']>;
  /** the width of the column (generic units; try-and-see) */
  size?: Maybe<Scalars['Float']>;
  /** the column title (possibly multiple levels) to be shown */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  upperBound?: Maybe<Scalars['Float']>;
};

export type ColumnI = {
  /** hides the column from view */
  hide?: Maybe<Scalars['Boolean']>;
  /** highlight a column to make it stand out from the rest */
  highlight?: Maybe<Scalars['Boolean']>;
  /** the unique identifier for this column */
  id: Scalars['ID'];
  /** pins the column to the start of the table (will not scroll) */
  pin?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the end of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinEnd?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the start of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinStart?: Maybe<Scalars['Boolean']>;
  /** Show Total for column */
  showTotals?: Maybe<Scalars['Boolean']>;
  /** the width of the column (generic units; try-and-see) */
  size?: Maybe<Scalars['Float']>;
  /** the column title (possibly multiple levels) to be shown */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Inputs relating to a table edit event */
export type ColumnInput = {
  boolValues?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  changesIndex: Array<Scalars['Int']>;
  dateTimeValues?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dateValues?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** column identity */
  id: Scalars['ID'];
  index?: InputMaybe<Array<Scalars['Int']>>;
  isIndex: Scalars['Boolean'];
  numberValues?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** the input-type tag (temporary workaround ahead of GraphQL tagged-union availability) */
  tag: ColumnTag;
  textValues?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum ColumnTag {
  BooleanColumn = 'BooleanColumn',
  DateColumn = 'DateColumn',
  DateTimeColumn = 'DateTimeColumn',
  NumberColumn = 'NumberColumn',
  TextColumn = 'TextColumn',
  UnknownColumn = 'UnknownColumn'
}

export type ComponentI = {
  id: Scalars['ID'];
  meta: Meta;
};

export type Content = Chart | DataTemplate | Form | Metric | Table | VegaLiteChart;

export type ContentI = {
  relatedFilters: Array<Field>;
};

/** a collection of content, listed by their unique identifiers */
export type ContentIds = {
  __typename?: 'ContentIds';
  /** app identifiers (IDs) */
  apps?: Maybe<Array<Scalars['String']>>;
  /** chart identifiers (IDs) */
  charts?: Maybe<Array<Scalars['String']>>;
  /** form identifiers (IDs) */
  forms?: Maybe<Array<Scalars['String']>>;
  /** metric identifiers (IDs) */
  metrics?: Maybe<Array<Scalars['String']>>;
  /** page identifiers (IDs) */
  pages?: Maybe<Array<Scalars['String']>>;
  /** table identifiers (IDs) */
  tables?: Maybe<Array<Scalars['String']>>;
};

export type Context = {
  __typename?: 'Context';
  appId: Scalars['ID'];
  pageId: Scalars['ID'];
  projectId?: Maybe<Scalars['Int']>;
  supportPageId?: Maybe<Scalars['ID']>;
};

export type DataTemplate = ComponentI & ContentI & {
  __typename?: 'DataTemplate';
  actions?: Maybe<Array<DataTemplateAction>>;
  activityLog?: Maybe<Array<ActivityLog>>;
  entityId: Scalars['Int'];
  id: Scalars['ID'];
  meta: Meta;
  relatedFilters: Array<Field>;
  template: Scalars['String'];
  templateFilename: Scalars['String'];
  templateId: Scalars['Int'];
  templateUrl: Scalars['String'];
};

export type DataTemplateAction = ComponentI & ContentI & {
  __typename?: 'DataTemplateAction';
  /** DownloadFile action */
  downloadField?: Maybe<DownloadFileField>;
  id: Scalars['ID'];
  meta: Meta;
  relatedFilters: Array<Field>;
  /** Template Action e.g. accept or revoke */
  templateAction?: Maybe<TemplateAction>;
  /** UploadFile action */
  uploadField?: Maybe<UploadFileField>;
};

/** A DataTemplateInput input */
export type DataTemplateInput = {
  /** Associated Entity id */
  entityId: Scalars['Int'];
  /** Associated template id */
  templateId: Scalars['Int'];
  /** Associated Entity id */
  transition: DataTemplateTransitionInput;
};

/** A DataTemplateTransition data type */
export type DataTemplateTransitionInput = {
  /** The unique value of this option */
  blurb?: InputMaybe<Scalars['String']>;
  /** Associated User id */
  completedBy: Scalars['Int'];
  /** Transition date */
  date: Scalars['String'];
  /** The unique value of this option */
  state: Scalars['String'];
  /** Associated template file URL */
  url: Scalars['String'];
};

export type DateColumn = AlignableColumnI & ColumnI & EditableColumnI & {
  __typename?: 'DateColumn';
  /** align values to the start/center/end of the cell */
  align?: Maybe<ColumnAlignment>;
  /** ordered date (Date or null) row values contained within this column */
  dateValues: Array<Maybe<Scalars['Date']>>;
  /** enables editing of this column's values */
  editable?: Maybe<Scalars['Boolean']>;
  /** hides the column from view */
  hide?: Maybe<Scalars['Boolean']>;
  /** highlight a column to make it stand out from the rest */
  highlight?: Maybe<Scalars['Boolean']>;
  /** the unique identifier for this column */
  id: Scalars['ID'];
  length: Scalars['Int'];
  localDateValues: Array<Maybe<Scalars['String']>>;
  /** pins the column to the start of the table (will not scroll) */
  pin?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the end of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinEnd?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the start of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinStart?: Maybe<Scalars['Boolean']>;
  /** Show Total for column */
  showTotals?: Maybe<Scalars['Boolean']>;
  /** the width of the column (generic units; try-and-see) */
  size?: Maybe<Scalars['Float']>;
  /** the column title (possibly multiple levels) to be shown */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Form control for selecting a date */
export type DateField = FieldI & {
  __typename?: 'DateField';
  /** Date in iso format */
  date?: Maybe<Scalars['Date']>;
  /** A brief description of this field */
  description?: Maybe<Scalars['String']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** An information tooltip to be shown for the field */
  information?: Maybe<Scalars['String']>;
  /** A short, human-readable label describing this field */
  label?: Maybe<Scalars['String']>;
  localDate?: Maybe<Scalars['String']>;
  localEdited?: Maybe<Scalars['Boolean']>;
};

export type DateTimeColumn = AlignableColumnI & ColumnI & EditableColumnI & {
  __typename?: 'DateTimeColumn';
  /** align values to the start/center/end of the cell */
  align?: Maybe<ColumnAlignment>;
  /** ordered date/time (DateTime or null) row values contained within this column */
  dateTimeValues: Array<Maybe<Scalars['DateTime']>>;
  /** enables editing of this column's values */
  editable?: Maybe<Scalars['Boolean']>;
  /** hides the column from view */
  hide?: Maybe<Scalars['Boolean']>;
  /** highlight a column to make it stand out from the rest */
  highlight?: Maybe<Scalars['Boolean']>;
  /** the unique identifier for this column */
  id: Scalars['ID'];
  length: Scalars['Int'];
  /** pins the column to the start of the table (will not scroll) */
  pin?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the end of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinEnd?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the start of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinStart?: Maybe<Scalars['Boolean']>;
  /** Show Total for column */
  showTotals?: Maybe<Scalars['Boolean']>;
  /** the width of the column (generic units; try-and-see) */
  size?: Maybe<Scalars['Float']>;
  /** the column title (possibly multiple levels) to be shown */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Form control for selecting a date time */
export type DateTimeField = FieldI & {
  __typename?: 'DateTimeField';
  /** Date-time in iso format */
  dateTime?: Maybe<Scalars['DateTime']>;
  /** A brief description of this field */
  description?: Maybe<Scalars['String']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** An information tooltip to be shown for the field */
  information?: Maybe<Scalars['String']>;
  /** A short, human-readable label describing this field */
  label?: Maybe<Scalars['String']>;
  localDateTime?: Maybe<Scalars['String']>;
  localEdited?: Maybe<Scalars['Boolean']>;
};

/** Form control for a download button */
export type DownloadFileField = FieldI & {
  __typename?: 'DownloadFileField';
  /** A brief description of this field */
  description?: Maybe<Scalars['String']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** An information tooltip to be shown for the field */
  information?: Maybe<Scalars['String']>;
  /** A short, human-readable label describing this field */
  label?: Maybe<Scalars['String']>;
  /** Always false */
  localEdited?: Maybe<Scalars['Boolean']>;
  /** Download selections */
  selection?: Maybe<SelectField>;
};

/** Edit Table result payload */
export type EditTableEvent = {
  __typename?: 'EditTableEvent';
  /** content which should be refreshed after processing this table edit event */
  contentToRefresh?: Maybe<ContentIds>;
  /** message for user after form submission event completion */
  message?: Maybe<Scalars['String']>;
  /** indicates whether table edit was processed successfully or not */
  ok: Scalars['Boolean'];
};

/** Inputs relating to a table edit event */
export type EditTableInput = {
  /** active app identity */
  appId: Scalars['ID'];
  changed: Array<ColumnInput>;
  /** data row count */
  dataRowCount: Scalars['Int'];
  /** active filter values */
  filters: Array<FieldInput>;
  /** table identity */
  id: Scalars['ID'];
  /** active project identity */
  projectId?: InputMaybe<Scalars['Int']>;
};

export type EditableColumnI = {
  /** enables editing of this column's values */
  editable?: Maybe<Scalars['Boolean']>;
};

export type ExternalLink = {
  __typename?: 'ExternalLink';
  externalLink?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type Field = BooleanField | DateField | DateTimeField | DownloadFileField | MultiSelectField | NumberField | NumberRangeField | SelectField | TextField | UploadFileField;

/** Common properties provided by all field types */
export type FieldI = {
  /** A brief description of this field */
  description?: Maybe<Scalars['String']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** An information tooltip to be shown for the field */
  information?: Maybe<Scalars['String']>;
  /** A short, human-readable label describing this field */
  label?: Maybe<Scalars['String']>;
  /** True if the local value differs from the latest server value */
  localEdited?: Maybe<Scalars['Boolean']>;
};

export type FieldInput = {
  /** (DataTemplateInput only) the data template transition */
  dataTemplate?: InputMaybe<DataTemplateInput>;
  /** (DateField only) the current date value */
  date?: InputMaybe<Scalars['Date']>;
  /** (DateTimeField only) the current date time value */
  dateTime?: InputMaybe<Scalars['DateTime']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** (NumberRangeField only) the current range */
  range?: InputMaybe<RangeInput>;
  /** (SelectField only) the selected option */
  selection?: InputMaybe<SelectOptionInput>;
  /** (MultiSelectField only) the selected option(s) */
  selections?: InputMaybe<Array<SelectOptionInput>>;
  /** (BooleanField only) the current boolean state (true/false) */
  state?: InputMaybe<Scalars['Boolean']>;
  /** the input-type tag (temporary workaround ahead of GraphQL tagged-union availability) */
  tag: FieldTag;
  /** (TextField only) the current text value */
  text?: InputMaybe<Scalars['String']>;
  /** (NumberField only) the current number value */
  value?: InputMaybe<Scalars['Float']>;
};

export enum FieldTag {
  BooleanField = 'BooleanField',
  DateField = 'DateField',
  DateTimeField = 'DateTimeField',
  DownloadFileField = 'DownloadFileField',
  MultiSelectField = 'MultiSelectField',
  NumberField = 'NumberField',
  NumberRangeField = 'NumberRangeField',
  SelectField = 'SelectField',
  TextField = 'TextField',
  UnknownField = 'UnknownField',
  UploadFileField = 'UploadFileField'
}

export type Footer = {
  __typename?: 'Footer';
  links: Array<ActionLinkNavLinkExternalLink>;
  text?: Maybe<Scalars['String']>;
};

/** Defines a form */
export type Form = ComponentI & ContentI & {
  __typename?: 'Form';
  /** a short caption describing the purpose of this form */
  caption?: Maybe<Scalars['String']>;
  /** show form in condensed table format */
  denseFormat?: Maybe<Scalars['Boolean']>;
  /** a description of the form's content */
  description?: Maybe<Scalars['String']>;
  /** fields for the user to fill */
  fields?: Maybe<Array<Field>>;
  id: Scalars['ID'];
  meta: Meta;
  relatedFilters: Array<Field>;
  /** show the actions section for the form */
  showActions?: Maybe<Scalars['Boolean']>;
  /** the text to display on the form-submit button */
  submitButtonText?: Maybe<Scalars['String']>;
  /** a short title for the form */
  title?: Maybe<Scalars['String']>;
};

export type FormulaColumn = AlignableColumnI & ColumnI & {
  __typename?: 'FormulaColumn';
  /** align values to the start/center/end of the cell */
  align?: Maybe<ColumnAlignment>;
  formula?: Maybe<ColumnFormula>;
  /** hides the column from view */
  hide?: Maybe<Scalars['Boolean']>;
  /** highlight a column to make it stand out from the rest */
  highlight?: Maybe<Scalars['Boolean']>;
  /** the unique identifier for this column */
  id: Scalars['ID'];
  /** pins the column to the start of the table (will not scroll) */
  pin?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the end of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinEnd?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the start of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinStart?: Maybe<Scalars['Boolean']>;
  /** Show Total for column */
  showTotals?: Maybe<Scalars['Boolean']>;
  /** the width of the column (generic units; try-and-see) */
  size?: Maybe<Scalars['Float']>;
  /** the column title (possibly multiple levels) to be shown */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum FormulaOperation {
  Count = 'count',
  Max = 'max',
  Mean = 'mean',
  Min = 'min',
  Sum = 'sum'
}

export type HierarchicalFilter = ComponentI & {
  __typename?: 'HierarchicalFilter';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  leafSelections?: Maybe<Array<HierarchicalOption>>;
  meta: Meta;
  multiselectOnLeaf?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<HierarchicalOption>>;
  optionsLabel?: Maybe<Scalars['String']>;
  selection?: Maybe<Array<HierarchicalOption>>;
};

export type HierarchicalOption = {
  __typename?: 'HierarchicalOption';
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<HierarchicalOption>>;
  optionsLabel?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** TODO provide description */
export type InvalidateInput = {
  message: Scalars['String'];
};

/** TODO provide description */
export type LoginError = {
  __typename?: 'LoginError';
  detail: Scalars['String'];
  message: Scalars['String'];
};

/** TODO provide description */
export type LoginInput = {
  authorizationCode: Scalars['String'];
  clientId: Scalars['String'];
  issuer: Scalars['String'];
  redirectUri: Scalars['String'];
};

/** TODO provide description */
export type LoginResult = {
  __typename?: 'LoginResult';
  platform: Platform;
  token: Scalars['String'];
};

export type LoginResultLoginError = LoginError | LoginResult;

/** TODO provide description */
export type LogoutInput = {
  reason: Scalars['String'];
};

/** TODO provide description */
export type LogoutResult = {
  __typename?: 'LogoutResult';
  redirectUri: Scalars['String'];
};

export type Meta = {
  __typename?: 'Meta';
  allowPublicAccess: Scalars['Boolean'];
  appId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  experimental: Scalars['Boolean'];
  label: Scalars['String'];
  projectId?: Maybe<Scalars['Int']>;
  requireAdmin: Scalars['Boolean'];
  requireDeveloper: Scalars['Boolean'];
  requireInternal: Scalars['Boolean'];
  requireSuperuser: Scalars['Boolean'];
  useFilters?: Maybe<Array<Scalars['String']>>;
  useProject: Scalars['Boolean'];
  useUser: Scalars['Boolean'];
};

export type Metric = ComponentI & ContentI & {
  __typename?: 'Metric';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  meta: Meta;
  relatedFilters: Array<Field>;
  value?: Maybe<Scalars['Float']>;
};

/** Choose multiple options from a set of options */
export type MultiSelectField = FieldI & {
  __typename?: 'MultiSelectField';
  /** A brief description of this field */
  description?: Maybe<Scalars['String']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** An information tooltip to be shown for the field */
  information?: Maybe<Scalars['String']>;
  /** A short, human-readable label describing this field */
  label?: Maybe<Scalars['String']>;
  localEdited?: Maybe<Scalars['Boolean']>;
  localSelections?: Maybe<Array<SelectOption>>;
  /** A set of options to select from */
  options?: Maybe<Array<SelectOption>>;
  /** The current/initial selection(s) */
  selections?: Maybe<Array<SelectOption>>;
  /** Explictly set the SelectField style as either 'dropdown' or 'tabs'.By default this field style is set to 'dropdown' when the options count exceeds a threshold (typically 16 items). */
  style?: Maybe<SelectStyle>;
};

/** TODO provide description */
export type Mutation = {
  __typename?: 'Mutation';
  /** TODO provide description */
  editTable: EditTableEvent;
  /** TODO provide description */
  invalidate: MutationResult;
  /** TODO provide description */
  login: LoginResultLoginError;
  /** TODO provide description */
  logout: LogoutResult;
  /** TODO provide description */
  run: MutationResult;
  /** TODO provide description */
  submitForm: SubmitFormEvent;
};


/** TODO provide description */
export type MutationEditTableArgs = {
  extraInputs?: InputMaybe<Array<QueryInput>>;
  input: EditTableInput;
};


/** TODO provide description */
export type MutationInvalidateArgs = {
  input: InvalidateInput;
};


/** TODO provide description */
export type MutationLoginArgs = {
  input: LoginInput;
};


/** TODO provide description */
export type MutationLogoutArgs = {
  input: LogoutInput;
};


/** TODO provide description */
export type MutationRunArgs = {
  input: RunInput;
};


/** TODO provide description */
export type MutationSubmitFormArgs = {
  extraInputs?: InputMaybe<Array<QueryInput>>;
  input: SubmitFormInput;
};

/** TODO provide description */
export type MutationResult = {
  __typename?: 'MutationResult';
  message: Scalars['String'];
};

export type NavLink = {
  __typename?: 'NavLink';
  appId?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['Int']>;
  setParams?: Maybe<Array<Param>>;
  toggleParams?: Maybe<Array<Param>>;
};

export type NumberColumn = AlignableColumnI & ColumnI & EditableColumnI & {
  __typename?: 'NumberColumn';
  /** align values to the start/center/end of the cell */
  align?: Maybe<ColumnAlignment>;
  /** Currency code for formatting column, cannot be used in conjuction with percent */
  currencyCode?: Maybe<Scalars['String']>;
  /** enables editing of this column's values */
  editable?: Maybe<Scalars['Boolean']>;
  /** Number of fraction digits to show */
  fractionDigits?: Maybe<Scalars['Int']>;
  /** hides the column from view */
  hide?: Maybe<Scalars['Boolean']>;
  /** highlight a column to make it stand out from the rest */
  highlight?: Maybe<Scalars['Boolean']>;
  /** the unique identifier for this column */
  id: Scalars['ID'];
  length: Scalars['Int'];
  localNumberValues: Array<Maybe<Scalars['Float']>>;
  /** ordered decimal (double-precision or null) row values contained within this column note: decimal values are subject to rounding issues (binary representation) */
  numberValues: Array<Maybe<Scalars['Float']>>;
  /** Percent format column, cannot be used in conjuction with currency */
  percent?: Maybe<Scalars['Boolean']>;
  /** pins the column to the start of the table (will not scroll) */
  pin?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the end of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinEnd?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the start of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinStart?: Maybe<Scalars['Boolean']>;
  /** Show Total for column */
  showTotals?: Maybe<Scalars['Boolean']>;
  /** the width of the column (generic units; try-and-see) */
  size?: Maybe<Scalars['Float']>;
  /** the column title (possibly multiple levels) to be shown */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NumberField = FieldI & {
  __typename?: 'NumberField';
  /** A brief description of this field */
  description?: Maybe<Scalars['String']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** An information tooltip to be shown for the field */
  information?: Maybe<Scalars['String']>;
  /** A short, human-readable label describing this field */
  label?: Maybe<Scalars['String']>;
  localEdited?: Maybe<Scalars['Boolean']>;
  /** The local/client-edited number value */
  localValue?: Maybe<Scalars['Float']>;
  /** The maximum value (inclusive) permitted */
  max?: Maybe<Scalars['Float']>;
  /** The minimum value (inclusive) permitted */
  min?: Maybe<Scalars['Float']>;
  /** The current numeric value */
  value?: Maybe<Scalars['Float']>;
};

/** Form control for a numeric-value range input (start, end)) */
export type NumberRangeField = FieldI & {
  __typename?: 'NumberRangeField';
  /** A brief description of this field */
  description?: Maybe<Scalars['String']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** An information tooltip to be shown for the field */
  information?: Maybe<Scalars['String']>;
  /** A short, human-readable label describing this field */
  label?: Maybe<Scalars['String']>;
  localEdited?: Maybe<Scalars['Boolean']>;
  /** The local/client-edited range value */
  localRange?: Maybe<Range>;
  /** The maximum value (inclusive) permitted */
  max?: Maybe<Scalars['Float']>;
  /** The minimum value (inclusive) permitted */
  min?: Maybe<Scalars['Float']>;
  /** The current range value */
  range?: Maybe<Range>;
};

export type Page = ComponentI & {
  __typename?: 'Page';
  background?: Maybe<Background>;
  banner?: Maybe<Banner>;
  filterIds?: Maybe<Array<Scalars['String']>>;
  filters: Array<Field>;
  hierarchicalFilterIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  link?: Maybe<ActionLinkNavLinkExternalLink>;
  meta: Meta;
  panels: Array<Panel>;
  primaryFilterIds?: Maybe<Array<Scalars['String']>>;
  quickLinks?: Maybe<QuickLinks>;
  title?: Maybe<Scalars['String']>;
};

export type PageLink = {
  __typename?: 'PageLink';
  label?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  pageKey?: Maybe<Scalars['String']>;
};

export type Panel = {
  __typename?: 'Panel';
  collapsible?: Maybe<Scalars['Boolean']>;
  tiles: Array<Tile>;
  title?: Maybe<Scalars['String']>;
};

export type Param = {
  __typename?: 'Param';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Platform = {
  __typename?: 'Platform';
  contextTray?: Maybe<Tray>;
  footer?: Maybe<Footer>;
  homePageId?: Maybe<Scalars['String']>;
  interactTray?: Maybe<Tray>;
  logoUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ProjectInfo = ComponentI & {
  __typename?: 'ProjectInfo';
  id: Scalars['ID'];
  meta: Meta;
  name?: Maybe<Scalars['String']>;
  organisation?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  app?: Maybe<App>;
  localContext: Context;
  localSession: Session;
  platform?: Maybe<Platform>;
  support?: Maybe<Support>;
};


export type QueryAppArgs = {
  extraInputs?: InputMaybe<Array<QueryInput>>;
  filters: Array<FieldInput>;
  id: Scalars['ID'];
  projectId?: InputMaybe<Scalars['Int']>;
};


export type QueryPlatformArgs = {
  appId: Scalars['ID'];
  projectId?: InputMaybe<Scalars['Int']>;
};


export type QuerySupportArgs = {
  appId: Scalars['ID'];
  extraInputs?: InputMaybe<Array<QueryInput>>;
  projectId?: InputMaybe<Scalars['Int']>;
};

export type QueryInput = {
  /** name of the variable */
  name?: InputMaybe<Scalars['String']>;
  /** value of the variable */
  value?: InputMaybe<Scalars['String']>;
};

export type QuickLink = {
  __typename?: 'QuickLink';
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  /** @deprecated replaced by `pageId` */
  link?: Maybe<PageLink>;
  navLink: NavLink;
  pageId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type QuickLinks = {
  __typename?: 'QuickLinks';
  links?: Maybe<Array<QuickLink>>;
  position?: Maybe<QuickLinksPosition>;
};

export enum QuickLinksPosition {
  Bottom = 'bottom',
  Side = 'side'
}

/** A range from low to high */
export type Range = {
  __typename?: 'Range';
  /** The high/upper value of the range */
  high?: Maybe<Scalars['Float']>;
  /** The low/lower value of the range */
  low?: Maybe<Scalars['Float']>;
};

/** A range from low to high */
export type RangeInput = {
  /** The high/upper value of the range */
  high?: InputMaybe<Scalars['Float']>;
  /** The low/lower value of the range */
  low?: InputMaybe<Scalars['Float']>;
};

export type Ref = {
  __typename?: 'Ref';
  id: Scalars['ID'];
  typename: Scalars['String'];
};

/** TODO provide description */
export type RunInput = {
  message: Scalars['String'];
};

/** Choose a single option from a set of options */
export type SelectField = FieldI & {
  __typename?: 'SelectField';
  /** A brief description of this field */
  description?: Maybe<Scalars['String']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** An information tooltip to be shown for the field */
  information?: Maybe<Scalars['String']>;
  /** A short, human-readable label describing this field */
  label?: Maybe<Scalars['String']>;
  localEdited?: Maybe<Scalars['Boolean']>;
  localSelection?: Maybe<SelectOption>;
  /** A set of options to select from */
  options?: Maybe<Array<SelectOption>>;
  /** The current/initial selection */
  selection?: Maybe<SelectOption>;
  /** Explictly set the SelectField style as either 'dropdown' or 'tabs'.By default this field style is set to 'dropdown' when the options count exceeds a threshold (typically 16 items). */
  style?: Maybe<SelectStyle>;
};

/** A SelectField or MultiSelectField option */
export type SelectOption = {
  __typename?: 'SelectOption';
  /** A human-readable label displayed to represent this option */
  label: Scalars['String'];
  /** The unique value of this option */
  value: Scalars['String'];
};

/** A SelectField or MultiSelectField option */
export type SelectOptionInput = {
  /** A human-readable label displayed to represent this option */
  label: Scalars['String'];
  /** The unique value of this option */
  value: Scalars['String'];
};

export enum SelectStyle {
  Dropdown = 'dropdown',
  Tabs = 'tabs'
}

export type Session = {
  __typename?: 'Session';
  expiresAt?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
};

/** Submit Form result payload */
export type SubmitFormEvent = {
  __typename?: 'SubmitFormEvent';
  /** content which should be refreshed after processing this form submission */
  contentToRefresh?: Maybe<ContentIds>;
  /** message for user after form submission event completion */
  message?: Maybe<Scalars['String']>;
  /** indicates whether form submission was processed successfully or not */
  ok: Scalars['Boolean'];
};

/** Inputs relating to a form submission */
export type SubmitFormInput = {
  /** the unique identifier of the active app */
  appId: Scalars['ID'];
  /** form fields and their values submitted for processing */
  fields: Array<FieldInput>;
  filters: Array<FieldInput>;
  /** the unique identifier of form being submitted */
  id: Scalars['ID'];
  /** the unique identifier of the active project */
  projectId?: InputMaybe<Scalars['Int']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  activeUsers: Array<ActiveUser>;
  count: Scalars['Int'];
  messages: Scalars['String'];
  staleNodes: Array<Ref>;
};


export type SubscriptionCountArgs = {
  target?: Scalars['Int'];
};

export type Support = ComponentI & {
  __typename?: 'Support';
  id: Scalars['ID'];
  meta: Meta;
  pageById?: Maybe<Page>;
  supportMenu: SupportMenu;
};


export type SupportPageByIdArgs = {
  id: Scalars['ID'];
};

export type SupportMenu = {
  __typename?: 'SupportMenu';
  items: Array<SupportMenuItem>;
};

export type SupportMenuItem = {
  __typename?: 'SupportMenuItem';
  link: NavLink;
};

export type Table = ComponentI & ContentI & {
  __typename?: 'Table';
  /** a short caption describing the purpose of this form */
  caption?: Maybe<Scalars['String']>;
  columnCount: Scalars['Int'];
  /** the column order to be drawn */
  columnOrder?: Maybe<Array<Scalars['String']>>;
  /** the set of columns with variable types */
  columns?: Maybe<Array<Column>>;
  dataRowCount: Scalars['Int'];
  /** a description of the table's content */
  description?: Maybe<Scalars['String']>;
  footerRowCount: Scalars['Int'];
  id: Scalars['ID'];
  meta: Meta;
  relatedFilters: Array<Field>;
  rowCount: Scalars['Int'];
  /** the text to display on the edit table / submit button */
  submitButtonText?: Maybe<Scalars['String']>;
  /** a short title capturing the essence of this table's content */
  title?: Maybe<Scalars['String']>;
  titleRowCount: Scalars['Int'];
  /** transposes the table along the diagonal (i.e. columns become rows) */
  transpose?: Maybe<Scalars['Boolean']>;
};

export type TemplateAction = ComponentI & ContentI & {
  __typename?: 'TemplateAction';
  contentId: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  meta: Meta;
  relatedFilters: Array<Field>;
};

export type TextColumn = AlignableColumnI & ColumnI & EditableColumnI & {
  __typename?: 'TextColumn';
  /** align values to the start/center/end of the cell */
  align?: Maybe<ColumnAlignment>;
  /** enables editing of this column's values */
  editable?: Maybe<Scalars['Boolean']>;
  /** hides the column from view */
  hide?: Maybe<Scalars['Boolean']>;
  /** highlight a column to make it stand out from the rest */
  highlight?: Maybe<Scalars['Boolean']>;
  /** the unique identifier for this column */
  id: Scalars['ID'];
  /** the text values are storage blobs columns */
  isStorageBlob?: Maybe<Scalars['Boolean']>;
  length: Scalars['Int'];
  localTextValues: Array<Maybe<Scalars['String']>>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** pins the column to the start of the table (will not scroll) */
  pin?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the end of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinEnd?: Maybe<Scalars['Boolean']>;
  /**
   * pins the column to the start of the table (will not scroll)
   * @deprecated use `pin` and `columnOrder` instead
   */
  pinStart?: Maybe<Scalars['Boolean']>;
  /** Show Total for column */
  showTotals?: Maybe<Scalars['Boolean']>;
  /** the width of the column (generic units; try-and-see) */
  size?: Maybe<Scalars['Float']>;
  /** ordered text (string or null) row values contained within this column */
  textValues: Array<Maybe<Scalars['String']>>;
  /** the column title (possibly multiple levels) to be shown */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TextField = FieldI & {
  __typename?: 'TextField';
  /** A brief description of this field */
  description?: Maybe<Scalars['String']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** An information tooltip to be shown for the field */
  information?: Maybe<Scalars['String']>;
  /** A short, human-readable label describing this field */
  label?: Maybe<Scalars['String']>;
  localEdited?: Maybe<Scalars['Boolean']>;
  /** The local/client-edited text value */
  localText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Tile = {
  __typename?: 'Tile';
  allowContentCopy?: Maybe<Scalars['Boolean']>;
  allowContentDownload?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
  collapsible?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Content>;
  filters: Array<Field>;
  shallowContent?: Maybe<Content>;
  title?: Maybe<Scalars['String']>;
};

export type Tray = {
  __typename?: 'Tray';
  sections: Array<TraySection>;
};

export type TrayItem = {
  __typename?: 'TrayItem';
  icon?: Maybe<Scalars['String']>;
  iconScale?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  link?: Maybe<ActionLinkNavLinkExternalLink>;
  shape?: Maybe<TrayItemShape>;
  theme?: Maybe<TrayItemTheme>;
  toggleIcon?: Maybe<Scalars['String']>;
  tooltip?: Maybe<Scalars['String']>;
};

export enum TrayItemShape {
  Circle = 'circle',
  Rounded = 'rounded',
  Square = 'square'
}

export enum TrayItemTheme {
  Default = 'default',
  GreyLight = 'grey_light',
  GreyLighter = 'grey_lighter',
  Orange = 'orange',
  Rose = 'rose',
  Tangerine = 'tangerine',
  Yellow = 'yellow'
}

export type TraySection = {
  __typename?: 'TraySection';
  items: Array<TrayItem>;
  title?: Maybe<Scalars['String']>;
};

/** Form control for an upload button */
export type UploadFileField = FieldI & {
  __typename?: 'UploadFileField';
  /** A brief description of this field */
  description?: Maybe<Scalars['String']>;
  /** entityId for upload */
  entityId?: Maybe<Scalars['String']>;
  /** the unique ID of this field */
  id: Scalars['ID'];
  /** An information tooltip to be shown for the field */
  information?: Maybe<Scalars['String']>;
  /** A short, human-readable label describing this field */
  label?: Maybe<Scalars['String']>;
  /** Always false */
  localEdited?: Maybe<Scalars['Boolean']>;
  /** appId override for uploading from main */
  overrideAppId?: Maybe<Scalars['String']>;
  /** templateId for upload */
  templateId?: Maybe<Scalars['String']>;
};

export type VegaLiteChart = ComponentI & ContentI & {
  __typename?: 'VegaLiteChart';
  config?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  meta: Meta;
  relatedFilters: Array<Field>;
  spec?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ActionLinkKeySpecifier = ('action' | 'label' | ActionLinkKeySpecifier)[];
export type ActionLinkFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ActiveUserKeySpecifier = ('name' | ActiveUserKeySpecifier)[];
export type ActiveUserFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ActivityLogKeySpecifier = ('blurb' | 'completedBy' | 'date' | 'filename' | 'id' | 'meta' | 'relatedFilters' | 'state' | 'url' | ActivityLogKeySpecifier)[];
export type ActivityLogFieldPolicy = {
	blurb?: FieldPolicy<any> | FieldReadFunction<any>,
	completedBy?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	filename?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	relatedFilters?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AlignableColumnIKeySpecifier = ('align' | AlignableColumnIKeySpecifier)[];
export type AlignableColumnIFieldPolicy = {
	align?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AppKeySpecifier = ('appMenu' | 'application' | 'chartById' | 'charts' | 'chartsByIds' | 'description' | 'filterById' | 'filters' | 'filtersByIds' | 'formById' | 'forms' | 'formsByIds' | 'hierarchicalFiltersByIds' | 'homePageId' | 'id' | 'meta' | 'metricById' | 'metrics' | 'metricsByIds' | 'pageById' | 'pages' | 'pagesByIds' | 'project' | 'slug' | 'status' | 'tableById' | 'tables' | 'tablesByIds' | 'title' | AppKeySpecifier)[];
export type AppFieldPolicy = {
	appMenu?: FieldPolicy<any> | FieldReadFunction<any>,
	application?: FieldPolicy<any> | FieldReadFunction<any>,
	chartById?: FieldPolicy<any> | FieldReadFunction<any>,
	charts?: FieldPolicy<any> | FieldReadFunction<any>,
	chartsByIds?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	filterById?: FieldPolicy<any> | FieldReadFunction<any>,
	filters?: FieldPolicy<any> | FieldReadFunction<any>,
	filtersByIds?: FieldPolicy<any> | FieldReadFunction<any>,
	formById?: FieldPolicy<any> | FieldReadFunction<any>,
	forms?: FieldPolicy<any> | FieldReadFunction<any>,
	formsByIds?: FieldPolicy<any> | FieldReadFunction<any>,
	hierarchicalFiltersByIds?: FieldPolicy<any> | FieldReadFunction<any>,
	homePageId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	metricById?: FieldPolicy<any> | FieldReadFunction<any>,
	metrics?: FieldPolicy<any> | FieldReadFunction<any>,
	metricsByIds?: FieldPolicy<any> | FieldReadFunction<any>,
	pageById?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	pagesByIds?: FieldPolicy<any> | FieldReadFunction<any>,
	project?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	tableById?: FieldPolicy<any> | FieldReadFunction<any>,
	tables?: FieldPolicy<any> | FieldReadFunction<any>,
	tablesByIds?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AppInfoKeySpecifier = ('appId' | 'description' | 'id' | 'meta' | 'status' | 'title' | AppInfoKeySpecifier)[];
export type AppInfoFieldPolicy = {
	appId?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AppMenuKeySpecifier = ('items' | AppMenuKeySpecifier)[];
export type AppMenuFieldPolicy = {
	items?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AppMenuItemKeySpecifier = ('children' | 'link' | AppMenuItemKeySpecifier)[];
export type AppMenuItemFieldPolicy = {
	children?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BackgroundKeySpecifier = ('colour' | 'imageUrl' | BackgroundKeySpecifier)[];
export type BackgroundFieldPolicy = {
	colour?: FieldPolicy<any> | FieldReadFunction<any>,
	imageUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BannerKeySpecifier = ('subtitle' | 'title' | BannerKeySpecifier)[];
export type BannerFieldPolicy = {
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BooleanColumnKeySpecifier = ('align' | 'boolValues' | 'editable' | 'hide' | 'highlight' | 'id' | 'length' | 'localBoolValues' | 'pin' | 'pinEnd' | 'pinStart' | 'showTotals' | 'size' | 'title' | BooleanColumnKeySpecifier)[];
export type BooleanColumnFieldPolicy = {
	align?: FieldPolicy<any> | FieldReadFunction<any>,
	boolValues?: FieldPolicy<any> | FieldReadFunction<any>,
	editable?: FieldPolicy<any> | FieldReadFunction<any>,
	hide?: FieldPolicy<any> | FieldReadFunction<any>,
	highlight?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	length?: FieldPolicy<any> | FieldReadFunction<any>,
	localBoolValues?: FieldPolicy<any> | FieldReadFunction<any>,
	pin?: FieldPolicy<any> | FieldReadFunction<any>,
	pinEnd?: FieldPolicy<any> | FieldReadFunction<any>,
	pinStart?: FieldPolicy<any> | FieldReadFunction<any>,
	showTotals?: FieldPolicy<any> | FieldReadFunction<any>,
	size?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BooleanFieldKeySpecifier = ('description' | 'id' | 'information' | 'label' | 'localEdited' | 'localState' | 'state' | BooleanFieldKeySpecifier)[];
export type BooleanFieldFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	information?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	localEdited?: FieldPolicy<any> | FieldReadFunction<any>,
	localState?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ChartKeySpecifier = ('config' | 'id' | 'label' | 'meta' | 'relatedFilters' | 'spec' | 'title' | ChartKeySpecifier)[];
export type ChartFieldPolicy = {
	config?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	relatedFilters?: FieldPolicy<any> | FieldReadFunction<any>,
	spec?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ColumnFormulaKeySpecifier = ('columnIds' | 'hide' | 'highlight' | 'id' | 'lowerBound' | 'operation' | 'pin' | 'pinEnd' | 'pinStart' | 'showTotals' | 'size' | 'title' | 'upperBound' | ColumnFormulaKeySpecifier)[];
export type ColumnFormulaFieldPolicy = {
	columnIds?: FieldPolicy<any> | FieldReadFunction<any>,
	hide?: FieldPolicy<any> | FieldReadFunction<any>,
	highlight?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	lowerBound?: FieldPolicy<any> | FieldReadFunction<any>,
	operation?: FieldPolicy<any> | FieldReadFunction<any>,
	pin?: FieldPolicy<any> | FieldReadFunction<any>,
	pinEnd?: FieldPolicy<any> | FieldReadFunction<any>,
	pinStart?: FieldPolicy<any> | FieldReadFunction<any>,
	showTotals?: FieldPolicy<any> | FieldReadFunction<any>,
	size?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	upperBound?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ColumnIKeySpecifier = ('hide' | 'highlight' | 'id' | 'pin' | 'pinEnd' | 'pinStart' | 'showTotals' | 'size' | 'title' | ColumnIKeySpecifier)[];
export type ColumnIFieldPolicy = {
	hide?: FieldPolicy<any> | FieldReadFunction<any>,
	highlight?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	pin?: FieldPolicy<any> | FieldReadFunction<any>,
	pinEnd?: FieldPolicy<any> | FieldReadFunction<any>,
	pinStart?: FieldPolicy<any> | FieldReadFunction<any>,
	showTotals?: FieldPolicy<any> | FieldReadFunction<any>,
	size?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ComponentIKeySpecifier = ('id' | 'meta' | ComponentIKeySpecifier)[];
export type ComponentIFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContentIKeySpecifier = ('relatedFilters' | ContentIKeySpecifier)[];
export type ContentIFieldPolicy = {
	relatedFilters?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContentIdsKeySpecifier = ('apps' | 'charts' | 'forms' | 'metrics' | 'pages' | 'tables' | ContentIdsKeySpecifier)[];
export type ContentIdsFieldPolicy = {
	apps?: FieldPolicy<any> | FieldReadFunction<any>,
	charts?: FieldPolicy<any> | FieldReadFunction<any>,
	forms?: FieldPolicy<any> | FieldReadFunction<any>,
	metrics?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	tables?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContextKeySpecifier = ('appId' | 'pageId' | 'projectId' | 'supportPageId' | ContextKeySpecifier)[];
export type ContextFieldPolicy = {
	appId?: FieldPolicy<any> | FieldReadFunction<any>,
	pageId?: FieldPolicy<any> | FieldReadFunction<any>,
	projectId?: FieldPolicy<any> | FieldReadFunction<any>,
	supportPageId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DataTemplateKeySpecifier = ('actions' | 'activityLog' | 'entityId' | 'id' | 'meta' | 'relatedFilters' | 'template' | 'templateFilename' | 'templateId' | 'templateUrl' | DataTemplateKeySpecifier)[];
export type DataTemplateFieldPolicy = {
	actions?: FieldPolicy<any> | FieldReadFunction<any>,
	activityLog?: FieldPolicy<any> | FieldReadFunction<any>,
	entityId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	relatedFilters?: FieldPolicy<any> | FieldReadFunction<any>,
	template?: FieldPolicy<any> | FieldReadFunction<any>,
	templateFilename?: FieldPolicy<any> | FieldReadFunction<any>,
	templateId?: FieldPolicy<any> | FieldReadFunction<any>,
	templateUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DataTemplateActionKeySpecifier = ('downloadField' | 'id' | 'meta' | 'relatedFilters' | 'templateAction' | 'uploadField' | DataTemplateActionKeySpecifier)[];
export type DataTemplateActionFieldPolicy = {
	downloadField?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	relatedFilters?: FieldPolicy<any> | FieldReadFunction<any>,
	templateAction?: FieldPolicy<any> | FieldReadFunction<any>,
	uploadField?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DateColumnKeySpecifier = ('align' | 'dateValues' | 'editable' | 'hide' | 'highlight' | 'id' | 'length' | 'localDateValues' | 'pin' | 'pinEnd' | 'pinStart' | 'showTotals' | 'size' | 'title' | DateColumnKeySpecifier)[];
export type DateColumnFieldPolicy = {
	align?: FieldPolicy<any> | FieldReadFunction<any>,
	dateValues?: FieldPolicy<any> | FieldReadFunction<any>,
	editable?: FieldPolicy<any> | FieldReadFunction<any>,
	hide?: FieldPolicy<any> | FieldReadFunction<any>,
	highlight?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	length?: FieldPolicy<any> | FieldReadFunction<any>,
	localDateValues?: FieldPolicy<any> | FieldReadFunction<any>,
	pin?: FieldPolicy<any> | FieldReadFunction<any>,
	pinEnd?: FieldPolicy<any> | FieldReadFunction<any>,
	pinStart?: FieldPolicy<any> | FieldReadFunction<any>,
	showTotals?: FieldPolicy<any> | FieldReadFunction<any>,
	size?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DateFieldKeySpecifier = ('date' | 'description' | 'id' | 'information' | 'label' | 'localDate' | 'localEdited' | DateFieldKeySpecifier)[];
export type DateFieldFieldPolicy = {
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	information?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	localDate?: FieldPolicy<any> | FieldReadFunction<any>,
	localEdited?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DateTimeColumnKeySpecifier = ('align' | 'dateTimeValues' | 'editable' | 'hide' | 'highlight' | 'id' | 'length' | 'pin' | 'pinEnd' | 'pinStart' | 'showTotals' | 'size' | 'title' | DateTimeColumnKeySpecifier)[];
export type DateTimeColumnFieldPolicy = {
	align?: FieldPolicy<any> | FieldReadFunction<any>,
	dateTimeValues?: FieldPolicy<any> | FieldReadFunction<any>,
	editable?: FieldPolicy<any> | FieldReadFunction<any>,
	hide?: FieldPolicy<any> | FieldReadFunction<any>,
	highlight?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	length?: FieldPolicy<any> | FieldReadFunction<any>,
	pin?: FieldPolicy<any> | FieldReadFunction<any>,
	pinEnd?: FieldPolicy<any> | FieldReadFunction<any>,
	pinStart?: FieldPolicy<any> | FieldReadFunction<any>,
	showTotals?: FieldPolicy<any> | FieldReadFunction<any>,
	size?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DateTimeFieldKeySpecifier = ('dateTime' | 'description' | 'id' | 'information' | 'label' | 'localDateTime' | 'localEdited' | DateTimeFieldKeySpecifier)[];
export type DateTimeFieldFieldPolicy = {
	dateTime?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	information?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	localDateTime?: FieldPolicy<any> | FieldReadFunction<any>,
	localEdited?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DownloadFileFieldKeySpecifier = ('description' | 'id' | 'information' | 'label' | 'localEdited' | 'selection' | DownloadFileFieldKeySpecifier)[];
export type DownloadFileFieldFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	information?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	localEdited?: FieldPolicy<any> | FieldReadFunction<any>,
	selection?: FieldPolicy<any> | FieldReadFunction<any>
};
export type EditTableEventKeySpecifier = ('contentToRefresh' | 'message' | 'ok' | EditTableEventKeySpecifier)[];
export type EditTableEventFieldPolicy = {
	contentToRefresh?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	ok?: FieldPolicy<any> | FieldReadFunction<any>
};
export type EditableColumnIKeySpecifier = ('editable' | EditableColumnIKeySpecifier)[];
export type EditableColumnIFieldPolicy = {
	editable?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExternalLinkKeySpecifier = ('externalLink' | 'label' | ExternalLinkKeySpecifier)[];
export type ExternalLinkFieldPolicy = {
	externalLink?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FieldIKeySpecifier = ('description' | 'id' | 'information' | 'label' | 'localEdited' | FieldIKeySpecifier)[];
export type FieldIFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	information?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	localEdited?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FooterKeySpecifier = ('links' | 'text' | FooterKeySpecifier)[];
export type FooterFieldPolicy = {
	links?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FormKeySpecifier = ('caption' | 'denseFormat' | 'description' | 'fields' | 'id' | 'meta' | 'relatedFilters' | 'showActions' | 'submitButtonText' | 'title' | FormKeySpecifier)[];
export type FormFieldPolicy = {
	caption?: FieldPolicy<any> | FieldReadFunction<any>,
	denseFormat?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	fields?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	relatedFilters?: FieldPolicy<any> | FieldReadFunction<any>,
	showActions?: FieldPolicy<any> | FieldReadFunction<any>,
	submitButtonText?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FormulaColumnKeySpecifier = ('align' | 'formula' | 'hide' | 'highlight' | 'id' | 'pin' | 'pinEnd' | 'pinStart' | 'showTotals' | 'size' | 'title' | FormulaColumnKeySpecifier)[];
export type FormulaColumnFieldPolicy = {
	align?: FieldPolicy<any> | FieldReadFunction<any>,
	formula?: FieldPolicy<any> | FieldReadFunction<any>,
	hide?: FieldPolicy<any> | FieldReadFunction<any>,
	highlight?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	pin?: FieldPolicy<any> | FieldReadFunction<any>,
	pinEnd?: FieldPolicy<any> | FieldReadFunction<any>,
	pinStart?: FieldPolicy<any> | FieldReadFunction<any>,
	showTotals?: FieldPolicy<any> | FieldReadFunction<any>,
	size?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HierarchicalFilterKeySpecifier = ('description' | 'id' | 'label' | 'leafSelections' | 'meta' | 'multiselectOnLeaf' | 'options' | 'optionsLabel' | 'selection' | HierarchicalFilterKeySpecifier)[];
export type HierarchicalFilterFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	leafSelections?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	multiselectOnLeaf?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	optionsLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	selection?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HierarchicalOptionKeySpecifier = ('label' | 'options' | 'optionsLabel' | 'value' | HierarchicalOptionKeySpecifier)[];
export type HierarchicalOptionFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	optionsLabel?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LoginErrorKeySpecifier = ('detail' | 'message' | LoginErrorKeySpecifier)[];
export type LoginErrorFieldPolicy = {
	detail?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LoginResultKeySpecifier = ('platform' | 'token' | LoginResultKeySpecifier)[];
export type LoginResultFieldPolicy = {
	platform?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LogoutResultKeySpecifier = ('redirectUri' | LogoutResultKeySpecifier)[];
export type LogoutResultFieldPolicy = {
	redirectUri?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MetaKeySpecifier = ('allowPublicAccess' | 'appId' | 'description' | 'experimental' | 'label' | 'projectId' | 'requireAdmin' | 'requireDeveloper' | 'requireInternal' | 'requireSuperuser' | 'useFilters' | 'useProject' | 'useUser' | MetaKeySpecifier)[];
export type MetaFieldPolicy = {
	allowPublicAccess?: FieldPolicy<any> | FieldReadFunction<any>,
	appId?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	experimental?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	projectId?: FieldPolicy<any> | FieldReadFunction<any>,
	requireAdmin?: FieldPolicy<any> | FieldReadFunction<any>,
	requireDeveloper?: FieldPolicy<any> | FieldReadFunction<any>,
	requireInternal?: FieldPolicy<any> | FieldReadFunction<any>,
	requireSuperuser?: FieldPolicy<any> | FieldReadFunction<any>,
	useFilters?: FieldPolicy<any> | FieldReadFunction<any>,
	useProject?: FieldPolicy<any> | FieldReadFunction<any>,
	useUser?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MetricKeySpecifier = ('id' | 'label' | 'meta' | 'relatedFilters' | 'value' | MetricKeySpecifier)[];
export type MetricFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	relatedFilters?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MultiSelectFieldKeySpecifier = ('description' | 'id' | 'information' | 'label' | 'localEdited' | 'localSelections' | 'options' | 'selections' | 'style' | MultiSelectFieldKeySpecifier)[];
export type MultiSelectFieldFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	information?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	localEdited?: FieldPolicy<any> | FieldReadFunction<any>,
	localSelections?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	selections?: FieldPolicy<any> | FieldReadFunction<any>,
	style?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('editTable' | 'invalidate' | 'login' | 'logout' | 'run' | 'submitForm' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	editTable?: FieldPolicy<any> | FieldReadFunction<any>,
	invalidate?: FieldPolicy<any> | FieldReadFunction<any>,
	login?: FieldPolicy<any> | FieldReadFunction<any>,
	logout?: FieldPolicy<any> | FieldReadFunction<any>,
	run?: FieldPolicy<any> | FieldReadFunction<any>,
	submitForm?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationResultKeySpecifier = ('message' | MutationResultKeySpecifier)[];
export type MutationResultFieldPolicy = {
	message?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NavLinkKeySpecifier = ('appId' | 'label' | 'pageId' | 'projectId' | 'setParams' | 'toggleParams' | NavLinkKeySpecifier)[];
export type NavLinkFieldPolicy = {
	appId?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	pageId?: FieldPolicy<any> | FieldReadFunction<any>,
	projectId?: FieldPolicy<any> | FieldReadFunction<any>,
	setParams?: FieldPolicy<any> | FieldReadFunction<any>,
	toggleParams?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NumberColumnKeySpecifier = ('align' | 'currencyCode' | 'editable' | 'fractionDigits' | 'hide' | 'highlight' | 'id' | 'length' | 'localNumberValues' | 'numberValues' | 'percent' | 'pin' | 'pinEnd' | 'pinStart' | 'showTotals' | 'size' | 'title' | NumberColumnKeySpecifier)[];
export type NumberColumnFieldPolicy = {
	align?: FieldPolicy<any> | FieldReadFunction<any>,
	currencyCode?: FieldPolicy<any> | FieldReadFunction<any>,
	editable?: FieldPolicy<any> | FieldReadFunction<any>,
	fractionDigits?: FieldPolicy<any> | FieldReadFunction<any>,
	hide?: FieldPolicy<any> | FieldReadFunction<any>,
	highlight?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	length?: FieldPolicy<any> | FieldReadFunction<any>,
	localNumberValues?: FieldPolicy<any> | FieldReadFunction<any>,
	numberValues?: FieldPolicy<any> | FieldReadFunction<any>,
	percent?: FieldPolicy<any> | FieldReadFunction<any>,
	pin?: FieldPolicy<any> | FieldReadFunction<any>,
	pinEnd?: FieldPolicy<any> | FieldReadFunction<any>,
	pinStart?: FieldPolicy<any> | FieldReadFunction<any>,
	showTotals?: FieldPolicy<any> | FieldReadFunction<any>,
	size?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NumberFieldKeySpecifier = ('description' | 'id' | 'information' | 'label' | 'localEdited' | 'localValue' | 'max' | 'min' | 'value' | NumberFieldKeySpecifier)[];
export type NumberFieldFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	information?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	localEdited?: FieldPolicy<any> | FieldReadFunction<any>,
	localValue?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NumberRangeFieldKeySpecifier = ('description' | 'id' | 'information' | 'label' | 'localEdited' | 'localRange' | 'max' | 'min' | 'range' | NumberRangeFieldKeySpecifier)[];
export type NumberRangeFieldFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	information?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	localEdited?: FieldPolicy<any> | FieldReadFunction<any>,
	localRange?: FieldPolicy<any> | FieldReadFunction<any>,
	max?: FieldPolicy<any> | FieldReadFunction<any>,
	min?: FieldPolicy<any> | FieldReadFunction<any>,
	range?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageKeySpecifier = ('background' | 'banner' | 'filterIds' | 'filters' | 'hierarchicalFilterIds' | 'id' | 'link' | 'meta' | 'panels' | 'primaryFilterIds' | 'quickLinks' | 'title' | PageKeySpecifier)[];
export type PageFieldPolicy = {
	background?: FieldPolicy<any> | FieldReadFunction<any>,
	banner?: FieldPolicy<any> | FieldReadFunction<any>,
	filterIds?: FieldPolicy<any> | FieldReadFunction<any>,
	filters?: FieldPolicy<any> | FieldReadFunction<any>,
	hierarchicalFilterIds?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	panels?: FieldPolicy<any> | FieldReadFunction<any>,
	primaryFilterIds?: FieldPolicy<any> | FieldReadFunction<any>,
	quickLinks?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageLinkKeySpecifier = ('label' | 'pageId' | 'pageKey' | PageLinkKeySpecifier)[];
export type PageLinkFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	pageId?: FieldPolicy<any> | FieldReadFunction<any>,
	pageKey?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PanelKeySpecifier = ('collapsible' | 'tiles' | 'title' | PanelKeySpecifier)[];
export type PanelFieldPolicy = {
	collapsible?: FieldPolicy<any> | FieldReadFunction<any>,
	tiles?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ParamKeySpecifier = ('key' | 'value' | ParamKeySpecifier)[];
export type ParamFieldPolicy = {
	key?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PlatformKeySpecifier = ('contextTray' | 'footer' | 'homePageId' | 'interactTray' | 'logoUrl' | 'title' | PlatformKeySpecifier)[];
export type PlatformFieldPolicy = {
	contextTray?: FieldPolicy<any> | FieldReadFunction<any>,
	footer?: FieldPolicy<any> | FieldReadFunction<any>,
	homePageId?: FieldPolicy<any> | FieldReadFunction<any>,
	interactTray?: FieldPolicy<any> | FieldReadFunction<any>,
	logoUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectInfoKeySpecifier = ('id' | 'meta' | 'name' | 'organisation' | ProjectInfoKeySpecifier)[];
export type ProjectInfoFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	organisation?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('app' | 'localContext' | 'localSession' | 'platform' | 'support' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	app?: FieldPolicy<any> | FieldReadFunction<any>,
	localContext?: FieldPolicy<any> | FieldReadFunction<any>,
	localSession?: FieldPolicy<any> | FieldReadFunction<any>,
	platform?: FieldPolicy<any> | FieldReadFunction<any>,
	support?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QuickLinkKeySpecifier = ('description' | 'imageUrl' | 'link' | 'navLink' | 'pageId' | 'title' | QuickLinkKeySpecifier)[];
export type QuickLinkFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	imageUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	navLink?: FieldPolicy<any> | FieldReadFunction<any>,
	pageId?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QuickLinksKeySpecifier = ('links' | 'position' | QuickLinksKeySpecifier)[];
export type QuickLinksFieldPolicy = {
	links?: FieldPolicy<any> | FieldReadFunction<any>,
	position?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RangeKeySpecifier = ('high' | 'low' | RangeKeySpecifier)[];
export type RangeFieldPolicy = {
	high?: FieldPolicy<any> | FieldReadFunction<any>,
	low?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RefKeySpecifier = ('id' | 'typename' | RefKeySpecifier)[];
export type RefFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	typename?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SelectFieldKeySpecifier = ('description' | 'id' | 'information' | 'label' | 'localEdited' | 'localSelection' | 'options' | 'selection' | 'style' | SelectFieldKeySpecifier)[];
export type SelectFieldFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	information?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	localEdited?: FieldPolicy<any> | FieldReadFunction<any>,
	localSelection?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	selection?: FieldPolicy<any> | FieldReadFunction<any>,
	style?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SelectOptionKeySpecifier = ('label' | 'value' | SelectOptionKeySpecifier)[];
export type SelectOptionFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SessionKeySpecifier = ('expiresAt' | 'token' | SessionKeySpecifier)[];
export type SessionFieldPolicy = {
	expiresAt?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubmitFormEventKeySpecifier = ('contentToRefresh' | 'message' | 'ok' | SubmitFormEventKeySpecifier)[];
export type SubmitFormEventFieldPolicy = {
	contentToRefresh?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	ok?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubscriptionKeySpecifier = ('activeUsers' | 'count' | 'messages' | 'staleNodes' | SubscriptionKeySpecifier)[];
export type SubscriptionFieldPolicy = {
	activeUsers?: FieldPolicy<any> | FieldReadFunction<any>,
	count?: FieldPolicy<any> | FieldReadFunction<any>,
	messages?: FieldPolicy<any> | FieldReadFunction<any>,
	staleNodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SupportKeySpecifier = ('id' | 'meta' | 'pageById' | 'supportMenu' | SupportKeySpecifier)[];
export type SupportFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	pageById?: FieldPolicy<any> | FieldReadFunction<any>,
	supportMenu?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SupportMenuKeySpecifier = ('items' | SupportMenuKeySpecifier)[];
export type SupportMenuFieldPolicy = {
	items?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SupportMenuItemKeySpecifier = ('link' | SupportMenuItemKeySpecifier)[];
export type SupportMenuItemFieldPolicy = {
	link?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TableKeySpecifier = ('caption' | 'columnCount' | 'columnOrder' | 'columns' | 'dataRowCount' | 'description' | 'footerRowCount' | 'id' | 'meta' | 'relatedFilters' | 'rowCount' | 'submitButtonText' | 'title' | 'titleRowCount' | 'transpose' | TableKeySpecifier)[];
export type TableFieldPolicy = {
	caption?: FieldPolicy<any> | FieldReadFunction<any>,
	columnCount?: FieldPolicy<any> | FieldReadFunction<any>,
	columnOrder?: FieldPolicy<any> | FieldReadFunction<any>,
	columns?: FieldPolicy<any> | FieldReadFunction<any>,
	dataRowCount?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	footerRowCount?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	relatedFilters?: FieldPolicy<any> | FieldReadFunction<any>,
	rowCount?: FieldPolicy<any> | FieldReadFunction<any>,
	submitButtonText?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	titleRowCount?: FieldPolicy<any> | FieldReadFunction<any>,
	transpose?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TemplateActionKeySpecifier = ('contentId' | 'id' | 'label' | 'meta' | 'relatedFilters' | TemplateActionKeySpecifier)[];
export type TemplateActionFieldPolicy = {
	contentId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	relatedFilters?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TextColumnKeySpecifier = ('align' | 'editable' | 'hide' | 'highlight' | 'id' | 'isStorageBlob' | 'length' | 'localTextValues' | 'options' | 'pin' | 'pinEnd' | 'pinStart' | 'showTotals' | 'size' | 'textValues' | 'title' | TextColumnKeySpecifier)[];
export type TextColumnFieldPolicy = {
	align?: FieldPolicy<any> | FieldReadFunction<any>,
	editable?: FieldPolicy<any> | FieldReadFunction<any>,
	hide?: FieldPolicy<any> | FieldReadFunction<any>,
	highlight?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isStorageBlob?: FieldPolicy<any> | FieldReadFunction<any>,
	length?: FieldPolicy<any> | FieldReadFunction<any>,
	localTextValues?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	pin?: FieldPolicy<any> | FieldReadFunction<any>,
	pinEnd?: FieldPolicy<any> | FieldReadFunction<any>,
	pinStart?: FieldPolicy<any> | FieldReadFunction<any>,
	showTotals?: FieldPolicy<any> | FieldReadFunction<any>,
	size?: FieldPolicy<any> | FieldReadFunction<any>,
	textValues?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TextFieldKeySpecifier = ('description' | 'id' | 'information' | 'label' | 'localEdited' | 'localText' | 'text' | TextFieldKeySpecifier)[];
export type TextFieldFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	information?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	localEdited?: FieldPolicy<any> | FieldReadFunction<any>,
	localText?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TileKeySpecifier = ('allowContentCopy' | 'allowContentDownload' | 'caption' | 'collapsible' | 'content' | 'filters' | 'shallowContent' | 'title' | TileKeySpecifier)[];
export type TileFieldPolicy = {
	allowContentCopy?: FieldPolicy<any> | FieldReadFunction<any>,
	allowContentDownload?: FieldPolicy<any> | FieldReadFunction<any>,
	caption?: FieldPolicy<any> | FieldReadFunction<any>,
	collapsible?: FieldPolicy<any> | FieldReadFunction<any>,
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	filters?: FieldPolicy<any> | FieldReadFunction<any>,
	shallowContent?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TrayKeySpecifier = ('sections' | TrayKeySpecifier)[];
export type TrayFieldPolicy = {
	sections?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TrayItemKeySpecifier = ('icon' | 'iconScale' | 'label' | 'link' | 'shape' | 'theme' | 'toggleIcon' | 'tooltip' | TrayItemKeySpecifier)[];
export type TrayItemFieldPolicy = {
	icon?: FieldPolicy<any> | FieldReadFunction<any>,
	iconScale?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	shape?: FieldPolicy<any> | FieldReadFunction<any>,
	theme?: FieldPolicy<any> | FieldReadFunction<any>,
	toggleIcon?: FieldPolicy<any> | FieldReadFunction<any>,
	tooltip?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TraySectionKeySpecifier = ('items' | 'title' | TraySectionKeySpecifier)[];
export type TraySectionFieldPolicy = {
	items?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UploadFileFieldKeySpecifier = ('description' | 'entityId' | 'id' | 'information' | 'label' | 'localEdited' | 'overrideAppId' | 'templateId' | UploadFileFieldKeySpecifier)[];
export type UploadFileFieldFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	entityId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	information?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	localEdited?: FieldPolicy<any> | FieldReadFunction<any>,
	overrideAppId?: FieldPolicy<any> | FieldReadFunction<any>,
	templateId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VegaLiteChartKeySpecifier = ('config' | 'id' | 'label' | 'meta' | 'relatedFilters' | 'spec' | 'title' | VegaLiteChartKeySpecifier)[];
export type VegaLiteChartFieldPolicy = {
	config?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	meta?: FieldPolicy<any> | FieldReadFunction<any>,
	relatedFilters?: FieldPolicy<any> | FieldReadFunction<any>,
	spec?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	ActionLink?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActionLinkKeySpecifier | (() => undefined | ActionLinkKeySpecifier),
		fields?: ActionLinkFieldPolicy,
	},
	ActiveUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActiveUserKeySpecifier | (() => undefined | ActiveUserKeySpecifier),
		fields?: ActiveUserFieldPolicy,
	},
	ActivityLog?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ActivityLogKeySpecifier | (() => undefined | ActivityLogKeySpecifier),
		fields?: ActivityLogFieldPolicy,
	},
	AlignableColumnI?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AlignableColumnIKeySpecifier | (() => undefined | AlignableColumnIKeySpecifier),
		fields?: AlignableColumnIFieldPolicy,
	},
	App?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppKeySpecifier | (() => undefined | AppKeySpecifier),
		fields?: AppFieldPolicy,
	},
	AppInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppInfoKeySpecifier | (() => undefined | AppInfoKeySpecifier),
		fields?: AppInfoFieldPolicy,
	},
	AppMenu?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppMenuKeySpecifier | (() => undefined | AppMenuKeySpecifier),
		fields?: AppMenuFieldPolicy,
	},
	AppMenuItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppMenuItemKeySpecifier | (() => undefined | AppMenuItemKeySpecifier),
		fields?: AppMenuItemFieldPolicy,
	},
	Background?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BackgroundKeySpecifier | (() => undefined | BackgroundKeySpecifier),
		fields?: BackgroundFieldPolicy,
	},
	Banner?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BannerKeySpecifier | (() => undefined | BannerKeySpecifier),
		fields?: BannerFieldPolicy,
	},
	BooleanColumn?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BooleanColumnKeySpecifier | (() => undefined | BooleanColumnKeySpecifier),
		fields?: BooleanColumnFieldPolicy,
	},
	BooleanField?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BooleanFieldKeySpecifier | (() => undefined | BooleanFieldKeySpecifier),
		fields?: BooleanFieldFieldPolicy,
	},
	Chart?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ChartKeySpecifier | (() => undefined | ChartKeySpecifier),
		fields?: ChartFieldPolicy,
	},
	ColumnFormula?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ColumnFormulaKeySpecifier | (() => undefined | ColumnFormulaKeySpecifier),
		fields?: ColumnFormulaFieldPolicy,
	},
	ColumnI?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ColumnIKeySpecifier | (() => undefined | ColumnIKeySpecifier),
		fields?: ColumnIFieldPolicy,
	},
	ComponentI?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ComponentIKeySpecifier | (() => undefined | ComponentIKeySpecifier),
		fields?: ComponentIFieldPolicy,
	},
	ContentI?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContentIKeySpecifier | (() => undefined | ContentIKeySpecifier),
		fields?: ContentIFieldPolicy,
	},
	ContentIds?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContentIdsKeySpecifier | (() => undefined | ContentIdsKeySpecifier),
		fields?: ContentIdsFieldPolicy,
	},
	Context?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContextKeySpecifier | (() => undefined | ContextKeySpecifier),
		fields?: ContextFieldPolicy,
	},
	DataTemplate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DataTemplateKeySpecifier | (() => undefined | DataTemplateKeySpecifier),
		fields?: DataTemplateFieldPolicy,
	},
	DataTemplateAction?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DataTemplateActionKeySpecifier | (() => undefined | DataTemplateActionKeySpecifier),
		fields?: DataTemplateActionFieldPolicy,
	},
	DateColumn?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DateColumnKeySpecifier | (() => undefined | DateColumnKeySpecifier),
		fields?: DateColumnFieldPolicy,
	},
	DateField?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DateFieldKeySpecifier | (() => undefined | DateFieldKeySpecifier),
		fields?: DateFieldFieldPolicy,
	},
	DateTimeColumn?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DateTimeColumnKeySpecifier | (() => undefined | DateTimeColumnKeySpecifier),
		fields?: DateTimeColumnFieldPolicy,
	},
	DateTimeField?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DateTimeFieldKeySpecifier | (() => undefined | DateTimeFieldKeySpecifier),
		fields?: DateTimeFieldFieldPolicy,
	},
	DownloadFileField?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DownloadFileFieldKeySpecifier | (() => undefined | DownloadFileFieldKeySpecifier),
		fields?: DownloadFileFieldFieldPolicy,
	},
	EditTableEvent?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | EditTableEventKeySpecifier | (() => undefined | EditTableEventKeySpecifier),
		fields?: EditTableEventFieldPolicy,
	},
	EditableColumnI?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | EditableColumnIKeySpecifier | (() => undefined | EditableColumnIKeySpecifier),
		fields?: EditableColumnIFieldPolicy,
	},
	ExternalLink?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExternalLinkKeySpecifier | (() => undefined | ExternalLinkKeySpecifier),
		fields?: ExternalLinkFieldPolicy,
	},
	FieldI?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FieldIKeySpecifier | (() => undefined | FieldIKeySpecifier),
		fields?: FieldIFieldPolicy,
	},
	Footer?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FooterKeySpecifier | (() => undefined | FooterKeySpecifier),
		fields?: FooterFieldPolicy,
	},
	Form?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FormKeySpecifier | (() => undefined | FormKeySpecifier),
		fields?: FormFieldPolicy,
	},
	FormulaColumn?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FormulaColumnKeySpecifier | (() => undefined | FormulaColumnKeySpecifier),
		fields?: FormulaColumnFieldPolicy,
	},
	HierarchicalFilter?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HierarchicalFilterKeySpecifier | (() => undefined | HierarchicalFilterKeySpecifier),
		fields?: HierarchicalFilterFieldPolicy,
	},
	HierarchicalOption?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HierarchicalOptionKeySpecifier | (() => undefined | HierarchicalOptionKeySpecifier),
		fields?: HierarchicalOptionFieldPolicy,
	},
	LoginError?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LoginErrorKeySpecifier | (() => undefined | LoginErrorKeySpecifier),
		fields?: LoginErrorFieldPolicy,
	},
	LoginResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LoginResultKeySpecifier | (() => undefined | LoginResultKeySpecifier),
		fields?: LoginResultFieldPolicy,
	},
	LogoutResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LogoutResultKeySpecifier | (() => undefined | LogoutResultKeySpecifier),
		fields?: LogoutResultFieldPolicy,
	},
	Meta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MetaKeySpecifier | (() => undefined | MetaKeySpecifier),
		fields?: MetaFieldPolicy,
	},
	Metric?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MetricKeySpecifier | (() => undefined | MetricKeySpecifier),
		fields?: MetricFieldPolicy,
	},
	MultiSelectField?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MultiSelectFieldKeySpecifier | (() => undefined | MultiSelectFieldKeySpecifier),
		fields?: MultiSelectFieldFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	MutationResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationResultKeySpecifier | (() => undefined | MutationResultKeySpecifier),
		fields?: MutationResultFieldPolicy,
	},
	NavLink?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NavLinkKeySpecifier | (() => undefined | NavLinkKeySpecifier),
		fields?: NavLinkFieldPolicy,
	},
	NumberColumn?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NumberColumnKeySpecifier | (() => undefined | NumberColumnKeySpecifier),
		fields?: NumberColumnFieldPolicy,
	},
	NumberField?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NumberFieldKeySpecifier | (() => undefined | NumberFieldKeySpecifier),
		fields?: NumberFieldFieldPolicy,
	},
	NumberRangeField?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NumberRangeFieldKeySpecifier | (() => undefined | NumberRangeFieldKeySpecifier),
		fields?: NumberRangeFieldFieldPolicy,
	},
	Page?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageKeySpecifier | (() => undefined | PageKeySpecifier),
		fields?: PageFieldPolicy,
	},
	PageLink?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageLinkKeySpecifier | (() => undefined | PageLinkKeySpecifier),
		fields?: PageLinkFieldPolicy,
	},
	Panel?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PanelKeySpecifier | (() => undefined | PanelKeySpecifier),
		fields?: PanelFieldPolicy,
	},
	Param?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ParamKeySpecifier | (() => undefined | ParamKeySpecifier),
		fields?: ParamFieldPolicy,
	},
	Platform?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PlatformKeySpecifier | (() => undefined | PlatformKeySpecifier),
		fields?: PlatformFieldPolicy,
	},
	ProjectInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectInfoKeySpecifier | (() => undefined | ProjectInfoKeySpecifier),
		fields?: ProjectInfoFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	QuickLink?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QuickLinkKeySpecifier | (() => undefined | QuickLinkKeySpecifier),
		fields?: QuickLinkFieldPolicy,
	},
	QuickLinks?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QuickLinksKeySpecifier | (() => undefined | QuickLinksKeySpecifier),
		fields?: QuickLinksFieldPolicy,
	},
	Range?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RangeKeySpecifier | (() => undefined | RangeKeySpecifier),
		fields?: RangeFieldPolicy,
	},
	Ref?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RefKeySpecifier | (() => undefined | RefKeySpecifier),
		fields?: RefFieldPolicy,
	},
	SelectField?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SelectFieldKeySpecifier | (() => undefined | SelectFieldKeySpecifier),
		fields?: SelectFieldFieldPolicy,
	},
	SelectOption?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SelectOptionKeySpecifier | (() => undefined | SelectOptionKeySpecifier),
		fields?: SelectOptionFieldPolicy,
	},
	Session?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SessionKeySpecifier | (() => undefined | SessionKeySpecifier),
		fields?: SessionFieldPolicy,
	},
	SubmitFormEvent?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubmitFormEventKeySpecifier | (() => undefined | SubmitFormEventKeySpecifier),
		fields?: SubmitFormEventFieldPolicy,
	},
	Subscription?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubscriptionKeySpecifier | (() => undefined | SubscriptionKeySpecifier),
		fields?: SubscriptionFieldPolicy,
	},
	Support?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SupportKeySpecifier | (() => undefined | SupportKeySpecifier),
		fields?: SupportFieldPolicy,
	},
	SupportMenu?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SupportMenuKeySpecifier | (() => undefined | SupportMenuKeySpecifier),
		fields?: SupportMenuFieldPolicy,
	},
	SupportMenuItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SupportMenuItemKeySpecifier | (() => undefined | SupportMenuItemKeySpecifier),
		fields?: SupportMenuItemFieldPolicy,
	},
	Table?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TableKeySpecifier | (() => undefined | TableKeySpecifier),
		fields?: TableFieldPolicy,
	},
	TemplateAction?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TemplateActionKeySpecifier | (() => undefined | TemplateActionKeySpecifier),
		fields?: TemplateActionFieldPolicy,
	},
	TextColumn?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TextColumnKeySpecifier | (() => undefined | TextColumnKeySpecifier),
		fields?: TextColumnFieldPolicy,
	},
	TextField?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TextFieldKeySpecifier | (() => undefined | TextFieldKeySpecifier),
		fields?: TextFieldFieldPolicy,
	},
	Tile?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TileKeySpecifier | (() => undefined | TileKeySpecifier),
		fields?: TileFieldPolicy,
	},
	Tray?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TrayKeySpecifier | (() => undefined | TrayKeySpecifier),
		fields?: TrayFieldPolicy,
	},
	TrayItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TrayItemKeySpecifier | (() => undefined | TrayItemKeySpecifier),
		fields?: TrayItemFieldPolicy,
	},
	TraySection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TraySectionKeySpecifier | (() => undefined | TraySectionKeySpecifier),
		fields?: TraySectionFieldPolicy,
	},
	UploadFileField?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UploadFileFieldKeySpecifier | (() => undefined | UploadFileFieldKeySpecifier),
		fields?: UploadFileFieldFieldPolicy,
	},
	VegaLiteChart?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VegaLiteChartKeySpecifier | (() => undefined | VegaLiteChartKeySpecifier),
		fields?: VegaLiteChartFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;