import { useCallback, } from "react";
import { useApolloClient } from "@apollo/client";
import * as g from "./useContext.generated";
import * as types from "@arq-apps/generated";
import { DEFAULT_CONTEXT } from "../../apollo/schema/context";


interface ContextUpdate {
  // undefined -> don't change current value
  // null -> replace current value with null
  appId?: string | null,
  // TODO perhaps "null" argument for pageId could send to App home page?
  pageId?: string,  // page cannot be null; can be empty-string ""
  projectId?: number | null,
  supportPageId?: string | null
}


interface Context extends types.Context {
  updateContext: (update: ContextUpdate) => void,
}


export function useContext(): Context {
  const client = useApolloClient();

  // TODO observe current appId / projectId / pageId
  const { data, loading } = g.useContextQuery({
    fetchPolicy: "cache-only",
  });
  const cxt = data?.localContext ?? null;

  const updateContext = useCallback((update: ContextUpdate) => {
    // console.debug('navigate to', to);
    client.cache.updateQuery<g.ContextQuery>({
      query: g.ContextDocument, }, (data) => {
      const localContext: types.Context = !data ? DEFAULT_CONTEXT : ({
        __typename: "Context",  // NB! will not work without this
        appId: update.appId !== undefined
          ? update.appId ?? DEFAULT_CONTEXT.appId
          : data.localContext.appId,
        pageId: update.pageId !== undefined
          ? update.pageId ?? DEFAULT_CONTEXT.pageId
          : data.localContext.pageId,
        projectId: update.projectId !== undefined
          ? update.projectId ?? DEFAULT_CONTEXT.projectId
          : data.localContext.projectId,
        supportPageId: update.supportPageId !== undefined
          ? update.supportPageId ?? DEFAULT_CONTEXT.supportPageId
          : data.localContext.supportPageId,
      })
      console.debug("useContext::updateQuery", data, { localContext });
      return { localContext };
    });
  }, [client])

  return {
    updateContext,
    ...(cxt ?? DEFAULT_CONTEXT),
  }
}
