/*
 * DO NOT EDIT
 * This code is managed by the ARQ Apps Core Team
 */

export function range(n: number) {
  return Array.from({ length: n }, (value, key) => key);
}

export function rangeBetween(start: number, end: number) {
  throw Error("rangeBetween not yet implemented");
}
