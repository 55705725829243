import './InteractPane.scss';
import { useSearchParams } from "react-router-dom";
import { Filters } from "../Filters";
import { Button } from '@arq-apps/ui';
import { useFooterHeight, usePageFilterContext } from 'src/hooks';

export interface InteractPaneProps {
}

export function InteractPane(props: InteractPaneProps) {
  const [searchParams] = useSearchParams();

  const paneKey = searchParams.get("interact") ?? null;

  const { pageFilters, pageFiltersLoaded, updatePageFilters, resetPageFilters, selectClear, multiSelect, multiClearAll, multiSelectAll } = usePageFilterContext();
  const footerHeight = useFooterHeight();
  const headerHeight = 43;

  const classes = [
    "ux-interact-pane",
    (!paneKey || paneKey !== "filters") && "hidden"
  ].filter(Boolean).join(" ");

  if (!pageFiltersLoaded) {
    return (
      <div className={ classes }>
        <header>
          <div>
            <span>Filters</span>
          </div>
        </header>
        <div className='no-filter'>
          <span>Page filters loading...</span>
        </div>
      </div>
    )
  }

  if (!pageFilters || pageFilters.length === 0) {
    return (
      <div className={ classes }>
        <header>
          <div>
            <span>Filters</span>
          </div>
        </header>
        <div className='no-filter'>
          <span>No filters</span>
        </div>
      </div>
    )
  }

  // TODO switch (paneKey) ...
  //    - delegate to specialised panes (FilterPane, ScenarioPane, etc)
  //    - OR define generic capability to create custom panes (e.g. using Forms)

  return (
    <div className={ classes } style={{height: `calc(100vh - ${footerHeight}px - ${headerHeight}px)`}}>
      <header>
        <div>
          <span>Filters</span>
        </div>
        <div>
          {/*<IconButton icon="close-outline" onClick={ handleClose } />*/}
        </div>
      </header>
      {pageFilters && pageFilters.length > 0 && <div className="scrollable">
        <Filters 
          filters={ pageFilters } 
          updateFilters={ updatePageFilters } 
          primaryFilters={false} 
          selectClear={selectClear}
          multiSelect={multiSelect}
          multiClearAll={multiClearAll} 
          multiSelectAll={multiSelectAll}
        />
      </div>}
      <div className="button-container">
        <Button
          text="Reset filters"
          onClick={ resetPageFilters }
        />
      </div>
    </div>
  );
}
