import './ComboBox.scss';
import {
  Combobox,
  ComboboxSlots,
  makeStyles,
  Option,
  shorthands,
  useId,
} from "@fluentui/react-components";
import type { ComboboxProps } from "@fluentui/react-components";
import { SelectOption } from "@arq-apps/generated";
import { useEffect, useMemo, useState } from 'react';
import {
  Virtualizer,
  useStaticVirtualizerMeasure,
} from "@fluentui/react-components/unstable";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("0px"),
    maxWidth: "400px",
  },
  combo: {
    borderBottomColor: "transparent",
    borderTopColor: "transparent",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    minWidth: "min-content",
    width: "100%",
    ":hover": {
      borderBottomColor: "transparent",
      borderTopColor: "transparent",
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
    }
  },
  listbox: {
    maxHeight: "400px",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0
  }
});

interface ComboboxFieldProps extends ComboboxProps {
  id: string,
  options: SelectOption[],
  selections?: SelectOption[],
  // selection?: SelectOption
  onAccept?: (id: string, options: SelectOption[]) => void
  onSelectOption?: (id: string, option: SelectOption) => void
  variant?: "borderless" | undefined
}

export const ComboBox = (props: ComboboxFieldProps) => {
  const comboId = useId("combo-multi");
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    props.selections?.map(option => option.label) ?? []
  );
  const [value, setValue] = useState<string>(
    props.selections ? props.selections.map(option => option.label).join(", ") : ""
  );
  const [open, setOpen] = useState(false);
  const styles = useStyles();

  const itemHeight = 24; //This should match the height of each item in the listbox

  const { virtualizerLength, bufferItems, bufferSize, scrollRef } = useStaticVirtualizerMeasure({
    defaultItemSize: itemHeight,
    direction: "vertical",
  });

  const filteredOptions: SelectOption[] = useMemo(() => {
    return props.options?.filter(opt => opt?.label?.toLowerCase().includes(value?.toLowerCase())) ?? props.options
  }, [props.options, value]);

  useEffect(() => {
    if (props.selections?.length === 0) {
      setSelectedOptions([])
      setValue("")
    } else {
      setSelectedOptions(props.selections?.map(option => option.label) ?? [])
      setValue(props.selections ? props.selections.map(option => option.label).join(", ") : "")
    }
  }, [props.selections])

  const onSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
    // update selectedOptions
    setSelectedOptions(data.selectedOptions);
    if (props.multiselect === false) {
      setValue(data.selectedOptions[0])
      // do the work here you fucking idiot @RM or there's a race condition :(
      const selected = props.options.filter(option => data.selectedOptions.includes(option.label))
      props.onSelectOption && props.onSelectOption(props.id, selected[0])
      setOpen(false)
    }
    if (props.multiselect === true) {
      setValue("");
    }
  };

  const onAccept = () => {
    setOpen(false)
    const selected = props.options.filter(option => selectedOptions.includes(option.label))
    props.onAccept && props.onAccept(props.id, selected)
  }

  const onSelectAll = () => {
    setSelectedOptions(props.options.map(option => option.label))
  }

  const onClearAll = () => {
    setSelectedOptions([])
  }

  // clear value on focus
  const onFocus = () => {
    setValue("");
    setOpen(true)
  };

  // update value to selected options on blur
  const onBlur = () => {
    setValue(selectedOptions.join(", "));
    setOpen(false)
  };

  // update value on input change
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div className={styles.root}>
      <Combobox
        className={props.variant === "borderless" ? styles.combo : undefined }
        open={open}
        // open
        aria-labelledby={comboId}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onOptionSelect={onSelect}
        onClick={() => setOpen(true)}
        selectedOptions={selectedOptions}
        placeholder={props.placeholder}
        multiselect={props.multiselect}
        // {...props}
        listbox={{ ref: scrollRef, className: styles.listbox }}
      >
        <Virtualizer
          numItems={filteredOptions.length}
          virtualizerLength={virtualizerLength}
          bufferItems={bufferItems}
          bufferSize={bufferSize}
          itemSize={itemHeight}
        >
          {(index) => {
            return (
              <Option
                className="option"
                aria-posinset={index}
                aria-setsize={filteredOptions.length}
                key={filteredOptions && filteredOptions[index] && filteredOptions[index].value}
              >
                {filteredOptions && filteredOptions[index] && filteredOptions[index].label}
              </Option>
            );
          }}
        </Virtualizer>
        {props.multiselect && <div className='select-action-container'>
          <button className='select-action-button' onClick={() => onClearAll()}>{"Clear all"}</button>
          <button className='select-action-button' onClick={() => onSelectAll()}>{"Select all"}</button>
          <button className='select-action-button' onClick={() => onAccept()}>{"Apply"}</button>
        </div>}
      </Combobox>
    </div>
  );
};