import { gql } from "@apollo/client";

export const baseSchema = gql`
  # TODO load this into gql --OR-- generate typeDefs (client schema as tsx file):
  #
  # export const typeDefs = gql
  #    ... this schema
  # ;
  #

  # Schema
  # ================================================================================

  # extend type Query {
  #   # readFilterSearch: String @client
  #   # readDebugMode: Boolean! @client
  #   # readDarkMode: Boolean! @client
  #   # readActiveFilterValues: [FilterValue!]! @client
  #   # readActiveContext: ActiveContext @client
  # }

  # Directives
  # ================================================================================

  directive @client on ENUM
  | INTERFACE
  | UNION
  | OBJECT
  | FIELD
  | FIELD_DEFINITION
  | INPUT_OBJECT
  | INPUT_FIELD_DEFINITION
  | FRAGMENT_DEFINITION
  | INTERFACE

  directive @export(
    as: String
  ) on FIELD | FIELD_DEFINITION

  # Scalars
  # ================================================================================

  # Enums
  # ================================================================================

  #enum InteractPane @client {
  #  filters
  #  scenarios
  #  settings
  #}

  # Interfaces
  # ================================================================================

  # Input types
  # ================================================================================

  # Object types
  # ================================================================================

  #type ActiveContext @client {
  #  appId: String
  #  agentId: String
  #  pageId: String
  #  pathname: String!
  #  projectId: String
  #  search: String
  #  lang: String
  #  darkMode: Boolean!
  #  debugMode: Boolean!
  #  interact: InteractPane
  #}

  #extend type ToggleControl {
  #  currentState: Boolean @client
  #}

  #extend type TextPickerControl {
  #  currentTextSelection: String @client
  #}

  #extend type TextMultiPickerControl {
  #  currentTextSelections: [String!] @client
  #}

  #extend type NumberPickerControl {
  #  currentNumberSelection: Float @client
  #}

  #extend type NumberMultiPickerControl {
  #  currentNumberSelections: [Float!] @client
  #}

  #extend type Toggle {
  #  currentValue: Boolean @client
  #}

  #extend type NumberPicker {
  #  currentValue: [NumberOption!] @client
  #}

  #extend type TextPicker {
  #  currentValue: [TextOption!] @client
  #}
`;
