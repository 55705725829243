import { createContext, useCallback, useContext, useEffect, useState } from "react";
import * as useAppContext from "../../hooks/useContext";
import * as types from "@arq-apps/generated";
import { useLandingContext } from "src/contexts/LandingContext"
import { useHierarchicalFiltersByIdsLazyQuery, useHierarchicalFilterIdsLazyQuery, HierarchicalFilterFragment} from "src/ux/HierarchicalFilter/HierarchicalFilter.generated"
import { FieldInput, HierarchicalOption } from "@arq-apps/generated"

type HierarchicalFilterContextType =  {
  hierarchicalFilters: HierarchicalFilterFragment[] | null | undefined,
  hierarchicalFilterInputs: FieldInput[] | null | undefined,
  hierarchicalFiltersLoaded: boolean,
  updateHierarchicalFilters: (id: string, option: HierarchicalOption | undefined, depth: number) => void,
  updateHierarchicalLeafSelections: (id: string, option: HierarchicalOption | undefined) => void,
  multiSelectAll: (id: string, options: HierarchicalOption[] | null | undefined) => void
  multiClearAll: (id: string) => void
}

const HierarchicalFilterContext = createContext<HierarchicalFilterContextType>({
  hierarchicalFilters: undefined,
  hierarchicalFilterInputs: undefined,
  hierarchicalFiltersLoaded: false,
  updateHierarchicalFilters: () => {},
  updateHierarchicalLeafSelections: () => {},
  multiSelectAll: () => {},
  multiClearAll: () => {}
})

export const HierarchicalFilterContextProvider = (props: { children: React.ReactNode }) => {
  const [hierarchicalFilters, setHierarchicalFilters] = useState<HierarchicalFilterFragment[]>();
  const [hierarchicalFilterInputs, setHierarchicalFilterInputs] = useState<FieldInput[]>();
  const [hierarchicalFiltersLoaded, setHierarchicalFiltersLoaded] = useState<boolean>(false);
  
  const {appId, pageId, projectId} = useAppContext.useContext();
  const LandingContext = useLandingContext();

  useEffect(() => {
    setHierarchicalFiltersLoaded(false);
  }, [pageId]);

  const getHierarchicalFilterIds = useHierarchicalFilterIdsLazyQuery()[0];
  const getHierFilters = useHierarchicalFiltersByIdsLazyQuery()[0];

  let extraInputs: types.QueryInput[] = [];
  if (LandingContext.appId) {extraInputs.push({"name": "appId", "value": `${LandingContext.appId}`})}
  if (LandingContext.projectId) {extraInputs.push({"name": "projectId", "value": `${LandingContext.projectId}`})}

  const fetchHierarchicalFilters = async() => {
    // TODO - check if there's id's first then load?
    setHierarchicalFiltersLoaded(false);
    const getHierarchicalFilterIdsData = await getHierarchicalFilterIds({
      fetchPolicy: "no-cache",
      variables: {
        appId,
        pageId,
        projectId,
      }
    });
    const _hierFilterIds = getHierarchicalFilterIdsData.data?.app?.pageById?.hierarchicalFilterIds

    const getHierFiltersData = await getHierFilters({
      fetchPolicy: "no-cache",
      variables: {
        appId,
        projectId,
        filterIds: _hierFilterIds ?? [],
        extraInputs
      },
    })

    const nonNullFilters = getHierFiltersData?.data?.app?.hierarchicalFiltersByIds.filter((x): x is HierarchicalFilterFragment => x !== null) ?? []
    setHierarchicalFilters(nonNullFilters)
    setHierarchicalFiltersLoaded(true);
  }

  useEffect(() => {
    // TODO @RM raise adding param to menu item for non-graphql pages
    const dotnetPageIds = ["/main/subscription", "/main/application/sync/import", "/main/application/sync/export"]
    if (!dotnetPageIds.includes(pageId)) {
      fetchHierarchicalFilters();
    }
  }, [pageId]);

  useEffect(() => {
    if (hierarchicalFilters && hierarchicalFilters.length > 0) {
      setHierarchicalFilterInputs(buildFilterInputsFromHierarchical([...hierarchicalFilters]));
    }
  }, [hierarchicalFilters]);

  const updateHierarchicalFilters = useCallback((id: string, option: HierarchicalOption | undefined, depth: number) => {
    console.debug(`updateHierarchicalFilters ${id} ${JSON.stringify(option)} ${JSON.stringify(depth)}`)
    if (!option) {
      return
    }
    setHierarchicalFilters((prev) => {
      const updated = prev?.map((hier) => {
        if (hier.id !== id) {
          return hier
        }
        
        var _selection = hier.selection?.slice(0, depth) ?? []
        _selection.push(option)

        return {
          ...hier,
          selection: _selection,
          leafSelections: null
        }
      })
      return updated
    })
  }, [hierarchicalFilters]);

  const updateHierarchicalLeafSelections = useCallback((id: string, option: HierarchicalOption | undefined) => {
    // console.debug(`updateHierarchicalFilters ${id} ${JSON.stringify(option)} ${JSON.stringify(depth)}`)
    if (!option) {
      return
    }
    setHierarchicalFilters((prev) => {
      const updated = prev?.map((hier) => {
        if (hier.id !== id) {
          return hier
        }

        const currentSelections = hier.leafSelections ?? []
        const filtered = currentSelections.filter((it) => it.value !== option.value);
        const newSelections = filtered.length < currentSelections.length ? filtered : [...filtered, option]

        return {
          ...hier,
          leafSelections: newSelections
        }
      })
      return updated
    })
  }, [hierarchicalFilters]);

  const multiSelectAll = useCallback((id: string, options: HierarchicalOption[] | null | undefined) => {
    console.log("context select event")
    setHierarchicalFilters((prev) => {
      const updated = prev?.map((hier) => {
        if (hier.id !== id) {
          return hier
        }

        return {
          ...hier,
          leafSelections: options
        }
      })
      return updated
    })
  }, [hierarchicalFilters]);

  const multiClearAll = useCallback((id: string) => {
    console.log("context clear event")
    setHierarchicalFilters((prev) => {
      const updated = prev?.map((hier) => {
        if (hier.id !== id) {
          return hier
        }

        return {
          ...hier,
          leafSelections: []
        }
      })
      return updated
    })
  }, [hierarchicalFilters]);

  return (
    <HierarchicalFilterContext.Provider value = {{
      hierarchicalFilters,
      hierarchicalFilterInputs,
      hierarchicalFiltersLoaded,
      updateHierarchicalFilters,
      updateHierarchicalLeafSelections,
      multiSelectAll,
      multiClearAll
    }}>
      { props.children }
    </HierarchicalFilterContext.Provider>
  )
}

export const useHierarchicalFilterContext = () => {
  return useContext(HierarchicalFilterContext);
}

function buildFilterInputsFromHierarchical(filters: HierarchicalFilterFragment[]): types.FieldInput[] {
  const filterArrays = filters.flatMap(filter => {
    const selections = filter.selection?.map((_selection, index) => {
      return {
        id: filter.id ? `${filter.id}-${index}` : "no filter id at HierarchicalFilterContextLine126",
        tag: types.FieldTag.SelectField,
        selection: {
          value: _selection.value ?? "",
          label: _selection.label ?? "",
        }
      }
    }) as types.FieldInput[]
    const leafSelections = {
      id: filter.id ? `${filter.id}-${filter.selection?.length}` : "no filter id at HierarchicalFilterContextLine126",
      tag: types.FieldTag.MultiSelectField,
      selections: filter.leafSelections?.map(it => {
        return {
          value: it.value ?? "",
          label: it.label ?? "",
        }
      })
    } as types.FieldInput
    if (filter.multiselectOnLeaf === true) {
      selections?.push(leafSelections)
    }
    return selections ?? []
  });
  return filterArrays
}