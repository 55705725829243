import { useCallback, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import * as g from "./useEditTable.generated";
import { ColumnInput, ColumnTag, FieldInput, QueryInput } from "@arq-apps/generated";
import { TableFragment } from "./Table.generated";
import { ColumnFragment } from "../Column/Column.generated";
import { toast, Zoom } from "react-toastify";
import { filterNotNull, range } from "@arq-apps/util";
import { usePageFilterContext } from "../../hooks";
import { useLandingContext } from "src/contexts/LandingContext"
import { useHierarchicalFilterContext } from "src/contexts/HierarchicalFilterContext/HierarchicalFilterContext"

interface BaseEditCellEvent {
  // tableId: string,
  // __typename: "BooleanColumn" | "TextColumn" | "NumberColumn",
  columnId: string,
  dataRowIdx: number,
}

interface EditBooleanCellEvent {
  __typename: "BooleanColumn",
  value: boolean | null,
}

interface EditTextCellEvent {
  __typename: "TextColumn",
  value: string | null,
}

interface EditNumberCellEvent {
  __typename: "NumberColumn",
  value: number | null,
}

interface EditDateCellEvent {
  __typename: "DateColumn",
  value: string | null,
}

export type EditCellEvent = BaseEditCellEvent & (
  EditBooleanCellEvent
  | EditTextCellEvent
  | EditNumberCellEvent
  | EditDateCellEvent
  );

export function useEditTable(
  tableId: string,
  tileFilterInputs?: FieldInput[]
) {
  const {cache} = useApolloClient();
  const LandingContext = useLandingContext();

  const { pageFilterInputs } = usePageFilterContext();
  const { hierarchicalFilterInputs } = useHierarchicalFilterContext();
  const filters = useMemo(() => {
    const _pageFilterInputs = pageFilterInputs ?? [];
    const _tileFilterinputs = tileFilterInputs ?? [];
    const _hierarchicalFilterInputs = hierarchicalFilterInputs ?? [];
    return [..._tileFilterinputs, ..._pageFilterInputs, ..._hierarchicalFilterInputs];
  }, [tileFilterInputs, pageFilterInputs, hierarchicalFilterInputs]);

  const tableIdentity = useMemo(() => cache.identify({
    __typename: "Table",
    id: tableId,
  }), [tableId]);

  const [
    editTableMutation,
    {
      data: editData,
      loading: submitting,
      error: submitError,
    },
  ] = g.useEditTableMutation({
    /* */
  });

  let extraInputs: QueryInput[] = [];
  if (LandingContext.appId) {extraInputs.push({"name": "appId", "value": `${LandingContext.appId}`})}
  if (LandingContext.projectId) {extraInputs.push({"name": "projectId", "value": `${LandingContext.projectId}`})}

  const submitTableChanges = useCallback((table: TableFragment) => {
    // TODO cache.readFragment({});
    // console.log('submit table changes');
    // console.log(' --> table:', table);
    // console.log(' --> data row count:', table.dataRowCount);
    // console.log(' --> changes:', "TODO");
    // const _ = submitTable(props.fields?.map(fieldToInput) ?? []);

    const dataRowIndex = range(table.dataRowCount);

    editTableMutation({
      variables: {
        appId: table.meta.appId,
        projectId: table.meta.projectId,
        // filters: table.meta.filters,
        filters: filters,
        tableId,
        dataRowCount: table.dataRowCount,
        // columns: filterNotNull(table.columns?.map(column =>
        //   extractColumnInputs(column)
        // ) ?? []),
        columns: table.columns?.map(column =>
          extractColumnInputs(column, dataRowIndex)
        ) ?? [],
        extraInputs
      }
    }).then(result => {
      // TODO re-fetch stale content...
      if (result.data?.editTable.ok) {
        const defaultMessage = "Table changes processed successfully"
        const message = result.data?.editTable.message
        
        toast.success(message ?? defaultMessage, {
          toastId: `/events/edit-table/${table.id}`,
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          transition: Zoom
        })
        const contentToRefresh = result.data?.editTable.contentToRefresh
        console.warn("table submit success", contentToRefresh);
        const all = [
          { __typename: "App", ids: contentToRefresh?.apps ?? [] },
          { __typename: "Chart", ids: contentToRefresh?.charts ?? [] },
          { __typename: "Form", ids: contentToRefresh?.forms ?? [] },
          { __typename: "Metric", ids: contentToRefresh?.metrics ?? [] },
          { __typename: "Page", ids: contentToRefresh?.pages ?? [] },
          { __typename: "Table", ids: contentToRefresh?.tables ?? [] },
        ];
        all.forEach(({ __typename, ids }) => {
          ids.forEach(id => {
            console.debug("invalidate", { __typename, id });
            cache.evict({
              id: cache.identify({ __typename, id }),
            });
          })
        });
      } else {
        const defaultMessage = "Table changes not processed"
        const message = result.data?.editTable.message
        
        toast.error(message ?? defaultMessage, {
          toastId: `/events/submit-form/${table.id}`,
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          transition: Zoom
        })
      }
      
    }).catch(err => {
      // TODO @RM - more useful error message
      console.warn(err)
      toast.error("Table changes not processed", {
        toastId: `/events/submit-form/${table.id}`,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        transition: Zoom
      })
    })
  }, [tableId, editTableMutation, filters]);

  const resetTableChanges = useCallback((table: TableFragment) => {
    console.debug('reset table', table);
    cache.updateFragment({
      id: tableIdentity,
      fragment: g.LocalEditTableFragmentDoc,
      fragmentName: "LocalEditTable",
      broadcast: true
    }, (data) => {
      return {
        ...data,
        // Note: this change will trigger `Table -> fields -> columns -> merge` TypePolicy
        columns: data.columns.map((column: g.LocalEditColumnFragment) => {
          switch (column.__typename) {
            case "BooleanColumn":
              return {
                ...column,
                localBoolValues: column.localBoolValues.map(() => null)
              }
            case "NumberColumn":
              return {
                ...column,
                localNumberValues: column.localNumberValues.map(() => null)
              }
            case "TextColumn":
              return {
                ...column,
                localTextValues: column.localTextValues.map(() => null)
              }
            case "DateColumn":
              return {
                ...column,
                localDateValues: column.localDateValues.map(() => null)
              }
            default:
              console.debug('reset column', column.__typename, column.id, column);
              console.error(`reset column does not support column type ${ column.__typename }`);
              throw Error(`reset column does not support column type ${ column.__typename }`);
          }
        }),
      }
    });
  }, [tableIdentity]);

  const localEditCell = useCallback((edit: EditCellEvent) => {
    cache.updateFragment({
      id: tableIdentity,
      fragment: g.LocalEditTableFragmentDoc,
      fragmentName: "LocalEditTable",
    }, (data) => {
      //console.debug(data);
      return {
        ...data,
        // Note: this change will trigger `Table -> fields -> columns -> merge` TypePolicy
        columns: data?.columns?.map((it: g.LocalEditColumnFragment) => {
          if (it.__typename === edit.__typename && it.id === edit.columnId) {
            return buildColumnChange(it, edit);
          }
          return it;
        }),
      }
    });
  }, [tableId, tableIdentity]);

  return {
    localEditCell,
    submitTableChanges,
    resetTableChanges
  };
}

function buildColumnChange(column: g.LocalEditColumnFragment, edit: EditCellEvent) {
  console.debug("in build column change")
  // TODO remove double check on typename
  switch (column.__typename) {
    case "BooleanColumn":
      return column.__typename === "BooleanColumn" ? {
        ...column,
        localBoolValues: column.localBoolValues.map(
          (it, idx) => idx === edit.dataRowIdx ? edit.value : it
        )
      } : column;
    case "NumberColumn":
      return column.__typename === "NumberColumn" ? {
        ...column,
        localNumberValues: column.localNumberValues.map(
          (it, idx) => idx === edit.dataRowIdx ? edit.value : it
        ),
      } : column;
    case "TextColumn":
      return column.__typename === "TextColumn" ? {
        ...column,
        localTextValues: column.localTextValues.map(
          (it, idx) => idx === edit.dataRowIdx ? edit.value : it
        ),
      } : column;
    case "DateColumn":
      // console.log(column.localDateValues.map(
      //   (it, idx) => idx === edit.dataRowIdx ? edit.value : it
      // ))
      return column.__typename === "DateColumn" ? {
        ...column,
        localDateValues: column.localDateValues.map(
          (it, idx) => idx === edit.dataRowIdx ? edit.value : it
        ),
      } : column;
    default:
      console.debug('buildColumnChange', column.__typename, column.id, column, edit);
      console.error(`buildColumnChange does not support column type ${ column.__typename }`);
      throw Error(`buildColumnChange does not support column type ${ column.__typename }`);
    // TODO add
  }
}

function extractColumnInputs(column: ColumnFragment, dataRowIndex: number[]): ColumnInput {
  const commonProps: ColumnInput = {
    tag: ColumnTag.UnknownColumn,  // replaced below with specific type
    id: column.id,
    isIndex: false,
    index: [],
    changesIndex: [],  // replaced below with index
  };
  // if ('editable' in column && column.editable !== true) {
  //   // not editable (skip)
  //   return null;
  // }
  // TODO this is too complex & repeated...
  switch (column.__typename) {
    case "BooleanColumn":
      return {
        ...commonProps,
        tag: ColumnTag.BooleanColumn,
        boolValues: dataRowIndex.map(row => column.localBoolValues[row] ?? column.boolValues[row] ?? null),
        changesIndex: dataRowIndex.filter(row =>
          (column.localBoolValues[row] !== null)
          && (column.localBoolValues[row] !== undefined) 
          && (column.localBoolValues[row] !== column.boolValues[row])
        ),
      };
    case "NumberColumn":
      return {
        ...commonProps,
        tag: ColumnTag.NumberColumn,
        numberValues: dataRowIndex.map(row =>
          column.localNumberValues[row] ?? column.numberValues[row] ?? null,
        ),
        changesIndex: dataRowIndex.filter(row =>
          (column.localNumberValues[row] !== null)
          && (column.localNumberValues[row] !== undefined)
          && (column.localNumberValues[row] !== column.numberValues[row])
        ),
      };
    case "TextColumn":
      return {
        ...commonProps,
        tag: ColumnTag.TextColumn,
        textValues: dataRowIndex.map(row =>
          column.localTextValues[row] ?? column.textValues[row] ?? null,
        ),
        changesIndex: dataRowIndex.filter(row =>
          (column.localTextValues[row] !== null)
          && (column.localTextValues[row] !== undefined) 
          && (column.localTextValues[row] !== column.textValues[row])
        ),
      };
    case "DateColumn":
      return {
        ...commonProps,
        tag: ColumnTag.DateColumn,
        dateValues: dataRowIndex.map(row =>
            column.localDateValues[row] ?? column.dateValues[row] ?? null,
        ),
        changesIndex: dataRowIndex.filter(row =>
          (column.localDateValues[row] !== null)
          && (column.localDateValues[row] !== undefined)
          && (column.localDateValues[row] !== column.dateValues[row])
        ),
      };
    // case "DateTimeColumn":
    //   return {
    //     ...commonProps,
    //     tag: ColumnTag.TextColumn,
    //     dateTimeValues: column.localDateTimeValues,
    //   };
  }
  console.warn(`useEditTable::extractColumnInputs - unhandled column type ${column.__typename}`);
  return {
    ...commonProps,
  };
}
