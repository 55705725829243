import './Menu.scss';
import { useMemo, useRef, useState } from "react"
import { useEditableMenu } from 'src/contexts/EditableMenuContext'
import { MessagesLandingMenuItem } from 'src/dotnet-client'
import { Icon, IconButton } from '@arq-apps/ui';

export function EditableMenu() {
  const { menu, setIndexArray } = useEditableMenu();
  const [addSelected, setAddSelected] = useState(false);

  const onAddClick = () => {
    setIndexArray([menu?.items.length ?? 0])
    setAddSelected(true)
  }

  return (
    <>
      <div className="ux-menu max-height-400">
        { menu?.items?.map((it, index) => (
          <MenuItem key={it.page_id} { ...it } indexArray={[index]} setAddSelectedFalse={() => setAddSelected(false)}/>
        )) }
        <div className={ `ux-menu-item level-1 plus ${addSelected && "active"}` } onClick={onAddClick}>
          <Icon icon='circle-plus-outline' color='#7D7D7D' scale={2}/>
        </div>
      </div>
    </>
  );
}

interface MenuItemProps extends MessagesLandingMenuItem {
  level?: number,
  indexArray: number[],
  setAddSelectedFalse: () => void
}

function MenuItem(props: MenuItemProps) {
  const level = props.level ?? 1;  // expect 1/2/3
  const { setMenu, indexArray, setIndexArray } = useEditableMenu();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [addSelected, setAddSelected] = useState(false);
  
  const selected = useMemo(() => {
    return props.indexArray.toString() === indexArray.toString()
  }, [indexArray, props.indexArray])

  const onUpClick = () => {
    if (props.indexArray.length === 1) setMenu(prev => {
      if (!prev?.items || props.indexArray[0] === 0) return prev
      return {items: [
        ...prev.items.slice(0, props.indexArray[0] - 1),
        prev.items[props.indexArray[0]],
        prev.items[props.indexArray[0] - 1],
        ...prev.items.slice(props.indexArray[0] + 1)
      ]}
    })
    if (props.indexArray.length === 2) setMenu(prev => {
      if (!prev?.items || props.indexArray[1] === 0) return prev
      var newItems = prev.items
      newItems[props.indexArray[0]].items = [
        ...prev.items[props.indexArray[0]].items.slice(0, props.indexArray[1] - 1),
        prev.items[props.indexArray[0]].items[props.indexArray[1]],
        prev.items[props.indexArray[0]].items[props.indexArray[1] - 1],
        ...prev.items[props.indexArray[0]].items.slice(props.indexArray[1] + 1)
      ]
      return {items: [...newItems]}
    })
    if (props.indexArray.length === 3) setMenu(prev => {
      if (!prev?.items || props.indexArray[2] === 0) return prev
      var newItems = prev.items
      newItems[props.indexArray[0]].items[props.indexArray[1]].items = [
        ...prev.items[props.indexArray[0]].items[props.indexArray[1]].items.slice(0, props.indexArray[2] - 1),
        prev.items[props.indexArray[0]].items[props.indexArray[1]].items[props.indexArray[2]],
        prev.items[props.indexArray[0]].items[props.indexArray[1]].items[props.indexArray[2] - 1],
        ...prev.items[props.indexArray[0]].items[props.indexArray[1]].items.slice(props.indexArray[2] + 1)
      ]
      return {items: [...newItems]}
    })
  }

  const onDownClick = () => {
    if (props.indexArray.length === 1) setMenu(prev => {
      if (!prev?.items || props.indexArray[0] === prev?.items.length - 1) return prev
      return {items: [
        ...prev.items.slice(0, props.indexArray[0]), 
        prev.items[props.indexArray[0] + 1], 
        prev.items[props.indexArray[0]], 
        ...prev.items.slice(props.indexArray[0] + 2)
      ]}
    })
    if (props.indexArray.length === 2) setMenu(prev => {
      if (!prev?.items || props.indexArray[1] === prev?.items[props.indexArray[0]].items.length - 1) return prev
      var newItems = prev.items
      newItems[props.indexArray[0]].items = [
        ...prev.items[props.indexArray[0]].items.slice(0, props.indexArray[1]),
        prev.items[props.indexArray[0]].items[props.indexArray[1] + 1],
        prev.items[props.indexArray[0]].items[props.indexArray[1]],
        ...prev.items[props.indexArray[0]].items.slice(props.indexArray[1] + 2)
      ]
      return {items: [...newItems]}
    })
    if (props.indexArray.length === 3) setMenu(prev => {
      if (!prev?.items || props.indexArray[2] === prev?.items[props.indexArray[0]].items[props.indexArray[1]].items.length - 1) return prev
      var newItems = prev.items
      newItems[props.indexArray[0]].items[props.indexArray[1]].items = [
        ...prev.items[props.indexArray[0]].items[props.indexArray[1]].items.slice(0, props.indexArray[2]),
        prev.items[props.indexArray[0]].items[props.indexArray[1]].items[props.indexArray[2] + 1],
        prev.items[props.indexArray[0]].items[props.indexArray[1]].items[props.indexArray[2]],
        ...prev.items[props.indexArray[0]].items[props.indexArray[1]].items.slice(props.indexArray[2] + 2)
      ]
      return {items: [...newItems]}
    })
  }

  const onRightClick = () => {
    if (props.indexArray.length === 1) setMenu(prev => {
      if (!prev?.items || props.indexArray[0] === 0) return prev
      var newItems = prev.items
      newItems[props.indexArray[0] - 1].items = [prev.items[props.indexArray[0]], ...prev.items[props.indexArray[0] - 1].items]
      return {items: [
        ...newItems.slice(0, props.indexArray[0]),
        ...newItems.slice(props.indexArray[0] + 1)
      ]}
    })
    // if (props.indexArray.length === 2) setMenu(prev => {
    //   if (!prev?.items || prev?.items[props.indexArray[0]].items.length <= 1 || props.indexArray[1] === 0) return prev
    //   var newItems = prev.items

    //   newItems[props.indexArray[0]].items[props.indexArray[1] - 1].items = [
    //     prev.items[props.indexArray[0]].items[props.indexArray[1]],
    //     ...prev.items[props.indexArray[0]].items[props.indexArray[1] - 1].items
    //   ]

    //   newItems[props.indexArray[0]].items.splice(props.indexArray[1], 1)

    //   return {items: newItems}
    // })
  }

  const onLeftClick = () => {
    if (props.indexArray.length === 2) setMenu(prev => {
      if (!prev?.items) return prev
      var newItems = [...prev.items]
      const deleted = newItems[props.indexArray[0]].items.splice(props.indexArray[1], 1)
      return {items: [
        ...newItems.slice(0, props.indexArray[0] + 1),
        ...deleted,
        ...newItems.slice(props.indexArray[0] + 1)
      ]}
    })
  }

  const onDeleteClick = () => {
    if (props.indexArray.length === 1) setMenu(prev => {
      if (!prev?.items) return prev
      return {items: [
        ...prev.items.slice(0, props.indexArray[0]),
        ...prev.items.slice(props.indexArray[0] + 1)
      ]}
    })
    if (props.indexArray.length === 2) setMenu(prev => {
      if (!prev?.items) return prev
      var newItems = prev.items
      newItems[props.indexArray[0]].items = [
        ...prev.items[props.indexArray[0]].items.slice(0, props.indexArray[1]),
        ...prev.items[props.indexArray[0]].items.slice(props.indexArray[1] + 1)
      ]
      return {items: [...newItems]}
    })
    if (props.indexArray.length === 3) setMenu(prev => {
      if (!prev?.items) return prev
      var newItems = prev.items
      newItems[props.indexArray[0]].items[props.indexArray[1]].items = [
        ...prev.items[props.indexArray[0]].items[props.indexArray[1]].items.slice(0, props.indexArray[2]),
        ...prev.items[props.indexArray[0]].items[props.indexArray[1]].items.slice(props.indexArray[2] + 1)
      ]
      return {items: [...newItems]}
    })
  }

  const onMenuItemClick = () => {
    setIndexArray(props.indexArray)
    props.setAddSelectedFalse()
    setAddSelected(false)
  }

  const onAddClick = () => {
    setIndexArray([...props.indexArray, (props.items && props.items.length) ?? 0])
    setAddSelected(true)
  }

  return (
    <>
      <div 
        className={ `ux-menu-item level-${ level } ${selected && "active"}` }
        onClick={onMenuItemClick}
      >
        <div style={{flexGrow: 1}} onClick={() => setExpanded(!expanded)}>{ props.name }</div>
        <div style={{display: "flex", flexDirection: "row", zIndex: "100"}}>
          <IconButton icon="left-chevron-outline" onClick={onLeftClick}/>
          <IconButton icon="right-chevron-outline" onClick={onRightClick}/>
          <IconButton icon="up-chevron-outline" onClick={onUpClick}/>
          <IconButton icon="down-chevron-outline" onClick={onDownClick}/>
          <IconButton icon="delete-outline" onClick={onDeleteClick}/>
        </div>
      </div>
      {expanded && (<>
        { props.items?.map((it, index) => (
          <MenuItem key={it.page_id} { ...it } level={ level + 1 } indexArray={[...props.indexArray, index]} setAddSelectedFalse={() => setAddSelected(false)}/>
        )) }
        <div className={ `ux-menu-item level-1 plus ${addSelected && "active"}` } onClick={onAddClick}>
          <Icon icon='circle-plus-outline' color='#7D7D7D' scale={(4 - level)/2}/>
        </div>
      </>)}
    </>
  );
}

  // Pre cursor for drag and drop

  // draggable
  // onDragStart={(e) => handleDragStart(e, props.indexArray)}
  // onDragEnter={(e) => handleDragEnter(e, props.indexArray)}
  
  // const [dragging, setDragging] = useState(false);
  // const dragItem = useRef<number[]>();
  // const dragItemNode = useRef<EventTarget>();

  // const handleDragStart = (e: React.DragEvent<HTMLDivElement>, item: number[]) => {
  //   console.log('Starting to drag', item)

  //   dragItemNode.current = e.target;
  //   dragItemNode.current.addEventListener('dragend', handleDragEnd)
  //   dragItem.current = item;

  //   setTimeout(() => {
  //       setDragging(true); 
  //   },0)       
  // }

  // const handleDragEnter = (e: React.DragEvent<HTMLDivElement>, targetItem: number[]) => {
  //   console.log('Entering a drag target', targetItem)
  //   if (dragItemNode.current !== e.target) {
  //     console.log('Target is NOT the same as dragged item')
  //     setMenu(prev => {
  //         let newMenu = JSON.parse(JSON.stringify(prev))
  //         newMenu.items.splice(targetItem[0], 0, newMenu.items.splice(dragItem.current ? dragItem.current[0] : 0,1)[0])
  //         dragItem.current = targetItem;
  //         return newMenu
  //     })
  //   }
  // }

  // const handleDragEnd = () => {
  //   setDragging(false);
  //   dragItem.current = undefined;
  //   dragItemNode.current && dragItemNode.current.removeEventListener('dragend', handleDragEnd)
  //   dragItemNode.current = undefined;
  // }