import './MultiSelect.scss';
import * as S from '@radix-ui/react-select';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon, FieldInput, Icon } from "@arq-apps/ui";
import { useCallback, useMemo, useState } from 'react';
import VirtualList from 'react-tiny-virtual-list';

// TODO - create class enum
// FOR now, vanilla and hierarchical are the only types
interface MultiSelectProps {
  class?: string,
	placeholder: string,
	options: string[],
	selections: string[] | null | undefined,
  lastHierFilter?: boolean
  onOptionToggled: (option: string) => void,
  onClearAll: () => void,
  onSelectAll: () => void
}

export function MultiSelect(props: MultiSelectProps) {
  const [search, setSearch] = useState<string>("");
  
  const handleSearch = useCallback((text: string, error: string | null) => {
    setSearch(text);
  }, []);

  const filteredOptions: string[] = useMemo(() => {
    return props.options?.filter(opt => opt.toLowerCase().includes(search.toLowerCase())) ?? []
  }, [props.options, search]);

  const SELECT_NONE = "__select-none__";

  const selectClasses = ['ux-select-vanilla', props.class && props.class].filter(Boolean).join(' ');
  const portalClasses = ['ux-select-portal-vanilla', props.class && props.class].filter(Boolean).join(' ');

  const selectedOptions = useMemo(() => filteredOptions?.filter(it => props.selections?.includes(it)), [props.selections?.length, props.options, search])
  const triggerText = useMemo(() => {
    const selectedOptionsCount = selectedOptions.length;
    const allText = selectedOptions.join(', ');

    let triggerText = allText;
    if (allText.length > 20 && selectedOptionsCount > 1)
      triggerText = selectedOptions[0] + ' (+' + (selectedOptionsCount-1) + ')';
    
    if (triggerText === '' || triggerText === undefined)
      triggerText = props.placeholder;

    return triggerText;
  }, [props.selections?.length])

  const DEFAULT_ITEM_HEIGHT = 24;
  const MAX_HEIGHT = 300;
  const wrapperHeight   = MAX_HEIGHT < filteredOptions.length * DEFAULT_ITEM_HEIGHT ? MAX_HEIGHT : filteredOptions.length * DEFAULT_ITEM_HEIGHT;

  return (
    <div className={selectClasses}>
      <S.Root 
        onValueChange={ (value) => props.onOptionToggled(value) } 
        value={ SELECT_NONE}
      >
        <S.Trigger aria-label="Select" className={(props.selections && props.selections?.length > 0) ? 'selectionMade' : undefined}>
          <S.SelectValue placeholder={props.placeholder} />
          {props.class === "hierarchical" && props.lastHierFilter && <Icon icon="down-chevron-outline" /> }
          {props.class !== "hierarchical" && <Icon icon="down-chevron-outline" /> }
        </S.Trigger>
        <S.Portal className={portalClasses}>
          <S.Content position="popper">
            <div className="label">{props.placeholder}</div>
            <FieldInput value={search} onChange={handleSearch} height={30}/>
            <S.Viewport>
              <S.SelectItem value={ SELECT_NONE } className="hidden">
                <S.SelectItemText>
                  { triggerText }
                  {/* {badgeCount > 1 && <span className="badge">{badgeCount}</span>} */}
                </S.SelectItemText>
              </S.SelectItem>
              <S.Group>
                <VirtualList 
                  width="100%"
                  height={wrapperHeight + 6}
                  itemCount={filteredOptions.length}
                  itemSize={DEFAULT_ITEM_HEIGHT}
                  renderItem={(({index, style}) => {
                    return (
                      <S.SelectItem 
                        key={ index } 
                        value={ filteredOptions[index] } 
                        style={{...style, width: "calc(100% - 16px)", display: "flex"}}
                      >
                        <Checkbox.Root checked={props.selections?.includes(filteredOptions[index])}>
                          <Checkbox.CheckboxIndicator>
                            <CheckIcon />
                          </Checkbox.CheckboxIndicator>
                        </Checkbox.Root>
                        <S.SelectItemText>
                          <span className='select-text'>
                            { filteredOptions[index] }
                          </span>
                        </S.SelectItemText>
                      </S.SelectItem>
                    )
                  })}
                />
              </S.Group>
            </S.Viewport>
            <div className='select-action-container'>
              <button className='select-action-button' onClick={() => props.onClearAll()}>{"Clear all"}</button>
              <button className='select-action-button' onClick={() => props.onSelectAll()}>{"Select all"}</button>
            </div>
          </S.Content>
        </S.Portal>
      </S.Root>
    </div>
  );
}