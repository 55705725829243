/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesSubscriptionsEntity } from '../models/MessagesSubscriptionsEntity';
import type { MessagesSubscriptionsNewOrganisationTeam } from '../models/MessagesSubscriptionsNewOrganisationTeam';
import type { MessagesSubscriptionsNewOrganisationUser } from '../models/MessagesSubscriptionsNewOrganisationUser';
import type { MessagesSubscriptionsNewOrganisationWorkspace } from '../models/MessagesSubscriptionsNewOrganisationWorkspace';
import type { MessagesSubscriptionsNewOrganisationWorkspaceTeam } from '../models/MessagesSubscriptionsNewOrganisationWorkspaceTeam';
import type { MessagesSubscriptionsOrganisation } from '../models/MessagesSubscriptionsOrganisation';
import type { MessagesSubscriptionsOrganisationProject } from '../models/MessagesSubscriptionsOrganisationProject';
import type { MessagesSubscriptionsOrganisationSummary } from '../models/MessagesSubscriptionsOrganisationSummary';
import type { MessagesSubscriptionsOrganisationTeam } from '../models/MessagesSubscriptionsOrganisationTeam';
import type { MessagesSubscriptionsOrganisationTeamUser } from '../models/MessagesSubscriptionsOrganisationTeamUser';
import type { MessagesSubscriptionsOrganisationUser } from '../models/MessagesSubscriptionsOrganisationUser';
import type { MessagesSubscriptionsOrganisationWorkspace } from '../models/MessagesSubscriptionsOrganisationWorkspace';
import type { MessagesSubscriptionsProject } from '../models/MessagesSubscriptionsProject';
import type { MessagesSubscriptionsUpdateEntity } from '../models/MessagesSubscriptionsUpdateEntity';
import type { MessagesSubscriptionsUpdateOrganisationTeam } from '../models/MessagesSubscriptionsUpdateOrganisationTeam';
import type { MessagesSubscriptionsUpdateOrganisationUser } from '../models/MessagesSubscriptionsUpdateOrganisationUser';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SubscriptionsApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns MessagesSubscriptionsOrganisationSummary OK
     * @throws ApiError
     */
    public getSubscriptionsApiGetOrganisations(): CancelablePromise<Array<MessagesSubscriptionsOrganisationSummary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisations',
        });
    }

    /**
     *  (Authorised Roles: SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationSummary OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddOrganisation(
        requestBody?: MessagesSubscriptionsOrganisation,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationSummary>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationSummary OK
     * @throws ApiError
     */
    public postSubscriptionsApiUpdateOrganisation(
        requestBody?: MessagesSubscriptionsOrganisation,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationSummary>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * admin and super can freeze any organisation
     * project lead can only freeze projects from own organisation (Authorised Roles: ProjectAdministrator, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationSummary OK
     * @throws ApiError
     */
    public postSubscriptionsApiFreezeOrganisation(
        requestBody?: MessagesSubscriptionsOrganisation,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationSummary>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/freeze',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, PlatformAdministrator, SuperUser)
     * @param orgId
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public getSubscriptionsApiListOrganisationUsers(
        orgId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisation/users',
            query: {
                'orgId': orgId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddUser(
        requestBody?: MessagesSubscriptionsNewOrganisationUser,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/users',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public postSubscriptionsApiUpdateUser(
        requestBody?: MessagesSubscriptionsUpdateOrganisationUser,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/users',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param userId
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiDeleteUser(
        userId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisation/users',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param orgId
     * @returns MessagesSubscriptionsOrganisationProject OK
     * @throws ApiError
     */
    public getSubscriptionsApiListOrganisationProjects(
        orgId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationProject>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisation/projects',
            query: {
                'orgId': orgId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsProject OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddProject(
        requestBody?: MessagesSubscriptionsProject,
    ): CancelablePromise<Array<MessagesSubscriptionsProject>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/projects',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsProject OK
     * @throws ApiError
     */
    public postSubscriptionsApiUpdateProject(
        requestBody?: MessagesSubscriptionsProject,
    ): CancelablePromise<Array<MessagesSubscriptionsProject>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/projects',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsProject OK
     * @throws ApiError
     */
    public postSubscriptionsApiFreezeProject(
        requestBody?: MessagesSubscriptionsProject,
    ): CancelablePromise<Array<MessagesSubscriptionsProject>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/projects/freeze',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsProject OK
     * @throws ApiError
     */
    public postSubscriptionsApiUnfreezeProject(
        requestBody?: MessagesSubscriptionsProject,
    ): CancelablePromise<Array<MessagesSubscriptionsProject>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/projects/unfreeze',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param projectId
     * @param adminId
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddProjectAdmin(
        projectId?: number,
        adminId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationUser> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/projects/admins',
            query: {
                'projectId': projectId,
                'adminId': adminId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param projectId
     * @param adminId
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiRemoveProjectAdmin(
        projectId?: number,
        adminId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationUser> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisation/projects/admins',
            query: {
                'projectId': projectId,
                'adminId': adminId,
            },
        });
    }

    /**
     *  (Authorised Roles: PlatformAdministrator, SuperUser)
     * @param projectId
     * @param adminId
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddProjectLeader(
        projectId?: number,
        adminId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationUser> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/projects/leaders',
            query: {
                'projectId': projectId,
                'adminId': adminId,
            },
        });
    }

    /**
     *  (Authorised Roles: PlatformAdministrator, SuperUser)
     * @param projectId
     * @param adminId
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiRemoveProjectLeader(
        projectId?: number,
        adminId?: number,
    ): CancelablePromise<MessagesSubscriptionsOrganisationUser> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisation/projects/leaders',
            query: {
                'projectId': projectId,
                'adminId': adminId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param userId
     * @returns MessagesSubscriptionsOrganisationUser OK
     * @throws ApiError
     */
    public postSubscriptionsApiFreezeUser(
        userId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationUser>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/users/freeze',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param orgId
     * @returns MessagesSubscriptionsOrganisationTeam OK
     * @throws ApiError
     */
    public getSubscriptionsApiGetOrganisationTeams(
        orgId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeam>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisations/teams',
            query: {
                'orgId': orgId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationTeam OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddTeam(
        requestBody?: MessagesSubscriptionsNewOrganisationTeam,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeam>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/teams',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationTeam OK
     * @throws ApiError
     */
    public postSubscriptionsApiUpdateTeam(
        requestBody?: MessagesSubscriptionsUpdateOrganisationTeam,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeam>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/teams',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param teamId
     * @returns MessagesSubscriptionsOrganisationTeam OK
     * @throws ApiError
     */
    public postSubscriptionsApiFreezeTeam(
        teamId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeam>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisation/teams/freeze',
            query: {
                'teamId': teamId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationTeamUser OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddTeamUser(
        requestBody?: MessagesSubscriptionsOrganisationTeamUser,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeamUser>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisation/teams/users',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationTeamUser OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiDeleteTeamUser(
        requestBody?: MessagesSubscriptionsOrganisationTeamUser,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationTeamUser>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisation/teams/users',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param orgId
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public getSubscriptionsApiGetOrganisationWorkspaces(
        orgId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsOrganisationWorkspace>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisations/workspaces',
            query: {
                'orgId': orgId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddOrganisationWorkspaces(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspace,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisations/workspaces',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiDeleteOrganisationWorkspaces(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspace,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisations/workspaces',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddProjectTeamToWorkspace(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspaceTeam,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisations/workspaces/projectTeam',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiDeleteProjectTeamFromWorkspace(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspaceTeam,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisations/workspaces/projectTeam',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public putSubscriptionsApiAddSupportTeamToWorkspace(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspaceTeam,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/subscriptions/organisations/workspaces/supportTeam',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsOrganisationWorkspace OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiDeleteSupportTeamFromWorkspace(
        requestBody?: MessagesSubscriptionsNewOrganisationWorkspaceTeam,
    ): CancelablePromise<MessagesSubscriptionsOrganisationWorkspace> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisations/workspaces/supportTeam',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param workspaceId
     * @returns MessagesSubscriptionsEntity OK
     * @throws ApiError
     */
    public getSubscriptionsApiGetOrganisationWorkspaceEntities(
        workspaceId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/subscriptions/organisations/workspaces/entities',
            query: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param requestBody
     * @returns MessagesSubscriptionsEntity OK
     * @throws ApiError
     */
    public postSubscriptionsApiUpdateEntity(
        requestBody?: MessagesSubscriptionsUpdateEntity,
    ): CancelablePromise<Array<MessagesSubscriptionsEntity>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/subscriptions/organisations/workspaces/entities',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param entityId
     * @returns MessagesSubscriptionsEntity OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiDeleteEntity(
        entityId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsEntity>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisations/workspaces/entities',
            query: {
                'entityId': entityId,
            },
        });
    }

    /**
     *  (Authorised Roles: ProjectAdministrator, ProjectLeader, PlatformAdministrator, SuperUser)
     * @param entityId
     * @returns MessagesSubscriptionsEntity OK
     * @throws ApiError
     */
    public deleteSubscriptionsApiDeleteEntityFiles(
        entityId?: number,
    ): CancelablePromise<Array<MessagesSubscriptionsEntity>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/subscriptions/organisations/workspaces/entities/files',
            query: {
                'entityId': entityId,
            },
        });
    }

}
