import './LoginPage.scss';
import { memo } from "react";
import { useLogin } from "../../hooks";

export const LoginPage = memo(() => {
  // TODO this page should be configuration too [e.g. Platform::login_page?]...
  const { isLoggingIn, isError } = useLogin();

  return (
    <div className="ux-login-page">
      {isLoggingIn && (
        <div>Please wait while we verify your account...</div>
      )}
      {isError && (
        <div>We failed to verify your account. Please click below to navigate back to the home screen</div>
      )}
      <a href={window.location.origin}>
        Navigate back to the home page
      </a>
    </div>
  );
});
