import "./UserWidget.scss";
import {Icon} from "../../ui/Icon"
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import  { useState } from "react";
import { PWC_ID_CONFIG } from "src/const";
import { useLogout, useFlushRedis } from "src/hooks";
import { useNavigate } from "react-router";

// TODO - move to utils
// function hasRoles(userRole: number, rolesArray: number[]) {
//     // Calculate the total value of all roles in the array
//     const totalRolesValue = rolesArray.reduce((acc, role) => acc + role, 0);

//     // Check if the totalRolesValue is allowed
//     return (userRole & totalRolesValue) === totalRolesValue;
// }
// const superUserRoles = [16]
// const subscriptionUserRoles = [2,4,8,16]

export function UserWidget(){
  const loggedIn = sessionStorage.getItem("token") !== null;
  const logout = useLogout();
  const flushRedis = useFlushRedis();
  const navigate = useNavigate();

  const handleLogin = () => {
    window.location.href = PWC_ID_CONFIG.authUrl();
  };
  
  const roles:number = parseInt(sessionStorage.getItem('roles') || "1");

  const isSuperUser = (roles & 16) > 0;
  const isSubUser = (roles & 2) > 0 || (roles & 4) > 0 || (roles & 8) > 0 || (roles & 16) > 0;
  const isImagesUser = (roles & 2) > 0 || (roles & 4) > 0 || (roles & 8) > 0 || (roles & 16) > 0;

  return(
    <div className="user-widget-wrapper">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className="user-widget-trigger">
          <div className = "userwidget"  onClick={handleLogin}>
            <Icon icon="menu-outline" debug="menu-outline" />
            <Icon icon="avatar-outline" debug="avatar-outline" />
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className="DropdownMenuContent">
            <DropdownMenu.Arrow className="DropdownMenuArrow" />
            {loggedIn && <DropdownMenu.Item className="DropdownMenuItem" onClick={logout}>
              Logout
            </DropdownMenu.Item>}
            {loggedIn && isSubUser && <DropdownMenu.Item className="DropdownMenuItem" onClick={() => navigate("/subscriptions")}>
              Subscriptions
            </DropdownMenu.Item>}
            {loggedIn && isImagesUser && <DropdownMenu.Item className="DropdownMenuItem" onClick={() => navigate("/images")}>
              Image library
            </DropdownMenu.Item>}
            {loggedIn && isSuperUser && <DropdownMenu.Item className="DropdownMenuItem" onClick={flushRedis}>
              Flush Redis
            </DropdownMenu.Item>}            
            {!loggedIn && <DropdownMenu.Item className="DropdownMenuItem" onClick={handleLogin}>
              Login
            </DropdownMenu.Item>}
            {/* <DropdownMenu.Item className="DropdownMenuItem">
              Request Access
            </DropdownMenu.Item> */}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  )
}
