import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import { TextFieldFragmentDoc } from '../../ux/TextField/TextField.generated';
export type FieldI_BooleanField_Fragment = { __typename?: 'BooleanField', id: string, label?: string | null, description?: string | null, information?: string | null };

export type FieldI_DateField_Fragment = { __typename?: 'DateField', id: string, label?: string | null, description?: string | null, information?: string | null };

export type FieldI_DateTimeField_Fragment = { __typename?: 'DateTimeField', id: string, label?: string | null, description?: string | null, information?: string | null };

export type FieldI_DownloadFileField_Fragment = { __typename?: 'DownloadFileField', id: string, label?: string | null, description?: string | null, information?: string | null };

export type FieldI_MultiSelectField_Fragment = { __typename?: 'MultiSelectField', id: string, label?: string | null, description?: string | null, information?: string | null };

export type FieldI_NumberField_Fragment = { __typename?: 'NumberField', id: string, label?: string | null, description?: string | null, information?: string | null };

export type FieldI_NumberRangeField_Fragment = { __typename?: 'NumberRangeField', id: string, label?: string | null, description?: string | null, information?: string | null };

export type FieldI_SelectField_Fragment = { __typename?: 'SelectField', id: string, label?: string | null, description?: string | null, information?: string | null };

export type FieldI_TextField_Fragment = { __typename?: 'TextField', id: string, label?: string | null, description?: string | null, information?: string | null };

export type FieldI_UploadFileField_Fragment = { __typename?: 'UploadFileField', id: string, label?: string | null, description?: string | null, information?: string | null };

export type FieldIFragment = FieldI_BooleanField_Fragment | FieldI_DateField_Fragment | FieldI_DateTimeField_Fragment | FieldI_DownloadFileField_Fragment | FieldI_MultiSelectField_Fragment | FieldI_NumberField_Fragment | FieldI_NumberRangeField_Fragment | FieldI_SelectField_Fragment | FieldI_TextField_Fragment | FieldI_UploadFileField_Fragment;

export type NumberFieldFragment = { __typename?: 'NumberField', value?: number | null, localEdited?: boolean | null, localValue?: number | null, id: string, label?: string | null, description?: string | null, information?: string | null };

export type UploadFileFieldFragment = { __typename?: 'UploadFileField', overrideAppId?: string | null, templateId?: string | null, entityId?: string | null, id: string, label?: string | null, description?: string | null, information?: string | null };

export type DownloadFileFieldFragment = { __typename?: 'DownloadFileField', id: string, label?: string | null, description?: string | null, information?: string | null, selection?: { __typename?: 'SelectField', style?: Types.SelectStyle | null, localEdited?: boolean | null, id: string, label?: string | null, description?: string | null, information?: string | null, options?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null, selection?: { __typename?: 'SelectOption', label: string, value: string } | null, localSelection?: { __typename?: 'SelectOption', label: string, value: string } | null } | null };

export type SelectFieldFragment = { __typename?: 'SelectField', style?: Types.SelectStyle | null, localEdited?: boolean | null, id: string, label?: string | null, description?: string | null, information?: string | null, options?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null, selection?: { __typename?: 'SelectOption', label: string, value: string } | null, localSelection?: { __typename?: 'SelectOption', label: string, value: string } | null };

export type BooleanFieldFragment = { __typename?: 'BooleanField', state?: boolean | null, localEdited?: boolean | null, localState?: boolean | null, id: string, label?: string | null, description?: string | null, information?: string | null };

export type MultiSelectFieldFragment = { __typename?: 'MultiSelectField', style?: Types.SelectStyle | null, localEdited?: boolean | null, id: string, label?: string | null, description?: string | null, information?: string | null, options?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null, selections?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null, localSelections?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null };

export type DateFieldFragment = { __typename?: 'DateField', date?: any | null, localEdited?: boolean | null, localDate?: string | null, id: string, label?: string | null, description?: string | null, information?: string | null };

export type DateTimeFieldFragment = { __typename?: 'DateTimeField', dateTime?: any | null, localEdited?: boolean | null, localDateTime?: string | null, id: string, label?: string | null, description?: string | null, information?: string | null };

export type Field_BooleanField_Fragment = { __typename: 'BooleanField', id: string, label?: string | null, description?: string | null, information?: string | null, state?: boolean | null, localEdited?: boolean | null, localState?: boolean | null };

export type Field_DateField_Fragment = { __typename: 'DateField', id: string, label?: string | null, description?: string | null, information?: string | null, date?: any | null, localEdited?: boolean | null, localDate?: string | null };

export type Field_DateTimeField_Fragment = { __typename: 'DateTimeField', id: string, label?: string | null, description?: string | null, information?: string | null, dateTime?: any | null, localEdited?: boolean | null, localDateTime?: string | null };

export type Field_DownloadFileField_Fragment = { __typename: 'DownloadFileField', id: string, label?: string | null, description?: string | null, information?: string | null, selection?: { __typename?: 'SelectField', style?: Types.SelectStyle | null, localEdited?: boolean | null, id: string, label?: string | null, description?: string | null, information?: string | null, options?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null, selection?: { __typename?: 'SelectOption', label: string, value: string } | null, localSelection?: { __typename?: 'SelectOption', label: string, value: string } | null } | null };

export type Field_MultiSelectField_Fragment = { __typename: 'MultiSelectField', id: string, label?: string | null, description?: string | null, information?: string | null, style?: Types.SelectStyle | null, localEdited?: boolean | null, options?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null, selections?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null, localSelections?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null };

export type Field_NumberField_Fragment = { __typename: 'NumberField', id: string, label?: string | null, description?: string | null, information?: string | null, value?: number | null, localEdited?: boolean | null, localValue?: number | null };

export type Field_NumberRangeField_Fragment = { __typename: 'NumberRangeField', id: string, label?: string | null, description?: string | null, information?: string | null };

export type Field_SelectField_Fragment = { __typename: 'SelectField', id: string, label?: string | null, description?: string | null, information?: string | null, style?: Types.SelectStyle | null, localEdited?: boolean | null, options?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null, selection?: { __typename?: 'SelectOption', label: string, value: string } | null, localSelection?: { __typename?: 'SelectOption', label: string, value: string } | null };

export type Field_TextField_Fragment = { __typename: 'TextField', id: string, label?: string | null, description?: string | null, information?: string | null, text?: string | null, localEdited?: boolean | null, localText?: string | null };

export type Field_UploadFileField_Fragment = { __typename: 'UploadFileField', id: string, label?: string | null, description?: string | null, information?: string | null, overrideAppId?: string | null, templateId?: string | null, entityId?: string | null };

export type FieldFragment = Field_BooleanField_Fragment | Field_DateField_Fragment | Field_DateTimeField_Fragment | Field_DownloadFileField_Fragment | Field_MultiSelectField_Fragment | Field_NumberField_Fragment | Field_NumberRangeField_Fragment | Field_SelectField_Fragment | Field_TextField_Fragment | Field_UploadFileField_Fragment;

export type EditTextFieldFragment = { __typename?: 'TextField', id: string, localText?: string | null };

export type EditNumberFieldFragment = { __typename?: 'NumberField', id: string, localValue?: number | null };

export type EditSelectFieldFragment = { __typename?: 'SelectField', id: string, localSelection?: { __typename?: 'SelectOption', label: string, value: string } | null };

export type EditMultiSelectFieldFragment = { __typename?: 'MultiSelectField', id: string, localSelections?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null };

export type EditBooleanFieldFragment = { __typename?: 'BooleanField', id: string, localState?: boolean | null };

export type EditDateFieldFragment = { __typename?: 'DateField', id: string, localDate?: string | null };

export type EditDateTimeFieldFragment = { __typename?: 'DateTimeField', id: string, localDateTime?: string | null };

export type EditField_BooleanField_Fragment = { __typename: 'BooleanField', id: string, localState?: boolean | null };

export type EditField_DateField_Fragment = { __typename: 'DateField', id: string, localDate?: string | null };

export type EditField_DateTimeField_Fragment = { __typename: 'DateTimeField', id: string, localDateTime?: string | null };

export type EditField_DownloadFileField_Fragment = { __typename: 'DownloadFileField' };

export type EditField_MultiSelectField_Fragment = { __typename: 'MultiSelectField', id: string, localSelections?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null };

export type EditField_NumberField_Fragment = { __typename: 'NumberField', id: string, localValue?: number | null };

export type EditField_NumberRangeField_Fragment = { __typename: 'NumberRangeField' };

export type EditField_SelectField_Fragment = { __typename: 'SelectField', id: string, localSelection?: { __typename?: 'SelectOption', label: string, value: string } | null };

export type EditField_TextField_Fragment = { __typename: 'TextField', id: string, localText?: string | null };

export type EditField_UploadFileField_Fragment = { __typename: 'UploadFileField' };

export type EditFieldFragment = EditField_BooleanField_Fragment | EditField_DateField_Fragment | EditField_DateTimeField_Fragment | EditField_DownloadFileField_Fragment | EditField_MultiSelectField_Fragment | EditField_NumberField_Fragment | EditField_NumberRangeField_Fragment | EditField_SelectField_Fragment | EditField_TextField_Fragment | EditField_UploadFileField_Fragment;

export type ResetField_BooleanField_Fragment = { __typename: 'BooleanField', id: string, localState?: boolean | null };

export type ResetField_DateField_Fragment = { __typename: 'DateField', id: string, localDate?: string | null };

export type ResetField_DateTimeField_Fragment = { __typename: 'DateTimeField', id: string, localDateTime?: string | null };

export type ResetField_DownloadFileField_Fragment = { __typename: 'DownloadFileField' };

export type ResetField_MultiSelectField_Fragment = { __typename: 'MultiSelectField', id: string, localSelections?: Array<{ __typename?: 'SelectOption', label: string, value: string }> | null };

export type ResetField_NumberField_Fragment = { __typename: 'NumberField', id: string, localValue?: number | null };

export type ResetField_NumberRangeField_Fragment = { __typename: 'NumberRangeField' };

export type ResetField_SelectField_Fragment = { __typename: 'SelectField', id: string, localSelection?: { __typename?: 'SelectOption', label: string, value: string } | null };

export type ResetField_TextField_Fragment = { __typename: 'TextField', id: string, localText?: string | null };

export type ResetField_UploadFileField_Fragment = { __typename: 'UploadFileField' };

export type ResetFieldFragment = ResetField_BooleanField_Fragment | ResetField_DateField_Fragment | ResetField_DateTimeField_Fragment | ResetField_DownloadFileField_Fragment | ResetField_MultiSelectField_Fragment | ResetField_NumberField_Fragment | ResetField_NumberRangeField_Fragment | ResetField_SelectField_Fragment | ResetField_TextField_Fragment | ResetField_UploadFileField_Fragment;

export const FieldIFragmentDoc = gql`
    fragment FieldI on FieldI {
  id
  label
  description
  information
}
    `;
export const NumberFieldFragmentDoc = gql`
    fragment NumberField on NumberField {
  ...FieldI
  value
  localEdited @client
  localValue @client
}
    ${FieldIFragmentDoc}`;
export const UploadFileFieldFragmentDoc = gql`
    fragment UploadFileField on UploadFileField {
  ...FieldI
  overrideAppId
  templateId
  entityId
}
    ${FieldIFragmentDoc}`;
export const SelectFieldFragmentDoc = gql`
    fragment SelectField on SelectField {
  ...FieldI
  options {
    label
    value
  }
  selection {
    label
    value
  }
  style
  localEdited @client
  localSelection @client {
    label
    value
  }
}
    ${FieldIFragmentDoc}`;
export const DownloadFileFieldFragmentDoc = gql`
    fragment DownloadFileField on DownloadFileField {
  ...FieldI
  selection {
    ...SelectField
  }
}
    ${FieldIFragmentDoc}
${SelectFieldFragmentDoc}`;
export const BooleanFieldFragmentDoc = gql`
    fragment BooleanField on BooleanField {
  ...FieldI
  state
  localEdited @client
  localState @client
}
    ${FieldIFragmentDoc}`;
export const MultiSelectFieldFragmentDoc = gql`
    fragment MultiSelectField on MultiSelectField {
  ...FieldI
  options {
    label
    value
  }
  selections {
    label
    value
  }
  style
  localEdited @client
  localSelections @client {
    label
    value
  }
}
    ${FieldIFragmentDoc}`;
export const DateFieldFragmentDoc = gql`
    fragment DateField on DateField {
  ...FieldI
  date
  localEdited @client
  localDate @client
}
    ${FieldIFragmentDoc}`;
export const DateTimeFieldFragmentDoc = gql`
    fragment DateTimeField on DateTimeField {
  ...FieldI
  dateTime
  localEdited @client
  localDateTime @client
}
    ${FieldIFragmentDoc}`;
export const FieldFragmentDoc = gql`
    fragment Field on Field {
  __typename
  ...FieldI
  ...TextField
  ...NumberField
  ...UploadFileField
  ...DownloadFileField
  ...SelectField
  ...BooleanField
  ...MultiSelectField
  ...DateField
  ...DateTimeField
}
    ${FieldIFragmentDoc}
${TextFieldFragmentDoc}
${NumberFieldFragmentDoc}
${UploadFileFieldFragmentDoc}
${DownloadFileFieldFragmentDoc}
${SelectFieldFragmentDoc}
${BooleanFieldFragmentDoc}
${MultiSelectFieldFragmentDoc}
${DateFieldFragmentDoc}
${DateTimeFieldFragmentDoc}`;
export const EditTextFieldFragmentDoc = gql`
    fragment EditTextField on TextField {
  id
  localText @client
}
    `;
export const EditNumberFieldFragmentDoc = gql`
    fragment EditNumberField on NumberField {
  id
  localValue @client
}
    `;
export const EditSelectFieldFragmentDoc = gql`
    fragment EditSelectField on SelectField {
  id
  localSelection @client {
    label
    value
  }
}
    `;
export const EditMultiSelectFieldFragmentDoc = gql`
    fragment EditMultiSelectField on MultiSelectField {
  id
  localSelections @client {
    label
    value
  }
}
    `;
export const EditBooleanFieldFragmentDoc = gql`
    fragment EditBooleanField on BooleanField {
  id
  localState @client
}
    `;
export const EditDateFieldFragmentDoc = gql`
    fragment EditDateField on DateField {
  id
  localDate @client
}
    `;
export const EditDateTimeFieldFragmentDoc = gql`
    fragment EditDateTimeField on DateTimeField {
  id
  localDateTime @client
}
    `;
export const EditFieldFragmentDoc = gql`
    fragment EditField on Field {
  __typename
  ... on TextField {
    ...EditTextField
  }
  ... on NumberField {
    ...EditNumberField
  }
  ... on SelectField {
    ...EditSelectField
  }
  ... on MultiSelectField {
    ...EditMultiSelectField
  }
  ... on BooleanField {
    ...EditBooleanField
  }
  ... on DateField {
    ...EditDateField
  }
  ... on DateTimeField {
    ...EditDateTimeField
  }
}
    ${EditTextFieldFragmentDoc}
${EditNumberFieldFragmentDoc}
${EditSelectFieldFragmentDoc}
${EditMultiSelectFieldFragmentDoc}
${EditBooleanFieldFragmentDoc}
${EditDateFieldFragmentDoc}
${EditDateTimeFieldFragmentDoc}`;
export const ResetFieldFragmentDoc = gql`
    fragment ResetField on Field {
  ...EditField
}
    ${EditFieldFragmentDoc}`;