import { MessagesLandingMenuItem } from "@arq-apps/dotnet"
import { Button, Select, MultiSelect } from "@arq-apps/ui"
import { useEffect, useMemo, useState } from "react"
import { useEditableMenu } from "src/contexts/EditableMenuContext"

export const AddEditMenuItem = () => {
  const { pages, roles, menu, setMenu, indexArray, setIndexArray } = useEditableMenu();
  const [name, setName] = useState<string>();
  const [selectedPage, setSelectedPage] = useState<string>();
  const [selectedRoles, setSelectedRoles] = useState<string[]>();
  const [error, setError] = useState(false);

  const edit = useMemo(() => {
    if (!menu) return false
    var baseItems = menu?.items
    const existsArray = indexArray.map((index) => {
      if (baseItems && baseItems[index]) {
        baseItems = baseItems[index].items
        return true
      } else {
        return false
      }
    })
    return !existsArray.includes(false)
  }, [menu, indexArray])

  useEffect(() => {
    if (!edit) {
      setName(undefined)
      setSelectedPage(undefined)
      setSelectedRoles(undefined)
      return
    }
    let item: MessagesLandingMenuItem | undefined
    switch(indexArray.length) {
      case 1: { 
          item = menu?.items[indexArray[0]]
          break; 
      }
      case 2: { 
          item = menu?.items[indexArray[0]].items[indexArray[1]]
          break; 
      }
      case 3: { 
          item = menu?.items[indexArray[0]].items[indexArray[1]].items[indexArray[2]]
          break; 
      }
      default: { 
          item = undefined
          break; 
      }
    }
    if (!item) return
    setName(item.name)
    setSelectedPage(item.page_id)
    setSelectedRoles(item.roles)
  }, [indexArray, edit])

  const onConfirm = () => {
    setError(false)
    // error checking/validation
    if (!name || !selectedPage || !selectedRoles || selectedRoles?.length === 0) {
      setError(true)
      return
    }
    // updating menu with the new item
    var newItem: MessagesLandingMenuItem = {
      name: name,
      items: [],
      roles: selectedRoles,
      page_id: selectedPage,
      item_type: "menu_item",
      description: "",
    }
    // TODO! - confrim is different for edit mode
    if (indexArray.length === 1) setMenu(prev => {
      if (!prev?.items) return {items: [newItem]}
      if (edit) {
        newItem.items = prev.items[indexArray[0]].items
        return {items: [
          ...prev.items.slice(0, indexArray[0]),
          newItem,
          ...prev.items.slice(indexArray[0] + 1)
        ]}
      }
      if (!prev?.items) return {items: [newItem]}
      const newItems = [...prev?.items, newItem]
      return {items: newItems}
      // var newItems = prev.items
      // newItems[indexArray[0]].items = [
      //   ...prev.items[indexArray[0]].items ?? [],
      //   newItem
      // ]
      // return {items: newItems}
    })
    if (indexArray.length === 2) setMenu(prev => {
      if (!prev?.items) return {items: [newItem]}
      var newItems = prev.items
      if (edit) {
        newItem.items = prev.items[indexArray[0]].items[indexArray[1]].items
        newItems[indexArray[0]].items = [
          ...prev.items[indexArray[0]].items.slice(0, indexArray[1]),
          newItem,
          ...prev.items[indexArray[0]].items.slice(indexArray[1] + 1)
        ]
        return {items: newItems}
      }
      newItems[indexArray[0]].items = [
        ...prev.items[indexArray[0]].items ?? [],
        newItem
      ]
      return {items: newItems}
    })
    if (indexArray.length === 3) setMenu(prev => {
      if (!prev?.items) return {items: [newItem]}
      var newItems = prev.items
      if (edit) {
        newItem.items = prev.items[indexArray[0]].items[indexArray[1]].items[indexArray[2]].items
        newItems[indexArray[0]].items[indexArray[1]].items = [
          ...prev.items[indexArray[0]].items[indexArray[1]].items.slice(0, indexArray[2]),
          newItem,
          ...prev.items[indexArray[0]].items[indexArray[1]].items.slice(indexArray[2] + 1)
        ]
        return {items: newItems}
      }
      newItems[indexArray[0]].items[indexArray[1]].items = [
        ...prev.items[indexArray[0]].items[indexArray[1]].items ?? [],
        newItem
      ]
      return {items: newItems}
    })
    // resetting the add/edit componenet after confirm
    setName(undefined)
    setSelectedPage(undefined)
    setSelectedRoles(undefined)
    setIndexArray([])
  }

  const onRoleSelect = (option: string) => {
    const currentSelections = selectedRoles ?? [];
    const filtered = currentSelections.filter(it => it !== option);
    setSelectedRoles((filtered.length < currentSelections.length) ? filtered : [...filtered, option])
  }

  return (
    <div className='add-edit-item'>
      {/* TODO! - write error css class */}
      <label>Name:</label>
      <input value={name ?? ""} onChange={(e) => setName(e.target.value)} />
      <label>Page id:</label>
      <Select
        selected={selectedPage}
        options={pages.map(page => page.page).filter(Boolean) as string[]}
        onOptionSelected={(option: string) => setSelectedPage(option)}
        placeholder="Select page id"
        class="vanilla"
      />
      {/* {!selectedPage && error && <div>Please select page</div>} */}
      <label>Roles:</label>
      <MultiSelect
        placeholder={"Select roles"}
        options={roles.map(role => role.name) as string[]}
        selections={selectedRoles ?? []}
        onOptionToggled={onRoleSelect}
        onClearAll={() => setSelectedRoles([])}
        onSelectAll={() => setSelectedRoles(roles.map(role => role.name) as string[])}
      />
      <div className='action-container'>
        <Button text="Cancel" theme="white" onClick={() => setIndexArray([])}/>
        <Button text="Confirm" theme="default" onClick={onConfirm}/>
      </div>
    </div>
  )
}