import { NavLink } from "@arq-apps/generated";

export interface BuildNavLinkProps {
  currentPathname: string,
  currentSearch: string,
  link: NavLink,
}

export function buildNavLink(props: BuildNavLinkProps): {
  pathname: string,
  search: string,
  active: boolean,
  childActive: boolean,
} {
  const params = new URLSearchParams(props.currentSearch);
  const link = props.link;
  let path = props.currentPathname;
  let pageActive = true;
  let childActive = true;
  let paramsActive = true;
  if (link.pageId) {
    pageActive = props.currentPathname === link.pageId;
    childActive = !pageActive && props.currentPathname.startsWith(link.pageId);
    path = link.pageId;
  } else if (link.appId) {
    // set or replace app ID path fragment
    if (!props.currentPathname.startsWith(`/${ link.appId }/`)) {
      paramsActive = false;  // app is not currently active
    }
    const match = props.currentPathname.match(/^\/([a-zA-Z0-9_-]+)\/(.*)$/i);
    path = `/${ link.appId }/${ match ? match[2] : "" }`;
  }
  if (link.projectId) {
    // set or replace project ID query param
    const pid = `${ link.projectId }`;
    if (!params.has("pid") || params.get("pid") !== pid) {
      paramsActive = false;  // project ID is not currently active
    }
    params.set("pid", pid);
  }
  if (link.toggleParams && link.toggleParams.length > 0) {
    // toggle each param
    link.toggleParams?.map(({key, value}) => {
      if (!params.has(key) || (value && params.get(key) !== value)) {
        paramsActive = false;
        params.set(key, value ?? "");
      } else {
        params.delete(key);
      }
    });
  }
  if (link.setParams && link.setParams.length > 0) {
    // set each param
    link.setParams?.map(({key, value}) => {
      if (!params.has(key) || (value && params.get(key) !== value)) {
        paramsActive = false;
        params.set(key, value ?? "");
      }
    });
  }
  return {
    active: pageActive && paramsActive,
    childActive: childActive && paramsActive,
    // paramsActive,
    pathname: path,
    search: params.toString(),
  };
}
