import './DatePicker.scss';
import { useCallback } from 'react'
import { makeStyles } from "@fluentui/react-components";
import { DatePicker as D, DatePickerProps } from "@fluentui/react-datepicker-compat";
import { dateToString } from '@arq-apps/util'

const useStyles = makeStyles({
  control: {
    maxWidth: "300px",
  },
});
    
function DatePicker(props: DatePickerProps) {
  const styles = useStyles();

  const formatDate = useCallback((date: Date | null | undefined) => {
    if (!date) return "No date"
    return dateToString(date)
  }, [])

  return (
    <D
      {...props}
      className={styles.control}
      placeholder="Select a date..."
      allowTextInput
      formatDate={formatDate}
    />
  )
}

export default DatePicker;

//** Leaving a list of potentially useful DatePickerProps here */
// VALUE/FORMAT RELATED
// parseDateFromString={(dateStr) => Date | null} 
// value={null}
// onChange={(e) => {}}
// onSelectDate={(date) => {}}

// VALIDATION RELATED
// required
// onValidationResult={(data) => {}}
// defaultDatePickerErrorStrings for validation error messages

// APPEARANCE RELATED
// size="medium"
// appearance="outline"
// underlined={false}
// contentBefore={<div>Hello</div>}
// contentAfter={<div>Bye</div>}
// positioning={}
// firstDayOfWeek={0}
// minDate
// maxDate