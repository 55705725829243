/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MessagesAppSettingsAppSettingsType {
    None = 0,
    App = 1,
    Roles = 2,
    Teams = 3,
    Organisation = 4,
    TeamRoles = 5,
    EntityTypes = 6,
    FileTemplates = 7,
    FileTemplateRoles = 8,
    ApplicationAttributes = 9,
    Glossary = 10,
    Faq = 11,
    ConfigSettings = 12,
    Pages = 13,
    PageRoles = 14,
    DataAssets = 15,
    DataAssetPermissions = 16,
}
