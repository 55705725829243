import './Landing.scss';
import { LandingLoggedOut } from './LandingLoggedOut';
import { LandingLoggedIn } from './LandingLoggedIn';
import { LandingProject } from './LandingProject';
import { LandingApplication } from './LandingApplication';
import { useLandingContext } from 'src/contexts/LandingContext';
import { useEffect } from 'react'

export function LandingControl() {
  const loggedIn = sessionStorage.getItem("token") !== null
  const { appId, projectId, updateLandingContext } = useLandingContext()

  useEffect(() => {
    if (loggedIn && appId && projectId) updateLandingContext({ appId: undefined, projectId: undefined })
  }, [])
  
  if (!loggedIn) {
    return <LandingLoggedOut />
  }

  if (!appId && !projectId) {
    return <LandingLoggedIn />
  }

  if (!appId && projectId) {
    return <LandingProject />
  }

  if (appId && !projectId) {
    return <LandingApplication />
  }

  return (
    <div className="landing">
        {`logged in: ${loggedIn}, appId: ${appId}, projectId:${projectId}`}
    </div>
  );
}