import "./SubscriptionControl.scss";
import { Logo, UserWidget } from "@arq-apps/ux";
import { Footer } from "@arq-apps/ui";
import { useDotnetClient } from "src/contexts/DotnetClientContext"
import { useEffect, useMemo, useState } from "react"
import { MessagesSubscriptionsOrganisationSummary } from "@arq-apps/dotnet"
import { DataGridComponent } from "../DataGrid/DataGridComponent"
import { TableCellLayout, TableColumnDefinition, createTableColumn } from "@fluentui/react-components";

type LabelValueCell = {
  label: string;
  value: string;
}

type BooleanCell = {
  state: boolean
}

type NumberValueCell = {
  label: number;
  value: number;
}

export type OrganisationItem = {
  name: LabelValueCell
  description: LabelValueCell
  // active: BooleanCell
  users: NumberValueCell
  projects: NumberValueCell
  teams: NumberValueCell
  workspaces: NumberValueCell
}

export const SubscriptionControl = () => {
  const { dmpClient } = useDotnetClient();
  const [organisations, setOrganisations] = useState<MessagesSubscriptionsOrganisationSummary[]>([]);

  const fetchOrganisationsList = async () => {
    dmpClient?.subscriptionsApi
    .getSubscriptionsApiGetOrganisations()
      .then((result) => {
        setOrganisations(result)
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchOrganisationsList()
  }, [])

  const items = useMemo(() => {
    return organisations.map((org) => {
      return {
        name: {label: org.name, value: org.name},
        description: {label: org.description, value: org.description},
        // active: {label: org.active, value: org.active},
        users: {label: org.users, value: org.users},
        // usersFrozen: {label: org.usersFrozen, value: org.usersFrozen},
        projects: {label: org.projects, value: org.projects},
        // usersFrozen: {label: org.usersFrozen, value: org.usersFrozen},
        teams: {label: org.teams, value: org.teams},
        workspaces: {label: org.workspaces, value: org.workspaces},
        // usersFrozen: {label: org.usersFrozen, value: org.usersFrozen},
      }
    })
  }, [organisations])

  const columns: TableColumnDefinition<OrganisationItem>[] = [
    createTableColumn<OrganisationItem>({
      columnId: "name",
      compare: (a, b) => {
        return a.name.label.localeCompare(b.name.label);
      },
      renderHeaderCell: () => {
        return "Name";
      },
      renderCell: (item) => {
        return item.name.label
      },
    }),
    createTableColumn<OrganisationItem>({
      columnId: "description" ,
      compare: (a, b) => {
        return a.description.label.localeCompare(b.description.label);
      },
      renderHeaderCell: () => {
        return "Description";
      },
      renderCell: (item) => {
        return item.description.label
      },
    }),
    createTableColumn<OrganisationItem>({
      columnId: "users",
      compare: (a, b) => {
        return a.users.value - b.users.value;
      },
      renderHeaderCell: () => {
        return "Users";
      },
      renderCell: (item) => {
        return item.users.label;
      },
    }),
    createTableColumn<OrganisationItem>({
      columnId: "projects",
      compare: (a, b) => {
        return a.projects.value - b.projects.value;
      },
      renderHeaderCell: () => {
        return "Projects";
      },
      renderCell: (item) => {
        return item.projects.label;
      },
    }),
    createTableColumn<OrganisationItem>({
      columnId: "teams",
      compare: (a, b) => {
        return a.teams.value - b.teams.value;
      },
      renderHeaderCell: () => {
        return "Teams";
      },
      renderCell: (item) => {
        return item.teams.label;
      },
    }),
    createTableColumn<OrganisationItem>({
      columnId: "workspaces",
      compare: (a, b) => {
        return a.workspaces.value - b.workspaces.value;
      },
      renderHeaderCell: () => {
        return "Workspaces";
      },
      renderCell: (item) => {
        return item.workspaces.label;
      },
    }),
  ];

  return (
    <div className="subscriptions">
      <div className="header">
        <Logo/> 
        <UserWidget />
      </div>
     
      <div style={{padding: "20px", backgroundColor: "#F3F3F3", overflowY: "auto"}}>
        <DataGridComponent 
          items={items}
          columns={columns}
        />
      </div>
      <Footer />
    </div>
  )
}