import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MenuItemDetailsFragment = { __typename?: 'AppMenuItem', link: { __typename?: 'NavLink', appId?: string | null, projectId?: number | null, pageId?: string | null, label?: string | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null } };

export type MenuFragment = { __typename?: 'AppMenu', items: Array<{ __typename?: 'AppMenuItem', children?: Array<{ __typename?: 'AppMenuItem', children?: Array<{ __typename?: 'AppMenuItem', link: { __typename?: 'NavLink', appId?: string | null, projectId?: number | null, pageId?: string | null, label?: string | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null } }> | null, link: { __typename?: 'NavLink', appId?: string | null, projectId?: number | null, pageId?: string | null, label?: string | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null } }> | null, link: { __typename?: 'NavLink', appId?: string | null, projectId?: number | null, pageId?: string | null, label?: string | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null } }> };

export type OldMenuQueryVariables = Types.Exact<{
  appId: Types.Scalars['ID'];
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type OldMenuQuery = { __typename?: 'Query', app?: { __typename?: 'App', appMenu: { __typename?: 'AppMenu', items: Array<{ __typename?: 'AppMenuItem', children?: Array<{ __typename?: 'AppMenuItem', children?: Array<{ __typename?: 'AppMenuItem', link: { __typename?: 'NavLink', appId?: string | null, projectId?: number | null, pageId?: string | null, label?: string | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null } }> | null, link: { __typename?: 'NavLink', appId?: string | null, projectId?: number | null, pageId?: string | null, label?: string | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null } }> | null, link: { __typename?: 'NavLink', appId?: string | null, projectId?: number | null, pageId?: string | null, label?: string | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null } }> } } | null };

export const MenuItemDetailsFragmentDoc = gql`
    fragment MenuItemDetails on AppMenuItem {
  link {
    appId
    projectId
    pageId
    label
    toggleParams {
      key
      value
    }
    setParams {
      key
      value
    }
  }
}
    `;
export const MenuFragmentDoc = gql`
    fragment Menu on AppMenu {
  items {
    ...MenuItemDetails
    children {
      ...MenuItemDetails
      children {
        ...MenuItemDetails
      }
    }
  }
}
    ${MenuItemDetailsFragmentDoc}`;
export const OldMenuDocument = gql`
    query OldMenu($appId: ID!, $projectId: Int) {
  app(id: $appId, projectId: $projectId, filters: []) {
    appMenu {
      ...Menu
    }
  }
}
    ${MenuFragmentDoc}`;

/**
 * __useOldMenuQuery__
 *
 * To run a query within a React component, call `useOldMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useOldMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOldMenuQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useOldMenuQuery(baseOptions: Apollo.QueryHookOptions<OldMenuQuery, OldMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OldMenuQuery, OldMenuQueryVariables>(OldMenuDocument, options);
      }
export function useOldMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OldMenuQuery, OldMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OldMenuQuery, OldMenuQueryVariables>(OldMenuDocument, options);
        }
export type OldMenuQueryHookResult = ReturnType<typeof useOldMenuQuery>;
export type OldMenuLazyQueryHookResult = ReturnType<typeof useOldMenuLazyQuery>;
export type OldMenuQueryResult = Apollo.QueryResult<OldMenuQuery, OldMenuQueryVariables>;