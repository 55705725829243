import './Platform.scss';
import 'react-toastify/dist/ReactToastify.css';
// import 'react-toastify/dist/ReactToastify.min.css';
import { App } from "../App";
import { memo, useEffect } from "react";
import { Centered } from "../Centered";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginPage } from "../LoginPage";
import { Footer } from 'src/ui/Footer'
import { useLandingContext } from 'src/contexts/LandingContext'

export const Platform = memo(() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { appId } = useLandingContext();

  useEffect(() => {
    console.debug(`pathname ${pathname}`);
    if (pathname === '/')
      navigate({ pathname: '/landing/' });
  }, [pathname]);

  if (pathname.startsWith("/oauth"))
    return <LoginPage />;

  else if (!appId) {
    return (<Centered>Redirecting</Centered>);
  }
  else
    return (
      <div className="ux-platform">
        {appId && <App />}
        <Footer />
      </div>
    );
});
