import './Button.scss';
import { IconName } from "../Theme";
import { Icon } from "../Icon";
import { Loading } from '../Loading'

// TODO memo() to configure as pure with shallow comparisons
//  https://reactjs.org/docs/react-api.html#reactmemo

export const ButtonThemes = [
  'default',
  'orange',
  'tangerine',
  'yellow',
  'rose',
  'grey',
  'dark',
  'white',
] as const;
export type ButtonTheme = typeof ButtonThemes[number];

export interface ButtonProps {
  text: string,
  theme?: ButtonTheme,
  disabled?: boolean,
  loading?: boolean,
  leftIcon?: IconName,
  rightIcon?: IconName,
  size?: 'normal' | 'table',
  table?: boolean,
  active?: boolean,
  onClick?: () => void,
}

export function Button(props: ButtonProps) {
  const classes = [
    'button',
    `theme-${props.theme ?? 'orange'}`,
    // props.tall ? 'tall' : '',
    props.table ? 'table' : '',
    props.active ? 'active' : '',
  ].filter(Boolean).join(' ');
  return (
    // <StyledButton {...props}>
    <button className={ classes } disabled={props.disabled} onClick={ props.onClick }>
      {props.loading 
        ? 
        <Loading />
        :
        <>
        { props.leftIcon && (
          <Icon icon={ props.leftIcon } />
        ) }
        <span>{ props.text ?? 'Button' }</span>
        { props.rightIcon && (
          <Icon icon={ props.rightIcon } />
        ) }
        </>
      }
    </button>
    // </StyledButton>
  );
}

export default Button;
