import './Loading.scss';

export interface LoadingProps {
  message?: string
}

export function Loading(props: LoadingProps) {
  const message = props.message ?? 'Loading, please wait...';

  return (
    <div className="loading">
      <div>
        { message }
      </div>
    </div>
  );
}
