import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// import { useContext } from "../useContext";
import { ENDPOINTS } from "@arq-apps/util";
import { DMPClient } from "src/dotnet-client";

function parseJwt (token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function useLogin() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const authCode = params.has("code") ? params.get("code") : null;
    const issuer = params.has("iss") ? params.get("iss") : null;
    const clientId = params.has("client_id") ? params.get("client_id") : null;
    const redirectUri = `${window.location.origin}/oauth`;

    if (authCode && redirectUri && clientId && issuer) {
      setLoggingIn(true)
      new DMPClient({
        BASE: ENDPOINTS.DOTNET,
        TOKEN: "",
      }).authenticationApi
        .postAuthenticationApiGetToken(authCode, redirectUri, clientId, issuer)
        .then((res) => {
          if (!res || !res.token) {
            navigate({ pathname: "/", search: "login-failed" }); // fallback by navigating back to root page
            return;
          }

          sessionStorage.setItem("token", res.token);

          const jwt = parseJwt(res.token);
          const roles = jwt.roles;
          sessionStorage.setItem("roles", roles);

          navigate({ pathname: "/", search: "login-succeeded" }); // always redirect to base and let main app redirect to landing etc
        })
        .catch((err) => {
          console.error(err);
          setError(err.message || err);
          // TODO redirect to root with error?
          navigate({ pathname: "/", search: "login-failed" }); // fallback by navigating back to root page
        })
        .finally(() => {
          setLoggingIn(false);
        });
    }
  }, [pathname, search]);

  return {
    isLoggingIn: loggingIn,
    isError: !!error,
  };
}
