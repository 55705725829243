import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VegaLiteChartFragment = { __typename?: 'VegaLiteChart', id: string, label?: string | null, spec?: string | null, config?: string | null };

export type ChartFragment = { __typename?: 'Chart', id: string, label?: string | null, spec?: string | null, config?: string | null };

export type VegaLiteChartQueryVariables = Types.Exact<{
  appId: Types.Scalars['ID'];
  chartId: Types.Scalars['ID'];
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  filters: Array<Types.FieldInput> | Types.FieldInput;
  extraInputs?: Types.InputMaybe<Array<Types.QueryInput> | Types.QueryInput>;
}>;


export type VegaLiteChartQuery = { __typename?: 'Query', app?: { __typename?: 'App', id: string, chartById?: { __typename?: 'Chart', id: string, label?: string | null, spec?: string | null, config?: string | null } | null } | null };

export const VegaLiteChartFragmentDoc = gql`
    fragment VegaLiteChart on VegaLiteChart {
  id
  label
  spec
  config
}
    `;
export const ChartFragmentDoc = gql`
    fragment Chart on Chart {
  id
  label
  spec
  config
}
    `;
export const VegaLiteChartDocument = gql`
    query VegaLiteChart($appId: ID!, $chartId: ID!, $projectId: Int, $filters: [FieldInput!]!, $extraInputs: [QueryInput!]) {
  app(
    id: $appId
    projectId: $projectId
    filters: $filters
    extraInputs: $extraInputs
  ) {
    id
    chartById(id: $chartId) {
      ...Chart
    }
  }
}
    ${ChartFragmentDoc}`;

/**
 * __useVegaLiteChartQuery__
 *
 * To run a query within a React component, call `useVegaLiteChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useVegaLiteChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVegaLiteChartQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      chartId: // value for 'chartId'
 *      projectId: // value for 'projectId'
 *      filters: // value for 'filters'
 *      extraInputs: // value for 'extraInputs'
 *   },
 * });
 */
export function useVegaLiteChartQuery(baseOptions: Apollo.QueryHookOptions<VegaLiteChartQuery, VegaLiteChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VegaLiteChartQuery, VegaLiteChartQueryVariables>(VegaLiteChartDocument, options);
      }
export function useVegaLiteChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VegaLiteChartQuery, VegaLiteChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VegaLiteChartQuery, VegaLiteChartQueryVariables>(VegaLiteChartDocument, options);
        }
export type VegaLiteChartQueryHookResult = ReturnType<typeof useVegaLiteChartQuery>;
export type VegaLiteChartLazyQueryHookResult = ReturnType<typeof useVegaLiteChartLazyQuery>;
export type VegaLiteChartQueryResult = Apollo.QueryResult<VegaLiteChartQuery, VegaLiteChartQueryVariables>;