// import "./Column.scss";
import * as g from "./Column.generated";
import { BaseColumnProps } from "./BaseColumn";

export interface DateTimeColumnProps extends g.DateTimeColumnFragment, BaseColumnProps {
  className?: string,
}

export function DateTimeColumn(props: DateTimeColumnProps) {
  const classes = [
    props.className,
    'date-time',
    // props.editable ? 'editable' : '',
    props.align ? props.align : '',
  ].filter(Boolean).join(' ');

  
   const footerClassName = ["footer-totals"].filter(Boolean).join('');
 
  return (
    <div className={ classes }>
      <div className="title">{ props.title?.at(0) ?? props.id }</div>
      { props.dataRowIndex?.map((row) => (
        <div>
          { props.dateTimeValues[row] }
        </div>
      )) }
      {props.showTotalsRow && <div className={footerClassName}></div> }
    </div>
  );
}
