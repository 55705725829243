/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesFaqFaq } from '../models/MessagesFaqFaq';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FaqApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get a whole collection of questions for an application
     * @param applicationId
     * @returns MessagesFaqFaq OK
     * @throws ApiError
     */
    public getFaqApiGetFaq(
        applicationId: number,
    ): CancelablePromise<Array<MessagesFaqFaq>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/faq/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
        });
    }

    /**
     * PUT a new question on an application
     * @param applicationId
     * @param requestBody
     * @returns MessagesFaqFaq OK
     * @throws ApiError
     */
    public putFaqApiAddFaq(
        applicationId: number,
        requestBody?: MessagesFaqFaq,
    ): CancelablePromise<Array<MessagesFaqFaq>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/rest/faq/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * POST a whole Faq to update the entire collection, eg. for sort orders
     * @param applicationId
     * @param requestBody
     * @returns MessagesFaqFaq OK
     * @throws ApiError
     */
    public postFaqApiPostFaq(
        applicationId: number,
        requestBody?: Array<MessagesFaqFaq>,
    ): CancelablePromise<Array<MessagesFaqFaq>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/faq/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Get a single question in an application
     * @param applicationId
     * @param questionId
     * @returns MessagesFaqFaq OK
     * @throws ApiError
     */
    public getFaqApiGetFaqItem(
        applicationId: number,
        questionId: number,
    ): CancelablePromise<MessagesFaqFaq> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/faq/{applicationId}/{questionId}',
            path: {
                'applicationId': applicationId,
                'questionId': questionId,
            },
        });
    }

    /**
     * Update a single question in an application
     * @param applicationId
     * @param questionId
     * @param requestBody
     * @returns MessagesFaqFaq OK
     * @throws ApiError
     */
    public patchFaqApiUpdateFaqItem(
        applicationId: number,
        questionId: number,
        requestBody?: MessagesFaqFaq,
    ): CancelablePromise<MessagesFaqFaq> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/rest/faq/{applicationId}/{questionId}',
            path: {
                'applicationId': applicationId,
                'questionId': questionId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Delete a single question in an application
     * @param applicationId
     * @param questionId
     * @returns MessagesFaqFaq OK
     * @throws ApiError
     */
    public deleteFaqApiDeleteFaqItem(
        applicationId: number,
        questionId: number,
    ): CancelablePromise<MessagesFaqFaq> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/rest/faq/{applicationId}/{questionId}',
            path: {
                'applicationId': applicationId,
                'questionId': questionId,
            },
        });
    }

}
