import { useState } from "react"
import { Button } from "@arq-apps/ui"
import { toast, Zoom } from "react-toastify"
import { useEditableMenu } from "src/contexts/EditableMenuContext"
import { useLandingContext } from "src/contexts/LandingContext"
import { useOldMenuLazyQuery } from "src/ux/Menu/Menu.generated"
import { MessagesLandingMenuItem } from "@arq-apps/dotnet"

interface oldMenuChild {
  link?: { 
    label?: string | null,
    pageId?: string | null,
  }
  children?: oldMenuChild[] | undefined | null
};

export const PreconfigureMenu = () => {
  const { roles, setMenu } = useEditableMenu();
  const { appKey } = useLandingContext();
  const [getOldMenu] = useOldMenuLazyQuery();
  const [projectId, setProjectId] = useState<number>();
  const [loading, setLoading] = useState(false);

  const oldChildrenToItems = (children: oldMenuChild[] | undefined | null):MessagesLandingMenuItem[] => {
    if (!children) return []
    return children.map(child => {
      return {
        item_type: "menu_item",
        name: child.link?.label ?? "",
        description: child.link?.label ?? "",
        page_id: child.link?.pageId ?? "",
        roles: roles.map(role => role.name ?? ""),
        items: oldChildrenToItems(child.children)
      }
    })
  }

  const onConfigureMenu = async () => {
    setLoading(true)
    if (!appKey) {
      toast.error("No app key provided", {
        toastId: "configure-menu",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        transition: Zoom
      })
      return
    }
    if (!projectId) {
      toast.error("No project id provided", {
        toastId: "configure-menu",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        transition: Zoom
      })
      return
    }
    getOldMenu({
      fetchPolicy: "network-only",
      variables: {
        appId: appKey,
        projectId: projectId
      }
    }).then(result => {
      const items = oldChildrenToItems(result.data?.app?.appMenu.items)
      setMenu({items})
      console.log(items)
    }).catch(err => {
      console.warn(err)
    }).finally(() => {
      setLoading(false)
      toast.success("Menu preconfigured", {
        toastId: "configure-menu",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        transition: Zoom
      })
    })
  }
  
  return (
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "1rem"}}>
      <label>Project ID:</label>
      <input type="number" value={projectId ?? ""} onChange={(e) => setProjectId(parseInt(e.target.value))}/>
      <Button text="Preconfigure menu" theme="white" onClick={onConfigureMenu} rightIcon={loading ? "spinner-outline" : undefined}/>
    </div>
  )
}