import { ReactNode } from "react"
import { Dialog } from "./Dialog";

interface CookieDialogProps {
	children: ReactNode
}

export const CookieDialog = (props: CookieDialogProps) => {
	const coookieContent = () => {
		return (
			<>
				<h3>This statement was last updated in June 2023.</h3>
				<p>
					Cookies are small text files that are placed on your computer by the websites that you visit. 
					They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. 
					The use of cookies is now standard for most websites. 
					If you are uncomfortable with the use of cookies, you can manage and control them through your browser, including removing cookies by deleting them from your ‘browser history’ (cache) when you leave the site.
				</p>
				<h3>Types of cookies</h3>
				<p>Session' cookies remain in your browser during your browser session only, i.e. until you leave the website.</p>
				<p>'Persistent' cookies remain in your browser after the session, unless you delete them.</p>
				<p>'Performance' cookies collect information about your use of the website, such as webpages visited and any error messages; they do not collect personally identifiable information, and the information collected is aggregated such that it is anonymous. Performance cookies are used to improve how a website works.</p>
				<p>'Functionality' cookies allow the website to remember any choices you make about the website (such as changes to text size, customised pages) or enable services such as commenting on a blog.</p>
				<p>In every application settings, the customers are provided with a feature either to track the user.So if the tracking functionality is enabled, then this cookie expiration would be set to Permanent. If it is disabled, then that means we do not track the information if the customer is revisiting back and hence it would be captured as Session.</p>
				<h3>Use of cookies by PwC:</h3>
				<table>
					<thead>
						<tr>
							<th>Provider</th>
							<th>Name</th>
							<th>Purpose</th>
							<th>Type</th>
							<th>Duration</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>.za.datamodellingplatform.africa.pwc.com</td>
							<td>sessionid</td>
							<td>This cookie is set by the PwC Data Modelling Platform. The cookie is used to maintain a user session by the server. It is required for the user to maintain a logged in state within the application</td>
							<td>Session</td>
							<td>Entire session</td>
						</tr>
					</tbody>
					<tfoot></tfoot>
				</table>
				<p>By using this website, you agree that we can place these cookies on your computer or device for the above purposes.</p>
			</>
		)
	}

  return (
		<Dialog title="Cookie Information" content={coookieContent()} onConfirm={() => {}} enabled={true}>
			{props.children}
		</Dialog>
	)
}