/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DataModellingPlatformCommonAppSettingsImportStatus {
    None = 0,
    Uploaded = 1,
    UploadedError = 2,
    Checked = 3,
    CheckedError = 4,
    Applied = 5,
}
