import { useEffect, useState, } from "react";
import { useContext } from "../useContext";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router";

const QueryKeys = {
  appId: "app",
  // pageId: null,
  projectId: "pid",
}

export function useNavObserver(): { initialised: boolean } {
  const [initialised, setInitialised] = useState(false);
  const [searchParams, _] = useSearchParams();
  const {pathname} = useLocation();

  const {
    updateContext,
    // appId,
    // pageId,
    // projectId,
  } = useContext();

  useEffect(() => {
    const pid = searchParams.get(QueryKeys.projectId) ?? null;
    const match = pathname.match(/^\/([a-zA-Z0-9_-]+)\/.*/i);
    const app = match ? match[1] : "";
    updateContext({
      pageId: pathname ?? "",
      appId: app,
      // appId: searchParams.get(QueryKeys.appId) ?? null,
      projectId: pid ? parseInt(pid) : null,
    });
    // mark as initialised AFTER context updated
    setInitialised(true);
  }, [
    // TODO we could extract "page ID" from /app/<appId>/<pageId> if preferred (as before)
    pathname, // page ID
    // searchParams.get(QueryKeys.appId), // app ID
    searchParams.get(QueryKeys.projectId), // project ID
  ]);

  return { initialised };
}
