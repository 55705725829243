import { CheckIcon } from '@arq-apps/ui'
import * as Checkbox from '@radix-ui/react-checkbox';
import { ReactNode, useState } from "react"
import { useDotnetClient } from 'src/contexts/DotnetClientContext'
import { Dialog } from "./Dialog";

interface TermsDialogProps {
	children: ReactNode
  termsAccepted: boolean
  termsUrl: string | undefined
  privacyUrl: string | undefined
	onConfirm: () => void
  appId: number | undefined
}

export const TermsDialog = (props: TermsDialogProps) => {
  const {dmpClient} = useDotnetClient()
  const [termsChecked, setTermsChecked] = useState(false)
  const [privacyChecked, setPrivacyChecked] = useState(false)

  const popupConfirm = () => {
    dmpClient?.activityLoggingApi.postActivityLoggingApiLogAppAccess(props.appId, true)
    props.onConfirm()
  }

  const nonPopupConfirm = () => {
    dmpClient?.activityLoggingApi.postActivityLoggingApiLogAppAccess(props.appId, false)
    props.onConfirm()
  }

	const termsContent = () => {
		return (
			<div className="terms-content">
				<div className="check-item">
          <Checkbox.Root 
            checked={ termsChecked } 
            onClick={() => setTermsChecked(!termsChecked)}
          >
						<Checkbox.CheckboxIndicator>
							<CheckIcon />
						</Checkbox.CheckboxIndicator>
					</Checkbox.Root>
          <div className="label">I have read and understood the <a href={props.termsUrl} target="_blank">terms of use</a></div>
        </div>
        <div className="check-item">
          <Checkbox.Root 
            checked={ privacyChecked } 
            onClick={() => setPrivacyChecked(!privacyChecked)}
          >
						<Checkbox.CheckboxIndicator>
							<CheckIcon />
						</Checkbox.CheckboxIndicator>
					</Checkbox.Root>
          <div className="label">I have read and understood the <a href={props.privacyUrl} target="_blank">privacy statement</a></div>
        </div>
			</div>
		)
	}

  if (props.termsAccepted) {
    return (
      <div className="dialog-seen" onClick={nonPopupConfirm}>
        {props.children}
      </div>
    )
  }

  return (
		<Dialog title="Terms of use" onConfirm={popupConfirm} content={termsContent()} enabled={termsChecked && privacyChecked}>
			{props.children}
		</Dialog>
	)
}