import './Root.scss';
import { Platform } from "../Platform";
import { useNavObserver } from "../../hooks";
import { Centered } from "../Centered";

export interface RootProps {
}

export function Root(props: RootProps) {
  // trigger nav watcher BEFORE requesting/rendering content
  const {initialised} = useNavObserver();

  if (!initialised) {
    return (
      <Centered>
        <div>Initialising platform. Please wait...</div>
      </Centered>
    );
  }

  return (
    <div className="ux-root">
      <Platform/>
      {/* <Test/> */}
    </div>
  );
}
