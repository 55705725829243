// import "./Column.scss";
import * as g from "./Column.generated";
import { BaseColumnProps } from "./BaseColumn";
import { Input } from "../Input";
import { memo, useCallback, useState } from "react";
import { EditCellEvent } from "../Table/useEditTable";

interface NumberColumnProps extends g.NumberColumnFragment, BaseColumnProps {
  className?: string,
}

export function NumberColumn(props: NumberColumnProps) {
  const classes = [
    props.className,
    'number',
    props.editable ? 'editable' : '',
    props.align ? props.align : '',
  ].filter(Boolean).join(' ');

  const validateInput = useCallback((value: string | null) => {
    if (value === null || isNaN(+value)) {
      throw Error("invalid number");
    }
    // TODO apply NumberColumn validation rules
  }, []);

  // TODO - useMemo
  const formatOptions = {
    style: props.currencyCode ? "currency" : props.percent ? "percent" : undefined,
    currency: props.currencyCode ?? undefined,
    maximumFractionDigits: props.fractionDigits ?? undefined,
    // minimumFractionDigits: props.fractionDigits,
    // maximumSignificantDigits: props.significantDigits,
    // minimumSignificantDigits: props.significantDigits,
  }

  let total = 0;
  for (let row = 0; row < props.dataRowCount; row++) {
    if (props.numberValues?.at(row) !== undefined && props.numberValues?.at(row) !== null)
      total += props.numberValues.at(row) ?? 0;
  }

  const footerClassName = ["footer-totals"].filter(Boolean).join('');

  return (
    <div className={classes}>
      <div className="title">{props.title?.at(0) ?? props.id}</div>
      {props.dataRowIndex?.map((row) => (
        <NumberCell
          key={`${props.id}/${row}`}
          columnId={props.id}
          editable={props.editable ?? false}
          rowIdx={row}
          value={props.numberValues?.at(row) ?? null}
          localValue={props.localNumberValues?.at(row) ?? null}
          onChange={props.onEditCell}
          validateInput={validateInput}
          formatOptions={formatOptions}
        />
      ))}
      {props.showTotals ?
        <NumberCell
          key={`${props.id}/total`}
          columnId={props.id}
          editable={false}
          rowIdx={props.dataRowCount + 1}
          value={total}
          validateInput={validateInput}
          formatOptions={formatOptions}
          className={footerClassName}
        /> :
        props.showTotalsRow && <div className={footerClassName}></div>
      }
    </div>
  );
}

interface NumberCellProps {
  columnId: string,
  rowIdx: number,
  value: number | null,
  localValue?: number | null,
  editable: boolean,
  onChange?: (event: EditCellEvent) => void,
  // onError: (error: string) => void,
  validateInput: (value: string | null) => void,
  formatOptions?: Intl.NumberFormatOptions,
  className?: string,
}

const NumberCell = memo(function NumberCell(props: NumberCellProps) {
  const [focused, setFocused] = useState(false);

  const onChange = useCallback((id: string, value: string, error: string | null) => {
    const val = +value;  // assume already validated
    props.onChange && props.onChange({
      __typename: "NumberColumn",
      columnId: props.columnId,
      dataRowIdx: props.rowIdx,
      value: val,
    });
  }, [props.columnId, props.rowIdx]);

  const value = props.localValue ?? props.value;

  const formattedValue = value?.toLocaleString(undefined, props.formatOptions);

  const handleClick = useCallback(() => {
    setFocused(true);
  }, []
  );

  if (!props.editable) {
    return <div className={props.className}>{formattedValue}</div>;
  }

  if (props.editable && !focused) {
    return <div onClick={handleClick}>{formattedValue}</div>;
  }

  // TODO format numeric values
  return (
    <Input
      id={""}
      value={`${value ?? ''}`}
      formattedValue={formattedValue}
      height={21}
      onChange={onChange}
      validateInput={props.validateInput}
      align={'end'}
    />
  );
}
);
