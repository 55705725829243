
import './Card.scss';
import { MessagesLandingMenuItem } from '@arq-apps/dotnet'

interface ManageCardProps extends MessagesLandingMenuItem {
  onClick?: () => void;
}

export function ManageCard(props: ManageCardProps) {

  return (
    <div className='ux-card-small manage-card' onClick={props.onClick}>
      <div className='manage-bar'/>
      <div className='content'>
        <div className='title'>{props.name}</div>
        <div className='description'>{props.description}</div>
      </div>
    </div>
  );
};