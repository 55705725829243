import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContextFragment = { __typename?: 'Context', appId: string, pageId: string, projectId?: number | null, supportPageId?: string | null };

export type ContextQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ContextQuery = { __typename?: 'Query', localContext: { __typename?: 'Context', appId: string, pageId: string, projectId?: number | null, supportPageId?: string | null } };

export const ContextFragmentDoc = gql`
    fragment Context on Context {
  appId
  pageId
  projectId
  supportPageId
}
    `;
export const ContextDocument = gql`
    query Context {
  localContext @client {
    ...Context
  }
}
    ${ContextFragmentDoc}`;

/**
 * __useContextQuery__
 *
 * To run a query within a React component, call `useContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useContextQuery(baseOptions?: Apollo.QueryHookOptions<ContextQuery, ContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContextQuery, ContextQueryVariables>(ContextDocument, options);
      }
export function useContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContextQuery, ContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContextQuery, ContextQueryVariables>(ContextDocument, options);
        }
export type ContextQueryHookResult = ReturnType<typeof useContextQuery>;
export type ContextLazyQueryHookResult = ReturnType<typeof useContextLazyQuery>;
export type ContextQueryResult = Apollo.QueryResult<ContextQuery, ContextQueryVariables>;