import './Landing.scss';
import { useEffect, useState } from "react";
import { useDotnetClient } from 'src/contexts/DotnetClientContext'
import { LOGGED_OUT_MENU, SEARCH_STRING_MENU, PLATFORM_EXTERNAL_MENU } from "./constants";
import { MessagesLandingApplicationMarketingCard, MessagesLandingPlatformMarketingCard } from '@arq-apps/dotnet'
import { searchFilterGenerator, sortMethod } from './functions'

import { Logo, SearchBox, UserWidget, TabMenu, ApplicationCard, MarketingPage } from "@arq-apps/ux";
import { IconButton, Footer } from '@arq-apps/ui';

export function LandingLoggedOut() {
  const menu = LOGGED_OUT_MENU;
  const [mainMenuIndex, setMainMenuIndex] = useState<number>(0);
  const secondMenuOptions = [SEARCH_STRING_MENU, PLATFORM_EXTERNAL_MENU]
  const secondMenu = secondMenuOptions[mainMenuIndex]
  const [secondMenuIndex, setSecondMenuIndex] = useState<number>(0);
  const [searchString, setSearchString] = useState<string>("");
  
  const { dmpClient, isDMPClientLoaded } = useDotnetClient();
  const [applicationExternal, setApplicationExternal] = useState<MessagesLandingApplicationMarketingCard[]>([]);
  const [platformExternal, setPlatformExternal] = useState<MessagesLandingPlatformMarketingCard[]>([]);
  const [marketingPageId, setMarketingPageId] = useState<number>();
  

  const fetchApplicationExternal = async () => {
    dmpClient?.landingApi.getLandingApiGetApplicationExternal()
    .then(result => {
      setApplicationExternal(result)
    })
    .catch(err => {
      console.warn(err)
    })
  }

  const fetchPlatformExternal = async () => {
    dmpClient?.landingApi.getLandingApiGetPlatformExternal()
      .then(result => {
        setPlatformExternal(result)
      })
      .catch(err => {
        console.warn(err)
      })
  }

  useEffect(() => {
    if (!isDMPClientLoaded) return
    if (menu[mainMenuIndex].key === "application_external" && applicationExternal.length === 0) fetchApplicationExternal()
    if (menu[mainMenuIndex].key === "platform_external" && platformExternal.length === 0) fetchPlatformExternal()
  }, [isDMPClientLoaded, mainMenuIndex])

  useEffect(() => {
    setSecondMenuIndex(0);
    setMarketingPageId(undefined);
  }, [mainMenuIndex])

  const secondMenuFilterApplicationExternal = (item: MessagesLandingApplicationMarketingCard) => {
    if (secondMenu[secondMenuIndex] && secondMenu[secondMenuIndex].title === "All") {
      return true
    }
    if (secondMenu[secondMenuIndex] && item.tag === secondMenu[secondMenuIndex].title) {
      return true
    }
    return false
  }

  const secondMenuFilterPlatformExternal = (item: MessagesLandingPlatformMarketingCard) => {
    if (item.card.groupings && item.card.groupings[0] && item.card.groupings[0].value === secondMenu[secondMenuIndex].title) {
      return true
    }
    return false
  }

  const searchFilter = searchFilterGenerator(searchString);

  return (
    <div className="landing">
      <div className='header'>
        <Logo />
        <TabMenu inHeader={true} items={menu} selectedIndex={mainMenuIndex} onChange={setMainMenuIndex} />
        <UserWidget />
      </div>
      {marketingPageId ?
        <>
          <div className='second-level-menu-container'>
            <IconButton icon="arrow-left-outline" hover-icon="arrow-left-fill" scale={2} onClick={() => setMarketingPageId(undefined)} />
          </div>
          <MarketingPage id={marketingPageId} />
        </>
        : 
        <>
          <div className='second-level-menu-container'>
            <div className='empty' />
            <TabMenu items={secondMenu} selectedIndex={secondMenuIndex} onChange={setSecondMenuIndex} />
            <SearchBox searchString={searchString} setSearchString={setSearchString} />
          </div>
          <div className='card-container'>
            {menu[mainMenuIndex].key === "application_external" 
            && 
            applicationExternal?.filter(secondMenuFilterApplicationExternal).sort(sortMethod).filter(searchFilter).map((item, index) => 
              <ApplicationCard key={index} {...item.card} onClick={() => setMarketingPageId(item.app_id)} />
            )}
            {menu[mainMenuIndex].key === "platform_external" 
            && 
            platformExternal?.filter(secondMenuFilterPlatformExternal).sort(sortMethod).filter(searchFilter).map((item, index) => 
              <ApplicationCard key={index} {...item.card} hover blurb={item.blurb} long_description={item.long_description} email={item.email} />
            )}
          </div>
        </>
      }
      <Footer />
    </div>
  );
};