import { Fragment } from "react";
import "./Breadcrumbs.scss";
import { IconButton } from "@arq-apps/ui";

interface BreadcrumbsProps {
	title: string;
	items: string[];
	onBack: () => void;
	onHome: () => void;
}


export const BreadCrumbs = ({title, items, onBack, onHome}: BreadcrumbsProps) => {
	return (
		<div className="ux-breadcrumbs">
			<div className="breadcrumbs small">
				<div className="hover" onClick={onHome}>Home</div>
				{items?.map((item, index) => 
					<Fragment key={item + "-" + index}>
						<div>{">"}</div>
						<div className="hover" onClick={onBack}>{item}</div>
					</Fragment>
				)}
			</div>
			<div className="breadcrumbs">
				<IconButton icon="arrow-left-outline" hover-icon="arrow-left-fill" scale={2} onClick={onBack}/>
				<div>{title}</div>
			</div>
		</div>
	)
}