import * as graph from "@arq-apps/generated";
import { Action } from "@arq-apps/generated";
import { ReactNode, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLogout, useNavLink } from "../../hooks";

export type AppLinkProps = {
  className?: string,
  title?: string,
  children: ReactNode,
  onActiveStateChange?: (active: boolean, activeChild: boolean) => void,
  to?: NavLinkTo
} & (graph.ActionLink | graph.NavLink | graph.ExternalLink);


export function AppLink(props: AppLinkProps) {
  // TODO pull in current app_id / project_id when navigating?
  switch (props.__typename) {
    case "ActionLink":
      return <ActionLink {...props}>{ props.children }</ActionLink>;
    case "NavLink":
      return <NavLink {...props}>{ props.children }</NavLink>;
    case "ExternalLink":
      return <ExternalLink {...props}>{ props.children }</ExternalLink>;
  }
  // poorly specified props are hidden
  // return <></>;
  return <a>{ props.label }</a>;
}

interface ActionLinkProps extends graph.ActionLink {
  className?: string,
  title?: string,
  children: ReactNode,
}

function ActionLink(props: ActionLinkProps) {
  const classes = [
    props.className,
    // active ? "active" : "",
  ].filter(Boolean).join(" ");

  const logout = useLogout();

  const performAction = useCallback(() => {
    switch (props.action) {
      case Action.Logout:
        logout();
        break;
      default:
        console.error(`unhandled action type '${props.action}'`)
    }
  }, [props.action, logout]);

  return (
    <button
      title={ props.title }
      className={ classes }
      onClick={ performAction }
    >
      { props.children }
    </button>
  );
}

export interface NavLinkTo {
  pathname: string,
  search: string,
  active: boolean,
  childActive: boolean,
}
interface NavLinkProps extends graph.NavLink {
  className?: string,
  title?: string,
  children: ReactNode,
  onActiveStateChange?: (active: boolean, activeChild: boolean) => void,
  // overrided build util for direct navigation
  to?: NavLinkTo
}

function NavLink(props: NavLinkProps) {
  const nav = useNavLink(props);

  useEffect(() => {
    if (props.onActiveStateChange) {
      // console.log("active state change", nav.active);
      props.onActiveStateChange(nav.active, nav.childActive);
    }
  }, [nav.active, nav.childActive]);

  const classes = [
    props.className,
    nav.active ? "active" : "",
    nav.childActive ? "active-child" : "",
  ].filter(Boolean).join(" ");

  return (
    <Link to={ props.to ?? nav } title={ props.title } className={ classes }>
      { props.children }
    </Link>
  );
}

interface ExternalLinkProps extends graph.ExternalLink {
  className?: string,
  title?: string,
  children: ReactNode,
}

function ExternalLink(props: ExternalLinkProps) {
  if (!props.externalLink || !props.externalLink.startsWith("https://")) {
    // TODO notify developer team -> cannot display insecure link
    return (
      <Link className={ props.className } title={ props.title } to={{
        // TODO preserve current search params?
        search: `error=invalid-external-url&url=${props.externalLink}`,
        pathname: "",
      }}>
        { props.children }
      </Link>
    );
  }
  // Navigate
  return (
    <a className={ props.className } href={ props.externalLink }>
      { props.children }
    </a>
  );
}
