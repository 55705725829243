import React from "react";
import { ApolloLink, from } from "@apollo/client";
import { authFlowLink, splitProtocolLink } from "./links";

const link = new ApolloLink((operation, forward) => {
  const context = operation.getContext()
  throw Error();
});

// Logs ALL GraphQL queries as clickable links to GraphQL Playground
// const graphqlPlaygroundConsoleLink = new ApolloLink((operation, forward) => {
//   // console.log(`starting request for ${operation.operationName}`);
//   return forward(operation).map((data) => {
//     // const playgroundLink =
//     // console.log(`${operation.operationName}`, operation.query);
//     // console.log(operation.operationName, operation.query.loc?.source?.body);
//     const query = operation.query.loc?.source?.body?.trimStart();
//     const variables = JSON.stringify(operation.variables );
//     if (query) {
//       // note: `variables` param is ignored by graphql playground; perhaps graphiql will work?
//       // const playgroundLink = encodeURI(`http://localhost:8000/graphql?query=${query}&variables=${variables}`);
//       // console.debug(operation.operationName, playgroundLink);
//     }
//     return data;
//   })
// })

// function readContext(): {
//   appId: string | null,
//   projectId: string | null,
// } {
//   // const data = apolloCache.readQuery<Link_DataQuery>({
//   //   query: Link_DataDocument,
//   // });
//   // return {
//   //   appId: data?.readActiveContext?.appId ?? null,
//   //   projectId: data?.readActiveContext?.projectId ?? null,
//   // };
//   return {
//     appId: 'x',
//     projectId: '2',
//   }
// }

export const apolloLink = from([
  authFlowLink,
  // authLink,
  // graphqlPlaygroundConsoleLink,
  splitProtocolLink,
  // httpLink
]);
