import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { NavLink } from "@arq-apps/generated";
import * as util from "@arq-apps/util";


export function useNavLink(props: NavLink): {
  pathname: string,
  search: string,
  active: boolean,
  childActive: boolean,
} {
  const { pathname, search } = useLocation();

  return useMemo(() => {
    return util.buildNavLink({
      currentPathname: pathname,
      currentSearch: search,
      link: props,
    });
  }, [
    pathname,
    search,
    props.appId,
    props.pageId,
    props.projectId,
    props.setParams,
    props.toggleParams,
  ]);
}
