import { DMPClient } from "../dotnet-client";
import { ENDPOINTS } from "@arq-apps/util";
import { createContext, useContext, useEffect, useState } from "react";
import { useLogin } from "src/hooks"

interface DotnetClientContext {
  dmpClient: DMPClient | undefined
  isDMPClientLoaded: boolean
}

const DEFAULT_CONTEXT: DotnetClientContext = {
  dmpClient: undefined,
  isDMPClientLoaded: false
}

const DotnetClientContext = createContext<DotnetClientContext>(DEFAULT_CONTEXT)

export const DotnetClientProvider = (props: { children: React.ReactNode }) => {
  const [dmpClient, setDmpClient] = useState<DMPClient>();
  const [isDMPClientLoaded, setIsDMPClientLoaded] = useState(false);
  const { isLoggingIn } = useLogin();
  

  useEffect(()=>{
    if (!isLoggingIn) {
      console.log(isLoggingIn)
      const token = sessionStorage.getItem("token");
      const _dmpClient = new DMPClient({
        BASE: ENDPOINTS.DOTNET,
        TOKEN: token || '',
      });
      setDmpClient(_dmpClient)
      setIsDMPClientLoaded(true)
    }
  }, [isLoggingIn])

  return (
    <DotnetClientContext.Provider value={{dmpClient, isDMPClientLoaded}}>
      { props.children }
    </DotnetClientContext.Provider>
  )
}

export const useDotnetClient = () => {
  return useContext(DotnetClientContext);
}