// import "./Column.scss";
import * as g from "./Column.generated";
import { BaseColumnProps } from "./BaseColumn";
import { Toggle } from "@arq-apps/ux";
import { useCallback } from "react";

export interface BooleanColumnProps extends g.BooleanColumnFragment, BaseColumnProps {
  className?: string,
}

export function BooleanColumn(props: BooleanColumnProps) {
  const classes = [
    props.className,
    'boolean',
    props.editable ? 'editable' : '',
    props.align ? props.align : '',
  ].filter(Boolean).join(' ');

  const onChange = useCallback((id: string, value: boolean, extra: number) => {
    props.onEditCell({
      __typename: "BooleanColumn",
      columnId: props.id,
      dataRowIdx: extra,  // extra contains dataRowIdx
      value: value,
    });
  }, [props.id, props.onEditCell]);

  const footerClassName = ["footer-totals"].filter(Boolean).join('');
  
  return (
    <div className={ classes }>
      <div className="title">{ props.title?.at(0) ?? props.id }</div>
      { props.dataRowIndex?.map((row) => (
        <Toggle
          key={ `${ props.id }/${ row }` }
          id={ `${ props.id }/${ row }` }
          label={ "" }
          state={ props.localBoolValues[row] ?? props.boolValues[row] ?? false }
          editable={ props.editable ?? false }
          onChange={ onChange }
          onChangeExtra={ row }
        />
      )) }
      {props.showTotalsRow && <div className={footerClassName}></div> }
    </div>
  );
}
