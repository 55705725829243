import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MetricFragment = { __typename?: 'Metric', id: string, value?: number | null, label?: string | null };

export type MetricQueryVariables = Types.Exact<{
  appId: Types.Scalars['ID'];
  metricId: Types.Scalars['ID'];
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  filters: Array<Types.FieldInput> | Types.FieldInput;
  extraInputs?: Types.InputMaybe<Array<Types.QueryInput> | Types.QueryInput>;
}>;


export type MetricQuery = { __typename?: 'Query', app?: { __typename?: 'App', id: string, metricById?: { __typename?: 'Metric', id: string, value?: number | null, label?: string | null } | null } | null };

export const MetricFragmentDoc = gql`
    fragment Metric on Metric {
  id
  value
  label
}
    `;
export const MetricDocument = gql`
    query Metric($appId: ID!, $metricId: ID!, $projectId: Int, $filters: [FieldInput!]!, $extraInputs: [QueryInput!]) {
  app(
    id: $appId
    projectId: $projectId
    filters: $filters
    extraInputs: $extraInputs
  ) {
    id
    metricById(id: $metricId) {
      ...Metric
    }
  }
}
    ${MetricFragmentDoc}`;

/**
 * __useMetricQuery__
 *
 * To run a query within a React component, call `useMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      metricId: // value for 'metricId'
 *      projectId: // value for 'projectId'
 *      filters: // value for 'filters'
 *      extraInputs: // value for 'extraInputs'
 *   },
 * });
 */
export function useMetricQuery(baseOptions: Apollo.QueryHookOptions<MetricQuery, MetricQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricQuery, MetricQueryVariables>(MetricDocument, options);
      }
export function useMetricLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricQuery, MetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricQuery, MetricQueryVariables>(MetricDocument, options);
        }
export type MetricQueryHookResult = ReturnType<typeof useMetricQuery>;
export type MetricLazyQueryHookResult = ReturnType<typeof useMetricLazyQuery>;
export type MetricQueryResult = Apollo.QueryResult<MetricQuery, MetricQueryVariables>;