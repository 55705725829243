/**
 * Domains `localhost`, `prev.za...` and `dev.za...` will always redirect API calls
 * to `localhost:8000` (i.e. development mode). All other domains will redirect to
 * self (i.e. hosted mode).
 *
 * @param protocol either 'http' or 'ws' (applied only to development mode connections)
 */
function endpoints(protocol: 'http' | 'ws' | 'https', port: '8000' | '5251' | '7244'): string {
  switch (window.location.hostname) {
    case "localhost":
    case "prev.za.datamodellingplatform.africa.pwc.com":
    case "prev.za.datamodellingplatform.africa.pwcglb.com":
    case "dev.za.datamodellingplatform.africa.pwc.com":
    case "dev.za.datamodellingplatform.africa.pwcglb.com":
      return `${protocol}://localhost:${port}`;
    // case "next.za.datamodellingplatform.africa.pwc.com":
    // case "stag.za.datamodellingplatform.africa.pwc.com":
    // case "za.datamodellingplatform.africa.pwc.com":
  }
  return "";
  // return window.location.hostname;
}

export const ENDPOINTS = {
  GRAPHQL: `${endpoints('http', '8000')}/graphql`,
  GRAPHQL_WS: `${endpoints('ws', '8000')}/graphql`,
  LANDING: `${endpoints('https', '7244')}/rest`,
  DOTNET: `${endpoints('https', '7244')}`
}
