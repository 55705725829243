import './Filter.scss';
import { Icon, MultiSelect } from "@arq-apps/ui";
import { FieldFragment } from "../../ui/Field/Field.generated";
import { SelectOption } from '@arq-apps/generated';
import Tooltip from '../Tooltip/Tooltip';
import { Select } from "@arq-apps/ui";
import { ComboBox } from 'src/ui/ComboBox/ComboBox';

type FilterProps = FieldFragment & {
  primaryFilter: boolean,
  updateFilters: (id: string, option: SelectOption | null | undefined) => void,
  selectClear: (id: string) => void,
  multiSelect: (id: string, options: SelectOption[] | null | undefined) => void,
  multiSelectAll: (id: string) => void,
  multiClearAll: (id:string) => void,
  topbarFilter?: boolean
}

export function Filter(props: FilterProps) {
  const classes = ["ux-filter", 
    props.primaryFilter && "primary",
    props.topbarFilter && "topbarFilter"
  ].filter(Boolean).join(" ");

  const FilterControl = (props: FilterProps) => {
    const field = props;
    switch (field.__typename) {
      case "SelectField":
        if (props.topbarFilter) {
          return <Select
            class={'topbarFilter'}
            placeholder={props.label || ''}
            options={field.options?.map(option => option.label) ?? []}
            selected={field.localSelection?.label ?? field.selection?.label ?? ""}
            onOptionSelected={(option: string) => {
              const selected = field.options?.find(o => o.label === option)
              props.updateFilters(field.id, selected)
            }}
            onClear={() => props.selectClear(field.id)}
          />
        }
        const selection = field.localSelection ?? field.selection ?? undefined
        const selections = selection ? [selection] : []
        return <ComboBox
          id={field.id}
          placeholder={props.label ?? ""}
          options={field.options ?? []}
          multiselect={false}
          selections={selections}
          onSelectOption={props.updateFilters}
          variant="borderless"
        />
      case "MultiSelectField":
        if (props.topbarFilter) {
          return <MultiSelect
            class={props.topbarFilter ? 'topbarFilter' : undefined}
            placeholder={props.label || ''}
            options={field.options?.map(option => option.label) ?? []}
            selections={field.localSelections?.map(option => option.label) ?? field.selections?.map(option => option.label) ?? []}
            onOptionToggled={(option: string) => {
              const selected = field.options?.find(o => o.label === option)
              props.updateFilters(field.id, selected)
            }}
            onSelectAll={() => props.multiSelectAll(field.id)}
            onClearAll={() => props.multiClearAll(field.id)}
          />
        }
        return <ComboBox
          id={field.id}
          placeholder={props.label ?? ""}
          options={field.options ?? []}
          multiselect
          selections={field.localSelections ?? field.selections ?? []}
          onAccept={props.multiSelect}
          variant="borderless"
        />
    }
    const message = `Filter type ${field.__typename} not yet supported.`;
    console.warn(message, field);
    return (
      <div>
        <span>WIP</span>
        {message}
      </div>
    );
  }

  return (
    <div className={classes}>
      <div className= "label-container">
        <div className = "label">
          {props.label ?? props.id}
        </div>
        <div className = "tooltip">
          {props.information && 
          <Tooltip message={props.information} >
            <Icon icon="information-outline" />
          </Tooltip>}
        </div>
      </div>
      <div className={`control ${props.__typename}`}>
        <FilterControl {...props}/>
      </div>
    </div>
  );
}