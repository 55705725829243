import * as g from "./usePage.generated";
import { useContext } from "../useContext";
import { useEffect, useState } from "react";
import { usePageFilterContext } from "../useFilters";
import { PageFragment } from "src/ux/Page/Page.generated";
import { useLandingContext } from "src/contexts/LandingContext"
import { QueryInput } from "@arq-apps/generated"

export function usePage() {
  const {appId, projectId, pageId,} = useContext();
  const LandingContext = useLandingContext();
  const {pageFilterInputs, pageFiltersLoaded} = usePageFilterContext();
  const [page, setPage] = useState<PageFragment | null | undefined>();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [getPage, pageData] = g.usePageLazyQuery();
  
  useEffect(() => {
    setPageLoaded(false)
  }, [pageId])
  
  let extraInputs: QueryInput[] = [];
  if (LandingContext.appId) {extraInputs.push({"name": "appId", "value": `${LandingContext.appId}`})}
  if (LandingContext.projectId) {extraInputs.push({"name": "projectId", "value": `${LandingContext.projectId}`})}
  
  const fetchPage = async() => {
    const getPageData = await getPage({
      // TODO check if page caching works
      fetchPolicy: "no-cache",
      variables: {
        appId,
        projectId,
        pageId,
        filters: pageFilterInputs ?? [],
        extraInputs
      },
    })
    const _page = getPageData.data?.app?.pageById
    setPage(_page);
    setPageLoaded(true);
  }

  useEffect(() => {
    if (pageFiltersLoaded && !pageLoaded) {
      fetchPage()
    }
  }, [pageFiltersLoaded, pageLoaded]);

  return {
    page: page,
    pageLoading: pageData.loading || !pageFiltersLoaded,
    pageLoaded
  };
}
