import "./DateTimeField.scss";
import { ChangeEvent } from "react";
import { DateTimeFieldFragment } from "src/ui/Field/Field.generated";

interface DateTimeFieldProps extends DateTimeFieldFragment {
    onChange: (id: string, dateTime: string) => void;
  }
  
  export function DateTimeField(props: DateTimeFieldProps) {
    // TODO @RM - handle iso to locale formatting
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      props.onChange(props.id, event.target.value);
    }
  
    return (
      <div className="date-time-field">
        <input 
          type="datetime-local"
          value={props.localDateTime ?? props.dateTime}
          onChange={handleChange}
        />
      </div>
    );
  }