import './Form.scss';
import * as g from "./Form.generated";
import { Field, Button, fieldToInput, resetLocalFieldValue, clearLocalFieldValue } from "@arq-apps/ui";
import { useCallback, useEffect, useMemo } from "react";
import { useContext, usePageFilterContext } from "../../hooks";
import { useApolloClient } from "@apollo/client";
import { toast, Zoom } from "react-toastify";
import { FieldInput, QueryInput } from '@arq-apps/generated';
import { useLandingContext } from 'src/contexts/LandingContext';
import { useHierarchicalFilterContext } from 'src/contexts/HierarchicalFilterContext/HierarchicalFilterContext'
import { usePage } from 'src/hooks/usePage'

export type FormProps = g.FormFragment & {
  tileFilterInputs?: FieldInput[]
}

function useForm(formId: string, tileFilterInputs?: FieldInput[], pageFilterInputs?: FieldInput[] | null) {
  const {appId, projectId} = useContext();
  const LandingContext = useLandingContext();
  const {hierarchicalFilterInputs} = useHierarchicalFilterContext();
  const {pageLoaded} = usePage();

  const [getForm, getFormData] = g.useFormLazyQuery();

  const filters = useMemo(() => {
    const _pageFilterInputs = pageFilterInputs ?? [];
    const _tileFilterinputs = tileFilterInputs ?? [];
    const _hierarchicalFilterinputs = hierarchicalFilterInputs ?? [];
    return [..._tileFilterinputs, ..._pageFilterInputs, ..._hierarchicalFilterinputs];
  }, [tileFilterInputs, pageFilterInputs, hierarchicalFilterInputs]);

  const fetchForm = async() => {
    getForm({
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-only",
      returnPartialData: true,
      variables: {
        appId: appId === "landing" ? "main" : appId,
        projectId,
        formId,
        filters,
        extraInputs: [
          {"name": "appId", "value": `${LandingContext.appId}`},
          {"name": "projectId", "value": `${LandingContext.projectId}`},
          {"name": "formId", "value": formId}
        ]
      },
    })
  };

  const formRefetch = () => {
    getFormData.refetch({
      appId: appId === "landing" ? "main" : appId,
      projectId,
      formId,
      filters,
      extraInputs: [
        {"name": "appId", "value": `${LandingContext.appId}`},
        {"name": "projectId", "value": `${LandingContext.projectId}`},
        {"name": "formId", "value": formId}
      ]
    })
  }

  useEffect(() => {
    if (pageLoaded) {
      fetchForm();
    }
  }, [formId, filters, pageLoaded]);

  return {
    form: getFormData.data?.app?.formById,
    formLoading: getFormData.loading,
    formRefetch
  }
}

export function Form(props: FormProps) {
  const { pageFilterInputs } = usePageFilterContext();
  const { hierarchicalFilterInputs } = useHierarchicalFilterContext();
  const { form, formLoading, formRefetch } = useForm(props.id, props.tileFilterInputs, pageFilterInputs);

  const [submitFormMutation, {
    // data: submitData,
    loading: submitting,
    error: submitError,
  }] = g.useSubmitFormMutation({ /* */ });

  const LandingContext = useLandingContext();
  const { appId, projectId, } = useContext();
  const { cache } = useApolloClient();

  // refetch form if form object gets evicted by contentToRefresh
  // form value defaults to undefined before they're fetched originally
  // form value becomes {} once cache is evicted
  useEffect(() => {
    if (!formLoading && form !== undefined && !form?.id) {
      formRefetch()
    }
  }, [form])

  const classes = [
    "ux-form",
  ].filter(Boolean).join(" ");

  const handleFormReset = useCallback(() => {
    form?.fields?.forEach((it) => resetLocalFieldValue(cache, it));
    console.debug('reset this form', form);
  }, [form?.fields]);

  let extraInputs: QueryInput[] = [];
  if (LandingContext.appId) {extraInputs.push({"name": "appId", "value": `${LandingContext.appId}`})}
  if (LandingContext.projectId) {extraInputs.push({"name": "projectId", "value": `${LandingContext.projectId}`})}

  const handleFormSubmission = async () => {
    console.debug('submit this form', form);
    // const _ = submitForm(props.fields?.map(fieldToInput) ?? []);
    const result = await submitFormMutation({
      variables: {
        formId: props.id,
        appId: appId, // TODO provided via @export
        projectId: projectId, // TODO provided via @export
        filters: [...pageFilterInputs ?? [], ...props.tileFilterInputs ?? [], ...hierarchicalFilterInputs ?? []],
        fields: form?.fields?.map(fieldToInput) ?? [],
        extraInputs
      }
    })

    // TODO: Change the if else to a .then .catch - Reason for if else: No errors occur on incorrect submission, so catch statement does not execute ever
    if (result.data?.submitForm?.ok == false) {
      const userMessage = result.data?.submitForm?.message ?? "Form not submitted successfully";
      const messengerClass = result.data?.submitForm.ok ? toast.success : toast.error;
      messengerClass(userMessage, {
        toastId: `/events/submit-form/${props.id}`,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        transition: Zoom
      })
    } else {
      //handleFormReset();
      const contentToRefresh = result.data?.submitForm?.contentToRefresh;
      console.warn("form submit success", contentToRefresh);
      // TODO @RM - standardise component and use on table and form
      const userMessage = result.data?.submitForm?.message ?? "Form submitted successfully";
      const messengerClass = result.data?.submitForm.ok ? toast.success : toast.error;
      messengerClass(userMessage, {
        toastId: `/events/submit-form/${props.id}`,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        transition: Zoom
      })

      // form self refresh after submission
      formRefetch()
      form?.fields?.forEach(field => {
        clearLocalFieldValue(cache, field)
      })

      // triggered contents to refresh
      const all = [
        { __typename: "App", ids: contentToRefresh?.apps ?? [] },
        { __typename: "Chart", ids: contentToRefresh?.charts ?? [] },
        { __typename: "Form", ids: contentToRefresh?.forms ?? [] },
        { __typename: "Metric", ids: contentToRefresh?.metrics ?? [] },
        { __typename: "Page", ids: contentToRefresh?.pages ?? [] },
        { __typename: "Table", ids: contentToRefresh?.tables ?? [] },
      ];
      all.forEach(({ __typename, ids }) => {
        ids.forEach(id => {
          console.debug("invalidate", { __typename, id });
          cache.evict({
            id: cache.identify({ __typename, id }),
          });
        })
      });
    }
  }

  if (formLoading) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <div className={ classes }>
      { form?.title && (
        <div className={ 'title' }>
          { form.title }
        </div>
      ) }
      { form?.caption && (
        <div className={ 'caption' }>
          { form.caption }
        </div>
      ) }
      { form?.description && (
        <div className={ 'description' }>
          { form.description }
        </div>
      ) }
      <div className = {form?.denseFormat? "dense" : ""}>
        {form?.fields?.map((field) =>
          <Field key={field.id} {...field} denseFormat={form?.denseFormat}/>
        )}
      </div>
      { form?.showActions && (
        <div className="actions">
          <Button
            text={ 'Reset' }
            theme='white'
            onClick={ handleFormReset }
          />
          <Button
            loading={ submitting }
            text={ form?.submitButtonText ?? 'Submit' }
            onClick={ handleFormSubmission }
          />
        </div>
      ) }
    </div>
  );
}
