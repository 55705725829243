import * as T from '@radix-ui/react-tooltip';
import './Tooltip.scss';

interface TooltipProps {
  message: string,
  children?: React.ReactNode
}

export const Tooltip = (props: TooltipProps) => {
  return (
    <T.Provider>
      <T.Root>
        <T.Trigger asChild>
          <div className="width-fit-content">
            {props.children}
          </div>
        </T.Trigger>
        <T.Portal>
          <T.Content className="TooltipContent" sideOffset={5}>
            {props.message}
            <T.Arrow className="TooltipArrow" />
          </T.Content>
        </T.Portal>
      </T.Root>
    </T.Provider>
  );    
}

export default Tooltip;