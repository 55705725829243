/**
 * 
 * @param date 
 * @returns string in the format yyyy-mm-dd
 */
export function dateToString(date: Date) {
  const year = date.getFullYear().toString()
  var month = (date.getMonth() + 1).toString()
  if (month.length < 2) month = "0" + month
  var day = date.getDate().toString()
  if (day.length < 2) day = "0" + day
  const dateString = [year, month, day].join("-")
  return dateString
}