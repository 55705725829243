import './VegaLiteChart.scss';
import { useVegaLiteChartLazyQuery, ChartFragment } from "./VegaLiteChart.generated";
import { VegaLite } from "react-vega";
import { useEffect, useMemo, useState } from "react";
import { useContext, usePageFilterContext } from 'src/hooks';
import { FieldInput } from '@arq-apps/generated';
import { useLandingContext } from 'src/contexts/LandingContext';
import { useHierarchicalFilterContext } from 'src/contexts/HierarchicalFilterContext/HierarchicalFilterContext'
import { usePage } from 'src/hooks/usePage'
import { Icon } from '@arq-apps/ui';

export type VegaLiteChartProps = {
  id: string,
  tileFilterInputs?: FieldInput[],
}

function useVegaLiteChart(chartId: string, tileFilterInputs?: FieldInput[], pageFilterInputs?: FieldInput[] | null) {
  const {appId, projectId} = useContext();
  const LandingContext = useLandingContext();
  const {hierarchicalFilterInputs} = useHierarchicalFilterContext();
  const {pageLoaded} = usePage();

  const [getChart, getChartData] = useVegaLiteChartLazyQuery();

  const filters = useMemo(() => {
    const _pageFilterInputs = pageFilterInputs ?? [];
    const _tileFilterinputs = tileFilterInputs ?? [];
    const _hierarchicalFilterinputs = hierarchicalFilterInputs ?? [];
    return [..._tileFilterinputs, ..._pageFilterInputs, ..._hierarchicalFilterinputs];
  }, [tileFilterInputs, pageFilterInputs, hierarchicalFilterInputs]);

  const fetchChart = async() => {
    getChart({
      fetchPolicy: "network-only",
      variables: {
        appId: appId === "landing" ? "main" : appId,
        projectId,
        chartId,
        filters,
        extraInputs: [
          {"name": "appId", "value": `${LandingContext.appId}`},
          {"name": "projectId", "value": `${LandingContext.projectId}`}
        ]
      },
    })
  };

  useEffect(() => {
    if (pageLoaded) {
      fetchChart();
    }
  }, [chartId, filters, pageLoaded]);

  return {
    chart: getChartData.data?.app?.chartById,
    chartLoading: getChartData.loading
  }
}

export function VegaLiteChart(props: VegaLiteChartProps) {
  const { pageFilterInputs } = usePageFilterContext();
  const { chart, chartLoading } = useVegaLiteChart(props.id, props.tileFilterInputs, pageFilterInputs);

  const classes = [
    "ux-vega-lite-chart",
  ].filter(Boolean).join(" ");

  const { spec, config } = useMemo(() => {
    try {
      return {
        spec: JSON.parse(chart?.spec ?? ''),
        config: JSON.parse(chart?.config ?? ''),
      };
    } catch (e) {
      return { spec: null, config: null }
    }
  }, [chart?.spec, chart?.config]);

  if (chartLoading) {
    return (
      <div>Loading...</div>
    )
  }

  if (!chart?.spec || !chart?.config) {
    return (
      <div className="invalidChartSpec"><Icon icon='bar-chart-outline' /></div>
    );
  }

  return (
    <div className={ "ux-vega-lite-chart-wrapper" }>
      <div className={ classes }>
        <VegaLite
          ast={ true }
          spec={ spec } // TODO could still be invalid...
          config={ config } // TODO could still be invalid...
        />
      </div>
    </div>
  );
}
