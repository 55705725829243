/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MessagesStorageStorageFileType {
    Unknown = 0,
    Template = 1,
    InApp = 2,
    Modelling = 3,
    Result = 4,
    Bin = 5,
    Other = 99,
}
