import { SelectFieldFragment } from "../../ui/Field/Field.generated";
import { SelectOption } from "@arq-apps/generated";
import { memo, useCallback, useEffect, useState } from "react";

export interface SelectOptionProps {
  fieldId: string;
  selected: boolean;
  option: SelectOption;
  onClick: (id: string, option: SelectOption) => void;
}

export interface TabSelectProps extends SelectFieldFragment {
  onOptionSelected: (id: string, option: SelectOption | null) => void,
}

export function TabSelect(props: TabSelectProps) {
    const [selected, setSelected] = useState("");
    useEffect(() => {
      // TODO lift local vs selection up one level (only concerned with current value)
      setSelected(props.localSelection?.value ?? props.selection?.value ?? "");
    }, [props.localSelection?.value, props.selection?.value]);
    
    const SelectOption = memo(function SelectOption(props: SelectOptionProps) {
      const handleClick = useCallback(() => {
        props.onClick(props.fieldId, props.option);
      }, [props.fieldId, props.option.value]);
    
      return (
        <div onClick={handleClick} className={props.selected ? "selected" : ""}>
          {props.option.label ?? props.option.value}
        </div>
      );
    });

    return (
      <div className="tabs">
        <div className="options">
          {props.options && props.options.map((option) => (
            <SelectOption
              key={option.value}
              fieldId={props.id}
              selected={selected === option.value}
              option={option}
              onClick={props.onOptionSelected}
            />
          ))}
        </div>
      </div>
    )
  }