import './Centered.scss';
import { memo, ReactNode } from "react";

export interface CenteredProps {
  className?: string,
  children: ReactNode,
  // fillHeight: bool,
}

// export function Page(props: PageProps) {
export const Centered = memo(function Centered(props: CenteredProps) {
  return (
    <div className={ "util-centered " + (props.className ?? "") }>
      <div>{ props.children }</div>
    </div>
  );
});
