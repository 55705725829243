import { gql } from "@apollo/client";
import * as types from "@arq-apps/generated";
import { range } from "@arq-apps/util";
import { ColumnFragment } from "../../ux/Column/Column.generated";
import { LocalEditColumnFragment } from "../../ux/Table/useEditTable.generated";


export const tableSchema = gql`

  extend type BooleanColumn {
    localBoolValues: [Boolean]! @client
  }

  extend type TextColumn {
    localTextValues: [String]! @client
  }

  extend type NumberColumn {
    localNumberValues: [Float]! @client
  }

  extend type DateColumn {
    localDateValues: [String]! @client
  }

`;

export const tableTypePolicies: types.StrictTypedTypePolicies = {
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
  // TODO specify cache keys OR empty lists if singletons
  Query: {
  },
  ColumnI: {
    // prevent normalisation of columns; prefer nesting within the table itself
    keyFields: false,
  },
  BooleanColumn: {
    fields: {
      localBoolValues: {
        read(cached = [], { readField }) {
          const length = (readField<(boolean | null)[]>("boolValues") ?? []).length;
          return cached.length >= length ? cached : range(length).map(_ => null);
        }
      }
    },
  },
  NumberColumn: {
    fields: {
      localNumberValues: {
        read(cached = [], { readField }) {
          const length = (readField<(number | null)[]>("numberValues") ?? []).length;
          return cached.length >= length ? cached : range(length).map(_ => null);
        }
      }
    },
  },
  TextColumn: {
    fields: {
      localTextValues: {
        read(cached = [], { readField }) {
          const length = (readField<(string | null)[]>("textValues") ?? []).length;
          return cached.length >= length ? cached : range(length).map(_ => null);
        }
      }
    },
  },
  DateColumn: {
    fields: {
      localDateValues: {
        read(cached = [], { readField }) {
          const length = (readField<(string | null)[]>("dateValues") ?? []).length;
          return cached.length >= length ? cached : range(length).map(_ => null);
        }
      }
    },
  },
  Table: {
    fields: {
      columns: {
        /**
         * Merge new columns OR column changes into an existing table.
         */
        merge(
          cached: ColumnFragment[] = [],
          incoming: (ColumnFragment | LocalEditColumnFragment)[] = [],
          { mergeObjects },
        ) {
          if (cached.length !== incoming.length) {
            return incoming;  // structural change (usually first load)
          }
          // console.debug('merge Table::columns', cached, incoming);
          return cached.map(col => mergeObjects(
            col,
            incoming.find(it => it.id === col.id) ?? {},
          ));
        }
      },
    },
  },
}
