import { Page } from "../Page";
import { PageFilterContextProvider } from "src/hooks"
import { EditableMenuProvider } from "src/contexts/EditableMenuContext"
import ErrorBoundary from "src/ui/ErrorBoundary/ErrorBoundary"
import { SyncExport } from "src/pages/SyncExport/SyncExport"
import { ManageFiles } from "src/pages/ManageFiles/ManageFiles"
import { SyncImport } from "src/pages/SyncImport/SyncImport"
import { MaintainMenu } from "src/pages/MaintainMenu/MaintainMenu"

interface ManagePageProps {
  managePageId: string,
}

export function ManagePage(props: ManagePageProps) {
  const renderManagePage = (id: string) => {
    // update src/hooks/useFilters/useFilters.tsx dotnetPageIds when adding pages here
    switch (id) {
      case "/main/subscription":
        return (
          <EditableMenuProvider>
            <MaintainMenu />
          </EditableMenuProvider>
        )
      case "/main/project/resources":
        return <ManageFiles />
      case "/main/application/resources":
        return <ManageFiles />        
      case "/main/application/sync/export":
        return <SyncExport />
      case "/main/application/sync/import":
        return <SyncImport />
      default:
        return <Page managePage />
    }
  }

  return (
    <PageFilterContextProvider>
      <ErrorBoundary>
        {renderManagePage(props.managePageId)}
      </ErrorBoundary>
    </PageFilterContextProvider>
  )
}