/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MessagesAppSettingsAppSettingsAction {
    None = 0,
    Check = 1,
    Merge = 2,
    Replace = 3,
}
