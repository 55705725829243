import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import { ContentIdsFragmentDoc } from '../Form/Form.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LocalEditColumn_BooleanColumn_Fragment = { __typename?: 'BooleanColumn', id: string, boolValues: Array<boolean | null>, localBoolValues: Array<boolean | null> };

export type LocalEditColumn_DateColumn_Fragment = { __typename?: 'DateColumn', id: string, dateValues: Array<any | null>, localDateValues: Array<string | null> };

export type LocalEditColumn_DateTimeColumn_Fragment = { __typename?: 'DateTimeColumn', id: string };

export type LocalEditColumn_FormulaColumn_Fragment = { __typename?: 'FormulaColumn', id: string };

export type LocalEditColumn_NumberColumn_Fragment = { __typename?: 'NumberColumn', id: string, numberValues: Array<number | null>, localNumberValues: Array<number | null> };

export type LocalEditColumn_TextColumn_Fragment = { __typename?: 'TextColumn', id: string, textValues: Array<string | null>, localTextValues: Array<string | null> };

export type LocalEditColumnFragment = LocalEditColumn_BooleanColumn_Fragment | LocalEditColumn_DateColumn_Fragment | LocalEditColumn_DateTimeColumn_Fragment | LocalEditColumn_FormulaColumn_Fragment | LocalEditColumn_NumberColumn_Fragment | LocalEditColumn_TextColumn_Fragment;

export type LocalEditTableFragment = { __typename?: 'Table', id: string, dataRowCount: number, columns?: Array<{ __typename?: 'BooleanColumn', id: string, boolValues: Array<boolean | null>, localBoolValues: Array<boolean | null> } | { __typename?: 'DateColumn', id: string, dateValues: Array<any | null>, localDateValues: Array<string | null> } | { __typename?: 'DateTimeColumn', id: string } | { __typename?: 'FormulaColumn', id: string } | { __typename?: 'NumberColumn', id: string, numberValues: Array<number | null>, localNumberValues: Array<number | null> } | { __typename?: 'TextColumn', id: string, textValues: Array<string | null>, localTextValues: Array<string | null> }> | null };

export type EditTableMutationVariables = Types.Exact<{
  appId: Types.Scalars['ID'];
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  filters: Array<Types.FieldInput> | Types.FieldInput;
  tableId: Types.Scalars['ID'];
  dataRowCount: Types.Scalars['Int'];
  columns: Array<Types.ColumnInput> | Types.ColumnInput;
  extraInputs: Array<Types.QueryInput> | Types.QueryInput;
}>;


export type EditTableMutation = { __typename?: 'Mutation', editTable: { __typename?: 'EditTableEvent', ok: boolean, message?: string | null, contentToRefresh?: { __typename?: 'ContentIds', apps?: Array<string> | null, charts?: Array<string> | null, forms?: Array<string> | null, metrics?: Array<string> | null, pages?: Array<string> | null, tables?: Array<string> | null } | null } };

export const LocalEditColumnFragmentDoc = gql`
    fragment LocalEditColumn on Column {
  ... on ColumnI {
    id
  }
  ... on TextColumn {
    textValues
    localTextValues @client
  }
  ... on BooleanColumn {
    boolValues
    localBoolValues @client
  }
  ... on NumberColumn {
    numberValues
    localNumberValues @client
  }
  ... on DateColumn {
    dateValues
    localDateValues @client
  }
}
    `;
export const LocalEditTableFragmentDoc = gql`
    fragment LocalEditTable on Table {
  id
  dataRowCount
  columns {
    ...LocalEditColumn
  }
}
    ${LocalEditColumnFragmentDoc}`;
export const EditTableDocument = gql`
    mutation EditTable($appId: ID!, $projectId: Int, $filters: [FieldInput!]!, $tableId: ID!, $dataRowCount: Int!, $columns: [ColumnInput!]!, $extraInputs: [QueryInput!]!) {
  editTable(
    input: {appId: $appId, projectId: $projectId, filters: $filters, id: $tableId, dataRowCount: $dataRowCount, changed: $columns}
    extraInputs: $extraInputs
  ) {
    ok
    message
    contentToRefresh {
      ...ContentIds
    }
  }
}
    ${ContentIdsFragmentDoc}`;
export type EditTableMutationFn = Apollo.MutationFunction<EditTableMutation, EditTableMutationVariables>;

/**
 * __useEditTableMutation__
 *
 * To run a mutation, you first call `useEditTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTableMutation, { data, loading, error }] = useEditTableMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *      projectId: // value for 'projectId'
 *      filters: // value for 'filters'
 *      tableId: // value for 'tableId'
 *      dataRowCount: // value for 'dataRowCount'
 *      columns: // value for 'columns'
 *      extraInputs: // value for 'extraInputs'
 *   },
 * });
 */
export function useEditTableMutation(baseOptions?: Apollo.MutationHookOptions<EditTableMutation, EditTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTableMutation, EditTableMutationVariables>(EditTableDocument, options);
      }
export type EditTableMutationHookResult = ReturnType<typeof useEditTableMutation>;
export type EditTableMutationResult = Apollo.MutationResult<EditTableMutation>;
export type EditTableMutationOptions = Apollo.BaseMutationOptions<EditTableMutation, EditTableMutationVariables>;