import { createContext, useContext, useState } from "react";

interface LandingContext {
  appKey?: string,
  appId?: number,
  projectId?: number,

  projectName?: string,
  projectOrg?: string,
  appTitle?: string,

  applicationName?: string,
  privacyUrl?: string | null
}

interface UseLandingContextType extends LandingContext {
  updateLandingContext: (update: LandingContext) => void
}

const DEFAULT_LANDING_CONTEXT = {
  appKey: undefined,
  appId: undefined,
  projectId: undefined,
  updateLandingContext: () => {}
}

const LandingContext = createContext<UseLandingContextType>(DEFAULT_LANDING_CONTEXT)

export const LandingContextProvider = (props: { children: React.ReactNode }) => {
  const [appKey, setAppKey] = useState<string>();
  const [appId, setAppId] = useState<number>();
  const [projectId, setProjectId] = useState<number>();

  const [projectName, setProjectName] = useState<string>();
  const [projectOrg, setProjectOrg] = useState<string>();
  const [appTitle, setAppTitle] = useState<string>();

  const [applicationName, setApplicationName] = useState<string>();
  const [privacyUrl, setPrivacyUrl] = useState<string | null>();

  const updateLandingContext = (update: LandingContext) => {
    setAppKey(update.appKey);
    setAppId(update.appId);
    setProjectId(update.projectId);

    setProjectName(update.projectName);
    setProjectOrg(update.projectOrg);
    setAppTitle(update.appTitle);

    setApplicationName(update.applicationName);
    setPrivacyUrl(update.privacyUrl);
  }

  return (
    <LandingContext.Provider value={{appKey, appId, projectId, projectName, projectOrg, appTitle, applicationName, privacyUrl, updateLandingContext}}>
      { props.children }
    </LandingContext.Provider>
  )
}

export const useLandingContext = () => {
  return useContext(LandingContext);
}