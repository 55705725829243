/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesLandingApplicationCard } from '../models/MessagesLandingApplicationCard';
import type { MessagesLandingApplicationMarketingCard } from '../models/MessagesLandingApplicationMarketingCard';
import type { MessagesLandingApplicationMarketingInfoCard } from '../models/MessagesLandingApplicationMarketingInfoCard';
import type { MessagesLandingApplicationProjectCard } from '../models/MessagesLandingApplicationProjectCard';
import type { MessagesLandingMenu } from '../models/MessagesLandingMenu';
import type { MessagesLandingPlatformMarketingCard } from '../models/MessagesLandingPlatformMarketingCard';
import type { MessagesLandingProjectCard } from '../models/MessagesLandingProjectCard';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LandingApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns MessagesLandingApplicationMarketingCard OK
     * @throws ApiError
     */
    public getLandingApiGetApplicationExternal(): CancelablePromise<Array<MessagesLandingApplicationMarketingCard>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/application_external',
        });
    }

    /**
     * @param applicationId
     * @returns MessagesLandingApplicationMarketingInfoCard OK
     * @throws ApiError
     */
    public getLandingApiGetApplicationInfoExternal(
        applicationId: number,
    ): CancelablePromise<MessagesLandingApplicationMarketingInfoCard> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/application_info_external/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
        });
    }

    /**
     * @returns MessagesLandingPlatformMarketingCard OK
     * @throws ApiError
     */
    public getLandingApiGetPlatformExternal(): CancelablePromise<Array<MessagesLandingPlatformMarketingCard>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/platform_external',
        });
    }

    /**
     *  (Authorised Roles: ProjectMember)
     * @returns MessagesLandingApplicationProjectCard OK
     * @throws ApiError
     */
    public getLandingApiGetApplicationProjects(): CancelablePromise<Array<MessagesLandingApplicationProjectCard>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/application_projects',
        });
    }

    /**
     *  (Authorised Roles: ProjectMember)
     * @returns MessagesLandingProjectCard OK
     * @throws ApiError
     */
    public getLandingApiGetProjects(): CancelablePromise<Array<MessagesLandingProjectCard>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/projects',
        });
    }

    /**
     *  (Authorised Roles: ProjectMember)
     * @returns MessagesLandingApplicationCard OK
     * @throws ApiError
     */
    public getLandingApiGetApplications(): CancelablePromise<Array<MessagesLandingApplicationCard>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/applications',
        });
    }

    /**
     *  (Authorised Roles: ProjectMember)
     * @param appId
     * @param projectId
     * @param grouping
     * @returns MessagesLandingMenu OK
     * @throws ApiError
     */
    public getLandingApiGetPlatformOperations(
        appId?: number,
        projectId?: number,
        grouping?: string,
    ): CancelablePromise<MessagesLandingMenu> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/platform',
            query: {
                'appId': appId,
                'projectId': projectId,
                'grouping': grouping,
            },
        });
    }

}
