import { DateFieldFragment } from "src/ui/Field/Field.generated";
import DatePicker from '../DatePicker/DatePicker';
import { Field } from "@fluentui/react-components";
import { useMemo } from 'react';
import { dateToString } from "@arq-apps/util"

interface DateFieldProps extends DateFieldFragment {
  onChange: (id: string, date: string | null) => void;
  editable?: boolean;
}
  
export const DateFormField = (props: DateFieldProps) => {
  const value = useMemo(() => {
    return new Date(props.localDate ?? props.date ?? null)
  }, [props.localDate, props.date])

  const onSelectDate = (date: Date | null | undefined) => {
    if (!date) {
      props.onChange(props.id, null)
      return
    }
    const dateString = dateToString(date)
    props.onChange(props.id, dateString)
  }

  return (
    <Field 
      label="Select a date"
      required
    >
      <DatePicker
        value={value}
        onSelectDate={onSelectDate}
        // onChange={(ev, data) => console.log(data)}
      />
    </Field>
  );
}