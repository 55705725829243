import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import { NavLinkFragmentDoc } from '../Footer/Footer.generated';
export type QuickLinkFragment = { __typename?: 'QuickLink', title?: string | null, description?: string | null, imageUrl?: string | null, navLink: { __typename?: 'NavLink', appId?: string | null, label?: string | null, projectId?: number | null, pageId?: string | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null } };

export type QuickLinksFragment = { __typename?: 'QuickLinks', position?: Types.QuickLinksPosition | null, links?: Array<{ __typename?: 'QuickLink', title?: string | null, description?: string | null, imageUrl?: string | null, navLink: { __typename?: 'NavLink', appId?: string | null, label?: string | null, projectId?: number | null, pageId?: string | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null } }> | null };

export const QuickLinkFragmentDoc = gql`
    fragment QuickLink on QuickLink {
  title
  description
  imageUrl
  navLink {
    ...NavLink
  }
}
    ${NavLinkFragmentDoc}`;
export const QuickLinksFragmentDoc = gql`
    fragment QuickLinks on QuickLinks {
  position
  links {
    ...QuickLink
  }
}
    ${QuickLinkFragmentDoc}`;