import { InMemoryCache, makeVar, } from "@apollo/client";
import possibleTypes from "../generated/possibleTypes";
import { typePolicies } from "./schema";

// TODO https://github.com/apollographql/ac3-state-management-examples/blob/master/apollo-local-state/src/cache.tsx
export const apolloCache: InMemoryCache = new InMemoryCache({
  typePolicies,
  // Possible types enable cache to support interfaces / common properties:
  //  - https://www.apollographql.com/docs/react/data/fragments/#fragments-on-unions-and-interfaces
  //  - https://www.graphql-code-generator.com/plugins/other/fragment-matcher
  possibleTypes: possibleTypes.possibleTypes,
});

// export const filterSearchVar = makeVar<string>('');  // TODO remove
