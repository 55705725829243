/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesAppSettingsAppSettingsExport } from '../models/MessagesAppSettingsAppSettingsExport';
import type { MessagesAppSettingsAppSettingsImport } from '../models/MessagesAppSettingsAppSettingsImport';
import type { MessagesAppSettingsAppSettingsImportList } from '../models/MessagesAppSettingsAppSettingsImportList';
import type { MessagesAppSettingsAppSettingsResultItem } from '../models/MessagesAppSettingsAppSettingsResultItem';
import type { MicrosoftAspNetCoreJsonPatchOperationsOperation } from '../models/MicrosoftAspNetCoreJsonPatchOperationsOperation';
import type { SystemNetHttpHttpResponseMessage } from '../models/SystemNetHttpHttpResponseMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AppSettingsApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get an apps settings (json response)
     * @param applicationId
     * @returns MessagesAppSettingsAppSettingsExport OK
     * @throws ApiError
     */
    public getAppSettingsApiGetAppSettings(
        applicationId: number,
    ): CancelablePromise<MessagesAppSettingsAppSettingsExport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/app-settings/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
        });
    }

    /**
     * Get an apps settings as downloadable json file
     * @param applicationId
     * @returns SystemNetHttpHttpResponseMessage OK
     * @throws ApiError
     */
    public getAppSettingsApiGetAppSettingsDownloadFile(
        applicationId: number,
    ): CancelablePromise<SystemNetHttpHttpResponseMessage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/app-settings/download/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
        });
    }

    /**
     * Upload an App Settings (Export) file to be imported into this environment.
     * File will automatically be checked to see what differences there are between the file and this environment
     * @param formData
     * @returns MessagesAppSettingsAppSettingsResultItem OK
     * @throws ApiError
     */
    public postAppSettingsApiAppSettingsUpload(
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<Array<MessagesAppSettingsAppSettingsResultItem>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/app-settings/upload',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Gets a list of previously uploaded app settings files
     * @returns MessagesAppSettingsAppSettingsImportList OK
     * @throws ApiError
     */
    public getAppSettingsApiGetAppSettingsUploads(): CancelablePromise<Array<MessagesAppSettingsAppSettingsImportList>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/app-settings-upload',
        });
    }

    /**
     * Gets a specific app settings upload
     * @param appSettingsUploadId
     * @returns MessagesAppSettingsAppSettingsImport OK
     * @throws ApiError
     */
    public getAppSettingsApiGetAppSettingsUpload(
        appSettingsUploadId: number,
    ): CancelablePromise<MessagesAppSettingsAppSettingsImport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/app-settings-upload/{appSettingsUploadId}',
            path: {
                'appSettingsUploadId': appSettingsUploadId,
            },
        });
    }

    /**
     * You can patch an app-settings-upload with a new status and an ApplyRequest to apply it
     * @param appSettingsUploadId 1
     * @param requestBody [
         * {
             * "operationType": 2,
             * "path": "/status",
             * "op": "replace",
             * "from": null,
             * "value": 2
             * },
             * {
                 * "operationType": 2,
                 * "path": "/applyRequest",
                 * "op": "replace",
                 * "from": null,
                 * "value": {items: [
                     * {type: 1, action: 2}
                     * ]}
                     * }
                     * ]
                     * @returns MessagesAppSettingsAppSettingsImport OK
                     * @throws ApiError
                     */
                    public patchAppSettingsApiGetAppSettingsUpload(
                        appSettingsUploadId: number,
                        requestBody?: Array<MicrosoftAspNetCoreJsonPatchOperationsOperation>,
                    ): CancelablePromise<MessagesAppSettingsAppSettingsImport> {
                        return this.httpRequest.request({
                            method: 'PATCH',
                            url: '/rest/app-settings-upload/{appSettingsUploadId}',
                            path: {
                                'appSettingsUploadId': appSettingsUploadId,
                            },
                            body: requestBody,
                            mediaType: 'application/json-patch+json',
                        });
                    }

                }
