import './Footer.scss';
import { CookieDialog } from 'src/ux/Dialog/CookieDialog';
import { useLandingContext } from 'src/contexts/LandingContext'

export interface FooterProps {
  privacyUrl?: string,
  termsAndConditionsUrl?: string,
  cookieInfoUrl?: string,
}

export function Footer(props: FooterProps) {
  const {privacyUrl} = useLandingContext();

  return (
    <div className="footer-wrapper" id="ui-footer">
      <span>
        &copy; 2024 PwC. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate
        legal entity. Please see <a href="https://www.pwc.com/structure" target="_blank">www.pwc.com/structure</a> for further details.
      </span>
      <div style={{display: "flex", alignItems: "center"}}>
        <a href={privacyUrl ?? undefined} target="_blank">
          Privacy
        </a>
        <div>{"|"}</div>
        <a href="https://www.pwc.co.za/en/about-us/legal-disclaimer.html" target="_blank">
          Legal
        </a>
        <div>{"|"}</div>
        <CookieDialog>
          <a style={{cursor: "pointer"}}>Cookie Info</a>
        </CookieDialog>
      </div>
    </div>
  );
}

export default Footer;