/*
 * DO NOT EDIT
 * This code is managed by the ARQ Apps Core Team
 */
import './Collapsible.scss';
import { memo, useState } from "react";
import { IconButton } from '@arq-apps/ui';


export interface CollapsibleProps {
  defaultCollapsed: boolean;
  title: string;
}

export const Collapsible = memo(function Collapsible(props: React.PropsWithChildren<CollapsibleProps>) {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  return (
    <div className="ux-collapsible">
      {(props.title) && <div className="title">
        <IconButton
          icon="down-chevron-outline"
          toggleIcon="right-chevron-outline"
          onClick={toggleCollapsed}
          toggleState={collapsed}
        />
        {props.title}
      </div>}
      <div className={`collapsible-content ${collapsed ? 'collapsed' : 'expanded'}`}>
        {props.children}
      </div>
    </div>
  );
});
