import "./App.scss";
import * as g from "./App.generated";
import { PageFilterContextProvider, useContext } from "../../hooks";
import { Page } from "../Page";
import { InteractTray } from "../InteractTray";
import { InteractPane } from "../InteractPane";
import { ContextPane } from "../ContextPane";
import { useEffect, useState } from "react";
import { ApolloError } from "@apollo/client";
import SupportPane from "../SupportPane/SupportPane";
import { Centered } from "../Centered";
import ErrorBoundary from "src/ui/ErrorBoundary/ErrorBoundary";
import { HierarchicalFilterContextProvider } from "src/contexts/HierarchicalFilterContext/HierarchicalFilterContext";
import { UserWidget } from "../UserWidget";
import { Logo } from "../Logo";
import { useLandingContext } from "src/contexts/LandingContext"

function useAppOrNull(): {
  app: g.AppInfoFragment | null;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { appId, projectId } = useContext();

  const { data, loading, error } = g.useAppQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      appId,
      projectId,
      filters: [],
    },
  });
  return {
    app: data?.app ?? null,
    loading,
    error,
  };
}

export enum SupportPaneSize {
  Max = "max",
  Min = "min",
}

export function App() {
  const { app, loading, error } = useAppOrNull();
  const { pageId } = useContext();
  const { appId, appTitle } = useLandingContext();
  const [appFound, setAppFound] = useState<boolean>(true);

  const [supportPaneKey, setSupportPaneKey] = useState<string>();
  const [supportPaneSize, setSupportPaneSize] = useState<SupportPaneSize>();

  useEffect(() => {
    if (!loading && !error && !app) {
      setAppFound(false)
    }
  }, [loading, error, app])

  useEffect(() => {
    setSupportPaneSize(undefined);
  }, [pageId]);

  const setSupport = (key: string) => {
    setSupportPaneKey(key);
    setSupportPaneSize(SupportPaneSize.Min);
  };

  const classes = [
    "ux-app",
    supportPaneSize === SupportPaneSize.Min && "min",
    supportPaneSize === SupportPaneSize.Max && "max",
  ]
    .filter(Boolean)
    .join(" ");
    
  if (loading) return <>
    <div className="app-header">
      <Logo title={appTitle ?? ""}/>
    </div>,
    <Centered>Loading App</Centered>
    </>;
  else {
    return (
      <HierarchicalFilterContextProvider>
        <PageFilterContextProvider>
          <div className="app-header">
            <Logo title={appTitle ?? "Untitled"}/>
            <UserWidget />
          </div>

          <div className={classes}>
            <div className="context-pane">
              {<ContextPane setSupport={setSupport} />}
            </div>
            {supportPaneSize && supportPaneKey && (
              <div>
                <SupportPane
                  supportPaneKey={supportPaneKey}
                  supportPaneSize={supportPaneSize}
                  setSupportPaneSize={setSupportPaneSize}
                  appId={appId ?? null}
                />
              </div>
            )}
            <div className="content">
              <ErrorBoundary>{appFound ? <Page /> : <Centered>App not found</Centered>}</ErrorBoundary>
            </div>
            <div className="interact-pane">{<InteractPane />}</div>
            <div className="interact-tray">
              <InteractTray />
            </div>
          </div>
        </PageFilterContextProvider>
      </HierarchicalFilterContextProvider>
    );
  }
}
