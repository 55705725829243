import './Select.scss';
import * as S from '@radix-ui/react-select';
import { FieldInput, Icon } from "@arq-apps/ui";
import { useCallback, useMemo, useState } from 'react';
import VirtualList from 'react-tiny-virtual-list';

// TODO - create class enum
// FOR now, vanilla and hierarchical are the only types
interface SelectProps {
	placeholder: string,
	selected: string | null | undefined,
	options: string[],
  onOptionSelected: (option: string) => void,
  onClear?: () => void,
  class?: string,
  lastHierFilter?: boolean
}

export function Select(props: SelectProps) {
  const [search, setSearch] = useState<string>("");

  const handleSearch = useCallback((text: string, error: string | null) => {
    setSearch(text);
  }, []);

  const filteredOptions: string[] = useMemo(() => {
    return props.options?.filter(opt => opt.toLowerCase().includes(search.toLowerCase())) ?? []
  }, [props.options, search]);

  const SELECT_NONE = "__select-none__";

  const selectClasses = ['ux-select-vanilla', props.class && props.class].filter(Boolean).join(' ');
  const portalClasses = ['ux-select-portal-vanilla', props.class && props.class].filter(Boolean).join(' ');

  const DEFAULT_ITEM_HEIGHT = 24;
  const MAX_HEIGHT = 300;
  const wrapperHeight   = MAX_HEIGHT < filteredOptions.length * DEFAULT_ITEM_HEIGHT ? MAX_HEIGHT : filteredOptions.length * DEFAULT_ITEM_HEIGHT;
  
  return (
    <div className={selectClasses}>
      <S.Root onValueChange={ (value) => props.onOptionSelected(value) } value={ props.selected ?? SELECT_NONE}>
          <S.Trigger aria-label="Select" className={props.selected ? 'selectionMade' : undefined}>
            {props.selected == '' && props.placeholder}
            <S.SelectValue placeholder={ props.placeholder } />
            {props.class === "hierarchical" && props.lastHierFilter && <Icon icon="down-chevron-outline" /> }
            {props.class !== "hierarchical" && <Icon icon="down-chevron-outline" /> }
          </S.Trigger>
          <S.Portal className={portalClasses}>
            <S.Content position="popper">
              <div className="label">{props.placeholder}</div>
              <FieldInput value={search} onChange={handleSearch} height={30}/>
              <S.Viewport>
                <S.SelectItem value={ SELECT_NONE } className="hidden">
                  <S.SelectItemText>
                    { props.placeholder }
                  </S.SelectItemText>
                </S.SelectItem>
                <S.Group>
                  <VirtualList 
                    width="100%"
                    height={wrapperHeight + 6}
                    itemCount={filteredOptions.length}
                    itemSize={DEFAULT_ITEM_HEIGHT}
                    renderItem={(({index, style}) => {
                      return (
                        <S.SelectItem key={ index } value={ filteredOptions[index] } style={{...style, width: "calc(100% - 16px)"}}>
                          <S.SelectItemText>
                            <span className='select-text'>{ filteredOptions[index] }</span>
                          </S.SelectItemText>
                        </S.SelectItem>
                      )
                    })}
                  />
                </S.Group>
              </S.Viewport>
              {props.onClear !== undefined && <div className='select-action-container'>
                <button 
                  className='select-action-button' 
                  onClick={() => {
                    if (props.onClear) props.onClear()
                  }}
                >
                  {"Clear"}
                </button>
              </div>}
            </S.Content>
          </S.Portal>
      </S.Root>
    </div>
  );
}