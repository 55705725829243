import { ApolloClient, gql } from "@apollo/client";
import { apolloCache } from "./cache";
import { apolloLink } from "./apolloLink";
// TODO import { clientSchema } from "./apolloClientSchema";

export const apolloClient = new ApolloClient({
  // https://www.apollographql.com/docs/react/get-started#3-connect-your-client-to-react
  // uri: 'http://localhost:8000/graphql/',
  connectToDevTools: true,
  cache: apolloCache,
  link: apolloLink,
  // TODO typeDefs: clientSchema,
});
