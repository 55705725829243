import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
export type TextFieldFragment = { __typename?: 'TextField', id: string, label?: string | null, description?: string | null, text?: string | null, localEdited?: boolean | null, localText?: string | null };

export const TextFieldFragmentDoc = gql`
    fragment TextField on TextField {
  id
  label
  description
  text
  localEdited @client
  localText @client
}
    `;