import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActionLinkFragment = { __typename?: 'ActionLink', label?: string | null, action?: Types.Action | null };

export type NavLinkFragment = { __typename?: 'NavLink', appId?: string | null, label?: string | null, projectId?: number | null, pageId?: string | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null };

export type ExternalLinkFragment = { __typename?: 'ExternalLink', label?: string | null, externalLink?: string | null };

export type FooterFragment = { __typename?: 'Footer', text?: string | null, links: Array<{ __typename?: 'ActionLink', label?: string | null, action?: Types.Action | null } | { __typename?: 'ExternalLink', label?: string | null, externalLink?: string | null } | { __typename?: 'NavLink', appId?: string | null, label?: string | null, projectId?: number | null, pageId?: string | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null }> };

export type FooterQueryVariables = Types.Exact<{
  appId: Types.Scalars['ID'];
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FooterQuery = { __typename?: 'Query', platform?: { __typename?: 'Platform', footer?: { __typename?: 'Footer', text?: string | null, links: Array<{ __typename?: 'ActionLink', label?: string | null, action?: Types.Action | null } | { __typename?: 'ExternalLink', label?: string | null, externalLink?: string | null } | { __typename?: 'NavLink', appId?: string | null, label?: string | null, projectId?: number | null, pageId?: string | null, setParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null, toggleParams?: Array<{ __typename?: 'Param', key: string, value?: string | null }> | null }> } | null } | null };

export const ActionLinkFragmentDoc = gql`
    fragment ActionLink on ActionLink {
  label
  action
}
    `;
export const NavLinkFragmentDoc = gql`
    fragment NavLink on NavLink {
  appId
  label
  projectId
  pageId
  setParams {
    key
    value
  }
  toggleParams {
    key
    value
  }
}
    `;
export const ExternalLinkFragmentDoc = gql`
    fragment ExternalLink on ExternalLink {
  label
  externalLink
}
    `;
export const FooterFragmentDoc = gql`
    fragment Footer on Footer {
  text
  links {
    ... on ActionLink {
      ...ActionLink
    }
    ... on NavLink {
      ...NavLink
    }
    ... on ExternalLink {
      ...ExternalLink
    }
  }
}
    ${ActionLinkFragmentDoc}
${NavLinkFragmentDoc}
${ExternalLinkFragmentDoc}`;
export const FooterDocument = gql`
    query Footer($appId: ID!, $projectId: Int) {
  platform(appId: $appId, projectId: $projectId) {
    footer {
      ...Footer
    }
  }
}
    ${FooterFragmentDoc}`;

/**
 * __useFooterQuery__
 *
 * To run a query within a React component, call `useFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFooterQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFooterQuery(baseOptions: Apollo.QueryHookOptions<FooterQuery, FooterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FooterQuery, FooterQueryVariables>(FooterDocument, options);
      }
export function useFooterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FooterQuery, FooterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FooterQuery, FooterQueryVariables>(FooterDocument, options);
        }
export type FooterQueryHookResult = ReturnType<typeof useFooterQuery>;
export type FooterLazyQueryHookResult = ReturnType<typeof useFooterLazyQuery>;
export type FooterQueryResult = Apollo.QueryResult<FooterQuery, FooterQueryVariables>;