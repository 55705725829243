import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
export type ColumnI_BooleanColumn_Fragment = { __typename?: 'BooleanColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null };

export type ColumnI_ColumnFormula_Fragment = { __typename?: 'ColumnFormula', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null };

export type ColumnI_DateColumn_Fragment = { __typename?: 'DateColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null };

export type ColumnI_DateTimeColumn_Fragment = { __typename?: 'DateTimeColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null };

export type ColumnI_FormulaColumn_Fragment = { __typename?: 'FormulaColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null };

export type ColumnI_NumberColumn_Fragment = { __typename?: 'NumberColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null };

export type ColumnI_TextColumn_Fragment = { __typename?: 'TextColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null };

export type ColumnIFragment = ColumnI_BooleanColumn_Fragment | ColumnI_ColumnFormula_Fragment | ColumnI_DateColumn_Fragment | ColumnI_DateTimeColumn_Fragment | ColumnI_FormulaColumn_Fragment | ColumnI_NumberColumn_Fragment | ColumnI_TextColumn_Fragment;

export type AlignableColumnI_BooleanColumn_Fragment = { __typename?: 'BooleanColumn', align?: Types.ColumnAlignment | null };

export type AlignableColumnI_DateColumn_Fragment = { __typename?: 'DateColumn', align?: Types.ColumnAlignment | null };

export type AlignableColumnI_DateTimeColumn_Fragment = { __typename?: 'DateTimeColumn', align?: Types.ColumnAlignment | null };

export type AlignableColumnI_FormulaColumn_Fragment = { __typename?: 'FormulaColumn', align?: Types.ColumnAlignment | null };

export type AlignableColumnI_NumberColumn_Fragment = { __typename?: 'NumberColumn', align?: Types.ColumnAlignment | null };

export type AlignableColumnI_TextColumn_Fragment = { __typename?: 'TextColumn', align?: Types.ColumnAlignment | null };

export type AlignableColumnIFragment = AlignableColumnI_BooleanColumn_Fragment | AlignableColumnI_DateColumn_Fragment | AlignableColumnI_DateTimeColumn_Fragment | AlignableColumnI_FormulaColumn_Fragment | AlignableColumnI_NumberColumn_Fragment | AlignableColumnI_TextColumn_Fragment;

export type EditableColumnI_BooleanColumn_Fragment = { __typename?: 'BooleanColumn', editable?: boolean | null };

export type EditableColumnI_DateColumn_Fragment = { __typename?: 'DateColumn', editable?: boolean | null };

export type EditableColumnI_DateTimeColumn_Fragment = { __typename?: 'DateTimeColumn', editable?: boolean | null };

export type EditableColumnI_NumberColumn_Fragment = { __typename?: 'NumberColumn', editable?: boolean | null };

export type EditableColumnI_TextColumn_Fragment = { __typename?: 'TextColumn', editable?: boolean | null };

export type EditableColumnIFragment = EditableColumnI_BooleanColumn_Fragment | EditableColumnI_DateColumn_Fragment | EditableColumnI_DateTimeColumn_Fragment | EditableColumnI_NumberColumn_Fragment | EditableColumnI_TextColumn_Fragment;

export type TextColumnFragment = { __typename?: 'TextColumn', textValues: Array<string | null>, options?: Array<string | null> | null, localTextValues: Array<string | null>, isStorageBlob?: boolean | null, length: number, id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null, align?: Types.ColumnAlignment | null, editable?: boolean | null };

export type EditTextColumnFragment = { __typename?: 'TextColumn', textValues: Array<string | null>, localTextValues: Array<string | null> };

export type NumberColumnFragment = { __typename?: 'NumberColumn', numberValues: Array<number | null>, localNumberValues: Array<number | null>, length: number, currencyCode?: string | null, percent?: boolean | null, fractionDigits?: number | null, id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null, align?: Types.ColumnAlignment | null, editable?: boolean | null };

export type BooleanColumnFragment = { __typename?: 'BooleanColumn', boolValues: Array<boolean | null>, localBoolValues: Array<boolean | null>, length: number, id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null, align?: Types.ColumnAlignment | null, editable?: boolean | null };

export type DateColumnFragment = { __typename?: 'DateColumn', dateValues: Array<any | null>, localDateValues: Array<string | null>, id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null, align?: Types.ColumnAlignment | null, editable?: boolean | null };

export type DateTimeColumnFragment = { __typename?: 'DateTimeColumn', dateTimeValues: Array<any | null>, id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null, align?: Types.ColumnAlignment | null, editable?: boolean | null };

export type Column_BooleanColumn_Fragment = { __typename?: 'BooleanColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null, align?: Types.ColumnAlignment | null, editable?: boolean | null, boolValues: Array<boolean | null>, localBoolValues: Array<boolean | null>, length: number };

export type Column_DateColumn_Fragment = { __typename?: 'DateColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null, align?: Types.ColumnAlignment | null, editable?: boolean | null, dateValues: Array<any | null>, localDateValues: Array<string | null> };

export type Column_DateTimeColumn_Fragment = { __typename?: 'DateTimeColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null, align?: Types.ColumnAlignment | null, editable?: boolean | null, dateTimeValues: Array<any | null> };

export type Column_FormulaColumn_Fragment = { __typename?: 'FormulaColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null, align?: Types.ColumnAlignment | null };

export type Column_NumberColumn_Fragment = { __typename?: 'NumberColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null, align?: Types.ColumnAlignment | null, editable?: boolean | null, numberValues: Array<number | null>, localNumberValues: Array<number | null>, length: number, currencyCode?: string | null, percent?: boolean | null, fractionDigits?: number | null };

export type Column_TextColumn_Fragment = { __typename?: 'TextColumn', id: string, hide?: boolean | null, highlight?: boolean | null, pin?: boolean | null, pinStart?: boolean | null, pinEnd?: boolean | null, size?: number | null, title?: Array<string | null> | null, showTotals?: boolean | null, align?: Types.ColumnAlignment | null, editable?: boolean | null, textValues: Array<string | null>, options?: Array<string | null> | null, localTextValues: Array<string | null>, isStorageBlob?: boolean | null, length: number };

export type ColumnFragment = Column_BooleanColumn_Fragment | Column_DateColumn_Fragment | Column_DateTimeColumn_Fragment | Column_FormulaColumn_Fragment | Column_NumberColumn_Fragment | Column_TextColumn_Fragment;

export const EditTextColumnFragmentDoc = gql`
    fragment EditTextColumn on TextColumn {
  textValues
  localTextValues @client
}
    `;
export const ColumnIFragmentDoc = gql`
    fragment ColumnI on ColumnI {
  id
  hide
  highlight
  pin
  pinStart
  pinEnd
  size
  title
  showTotals
}
    `;
export const AlignableColumnIFragmentDoc = gql`
    fragment AlignableColumnI on AlignableColumnI {
  align
}
    `;
export const EditableColumnIFragmentDoc = gql`
    fragment EditableColumnI on EditableColumnI {
  editable
}
    `;
export const TextColumnFragmentDoc = gql`
    fragment TextColumn on TextColumn {
  ...ColumnI
  ...AlignableColumnI
  ...EditableColumnI
  textValues
  options
  localTextValues @client
  isStorageBlob
  length
}
    ${ColumnIFragmentDoc}
${AlignableColumnIFragmentDoc}
${EditableColumnIFragmentDoc}`;
export const BooleanColumnFragmentDoc = gql`
    fragment BooleanColumn on BooleanColumn {
  ...ColumnI
  ...AlignableColumnI
  ...EditableColumnI
  boolValues
  localBoolValues @client
  length
}
    ${ColumnIFragmentDoc}
${AlignableColumnIFragmentDoc}
${EditableColumnIFragmentDoc}`;
export const NumberColumnFragmentDoc = gql`
    fragment NumberColumn on NumberColumn {
  ...ColumnI
  ...AlignableColumnI
  ...EditableColumnI
  numberValues
  localNumberValues @client
  length
  currencyCode
  percent
  fractionDigits
}
    ${ColumnIFragmentDoc}
${AlignableColumnIFragmentDoc}
${EditableColumnIFragmentDoc}`;
export const DateColumnFragmentDoc = gql`
    fragment DateColumn on DateColumn {
  ...ColumnI
  ...AlignableColumnI
  ...EditableColumnI
  dateValues
  localDateValues @client
}
    ${ColumnIFragmentDoc}
${AlignableColumnIFragmentDoc}
${EditableColumnIFragmentDoc}`;
export const DateTimeColumnFragmentDoc = gql`
    fragment DateTimeColumn on DateTimeColumn {
  ...ColumnI
  ...AlignableColumnI
  ...EditableColumnI
  dateTimeValues
}
    ${ColumnIFragmentDoc}
${AlignableColumnIFragmentDoc}
${EditableColumnIFragmentDoc}`;
export const ColumnFragmentDoc = gql`
    fragment Column on Column {
  ... on ColumnI {
    ...ColumnI
  }
  ... on AlignableColumnI {
    ...AlignableColumnI
  }
  ... on EditableColumnI {
    ...EditableColumnI
  }
  ... on TextColumn {
    ...TextColumn
  }
  ... on BooleanColumn {
    ...BooleanColumn
  }
  ... on NumberColumn {
    ...NumberColumn
  }
  ... on DateColumn {
    ...DateColumn
  }
  ... on DateTimeColumn {
    ...DateTimeColumn
  }
}
    ${ColumnIFragmentDoc}
${AlignableColumnIFragmentDoc}
${EditableColumnIFragmentDoc}
${TextColumnFragmentDoc}
${BooleanColumnFragmentDoc}
${NumberColumnFragmentDoc}
${DateColumnFragmentDoc}
${DateTimeColumnFragmentDoc}`;