import './Metric.scss';
import * as g from "./Metric.generated";
import { useContext, usePageFilterContext } from 'src/hooks';
import { useEffect, useMemo, useState } from 'react';
import { FieldInput } from '@arq-apps/generated';
import { useLandingContext } from 'src/contexts/LandingContext'
import { useHierarchicalFilterContext } from 'src/contexts/HierarchicalFilterContext/HierarchicalFilterContext'
import { usePage } from 'src/hooks/usePage'

export type MetricProps = g.MetricFragment & {
  tileFilterInputs?: FieldInput[],
}

function useMetric(metricId: string, tileFilterInputs?: FieldInput[], pageFilterInputs?: FieldInput[] | null) {  
  const {appId, projectId} = useContext();
  const LandingContext = useLandingContext();
  const {hierarchicalFilterInputs} = useHierarchicalFilterContext();
  const [metric, setMetric] = useState<g.MetricFragment | null | undefined>();
  const {pageLoaded} = usePage();

  const [getMetric, getMetricData] = g.useMetricLazyQuery();

  const filters = useMemo(() => {
    const _pageFilterInputs = pageFilterInputs ?? [];
    const _tileFilterinputs = tileFilterInputs ?? [];
    const _hierarchicalFilterinputs = hierarchicalFilterInputs ?? [];
    return [..._tileFilterinputs, ..._pageFilterInputs, ..._hierarchicalFilterinputs];
  }, [tileFilterInputs, pageFilterInputs, hierarchicalFilterInputs]);

  const fetchMetric = async() => {
    const fetchMetricData = await getMetric({
      fetchPolicy: "network-only",
      variables: {
        appId: appId === "landing" ? "main" : appId,
        projectId,
        metricId,
        filters,
        extraInputs: [
          {"name": "appId", "value": `${LandingContext.appId}`},
          {"name": "projectId", "value": `${LandingContext.projectId}`}
        ]
      },
    })
    const _metric = fetchMetricData.data?.app?.metricById
    setMetric(_metric)
  };

  useEffect(() => {
    if (pageLoaded) {
      fetchMetric();
    }
  }, [metricId, filters, pageLoaded]);

  return {
    metric,
    metricLoading: getMetricData.loading
  }
}

export function Metric(props: MetricProps) {
  const { pageFilterInputs } = usePageFilterContext();
  const { metric, metricLoading } = useMetric(props.id, props.tileFilterInputs, pageFilterInputs);

  const classes = [
    "ux-metric",
  ].filter(Boolean).join(" ");

  return (
    <div className={ classes }>
      { metricLoading && "Loading..."}
      { !metricLoading && (metric?.label ?? metric?.value) }
    </div>
  );
}
