import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ApolloLink, ServerError } from "@apollo/client";

// https://www.apollographql.com/docs/react/api/link/apollo-link-context

// cached storage for the user token
let token: string | null = null;

const withToken = setContext(() => {
  if (token) {
    return { token };  // return cached token if exists
  }
  // return AsyncTokenLookup().then(userToken => {
  //   token = userToken;
  //   return { token };
  // });
  token = sessionStorage.getItem("token") || null;
  console.log("~~~~~~ WILL ADD TOKEN", token)
  return { token };
});

const resetToken = onError(({ networkError }) => {
  if (
    networkError &&
    networkError.name === "ServerError" &&
    (networkError as ServerError).statusCode === 401
  ) {
    // remove cached token on 401 from the server
    token = null;
    // console.debug("~~~~~~ WILL REMOVE TOKEN [401]")
    // purge local storage & session storage
    sessionStorage.removeItem("token");
    sessionStorage.clear();
    // if no redirectUri is provided - reload the root platform page
    window.location.href = window.location.origin;
  }
});

/**
 * Adds the Authorization/Bearer header
 */
const addAuthHeaderLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {}, token = null }) => {
    return {
      headers: {
        ...headers,
        ...(token ? {
          authorization: `Bearer ${token}`,
        } : { }),
      },
    };
  });
  return forward(operation);
});

export const authFlowLink = withToken.concat(addAuthHeaderLink).concat(resetToken);
