import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HierarchicalOptionDetailsFragment = { __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null };

export type HierarchicalFilterFragment = { __typename?: 'HierarchicalFilter', id: string, label?: string | null, description?: string | null, optionsLabel?: string | null, multiselectOnLeaf?: boolean | null, options?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null, options?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null, options?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null }> | null }> | null }> | null, selection?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null, options?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null, options?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null }> | null }> | null }> | null, leafSelections?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null }> | null };

export type HierarchicalFilterIdsQueryVariables = Types.Exact<{
  appId: Types.Scalars['ID'];
  pageId: Types.Scalars['ID'];
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type HierarchicalFilterIdsQuery = { __typename?: 'Query', app?: { __typename?: 'App', pageById?: { __typename?: 'Page', hierarchicalFilterIds?: Array<string> | null } | null } | null };

export type HierarchicalFiltersByIdsQueryVariables = Types.Exact<{
  appId: Types.Scalars['ID'];
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  filterIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  extraInputs: Array<Types.QueryInput> | Types.QueryInput;
}>;


export type HierarchicalFiltersByIdsQuery = { __typename?: 'Query', app?: { __typename?: 'App', hierarchicalFiltersByIds: Array<{ __typename?: 'HierarchicalFilter', id: string, label?: string | null, description?: string | null, optionsLabel?: string | null, multiselectOnLeaf?: boolean | null, options?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null, options?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null, options?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null }> | null }> | null }> | null, selection?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null, options?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null, options?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null }> | null }> | null }> | null, leafSelections?: Array<{ __typename?: 'HierarchicalOption', label?: string | null, value?: string | null, optionsLabel?: string | null }> | null } | null> } | null };

export const HierarchicalOptionDetailsFragmentDoc = gql`
    fragment HierarchicalOptionDetails on HierarchicalOption {
  label
  value
  optionsLabel
}
    `;
export const HierarchicalFilterFragmentDoc = gql`
    fragment HierarchicalFilter on HierarchicalFilter {
  id
  label
  description
  optionsLabel
  options {
    ...HierarchicalOptionDetails
    options {
      ...HierarchicalOptionDetails
      options {
        ...HierarchicalOptionDetails
      }
    }
  }
  selection {
    ...HierarchicalOptionDetails
    options {
      ...HierarchicalOptionDetails
      options {
        ...HierarchicalOptionDetails
      }
    }
  }
  leafSelections {
    ...HierarchicalOptionDetails
  }
  multiselectOnLeaf
}
    ${HierarchicalOptionDetailsFragmentDoc}`;
export const HierarchicalFilterIdsDocument = gql`
    query HierarchicalFilterIds($appId: ID!, $pageId: ID!, $projectId: Int) {
  app(id: $appId, projectId: $projectId, filters: []) {
    pageById(id: $pageId) {
      hierarchicalFilterIds
    }
  }
}
    `;

/**
 * __useHierarchicalFilterIdsQuery__
 *
 * To run a query within a React component, call `useHierarchicalFilterIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHierarchicalFilterIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHierarchicalFilterIdsQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      pageId: // value for 'pageId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useHierarchicalFilterIdsQuery(baseOptions: Apollo.QueryHookOptions<HierarchicalFilterIdsQuery, HierarchicalFilterIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HierarchicalFilterIdsQuery, HierarchicalFilterIdsQueryVariables>(HierarchicalFilterIdsDocument, options);
      }
export function useHierarchicalFilterIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HierarchicalFilterIdsQuery, HierarchicalFilterIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HierarchicalFilterIdsQuery, HierarchicalFilterIdsQueryVariables>(HierarchicalFilterIdsDocument, options);
        }
export type HierarchicalFilterIdsQueryHookResult = ReturnType<typeof useHierarchicalFilterIdsQuery>;
export type HierarchicalFilterIdsLazyQueryHookResult = ReturnType<typeof useHierarchicalFilterIdsLazyQuery>;
export type HierarchicalFilterIdsQueryResult = Apollo.QueryResult<HierarchicalFilterIdsQuery, HierarchicalFilterIdsQueryVariables>;
export const HierarchicalFiltersByIdsDocument = gql`
    query HierarchicalFiltersByIds($appId: ID!, $projectId: Int, $filterIds: [ID!]!, $extraInputs: [QueryInput!]!) {
  app(id: $appId, projectId: $projectId, extraInputs: $extraInputs, filters: []) {
    hierarchicalFiltersByIds(ids: $filterIds) {
      ...HierarchicalFilter
    }
  }
}
    ${HierarchicalFilterFragmentDoc}`;

/**
 * __useHierarchicalFiltersByIdsQuery__
 *
 * To run a query within a React component, call `useHierarchicalFiltersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHierarchicalFiltersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHierarchicalFiltersByIdsQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      projectId: // value for 'projectId'
 *      filterIds: // value for 'filterIds'
 *      extraInputs: // value for 'extraInputs'
 *   },
 * });
 */
export function useHierarchicalFiltersByIdsQuery(baseOptions: Apollo.QueryHookOptions<HierarchicalFiltersByIdsQuery, HierarchicalFiltersByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HierarchicalFiltersByIdsQuery, HierarchicalFiltersByIdsQueryVariables>(HierarchicalFiltersByIdsDocument, options);
      }
export function useHierarchicalFiltersByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HierarchicalFiltersByIdsQuery, HierarchicalFiltersByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HierarchicalFiltersByIdsQuery, HierarchicalFiltersByIdsQueryVariables>(HierarchicalFiltersByIdsDocument, options);
        }
export type HierarchicalFiltersByIdsQueryHookResult = ReturnType<typeof useHierarchicalFiltersByIdsQuery>;
export type HierarchicalFiltersByIdsLazyQueryHookResult = ReturnType<typeof useHierarchicalFiltersByIdsLazyQuery>;
export type HierarchicalFiltersByIdsQueryResult = Apollo.QueryResult<HierarchicalFiltersByIdsQuery, HierarchicalFiltersByIdsQueryVariables>;