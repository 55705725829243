import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppInfoFragment = { __typename?: 'App', id: string };

export type AppQueryVariables = Types.Exact<{
  appId: Types.Scalars['ID'];
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  filters: Array<Types.FieldInput> | Types.FieldInput;
}>;


export type AppQuery = { __typename?: 'Query', app?: { __typename?: 'App', id: string } | null };

export const AppInfoFragmentDoc = gql`
    fragment AppInfo on App {
  id
}
    `;
export const AppDocument = gql`
    query App($appId: ID!, $projectId: Int, $filters: [FieldInput!]!) {
  app(id: $appId, projectId: $projectId, filters: $filters) {
    ...AppInfo
  }
}
    ${AppInfoFragmentDoc}`;

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      projectId: // value for 'projectId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAppQuery(baseOptions: Apollo.QueryHookOptions<AppQuery, AppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppQuery, AppQueryVariables>(AppDocument, options);
      }
export function useAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppQuery, AppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppQuery, AppQueryVariables>(AppDocument, options);
        }
export type AppQueryHookResult = ReturnType<typeof useAppQuery>;
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>;
export type AppQueryResult = Apollo.QueryResult<AppQuery, AppQueryVariables>;