import "./Landing.scss";
import { useEffect, useState } from "react";
import { useContext } from "src/hooks";
import { useLandingContext } from "src/contexts/LandingContext";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { LOGGED_IN_MENU } from "./constants";
import {
  MessagesLandingApplicationCard,
  MessagesLandingApplicationProjectCard,
  MessagesLandingMenuItem,
  MessagesLandingProjectCard,
} from "@arq-apps/dotnet";
import { CardsToMenu, searchFilterGenerator } from "./functions";

import {
  BreadCrumbs,
  Logo,
  UserWidget,
  SearchBox,
  TabMenu,
  TabMenuItem,
  LaunchCard,
  ApplicationCard,
  ProjectCard,
  ManageCard,
  ManagePage,
} from "@arq-apps/ux";
import { Footer } from "@arq-apps/ui";

export function LandingLoggedIn() {
  const menu = LOGGED_IN_MENU;
  const [manageMenu, setManageMenu] = useState<MessagesLandingMenuItem[]>();
  const { updateLandingContext } = useLandingContext();
  const [mainMenuIndex, setMainMenuIndex] = useState<number>(0);
  const [secondMenu, setSecondMenu] = useState<TabMenuItem[]>([]);
  const [secondMenuIndex, setSecondMenuIndex] = useState<number>(0);
  const [searchString, setSearchString] = useState<string>("");

  const [applicationProjects, setApplicationProjects] = useState<
    MessagesLandingApplicationProjectCard[]
  >([]);
  const [applications, setApplications] = useState<
    MessagesLandingApplicationCard[]
  >([]);
  const [projects, setProjects] = useState<MessagesLandingProjectCard[]>([]);
  const [manage, setManage] = useState<MessagesLandingMenuItem[]>([]);

  const { appId, updateContext } = useContext();
  const [managePageId, setManagePageId] = useState<string>();
  const [managePageName, setManagePageName] = useState<string>("");

  const { dmpClient, isDMPClientLoaded } = useDotnetClient();

  useEffect(() => {
    if (appId === ("landing" || "")) {
      updateContext({ appId: "main" });
    }
  }, [appId]);

  const fetchApplicationProjects = async () => {
    dmpClient?.landingApi
      .getLandingApiGetApplicationProjects()
      .then((result) => {
        setApplicationProjects(result);
        setSecondMenu(CardsToMenu(result));
        setSecondMenuIndex(0);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const fetchProjects = async () => {
    dmpClient?.landingApi
      .getLandingApiGetProjects()
      .then((result) => {
        setProjects(result);
        setSecondMenu(CardsToMenu(result));
        setSecondMenuIndex(0);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const fetchApplications = async () => {
    dmpClient?.landingApi
      .getLandingApiGetApplications()
      .then((result) => {
        setApplications(result);
        setSecondMenu(CardsToMenu(result));
        setSecondMenuIndex(0);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const fetchManage = async () => {
    dmpClient?.landingApi
      .getLandingApiGetPlatformOperations(1, 0, "manage")
      .then((result) => {
        setManageMenu(result.items[0].items);
        const platformOperationsMenu = result.items[0].items.map(
          (item: MessagesLandingMenuItem) => {
            return {
              key: item.name,
              title: item.name,
            };
          }
        );
        setSecondMenu(platformOperationsMenu);
        setSecondMenuIndex(0);
        setManage(result.items[0].items[0].items);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(() => {
    if (!isDMPClientLoaded) return;
    if (menu[mainMenuIndex].key === "application_projects") {
      if (applicationProjects.length === 0) {
        fetchApplicationProjects();
      } else {
        setSecondMenu(CardsToMenu(applicationProjects));
        setSecondMenuIndex(0);
      }
    }
    if (menu[mainMenuIndex].key === "projects") {
      if (projects.length === 0) {
        fetchProjects();
      } else {
        setSecondMenu(CardsToMenu(projects));
        setSecondMenuIndex(0);
      }
    }
    if (menu[mainMenuIndex].key === "applications") {
      if (applications.length === 0) {
        fetchApplications();
      } else {
        setSecondMenu(CardsToMenu(applications));
        setSecondMenuIndex(0);
      }
    }
    if (menu[mainMenuIndex].key === "platform_operations") {
      if (manage.length === 0) {
        fetchManage();
      } else {
        setSecondMenu(
          manageMenu?.map((item: MessagesLandingMenuItem) => {
            return {
              key: item.name,
              title: item.name,
            };
          }) ?? []
        );
      }
    }
  }, [isDMPClientLoaded, mainMenuIndex]);

  useEffect(() => {
    if (mainMenuIndex === 3 && manageMenu) {
      setManage(manageMenu[secondMenuIndex].items);
    }
  }, [secondMenuIndex]);

  const searchFilter = searchFilterGenerator(searchString);

  return (
    <div className="landing">
      <div className="header">
        <Logo
          onClick={() => {
            setMainMenuIndex(0);
            setSecondMenuIndex(0);
            setManagePageId(undefined);
          }}
        />
        <TabMenu
          inHeader={true} 
          items={menu}
          selectedIndex={mainMenuIndex}
          onChange={(value) => {
            setMainMenuIndex(value);
            setSecondMenuIndex(0);
            setManagePageId(undefined);
          }}
        />
        <UserWidget />
      </div>

      {managePageId ? (
        <>
          <BreadCrumbs
            title={"Platform"}
            items={[
              menu[mainMenuIndex].title,
              secondMenu[secondMenuIndex].title,
              managePageName,
            ]}
            onBack={() => setManagePageId(undefined)}
            onHome={() => {
              setMainMenuIndex(0);
              setSecondMenuIndex(0);
              setManagePageId(undefined);
            }}
          />
          <ManagePage managePageId={managePageId} />
        </>
      ) : (
        <>
          <div className="second-level-menu-container">
            <div></div>
            <TabMenu
              items={secondMenu}
              selectedIndex={secondMenuIndex}
              onChange={setSecondMenuIndex}
            />
            <SearchBox
              searchString={searchString}
              setSearchString={setSearchString}
            />
          </div>
          <div className="card-container">
            {/* TODO @RM - prevent flash with delayed loading state */}
            {menu[mainMenuIndex].key === "application_projects" &&
              applicationProjects
                .filter((x) =>
                  x?.card?.groupings?.some(
                    (x) => x.value === secondMenu[secondMenuIndex].title
                  )
                )
                .filter(searchFilter)
                .map((item, index) => (
                  <LaunchCard
                    key={`${item.app_key}-${item.project_id}`}
                    {...item}
                  />
                ))}
            {menu[mainMenuIndex].key === "projects" &&
              projects
                .filter((x) =>
                  x?.card?.groupings?.some(
                    (x) => x.value === secondMenu[secondMenuIndex].title
                  )
                )
                .filter(searchFilter)
                .map((item, index) => (
                  <ProjectCard
                    key={index}
                    {...item}
                    onClick={() =>
                      updateLandingContext({
                        appKey: undefined,
                        appId: undefined,
                        projectId: item.project_id,
                        projectName: item.project,
                      })
                    }
                  />
                ))}
            {menu[mainMenuIndex].key === "applications" &&
              applications
                .filter((x) =>
                  x?.card?.groupings?.some(
                    (x) => x.value === secondMenu[secondMenuIndex].title
                  )
                )
                .filter(searchFilter)
                .map((item, index) => (
                  <ApplicationCard
                    key={`${index}:${item.app_id}`}
                    {...item.card}
                    onClick={() =>
                      updateLandingContext({
                        appKey: item.app_key,
                        appId: item.app_id,
                        projectId: undefined,
                        applicationName: item.application,
                      })
                    }
                  />
                ))}
            {menu[mainMenuIndex].key === "platform_operations" &&
              manage.map((item, index) => (
                <ManageCard
                  key={index}
                  {...item}
                  onClick={() => {
                    updateContext({
                      appId: "main",
                      projectId: null,
                      pageId: item.page_id,
                    });
                    setManagePageName(item.name ?? "Page");
                    setManagePageId(item.page_id);
                  }}
                />
              ))}
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}
