import './Icon.scss';
import { IconName } from "../Theme";

// TODO memo() to configure as pure with shallow comparisons
//  https://reactjs.org/docs/react-api.html#reactmemo

export interface IconProps {
  // icon?: IconName,
  // hoverIcon?: IconName,
  icon?: string,
  hoverIcon?: string,
  scale?: number,
  debug?: IconName,  // convenience for finding check marks
  color?: string
}

export function Icon(props: IconProps) {
  const classNames = [
    'ui-icon',
    props.icon ? `ui-icon ui-icon-${props.icon}` : '',
    props.hoverIcon ? `ui-hover-icon-${props.hoverIcon}` : '',
  ].filter(Boolean).join(' ');
  return (
    <i className={ classNames }
       style={{ transform: `scale(${props.scale ?? 1})`, color: `${props.color ? props.color : ""}` }}
    ></i>
  );
}

export function CheckIcon(props: IconProps) {
  return <Icon icon="check-mark-fill" debug="check-mark-fill" />;
}
