import "./DataAssetLog.scss";
import React, { useEffect, useState } from "react"
import { ENDPOINTS } from "@arq-apps/util"
import { DateTimeFormatOptions } from "luxon"
import { useContext } from "src/hooks"
import { useDotnetClient } from "src/contexts/DotnetClientContext"
import { CancelablePromise, MessagesStorageFileRequirements, MessagesDataAssetsTDataAssetActivity } from "@arq-apps/dotnet"
import { toast } from "react-toastify"

import { Button, ErrorBoundary, Icon, IconButton } from "@arq-apps/ui"
import { Tooltip } from "@arq-apps/ux"

interface ActivityLogContext {
	appId: number | null,
	projectId: number | undefined | null,
	entityId: number | undefined,
	dataAssetId: number | undefined,
}

interface ActivityStepItemProps {
	activityContext: ActivityLogContext,
	updateActivityLog: (log: MessagesDataAssetsTDataAssetActivity[]) => void
}

interface StepItemProps extends MessagesDataAssetsTDataAssetActivity, ActivityStepItemProps { }
interface StepItemUploadProps extends StepItemProps {
	fileSizeLimit: number;
	acceptFiles: string[];
	acceptMimeTypes: string[];
}

const formatDateTimeLocal = (dateString: string) : string => {
	const date = new Date(dateString);
	const offsetMinutes = date.getTimezoneOffset();
	const localTime = new Date(date.getTime() - offsetMinutes * 60 * 1000);
	const options: DateTimeFormatOptions = {
		weekday: "short",
		day: "2-digit",
		month: "2-digit",
		hour: "numeric",
		minute: "numeric"
	}
	const localDateString = localTime.toLocaleString("en-GB", options)
	return localDateString;
}

const validateFile = (file: File | null = null, fileSizeLimit: number = 20 * 1024 * 1024, acceptFiles: string[] = [".csv", ".xlsx"]): boolean => {
	if (!file)
		return false;

	if (file.size === 0) {
		toast.error(`File is empty.`);
		return false;
	}

	if (file.size > fileSizeLimit) {
		toast.error(`File size exceeds max size of ${fileSizeLimit / 1024 / 1024} MB`);
		return false;
	}

	const fileName = file?.name || '';
	if (!fileName) {
		toast.error(`Cant determine filename`);
		return false;
	}

	const match = fileName.match(/\.([^\.]+)$/);
	if (match === null || match.length < 2) {
		toast.error(`Cant find extension`);
		return false;
	}

	const fileExt = match[1];
	console.log(fileExt);
	if (acceptFiles.findIndex(x => x === '.' + fileExt) === -1) {
		toast.error(`Invalid file type selected. Must be ${acceptFiles.join('/')}`);
		return false;
	}

	return true;
}

/// When the next step is to upload a file
const UploadStepItem = (props: StepItemUploadProps) => {

	return (
		<div className="steps-item">
			<div className="steps-item-tail"></div>
			<div className="steps-item-icon">
				<Icon icon="time-outline" />
			</div>
			<div className="steps-item-content">
				<div className="steps-item-byline">
					<div className="byline-title">
						<div>Next step: Process data</div>
					</div>
				</div>
				<div className="steps-item-section">
					<div>
						<div className="steps-item">Process data within the app to generate a new result.</div>
					</div>
				</div>
			</div>
		</div>
	)
}

// When a file was uploaded in the history
const UploadedLogItem = (props: StepItemProps) => {
	const [loading, setLoading] = useState<boolean>(false)
	const dateString = formatDateTimeLocal(props.date);

	const onDownloadClick = async () => {

		try {
			setLoading(true)
			const response = await fetch(`${ENDPOINTS.LANDING}/storage/download_file/${props.url},${props.activityContext.appId},${props.activityContext.projectId},4`, {
				headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") }
			})
			if (response.ok) {
				const blob = await response.blob();
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = props.file_name ?? "filename.txt";
				document.body.appendChild(a);
				a.click();
				a.remove();
				}
			else {
				let errMessage = 'Unknown error';
				if (response.statusText)
					errMessage = response.statusText;
				try {
					const tryJson = await response.json();
					if (tryJson.Message)
						errMessage = tryJson.Message;
				} catch (error) {
					// ignore
				}
				toast.error('Error downloading file: ' + errMessage);
			}
		} catch (error) {
			toast.error('Error downloading file: ' + error);
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	if (props.activity === "Comment") {
		return (
			<div className="steps-item" key={props.date}>
				<div className="steps-item-tail no-icon"></div>
				<div className="steps-item-content no-icon">
					<div className="steps-item-byline">
						<div className="byline-title">
							<div>reply from</div>
							<div className="accent">{props.user}</div>
						</div>
						<div className="byline-date">{dateString}</div>
					</div>
					{props.blurb && <div className="steps-item-section">
						{props.blurb}
					</div>}
				</div>
			</div>
		)
	}

	return (
		<div className="steps-item" key={props.date}>
			<div className="steps-item-tail"></div>
			<div className="steps-item-icon">
				<Icon icon="upload-outline" />
			</div>
			<div className="steps-item-content">
				<div className="steps-item-byline">
					<div className="byline-title">
						<div>File uploaded by</div>
						<div className="accent">{props.user}</div>
					</div>
					<div className="byline-date">{dateString}</div>
				</div>
				<div className="steps-item-section">
					<div>
						<div className="steps-item-filename">{props.file_name}</div>
					</div>
					<div className="action-container">
						<IconButton icon="download-outline" onClick={onDownloadClick} disabled={loading || !props.data_asset.allow_download_draft} />
					</div>
				</div>
				{props.blurb && <div className="steps-item-section">
					{props.blurb}
				</div>}
			</div>
		</div>
	)
}

// When the next step is to review the file (last activity was Uploaded)
const ReviewStepItem = (props: StepItemUploadProps) => {
	const [replyOpen, setReplyOpen] = useState(false);
	const [replyComment, setReplyComment] = useState<string>();
	const [discardOpen, setDiscardOpen] = useState(false);
	const [uploadedFile, setUploadedFile] = useState<File>();
	const [comment, setComment] = useState<string>();
	const [uploading, setUploading] = useState<boolean>();
	const [error, setError] = useState<string>('');

	const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
		setError('');
		const files = e.target.files ?? []
		const selectedFile = files[0]
		setUploadedFile(selectedFile)
	};

	const onAccept = async () => {
		setError('');
		setUploading(true);
		const formData = new FormData();
		if (props.activityContext.appId
			&& props.activityContext.projectId
			&& props.activityContext.entityId
			&& props.activityContext.dataAssetId
		) {
			formData.append("application_id", props.activityContext.appId?.toString());
			formData.append("project_id", props.activityContext.projectId.toString());
			formData.append("entity_id", props.activityContext.entityId.toString());
			formData.append("data_asset_id", props.activityContext.dataAssetId.toString());
		}
		formData.append("transition", "Accept");
		fetch(`${ENDPOINTS.LANDING}/data_asset/transition`, {
			method: 'POST',
			headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
			body: formData,
		})
			.then(async resp => {
				if (resp.ok)
					return resp.json();

					console.log(resp);
				const errorMessage = await resp.json().then(x => x.Message).catch(() => `${resp.status} ${resp.statusText}`);
				throw new Error(errorMessage);
			})
			.then(result => props.updateActivityLog(result))
			.catch(error => {
				setError(`File upload failed : ${error.message || error}`);
				toast.error(`File upload failed : ${error}`);
				console.error(error);
			})
			.finally(() => setUploading(false));

	}

	const onDiscard = async () => {
		setError('');
		if (uploadedFile && !validateFile(uploadedFile, props.fileSizeLimit, props.acceptFiles))
			return;

		setUploading(true);
		const formData = new FormData();
		if (props.activityContext.appId
			&& props.activityContext.projectId
			&& props.activityContext.entityId
			&& props.activityContext.dataAssetId
		) {
			formData.append("application_id", props.activityContext.appId?.toString());
			formData.append("project_id", props.activityContext.projectId.toString());
			formData.append("entity_id", props.activityContext.entityId.toString());
			formData.append("data_asset_id", props.activityContext.dataAssetId.toString());
		}
		formData.append("transition", "Discard");
		if (uploadedFile) {
			formData.append('file', uploadedFile, uploadedFile?.name);
		}
		if (comment) {
			formData.append('notes', comment);
		}
		fetch(`${ENDPOINTS.LANDING}/data_asset/transition`, {
			method: 'POST',
			headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
			body: formData,
		})
			.then(async resp => {
				if (resp.ok)
					return resp.json();

					console.log(resp);
				const errorMessage = await resp.json().then(x => x.Message).catch(() => `${resp.status} ${resp.statusText}`);
				throw new Error(errorMessage);
			})
			.then(result => props.updateActivityLog(result))
			.catch(error => {
				setError(`File upload failed : ${error.message || error}`);
				toast.error(`File upload failed : ${error}`);
				console.error(error);
			})
			.finally(() => setUploading(false));
	}

	const onReply = async () => {
		setError('');
		setUploading(true);
		const formData = new FormData();
		if (props.activityContext.appId
			&& props.activityContext.projectId
			&& props.activityContext.entityId
			&& props.activityContext.dataAssetId
		) {
			formData.append("application_id", props.activityContext.appId?.toString());
			formData.append("project_id", props.activityContext.projectId.toString());
			formData.append("entity_id", props.activityContext.entityId.toString());
			formData.append("data_asset_id", props.activityContext.dataAssetId.toString());
		}
		formData.append("transition", "Comment");
		if (replyComment)
			formData.append('notes', replyComment);

		fetch(`${ENDPOINTS.LANDING}/data_asset/transition`, {
			method: 'POST',
			headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
			body: formData,
		})
			.then(async resp => {
				if (resp.ok)
					return resp.json();

					console.log(resp);
				const errorMessage = await resp.json().then(x => x.Message).catch(() => `${resp.status} ${resp.statusText}`);
				throw new Error(errorMessage);
			})
			.then(result => {
				setReplyOpen(false);
				setReplyComment(undefined);
				props.updateActivityLog(result);
			})
			.catch(error => {
				setError(`File upload failed : ${error.message || error}`);
				toast.error(`File upload failed : ${error}`);
				console.error(error);
			})
			.finally(() => setUploading(false));
	}

	return (
		<>
			<div className="steps-item">
				<div className="steps-item-tail no-icon"></div>
				<div className="steps-item-content no-icon">
					{replyOpen && <textarea className="blurb-input" placeholder="Leave your comments" autoFocus
						value={replyComment} onChange={(e) => { setReplyComment(e.target.value) }}
						cols={50} rows={(replyComment?.length ?? 150) / 40}
					/>}
					{replyOpen && <div className="action-buttons">
						<Button text="Confirm" theme="default" onClick={onReply} disabled={uploading} />
						<Button text="Cancel" theme="white" onClick={() => setReplyOpen(false)} />
					</div>}
				</div>
			</div>
			<div className="steps-item">
				<div className="steps-item-tail"></div>
				<div className="steps-item-icon">
					<Icon icon="search-outline" />
				</div>
				<div className="steps-item-content">
					<div className="steps-item-byline">
						<div className="byline-title">
							<div>Review file</div>
						</div>
						{!replyOpen && props.data_asset.allow_comment && <div className="accent pointer semi-bold" onClick={() => setReplyOpen(true)}>reply</div>}
					</div>
					<div className="steps-item-byline">
						<div>{!discardOpen ?
							"Approve file or reject with comments" :
							"You are returning the uploaded file. Please provide clear reasons for your decision."
						}</div>
					</div>
					{!discardOpen && <div className="action-buttons">
						<Tooltip message={props.data_asset.allow_accept ? "Accept" : "Action not permitted for user"}>
							<Button text="Accept file" theme="default" onClick={onAccept} disabled={uploading || !props.data_asset.allow_accept} />
						</Tooltip>
						<Tooltip message={props.data_asset.allow_discard ? "Discard" : "Action not permitted for user"}>
							<Button text="Discard file" theme="white" onClick={() => setDiscardOpen(true)} disabled={!props.data_asset.allow_discard} />
						</Tooltip>
					</div>}
					{discardOpen && <>
						<div className="steps-item-section">
							<div>
								<div className="steps-item-filename">{uploadedFile ? uploadedFile.name : "Select a file to upload"}</div>
								{uploadedFile && <div>{uploadedFile.size}KB</div>}
							</div>
							<div className="action-container">
								<div onClick={() => document.getElementById("upload-input")?.click()}>
									<Icon icon="upload-outline" />
									<input id="upload-input" type="file" hidden onChangeCapture={onFileChangeCapture} accept={props.acceptFiles.concat(props.acceptMimeTypes).join(',')} />
								</div>
								<IconButton icon="delete-outline" onClick={() => setUploadedFile(undefined)} />
							</div>
						</div>
						<textarea className="blurb-input" placeholder="Leave your comments" autoFocus
							value={comment} onChange={(e) => { setComment(e.target.value) }}
							cols={50} rows={(comment?.length ?? 150) / 40}
						/>
						<div className="action-buttons">
							<Button text="Confirm" theme="default" onClick={onDiscard} disabled={uploading} />
							<Button text="Cancel" theme="white" onClick={() => setDiscardOpen(false)} />
						</div>
					</>
					}
					{error && <div className="error">{error}</div>}
				</div>
			</div>
		</>
	)
}

// When a file was accepted in the history
const FinalLogItem = (props: StepItemProps) => {
	const dateString = formatDateTimeLocal(props.date);

	return (
		<div className="steps-item">
			<div className="steps-item-tail"></div>
			<div className="steps-item-icon">
				<Icon icon="circle-checkmark-outline" />
			</div>
			<div className="steps-item-content">
				<div className="steps-item-byline">
					<div className="byline-title">
						<div>File accepted by</div>
						<div className="accent">{props.user}</div>
					</div>
					<div className="byline-date">{dateString}</div>
				</div>
				<div className="steps-item-byline">
					<div className="byline-title">Process complete</div>
				</div>
			</div>
		</div>
	)
}

// When the last thing in the log was Final
const FinalStepItem = (props: StepItemUploadProps) => {
	const [revokeOpen, setRevokeOpen] = useState(false);
	const [uploadedFile, setUploadedFile] = useState<File>();
	const [comment, setComment] = useState<string>();
	const [uploading, setUploading] = useState<boolean>();
	const [error, setError] = useState<string>('');

	const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
		setError('');
		const files = e.target.files ?? []
		const selectedFile = files[0]
		setUploadedFile(selectedFile)
	};

	const onRevoke = async () => {
		setError('');
		if (uploadedFile && !validateFile(uploadedFile, props.fileSizeLimit, props.acceptFiles))
			return;


		setUploading(true);
		const formData = new FormData();
		//const formData = new Array<SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues>();
		if (props.activityContext.appId
			&& props.activityContext.projectId
			&& props.activityContext.entityId
			&& props.activityContext.dataAssetId
		) {
			formData.append("application_id", props.activityContext.appId?.toString());
			formData.append("project_id", props.activityContext.projectId.toString());
			formData.append("entity_id", props.activityContext.entityId.toString());
			formData.append("data_asset_id", props.activityContext.dataAssetId.toString());
			// formData.push({key: "application_id", value: [props.activityContext.appId?.toString()]});
			// formData.push({key: "project_id", value: [props.activityContext.projectId.toString()]});
			// formData.push({key: "entity_id", value: [props.activityContext.entityId.toString()]});
			// formData.push({key: "template_id", value: [props.activityContext.templateId.toString()]});
		}
		formData.append("transition", "Discard");
		//formData.push({key: "transition", value: ["Return"]});
		if (uploadedFile) {
			formData.append('file', uploadedFile, uploadedFile?.name);
			//formData.push({key: 'file', value: [uploadedFile, uploadedFile?.name]});
		}
		if (comment)
			formData.append('notes', comment);
		//formData.push({key: 'notes', value: [comment]});

		// dmpClient?.templatesApi.postTemplatesApiPostTransitionUpdate({formData: formData})
		// .then(res => props.updateActivityLog(res))
		// .catch(err => {
		// 	setError(err.message || err);
		// 	console.error(err);
		// })
		// .finally(() => setUploading(false));

		fetch(`${ENDPOINTS.LANDING}/data_asset/transition`, {
			method: 'POST',
			headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
			body: formData,
		})
			.then(async resp => {
				if (resp.ok)
					return resp.json();

					console.log(resp);
				const errorMessage = await resp.json().then(x => x.Message).catch(() => `${resp.status} ${resp.statusText}`);
				throw new Error(errorMessage);
			})
			.then(result => props.updateActivityLog(result))
			.catch(error => {
				setError(error.message || error);
				toast.error(`Revoke failed : ${error}`);

				console.error(error);
			})
			.finally(() => setUploading(false));
	}

	return (
		<div className="steps-item">
			<div className="steps-item-tail"></div>
			<div className="steps-item-icon">
				<Icon icon="end-process-outline" />
			</div>
			{!revokeOpen && <div className="steps-item-content">
				<div className="action-buttons">
					<Tooltip message={props.data_asset.allow_discard ? "Discard" : "Action not permitted for user"}>
						<Button text="Discard file" theme="default" onClick={() => setRevokeOpen(true)} disabled={!props.data_asset.allow_discard} />
					</Tooltip>
				</div>
			</div>}
			{revokeOpen && <div className="steps-item-content">
				<div className="steps-item-byline">
					<div className="byline-title">
						<div>Discard file</div>
					</div>
				</div>
				<div className="steps-item-byline">
					<div>You are about to discard a file that has completed the acceptance process. Please provide clear reasons for your decision.</div>
				</div>
				<div className="steps-item-section">
					<div>
						<div className="steps-item-filename">{uploadedFile ? uploadedFile.name : "Select a file to upload"}</div>
						{uploadedFile && <div>{uploadedFile.size}KB</div>}
					</div>
					<div className="action-container">
						<div onClick={() => document.getElementById("upload-input")?.click()}>
							<Icon icon="upload-outline" />
							<input id="upload-input" type="file" hidden onChangeCapture={onFileChangeCapture} accept={props.acceptFiles.concat(props.acceptMimeTypes).join(',')} />
						</div>
						<IconButton icon="delete-outline" onClick={() => setUploadedFile(undefined)} />
					</div>
				</div>
				<textarea className="blurb-input" placeholder="Leave your comments" autoFocus
					value={comment} onChange={(e) => { setComment(e.target.value) }}
					cols={50} rows={(comment?.length ?? 150) / 40}
				/>
				<div className="action-buttons">
					<Button text="Confirm" theme="default" onClick={onRevoke} disabled={uploading || !comment || comment?.length < 3} />
					<Button text="Cancel" theme="white" onClick={() => setRevokeOpen(false)} />
				</div>
				{error && <div className="error">{error}</div>}
			</div>}
		</div>
	)
}

// When a file was discarded in the log
const DiscardedLogItem = (props: StepItemProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const dateString = formatDateTimeLocal(props.date);

	const onDownloadClick = async () => {
		try {
			setLoading(true)
			const response = await fetch(`${ENDPOINTS.LANDING}/storage/download_file/${props.url},${props.activityContext.appId},${props.activityContext.projectId},4`, {
				headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") }
			})
			if (response.ok) {
				const blob = await response.blob();
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = props.file_name ?? "filename.txt";
				document.body.appendChild(a);
				a.click();
				a.remove();
				}
			else {
				let errMessage = 'Unknown error';
				if (response.statusText)
					errMessage = response.statusText;
				try {
					const tryJson = await response.json();
					if (tryJson.Message)
						errMessage = tryJson.Message;
				} catch (error) {
					// ignore
				}
				toast.error('Error downloading file: ' + errMessage);
			}
		} catch (error) {
			toast.error('Error downloading file: ' + error);
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="steps-item">
			<div className="steps-item-tail"></div>
			<div className="steps-item-icon">
				<Icon icon="arrow-left-outline" />
			</div>
			<div className="steps-item-content">
				<div className="steps-item-byline">
					<div className="byline-title">
						<div>File discarded by</div>
						<div className="accent">{props.user}</div>
					</div>
					<div className="byline-date">{dateString}</div>
				</div>
				{!!props.url &&
					<div className="steps-item-section">
						<div>
							<div className="steps-item-filename">{props.file_name}</div>
						</div>
						<div className="action-container">
							<IconButton icon="download-outline" onClick={onDownloadClick} />
						</div>
					</div>
				}
				{props.blurb && <div className="steps-item-section">
					{props.blurb}
				</div>}
			</div>
		</div>
	)
}

// Currently a default for the switch in case there's some other status??
const StepItem = (props: MessagesDataAssetsTDataAssetActivity) => {
	const dateString = formatDateTimeLocal(props.date);

	return (
		<div className="steps-item">
			<div className="steps-item-tail"></div>
			<div className="steps-item-icon">
				<Icon icon="folder-opened-outline" />
			</div>
			<div className="steps-item-content">
				<div className="steps-item-byline">
					<div className="byline-title">
						<div>File {props.state.toLowerCase()} by</div>
						<div className="accent">{props.user}</div>
					</div>
					<div className="byline-date">{dateString}</div>
				</div>
				<div className="steps-item-section">
					<div>
						<div className="steps-item-filename">{props.file_name}</div>
					</div>
				</div>
				<div className="steps-item-section">
					{props.blurb}
				</div>
			</div>
		</div>
	)
}

interface ActivityLogProps {
	entityId: number | undefined,
	dataAssetId: number | undefined,
	appId: number | null,
	newLastActivity: (activity: MessagesDataAssetsTDataAssetActivity) => void,
}

export const DataAssetLog = ({ appId, entityId, dataAssetId, newLastActivity }: ActivityLogProps) => {
	const { projectId } = useContext();
	const activityLogContext = {
		appId,
		projectId,
		entityId,
		dataAssetId
	};
	const [loading, setLoading] = useState(false);
	const [activityLog, setActivityLog] = useState<MessagesDataAssetsTDataAssetActivity[]>();
	const { dmpClient } = useDotnetClient();
	const [fileSizeLimit, setFileSizeLimit] = useState<number>(20 * 1024 * 1024);
	const [acceptFiles, setAcceptFiles] = useState<string[]>(['.csv', '.xlsx']);
	const [acceptMimeTypes, setAcceptMimeTypes] = useState<string[]>(['text/csv', '.xlsx']);


	let fetchingFileReqs: CancelablePromise<MessagesStorageFileRequirements> | undefined;

	const loadFileRequirements = async () => {
		if (!dmpClient)
			return;

		fetchingFileReqs = dmpClient.storageApi.postStorageApiStorageFileRequirements(appId!, dataAssetId!);
		fetchingFileReqs.then(res => {
			setFileSizeLimit(res.fileSizeLimit);
			setAcceptFiles(res.extensionsAllowed);
			setAcceptMimeTypes(res.mimeTypesAllowed);
		})
			.catch(err => console.error(err));

	};

	useEffect(() => {
		loadFileRequirements();
		return () => { if (fetchingFileReqs) fetchingFileReqs.cancel(); }
	}, [appId, dataAssetId]);


	let fetchingActivity: CancelablePromise<MessagesDataAssetsTDataAssetActivity[]> | undefined;

	const fetchActivityLog = async () => {
		try {
			setLoading(true);
			setActivityLog([]);

			if (!dmpClient)
				return;

			fetchingActivity = dmpClient.dataAssetApi.getDataAssetApiGetSpecificDataAssetSummary(appId!, projectId!, entityId!, dataAssetId!);
			fetchingActivity.then(
				res => setActivityLog(res)
			).catch(err => {
				console.log(err);
			});

		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!appId || !entityId || !dataAssetId) {
			return;
		}
		fetchActivityLog();
		return () => { if (fetchingActivity) fetchingActivity.cancel(); }
	}, [appId, entityId, dataAssetId]);

	const updateActivityLog = (newlog: MessagesDataAssetsTDataAssetActivity[]) => {
		const lastActivity = newlog[newlog.length - 1];
		newLastActivity(lastActivity);

		setActivityLog(newlog);
	}

	const renderNextStep = () => {
		const lastActivity = activityLog && activityLog[activityLog.length - 1];

		if (!lastActivity)
			return <div>Select a template</div>
		else if (lastActivity.state === "None")
			return <ErrorBoundary><UploadStepItem {...lastActivity} activityContext={activityLogContext} updateActivityLog={updateActivityLog} fileSizeLimit={fileSizeLimit} acceptFiles={acceptFiles} acceptMimeTypes={acceptMimeTypes} /></ErrorBoundary>
		else if (lastActivity.state === "Uploaded")
			return <ErrorBoundary><ReviewStepItem {...lastActivity} activityContext={activityLogContext} updateActivityLog={updateActivityLog} fileSizeLimit={fileSizeLimit} acceptFiles={acceptFiles} acceptMimeTypes={acceptMimeTypes} /></ErrorBoundary>
		else if (lastActivity.state === "Final")
			return <ErrorBoundary><FinalStepItem {...lastActivity} activityContext={activityLogContext} updateActivityLog={updateActivityLog} fileSizeLimit={fileSizeLimit} acceptFiles={acceptFiles} acceptMimeTypes={acceptMimeTypes} /></ErrorBoundary>
	}

	return (
		<div className='ux-activity-log'>
			<ErrorBoundary>
				{loading ? <div>Loading...</div>
					:
					<>
						<div>Activity log</div>
						<div className="steps-item-map">
							{activityLog?.map((logItem) => {
								switch (logItem.state) {
									case "Uploaded":
										return <UploadedLogItem {...logItem} activityContext={activityLogContext} updateActivityLog={updateActivityLog} key={`${logItem.activity}${logItem.state}${logItem.date}${logItem.url}${logItem.file_name}`} />
									case "Final":
										return <FinalLogItem {...logItem} activityContext={activityLogContext} updateActivityLog={updateActivityLog} key={`${logItem.activity}${logItem.state}${logItem.date}${logItem.url}${logItem.file_name}`} />
									case "None":
										return <DiscardedLogItem {...logItem} activityContext={activityLogContext} updateActivityLog={updateActivityLog} key={`${logItem.activity}${logItem.state}${logItem.date}${logItem.url}${logItem.file_name}`} />
									default:
										return <StepItem {...logItem} />
								}
							})}
							<ErrorBoundary>
								{renderNextStep()}
							</ErrorBoundary>
						</div>
					</>
				}
			</ErrorBoundary>
		</div>
	)
}

export default DataAssetLog;