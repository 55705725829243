import "./SyncImport.scss";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { useCallback, useState } from "react";
import { MessagesAppSettingsAppSettingsAction, MessagesAppSettingsAppSettingsResultItem, MessagesAppSettingsAppSettingsType } from "../../dotnet-client";
import { Button } from "@arq-apps/ui";

const SyncTypeBlock = (title: string, action: number, setAction: (action: number) => void, items: MessagesAppSettingsAppSettingsResultItem[]) => {
  return (
    <div className="sync-block">
      <div className="sync-title-control">
        <div className="sync-title">{title}</div>
        {items.length > 0 && <>
          <div className="control">
            <label className="radio">
              <input type="radio" name={title} checked={action === 0} onChange={() => setAction(0)} />
              <span>Skip</span>
            </label>
            <label className="radio">
              <input type="radio" name={title} checked={action === 2} onChange={() => setAction(2)} />
              Merge
            </label>
            <label className="radio">
              <input type="radio" name={title} checked={action === 3} onChange={() => setAction(3)} />
              Replace
            </label>
          </div>
        </>}
      </div>
      {items.length > 0 ? <table className="sync-table">
        <thead className="sync-table-head">
          <tr>
            <th className="cell">Name</th>
            <th className="cell">Status</th>
            <th className="cell">Action</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            const itemResolution = () => {
              const status = item.status as unknown
              if (action === MessagesAppSettingsAppSettingsAction.None && status === "extra") return "Remain"
              if (action === MessagesAppSettingsAppSettingsAction.None && status === "exists") return "Remain"
              if (action === MessagesAppSettingsAppSettingsAction.None && status === "new") return "Skip"
              if (action === MessagesAppSettingsAppSettingsAction.Merge && status === "extra") return "Remain"
              if (action === MessagesAppSettingsAppSettingsAction.Merge && status === "exists") return "Update"
              if (action === MessagesAppSettingsAppSettingsAction.Merge && status === "new") return "Add"
              if (action === MessagesAppSettingsAppSettingsAction.Replace && status === "extra") return "Delete"
              if (action === MessagesAppSettingsAppSettingsAction.Replace && status === "exists") return "Update"
              if (action === MessagesAppSettingsAppSettingsAction.Replace && status === "new") return "Add"
              return "No Action"
            }

            return (
              <tr key={`${item.type}-${item.elementName}`}>
                <td className="cell">{item.elementName}</td>
                <td className="cell" width="10%">{item.status}</td>
                <td className="cell" width="10%">{itemResolution()}</td>
              </tr>
            )
          })}
        </tbody>
      </table> : <div>
        No items in current configuration or uploaded file
      </div>}
    </div>
  )
}

export function SyncImport() {
  const [uploadedFile, setUploadedFile] = useState<File>();
  const { dmpClient } = useDotnetClient();
  const [uploadId, setUploadId] = useState<number>();

  const [app, setApp] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  // const [applicationAttributes, setApplicationAttributes] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);

  const [roles, setRoles] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  const [teams, setTeams] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  const [organisation, setOrganisation] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  const [teamRoles, setTeamRoles] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  const [entityTypes, setEntityTypes] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  const [fileTemplates, setFileTemplates] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  const [fileTemplateRoles, setFileTemplateRoles] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  const [glossary, setGlossary] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  const [faq, setFaq] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  const [configSettings, setConfigSettings] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  const [dataAssets, setDataAssets] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);
  const [dataAssetPermissions, setDataAssetPermissions] = useState<MessagesAppSettingsAppSettingsResultItem[]>([]);

  // const [appAttrAction, setAppAttrAction] = useState(0);
  const [rolesAction, setRolesAction] = useState(0);
  const [teamsAction, setTeamsAction] = useState(0);
  const [orgAction, setOrgAction] = useState(0);
  const [teamRolesAction, setTeamRolesAction] = useState(0);
  const [entityTypesAction, setEntityTypesAction] = useState(0);
  const [fileTemplatesAction, setFileTemplatesAction] = useState(0);
  const [fileTemplateRolesAction, setFileTemplateRolesAction] = useState(0);
  const [glossaryAction, setGlossaryAction] = useState(0);
  const [faqAction, setFaqAction] = useState(0);
  const [configSettingsAction, setConfigSettingsAction] = useState(0);
  const [dataAssetsAction, setDataAssetsAction] = useState(0);
  const [dataAssetPermissionsAction, setDataAssetPermissionsAction] = useState(0);

  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<string | null>(null);

  const onFileChangeCapture = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ?? []
    if (files.length === 0)
      return;
      
    const selectedFile = files[0]
    setUploadedFile(selectedFile)
    setError(null)
    setResults(null)
    const _uploadId = await dmpClient?.appSettingsApi.postAppSettingsApiAppSettingsUpload({
      file: selectedFile
    }) as unknown
    setUploadId(_uploadId as number)
    dmpClient?.appSettingsApi.getAppSettingsApiGetAppSettingsUpload(_uploadId as number)
      .then(upload => {
        setApp([]);
        setRoles([]);
        setTeams([]);
        setOrganisation([]);
        setTeamRoles([]);
        setEntityTypes([]);
        setFileTemplates([]);
        setFileTemplateRoles([]);
        setGlossary([]);
        setFaq([]);
        setConfigSettings([]);
        setDataAssets([]);
        setDataAssetPermissions([]);

        upload.checkResults?.forEach(item => {
          switch (item.type as unknown) {
            case "app": return setApp(prev => [...prev, item])
            case "roles": return setRoles(prev => [...prev, item])
            case "teams": return setTeams(prev => [...prev, item])
            case "organisation": return setOrganisation(prev => [...prev, item])
            case "teamRoles": return setTeamRoles(prev => [...prev, item])
            case "entityTypes": return setEntityTypes(prev => [...prev, item])
            case "fileTemplates": return setFileTemplates(prev => [...prev, item])
            case "fileTemplateRoles": return setFileTemplateRoles(prev => [...prev, item])
            // case "applicationAttributes": return setApplicationAttributes(prev => [...prev, item])
            case "glossary": return setGlossary(prev => [...prev, item])
            case "faq": return setFaq(prev => [...prev, item])
            case "configSettings": return setConfigSettings(prev => [...prev, item])
            case "dataAssets": return setDataAssets(prev => [...prev, item])
            case "dataAssetPermissions": return setDataAssetPermissions(prev => [...prev, item])
            default: return
          }
        })
      })
  };

  const setAllActions = (action: number) => {
    setRolesAction(action)
    setTeamsAction(action)
    setOrgAction(action)
    setTeamRolesAction(action)
    setEntityTypesAction(action)
    setFileTemplatesAction(action)
    setFileTemplateRolesAction(action)
    setGlossaryAction(action)
    setFaqAction(action)
    setConfigSettingsAction(action)
    setDataAssetsAction(action)
    setDataAssetPermissionsAction(action)
  }

  const onApply = useCallback(() => {
    setError(null)
    setResults(null)

    if (!uploadId) return

    dmpClient?.appSettingsApi.patchAppSettingsApiGetAppSettingsUpload(uploadId, [
      { "operationType": 2, "path": "/status", "op": "replace", "from": null, "value": 2 },
      {
        "operationType": 2,
        "path": "/applyRequest",
        "op": "replace",
        "from": null,
        "value": {
          items: [
            { type: MessagesAppSettingsAppSettingsType.App, action: 2 },
            { type: MessagesAppSettingsAppSettingsType.Roles, action: rolesAction },
            { type: MessagesAppSettingsAppSettingsType.Teams, action: teamsAction },
            { type: MessagesAppSettingsAppSettingsType.Organisation, action: orgAction },
            { type: MessagesAppSettingsAppSettingsType.TeamRoles, action: teamRolesAction },
            { type: MessagesAppSettingsAppSettingsType.EntityTypes, action: entityTypesAction },
            { type: MessagesAppSettingsAppSettingsType.FileTemplates, action: fileTemplatesAction },
            { type: MessagesAppSettingsAppSettingsType.FileTemplateRoles, action: fileTemplateRolesAction },
            // {type: MessagesAppSettingsAppSettingsType.ApplicationAttributes, action: appAttrAction},
            { type: MessagesAppSettingsAppSettingsType.Glossary, action: glossaryAction },
            { type: MessagesAppSettingsAppSettingsType.Faq, action: faqAction },
            { type: MessagesAppSettingsAppSettingsType.ConfigSettings, action: configSettingsAction },
            { type: MessagesAppSettingsAppSettingsType.DataAssets, action: dataAssetsAction },
            { type: MessagesAppSettingsAppSettingsType.DataAssetPermissions, action: dataAssetPermissionsAction },
          ]
        }
      }
    ]).then(result => {
      console.log(result);
      setResults("Successfully applied file");
    }).catch((err) => {
      setError(err.message || err);
    })
  }, [uploadId, rolesAction, teamsAction, orgAction, teamRolesAction, entityTypesAction, fileTemplatesAction, fileTemplateRolesAction, glossaryAction, faqAction, configSettingsAction, dataAssetsAction, dataAssetPermissionsAction ])

  return (
    <div className="ux-page manage">
      <div className="panels">
        <div className="ux-panel">
          <div className="tiles">
            <div className="ux-tile">
              <header>
                <span className="title">Import configuration</span>
              </header>
              <div className="ux-form">
                <div style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
                  <div>
                    <div className="steps-item-filename">{uploadedFile ? uploadedFile.name : "Select a file to upload"}</div>
                    {uploadedFile && <div>{uploadedFile.size}KB</div>}
                  </div>
                  <div onClick={() => document.getElementById("upload-input")?.click()}>
                    <Button text="Import" />
                    <input id="upload-input" type="file" hidden onChangeCapture={onFileChangeCapture} />
                  </div>
                  <div className="control">
                    <label className="radio">
                      <input type="radio" name="all" onChange={() => setAllActions(0)} />
                      Skip all
                    </label>
                    <label className="radio">
                      <input type="radio" name="all" onChange={() => setAllActions(2)} />
                      Merge all
                    </label>
                    <label className="radio">
                      <input type="radio" name="all" onChange={() => setAllActions(3)} />
                      Replace all
                    </label>
                  </div>
                </div>
                {uploadedFile && <div style={{ display: "grid", gap: "8px", justifyContent: "start" }}>
                  {app[0] && <div className="title">Application: {app[0].elementName}</div>}

                  {/* {<div>
                    <div style={{display: "flex", flexDirection: "row"}}>
                      <div>Application attributes</div>
                      <div className="control">
                        <label className="radio">
                          <input type="radio" name="group" checked={appAttrAction === 0} onChange={() => setAppAttrAction(0)} />
                          Skip
                        </label>
                        <label className="radio">
                          <input type="radio" name="group" checked={appAttrAction === 2} onChange={() => setAppAttrAction(2)}/>
                          Merge
                        </label>
                        <label className="radio">
                          <input type="radio" name="group" checked={appAttrAction === 3} onChange={() => setAppAttrAction(3)}/>
                          Replace
                        </label>
                      </div>
                    </div>
                  </div>} */}

                  {SyncTypeBlock("Roles", rolesAction, setRolesAction, roles)}
                  {SyncTypeBlock("Teams", teamsAction, setTeamsAction, teams)}
                  {SyncTypeBlock("Organisations", orgAction, setOrgAction, organisation)}
                  {SyncTypeBlock("Team roles", teamRolesAction, setTeamRolesAction, teamRoles)}
                  {SyncTypeBlock("Entity types", entityTypesAction, setEntityTypesAction, entityTypes)}
                  {SyncTypeBlock("File templates", fileTemplatesAction, setFileTemplatesAction, fileTemplates)}
                  {SyncTypeBlock("File template roles", fileTemplateRolesAction, setFileTemplateRolesAction, fileTemplateRoles)}
                  {SyncTypeBlock("Glossary", glossaryAction, setGlossaryAction, glossary)}
                  {SyncTypeBlock("FAQ", faqAction, setFaqAction, faq)}
                  {SyncTypeBlock("Config Settings", configSettingsAction, setConfigSettingsAction, configSettings)}
                  {SyncTypeBlock("Data Assets", dataAssetsAction, setDataAssetsAction, dataAssets)}
                  {SyncTypeBlock("Data Asset Permissions", dataAssetPermissionsAction, setDataAssetPermissionsAction, dataAssetPermissions)}

                  <div>
                    <Button text="Apply" onClick={onApply} />
                    {error !== null && <div className="error">{error}</div>}
                    {results !== null && <div className="">{results}</div>}
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}