import { TabMenuItem } from "src/ux/TabMenu/TabMenu"

export const LOGGED_OUT_MENU: TabMenuItem[] = [
  {
      key: "application_external",
      title: "Application"
  }, 
  {
    key: "platform_external",
    title: "Platform"
  }
]
  
export const LOGGED_IN_MENU: TabMenuItem[] = [
  {
    key: "application_projects",
    title: "Launch"
  }, 
  {
    key: "projects",
    title: "Projects"
  },
  {
    key: "applications",
    title: "Applications"
  },
  {
    key: "platform_operations",
    title: "Manage"
  }
]
  
export const SEARCH_STRING_MENU: TabMenuItem[] = [
  {
    key: "all",
    title: "All"
  }, 
  {
    key: "live",
    title: "Live"
  },
  {
    key: "pending",
    title: "Pending"
  },
  {
    key: "developing",
    title: "Developing"
  }
]

export const PLATFORM_EXTERNAL_MENU: TabMenuItem[] = [
  {
    key: "benefits",
    title: "Benefits"
  },
  {
    key: "our_edge",
    title: "Our edge"
  },
  {
    key: "get_started",
    title: "Get started"
  },
  {
    key: "terminology",
    title: "Terminology"
  },
  {
    key: "get_in_touch",
    title: "Get in touch"
  },
]

export const SEARCH_DAYS_MENU: TabMenuItem[] = [
  {
    key: "all",
    title: "All"
  },
  {
    key: "30",
    title: "Last 30 days"
  },
  {
    key: "60",
    title: "Last 60 days"
  },
  {
    key: "90",
    title: "Last 90 days"
  },
  {
    key: "archived",
    title: "Archived"
  },
]