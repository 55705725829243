import './Glossary.scss';
import { SupportPaneSize } from '../App'
import { useEffect, useState } from 'react'
import { SearchBox } from '../SearchBox'
import { MessagesGlossaryGlossary } from '../../dotnet-client'
import { useDotnetClient } from 'src/contexts/DotnetClientContext'
import { Loading } from '@arq-apps/ui'

interface GlossaryItemProps {
  item: MessagesGlossaryGlossary,
  onClick: () => void,
  selected: boolean
}

const GlossaryItem = (props: GlossaryItemProps) => {
  const classes = [
		"glossary-item",
		props.selected && "selected"
	].filter(Boolean).join(" ");

  return (
    <div className={classes} onClick={props.onClick} >
      <div className='glossary-title'>{props.item.term}</div>
      <div className='glossary-description'>{props.item.short ?? props.item.long}</div>
    </div>
  )
}

interface GlossaryProps {
	supportPaneSize: SupportPaneSize | undefined
	setSupportPaneSize: (size: SupportPaneSize | undefined) => void
	appId: number | null
}

const Glossary = ({ supportPaneSize, setSupportPaneSize, appId }: GlossaryProps) => {
  const [glossary, setGlossary] = useState<MessagesGlossaryGlossary[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(0);
  const [searchString, setSearchString] = useState("");
  const { dmpClient } = useDotnetClient();

  useEffect(()=>{
    if (!appId) return
    if (!dmpClient) return

    setLoading(true)
    dmpClient.glossaryApi.getGlossaryApiGetGlossary(appId)
      .then(_glossary => {
        setGlossary(_glossary)
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }, []);

  const filterOnSearchTerm = (term: MessagesGlossaryGlossary) =>
		!searchString
		|| searchString === ""
		|| JSON.stringify(term).toLowerCase().includes(searchString.toLowerCase());

  const numberOfFiles = () => {
		if (!glossary)
			return '';
		const total = glossary.filter(filterOnSearchTerm).length ?? 0
		const string = total === 1 ? "term" : "terms"
    return `${total} ${string}`
	}

  const onSelect = (index: number) => {
    setSupportPaneSize(SupportPaneSize.Max)
    setSelected(index)
  }

  const classes = [
		"ux-glossary-layout",
		supportPaneSize
	].filter(Boolean).join(" ");

  return (
    <div className={classes}>
			<div className="glossary">
				<SearchBox searchString={searchString} setSearchString={setSearchString}/>
				<div className="file-count" key="file_count">{numberOfFiles()}</div>
        {loading && <Loading />}
        {!loading && glossary.filter(filterOnSearchTerm).length === 0 && <Loading message='No glossary items'/>}
				{!loading && glossary.filter(filterOnSearchTerm).length > 0 && glossary.filter(filterOnSearchTerm).map((term, index) => 
          <GlossaryItem key={term.id} item={term} onClick={() => onSelect(index)} selected={index === selected} />
        )}
			</div>
      {supportPaneSize === SupportPaneSize.Max && !loading && glossary.length > 0 &&
				<div className='glossary-long'>
          <div className='glossary-title'>{glossary[selected].term}</div>
          <div className='glossary-long-description'>{glossary[selected].long ?? glossary[selected].short}</div>
        </div>
			}
		</div>
  )
}

export default Glossary;