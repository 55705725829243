import './Tray.scss';
import { AppLink } from "../Link";
import { Icon } from "@arq-apps/ui";

export function Tray() {
  const classes = [
    "ux-tray-item",
    "ux-tray-icon-button",
    "theme-grey_lighter",
    "shape-circle",
  ].filter(Boolean).join(" ");

  return (
    <div className="ux-tray">
      <div className="ux-tray-section">
        <AppLink
          className={ classes }
          toggleParams={[
            {
              "key": "interact",
              "value": "filters",
              "__typename": "Param"
            }
          ]}
          __typename="NavLink"
        >
          <Icon
            scale={ 1.4 }
            icon={ "filter-outline" }
            hoverIcon={ "filter-fill" }
          />
        </AppLink>
      </div>
    </div>
  );
}
