import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { filterNotNull } from "@arq-apps/util";
import * as useAppContext from "../useContext";
import * as types from "@arq-apps/generated";
import { useFiltersByIdsLazyQuery, usePageFilterIdsLazyQuery } from "./useFilters.generated";
import { FilterFragment } from "../../ux/Filter/Filter.generated";
import { FieldInput, QueryInput } from "@arq-apps/generated";
import { useLandingContext } from "src/contexts/LandingContext"

function _optionToInput(
  option: types.SelectOption | null | undefined
): types.SelectOptionInput | undefined {
  return option ? {
    value: option.value,
    label: option.label,
  } : undefined;
}

type PageFilterContextType =  {
  pageFilters: FilterFragment[] | null | undefined,
  primaryPageFilters: FilterFragment[] | null | undefined,
  pageFilterInputs: FieldInput[] | null | undefined,
  pageFiltersLoaded: boolean,
  updatePageFilters: (id: string, option: types.SelectOption | null | undefined) => void,
  updatePrimaryPageFilters: (id: string, option: types.SelectOption | null | undefined) => void,
  resetPageFilters: () => void,
  selectClear: (id: string) => void,
  multiSelect: (id: string, options: types.SelectOption[] | null | undefined) => void,
  multiSelectAll: (id: string) => void,
  multiClearAll: (id: string) => void
}
const PageFilterContext = createContext<PageFilterContextType>({
  pageFilters: undefined,
  primaryPageFilters: undefined,
  pageFilterInputs: undefined,
  pageFiltersLoaded: false,
  updatePageFilters: () => {},
  updatePrimaryPageFilters: () => {},
  resetPageFilters: () => {},
  selectClear: () => {},
  multiSelect: () => {},
  multiSelectAll: () => {},
  multiClearAll: () => {}
})
export const PageFilterContextProvider = (props: { children: React.ReactNode }) => {
  const {appId, pageId, projectId} = useAppContext.useContext();
  const LandingContext = useLandingContext();
  const [pageFilters, setPageFilters] = useState<FilterFragment[]>();
  const [primaryPageFilters, setPrimaryPageFilters] = useState<FilterFragment[]>();
  const [pageFilterInputs, setPageFilterInputs] = useState<FieldInput[]>();
  const [pageFiltersLoaded, setPageFiltersLoaded] = useState<boolean>(false);

  useEffect(() => {
    setPageFiltersLoaded(false);
  }, [pageId]);

  const getPageFilterIds = usePageFilterIdsLazyQuery()[0];
  const getPageFilters = useFiltersByIdsLazyQuery()[0];

  let extraInputs: QueryInput[] = [];
  if (LandingContext.appId) {extraInputs.push({"name": "appId", "value": `${LandingContext.appId}`})}
  if (LandingContext.projectId) {extraInputs.push({"name": "projectId", "value": `${LandingContext.projectId}`})}

  const fetchPageFilters = async() => {
    setPageFiltersLoaded(false);
    const getPageFilterIdsData = await getPageFilterIds({
      fetchPolicy: "no-cache",
      variables: {
        appId,
        pageId,
        projectId,
      }
    });
    const _pageFilterIds = getPageFilterIdsData.data?.app?.pageById?.filterIds
    const _primaryPageFilterIds = getPageFilterIdsData.data?.app?.pageById?.primaryFilterIds

    const getPageFiltersData = await getPageFilters({
      fetchPolicy: "no-cache",
      variables: {
        appId,
        projectId,
        filterIds: _pageFilterIds ?? [],
        extraInputs
      },
    });
    const _pageFilters = getPageFiltersData.data?.app?.filtersByIds;
    // setPageFilters(_pageFilters as FilterFragment[]);

    const getPrimaryPageFiltersData = await getPageFilters({
      fetchPolicy: "no-cache",
      variables: {
        appId,
        projectId,
        filterIds: _primaryPageFilterIds ?? [],
        extraInputs
      },
    });
    const _primaryPageFilters = getPrimaryPageFiltersData.data?.app?.filtersByIds as (FilterFragment[] | undefined)
    
    const reUsed = primaryPageFilters?.filter(filter => {
      if (filter) {
        // console.log(_primaryPageFilterIds)
        // console.log(filter?.id.replace("/" + appId, ""))
        if (_primaryPageFilterIds?.includes(filter?.id.replace("/" + appId, ""))) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }) ?? []

    const newFilters = _primaryPageFilters?.filter(filter => {
      if (filter) {
        if (reUsed?.map(filter => filter.id).includes(filter.id)) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }) ?? []

    // console.log("reused", reUsed)
    // console.log("new", newFilters)

    const normalisedPrimaryFilters = reUsed.concat(newFilters)
    // [...reUsed, ...newFilters]

    const nonNullPageFilters = _pageFilters?.filter((x): x is FilterFragment => x !== null) ?? []
    const nonNullPrimaryPageFilters = normalisedPrimaryFilters?.filter((x): x is FilterFragment => x !== null)

    setPageFilters(nonNullPageFilters)
    setPrimaryPageFilters(nonNullPrimaryPageFilters)
    setPageFilterInputs(buildFilterInputs((nonNullPageFilters.concat(nonNullPrimaryPageFilters)) as FilterFragment[]));
    // [...nonNullPageFilters, ...nonNullPrimaryPageFilters]
    setPageFiltersLoaded(true);
  }

  useEffect(() => {
    // TODO @RM raise adding param to menu item for non-graphql pages
    const dotnetPageIds = ["/main/subscription", "/main/application/sync/import", "/main/application/sync/export", "/main/project/resources", "/main/application/resources"]
    if (!dotnetPageIds.includes(pageId)) {
      fetchPageFilters();
    }
  }, [pageId]);

  useEffect(() => {
    const nonNullPageFilters = pageFilters?.filter((x): x is FilterFragment => x !== null) ?? []
    const nonNullPrimaryPageFilters = primaryPageFilters?.filter((x): x is FilterFragment => x !== null) ?? []
    setPageFilterInputs(buildFilterInputs([...nonNullPageFilters, ...nonNullPrimaryPageFilters]));
  }, [pageFilters, primaryPageFilters]);

  const updatePageFilters = useCallback((id: string, option: types.SelectOption | null | undefined, extra?: any) => {
    console.debug(`updatePageFilters ${id} ${JSON.stringify(option)} ${JSON.stringify(extra)}`)
    if (!option) {
      return
    }
    setPageFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id !== id) {
          return field
        }
        if (field.__typename === "SelectField") {
          return {
            ...field,
            localSelection: option,
          }
        }
        if (field.__typename === "MultiSelectField") {
          const currentSelections = field.localSelections ?? field.selections ?? [];
          const filtered = currentSelections.filter(
            (it) => it.value !== option?.value
          );
          const object = {
            ...field,
            localSelections:
              filtered.length < currentSelections.length
                ? // option was filtered out
                  filtered
                : // option must be added
                  [...filtered, option],
          }
          return object;
        }
        // Extend othe filter types here
        return field
      })

      return updated
    })
  }, [pageFilters]);

  const updatePrimaryPageFilters = useCallback((id: string, option: types.SelectOption | null | undefined, extra?: any) => {
    console.debug(`updatePrimaryPageFilters ${id} ${JSON.stringify(option)} ${JSON.stringify(extra)}`)
    if (!option) {
      return
    }
    setPrimaryPageFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id !== id) {
          return field
        }
        if (field.__typename === "SelectField") {
          return {
            ...field,
            localSelection: option,
          }
        }
        if (field.__typename === "MultiSelectField") {
          const currentSelections = field.localSelections ?? field.selections ?? [];
          const filtered = currentSelections.filter(
            (it) => it.value !== option?.value
          );
          const object = {
            ...field,
            localSelections:
              filtered.length < currentSelections.length
                ? // option was filtered out
                  filtered
                : // option must be added
                  [...filtered, option],
          }
          return object;
        }
        // Extend othe filter types here
        return field
      })

      return updated
    })
  }, [primaryPageFilters]);

  // TODO! - write reset all and select all for multiselect

  const selectClear = (id: string) => {
    setPageFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "SelectField") {
          return {
            ...field,
            localSelection: undefined,
          }
        }
        return field
      })

      return updated
    })
    setPrimaryPageFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "SelectField") {
          return {
            ...field,
            localSelection: undefined,
          }
        }
        return field
      })

      return updated
    })
  }

  const multiSelect = (id: string, options: types.SelectOption[] | null | undefined, extra?: any) => {
    setPageFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "MultiSelectField") {
          return {
            ...field,
            localSelections: options,
          }
        }
        return field
      })

      return updated
    })
    setPrimaryPageFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "MultiSelectField") {
          return {
            ...field,
            localSelections: options,
          }
        }
        return field
      })

      return updated
    })
  }

  const multiSelectAll = (id: string) => {
    setPageFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "MultiSelectField") {
          return {
            ...field,
            localSelections: field.options,
          }
        }
        return field
      })

      return updated
    })
    setPrimaryPageFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "MultiSelectField") {
          return {
            ...field,
            localSelections: field.options,
          }
        }
        return field
      })

      return updated
    })
  }

  const multiClearAll = (id: string) => {
    setPageFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "MultiSelectField") {
          return {
            ...field,
            localSelections: [],
          }
        }
        return field
      })

      return updated
    })
    setPrimaryPageFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "MultiSelectField") {
          return {
            ...field,
            localSelections: [],
          }
        }
        return field
      })

      return updated
    })
  }
  
  const resetPageFilters = useCallback(() => {
    setPageFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.__typename === "SelectField") {
          return {
            ...field,
            localSelection: null,
          }
        }
        if (field.__typename === "MultiSelectField") {
          const object = {
            ...field,
            localSelections: null,
          }
          return object;
        }
        return field
      })

      return updated
    })
  }, [pageFilters]);


  return (
    <PageFilterContext.Provider value = {{
      pageFilters, 
      primaryPageFilters, 
      pageFilterInputs, 
      pageFiltersLoaded, 
      updatePageFilters, 
      updatePrimaryPageFilters, 
      resetPageFilters,
      selectClear,
      multiSelect,
      multiSelectAll,
      multiClearAll
    }}>
      { props.children }
    </PageFilterContext.Provider>
  )
}

export const usePageFilterContext = () => {
  return useContext(PageFilterContext);
}

export function buildFilterInputs(filters: FilterFragment[]): types.FieldInput[] {
  return filters.map(filter => {
    let tag: types.FieldTag = types.FieldTag.UnknownField;
    switch (filter.__typename) {
      // TODO prefer lookup / dictionary to avoid this long switch
      case "BooleanField":
        tag = types.FieldTag.BooleanField;
        break;
      case "MultiSelectField":
        tag = types.FieldTag.MultiSelectField;
        break;
      case "NumberField":
        tag = types.FieldTag.NumberField;
        break;
      case "NumberRangeField":
        tag = types.FieldTag.NumberRangeField;
        break;
      case "SelectField":
        tag = types.FieldTag.SelectField;
        break;
      case "TextField":
        tag = types.FieldTag.TextField;
        break;
      case "UploadFileField":
        tag = types.FieldTag.UploadFileField;
        break;
    }
    return {
      id: filter.id ?? "",
      tag,
      text: filter.__typename === "TextField" ? (filter.localText ?? filter.text) : undefined,
      value: filter.__typename === "NumberField" ? (filter.localValue ?? filter.value) : undefined,
      // TODO state: filter.__typename === "BooleanField" ? filter.state : undefined,
      // TODO range: filter.__typename === "NumberRangeField" ? filter.range : undefined,
      selection: filter.__typename === "SelectField" ? (
        // convert selection to input
        _optionToInput(filter.localSelection ?? filter.selection)
      ) : undefined,
      selections: filter.__typename === "MultiSelectField" ? filterNotNull(
        // convert selections to inputs
        (filter.localSelections ?? filter.selections)?.map(selection =>
          _optionToInput(selection)
        ) ?? []
      ) : undefined,
    }
  });
}

// TODO types.Field["__typename"] <-- all options
// const x: types.Field["__typename"] = "";
const FIELD_TAG_BY_TYPENAME: Record<string, types.FieldTag> = {
  "BooleanField": types.FieldTag.BooleanField,
  "MultiSelectField": types.FieldTag.MultiSelectField,
  "NumberField": types.FieldTag.NumberField,
  "NumberRangeField": types.FieldTag.NumberRangeField,
  "SelectField": types.FieldTag.SelectField,
  "TextField": types.FieldTag.TextField,
  "UploadFileField": types.FieldTag.UploadFileField,
  // default -> types.FieldTag.UnknownField,
}