/*
 * DO NOT EDIT
 * This code is managed by the ARQ Apps Core Team
 */

import "./Column.scss";
import * as g from "./Column.generated";
import { TextColumn } from "./TextColumn";
import { NumberColumn } from "./NumberColumn";
import { BooleanColumn } from "./BooleanColumn";
import { BaseColumnProps } from "./BaseColumn";
import { DateColumn } from "./DateColumn";
import { DateTimeColumn } from "./DateTimeColumn";

type ColumnProps = g.ColumnFragment & BaseColumnProps;

export function Column(props: ColumnProps) {
  const commonClasses = [
    'ux-column',
  ].filter(Boolean).join(' ');

  switch (props.__typename) {
    case "BooleanColumn":
      return <BooleanColumn { ...props } className={ commonClasses }/>;
    case "TextColumn":
      return <TextColumn { ...props } className={ commonClasses }/>;
    case "NumberColumn":
      return <NumberColumn { ...props } className={ commonClasses }/>;
    case "DateColumn":
      return <DateColumn {...props} className={ commonClasses }/>;
    case "DateTimeColumn":
      return <DateTimeColumn {...props} className={ commonClasses }/>;
  }
  console.warn(`unknown column type [${ props.__typename }]`);
  return <></>;
}
