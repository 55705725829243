import "./HierarchicalFilter.scss"
import { HierarchicalFilterFragment } from "./HierarchicalFilter.generated"
import { HierarchicalOption } from "@arq-apps/generated"
import { useHierarchicalFilterContext } from "src/contexts/HierarchicalFilterContext/HierarchicalFilterContext"
import { Select, MultiSelect } from "@arq-apps/ui"

interface HierarchicalSelectProps {
  id: string
  depth: number
  label: string | null | undefined
  options: HierarchicalOption[] | null | undefined
  selection: HierarchicalOption[] | null | undefined
  multiSelectOnLeaf?: boolean | null | undefined
  leafSelections?: HierarchicalOption[] | null | undefined
}

const HierarchicalSelect = (props: HierarchicalSelectProps) => {
  const {updateHierarchicalFilters, updateHierarchicalLeafSelections, multiSelectAll, multiClearAll} = useHierarchicalFilterContext();

  const onOptionSelected = (option: string) => {
    const selected = props.options?.find(o => o.label === option)
    updateHierarchicalFilters(props.id, selected, props.depth)
  }

  const onOptionToggled = (option: string) => {
    const selected = props.options?.find(o => o.label === option)
    updateHierarchicalLeafSelections(props.id, selected)
  }

  const calculateNextDepthOptions = () => {
    const selected = props.options?.find((option) => {
      if (props.selection && option.value === props.selection[props.depth].value) {
        return true
      } else {
        return false
      }
    })
    return selected?.options
  }

  const calculateNextDepthLabel = () => {
    const selected = props.options?.find((option) => {
      if (props.selection && option.value === props.selection[props.depth].value) {
        return true
      } else {
        return false
      }
    })
    return selected?.optionsLabel
  }

  // TODO - have alternative style for SelectVanilla as hierarchical

  return (
    <div className="hierarchical-select">
      {props.options && !props.options[0]?.options && props.multiSelectOnLeaf
        ?
        <MultiSelect class="hierarchical"
          placeholder={props.label ? `Select ${props.label}` : "Select"}
          options={props.options?.map(option => option.label ?? "") ?? []}
          selections={props.leafSelections?.map(option => option.label ?? "") ?? []}
          onOptionToggled={onOptionToggled}
          onClearAll={() => multiClearAll(props.id)}
          onSelectAll={() => multiSelectAll(props.id, props.options)}
        />
        :
        <Select class="hierarchical"
          selected={props.selection && props.selection[props.depth] && props.selection[props.depth].label}
          options={props.options?.map(option => option.label ?? "") ?? []}
          placeholder={props.label ? `Select ${props.label}` : "Select"}
          onOptionSelected={onOptionSelected}
          lastHierFilter={props.depth === props.selection?.length}
        />
      }
      {props.selection && props.selection[props.depth] && props.selection[props.depth].options && <div className="pr-1">/</div>}
      {props.selection && props.selection[props.depth] && props.selection[props.depth].options && <HierarchicalSelect
        id={props.id}
        depth={props.depth + 1}
        label={calculateNextDepthLabel()}
        selection={props.selection}
        options={calculateNextDepthOptions()}
        multiSelectOnLeaf={props.multiSelectOnLeaf}
        leafSelections={props.leafSelections}
      />}
    </div>
  )
}

const HierarchicalFilter = (props: HierarchicalFilterFragment) => {

  return (
    <div className='hierarchical-filter'>
      <div style={{display: "flex", alignItems: "center"}}>{props.label + ": "}</div>
      <HierarchicalSelect
        id={props.id}
        depth={0}
        label={props.optionsLabel ?? props.label}
        selection={props.selection}
        options={props.options}
        multiSelectOnLeaf={props.multiselectOnLeaf}
        leafSelections={props.leafSelections}
      />
    </div>
  )
}

export default HierarchicalFilter;
