/*
 * DO NOT EDIT
 * This code is managed by the ARQ Apps Core Team
 */
import './Tile.scss';
import * as g from './Tile.generated';
import { Content } from "../Content";
import { Filters } from '../Filters';
import { buildFilterInputs } from 'src/hooks';
import { useCallback, useEffect, useState } from 'react';
import { FilterFragment } from '../Filter/Filter.generated';
import { SelectOption } from '@arq-apps/generated';

export interface TileProps extends g.TileFragment {
}

export function Tile(props: TileProps) {
  const [tileFilters, setTileFilters] = useState<FilterFragment[]>();

  const updateTileFilters = useCallback((id: string, option: SelectOption | null | undefined, extra?: any) => {
    if (!option) {
      return
    }
    setTileFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id !== id) {
          return field
        }
        if (field.__typename === "SelectField") {
          return {
            ...field,
            localSelection: option,
          }
        }
        if (field.__typename === "MultiSelectField") {
          const currentSelections = field.localSelections ?? field.selections ?? [];
          const filtered = currentSelections.filter(
            (it) => it.value !== option?.value
          );
          const object = {
            ...field,
            localSelections:
              filtered.length < currentSelections.length
                ? // option was filtered out
                  filtered
                : // option must be added
                  [...filtered, option],
          }
          return object;
        }
        return field
      })

      return updated
    })
  }, [tileFilters]);

  const selectClear = (id: string) => {
    setTileFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "SelectField") {
          return {
            ...field,
            localSelection: undefined,
          }
        }
        return field
      })

      return updated
    })
  }

  const multiSelect = (id: string, options: SelectOption[] | null | undefined) => {
    setTileFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "MultiSelectField") {
          return {
            ...field,
            localSelections: options,
          }
        }
        return field
      })

      return updated
    })
  }

  const multiSelectAll = (id: string) => {
    setTileFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "MultiSelectField") {
          return {
            ...field,
            localSelections: field.options,
          }
        }
        return field
      })

      return updated
    })
  }

  const multiClearAll = (id: string) => {
    setTileFilters((prev) => {
      const updated = prev?.map((field) => {
        if (field.id === id && field.__typename === "MultiSelectField") {
          return {
            ...field,
            localSelections: [],
          }
        }
        return field
      })

      return updated
    })
  }

  useEffect(() => {
    setTileFilters(props.filters)
  }, [props.filters])
  

  return (
    <div className="ux-tile">
      <header>
        <span className="title">{ props.title }</span>
      </header>
      { props.caption && <p className="caption">{ props.caption }</p>}
      { tileFilters && tileFilters.length > 0 && (
        <Filters 
          filters={ tileFilters } 
          updateFilters={ updateTileFilters } 
          primaryFilters={false}
          selectClear={selectClear}
          multiSelect={multiSelect}
          multiClearAll={multiClearAll}
          multiSelectAll={multiSelectAll}
        />
      )}
      { props.shallowContent && (
        <div>
          <Content { ...props.shallowContent }
            tileFilterInputs={buildFilterInputs(tileFilters ?? [])}
          />
        </div>
      ) }
    </div>
  );
}
