import { HttpLink, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { ENDPOINTS } from "@arq-apps/util";


const httpLink = new HttpLink({
  // options: https://www.apollographql.com/docs/react/api/link/apollo-link-http#httplink-constructor-options
  uri: ({operationName, getContext, setContext}) => {
    const {headers, token,} = getContext();
    setContext({
      headers: {
        ...headers,
      },
    });
    return `${ ENDPOINTS.GRAPHQL }?op=${ operationName }`;
  },
});

const wsLink = new GraphQLWsLink(createClient({
  url: ENDPOINTS.GRAPHQL_WS,
}));

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
export const splitProtocolLink = split(
  ({query}) => {
    // https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);
