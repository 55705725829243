/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesAuthenticationLoginResult } from '../models/MessagesAuthenticationLoginResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthenticationApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Logs a user into the DMP application by using the code returned to the front end by the PwC identity service and returns a jwt token
     * @param authCode
     * @param redirectUri
     * @param clientId
     * @param issuer
     * @returns MessagesAuthenticationLoginResult OK
     * @throws ApiError
     */
    public postAuthenticationApiGetToken(
        authCode?: string,
        redirectUri?: string,
        clientId?: string,
        issuer?: string,
    ): CancelablePromise<MessagesAuthenticationLoginResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/getToken',
            query: {
                'authCode': authCode,
                'redirectUri': redirectUri,
                'clientId': clientId,
                'issuer': issuer,
            },
        });
    }

    /**
     * Logs a user out by removing their token from cache and adding to blacklist
     * @returns any OK
     * @throws ApiError
     */
    public postAuthenticationApiLogout(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/logout',
        });
    }

}
