import "./ManageFiles.scss";
import { useDotnetClient } from "src/contexts/DotnetClientContext";
import { useEffect, useState } from "react";
import { useLandingContext } from "src/contexts/LandingContext";
import { Button, Field, Icon, IconButton } from "@arq-apps/ui";
import { MessagesStorageStorageSummaryItem } from "src/dotnet-client/models/MessagesStorageStorageSummaryItem";
import { MessagesStorageStorageDetailItem } from "src/dotnet-client/models/MessagesStorageStorageDetailItem";
import { MessagesStorageStorageFileType } from "src/dotnet-client/models/MessagesStorageStorageFileType";
import { MessagesStorageStorageMigrationJob } from "@arq-apps/dotnet";
import { SelectOption } from "@arq-apps/generated";
import { Id, toast } from "react-toastify";

export function ManageFiles() {
  // const [sync, setSync] = useState<MessagesAppSettingsAppSettingsExport>();
  const [loading, setLoading] = useState<boolean>(false);
  const { dmpClient } = useDotnetClient();
  const { appId, projectId } = useLandingContext();
  const [searchType, setSearchType] =
    useState<MessagesStorageStorageFileType>();
  const [summary, setSummary] = useState<MessagesStorageStorageSummaryItem[]>();
  const [fileDetails, setFileDetails] =
    useState<MessagesStorageStorageDetailItem[]>();
  const [migrationJob, setMigrationJob] =
    useState<MessagesStorageStorageMigrationJob>();
  const [selectedAppId, setSelectedAppId] = useState<number | undefined>();
  const [selectedProjectId, setSelectedProjectId] = useState<
    number | undefined
  >();
  const [appList, setAppList] = useState<SelectOption[]>();

  // figure out application mode or project mode
  // in application mode there should only be some templates ("project 0")
  // in project mode the user must selecte the application from a dropdown
  useEffect(() => {
    if (appId && !projectId) {
      console.log("Application mode");
      setSelectedAppId(appId);
      setSelectedProjectId(0);
      return;
    }

    if (!appId && projectId) {
      console.log("Project mode");
      setSelectedProjectId(projectId);
    }
  }, []);

  // if in project mode, load the list of applications to select
  useEffect(() => {
    if (selectedProjectId === undefined) return;

    setLoading(true);
    dmpClient?.uiApi
      .getUiApiGetApplicationsByProject(selectedProjectId)
      .then((res) => {
        if (res.length > 0) setSelectedAppId(res[0].id);

        setAppList(
          res.map((x) => {
            return { label: x.name, value: x.id.toString() } as SelectOption;
          })
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      })
      .finally(() => setLoading(false));
  }, [selectedProjectId]);

  // when an app is selected within project mode, or in application mode then load the summary
  useEffect(() => {
    if (loading || !selectedAppId || selectedProjectId === undefined) {
      return; // wait while loading..
    }

    // clear everything
    setSummary([]);
    setSearchType(undefined);
    setFileDetails([]);
    setMigrationJob(undefined);

    dmpClient?.storageApi
      .getStorageApiGetFilesSummary(selectedAppId, selectedProjectId)
      .then((res) => {
        setSummary(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  }, [selectedAppId]);

  useEffect(() => {
    if (searchType == MessagesStorageStorageFileType.Unknown) {
      setFileDetails(undefined);
      return;
    }

    if (
      selectedProjectId === undefined ||
      loading ||
      !selectedAppId ||
      !searchType
    ) {
      return; // wait while loading..
    }

    dmpClient?.storageApi
      .getStorageApiGetFilesDetails(
        selectedAppId,
        selectedProjectId,
        searchType
      )
      .then((res) => {
        setFileDetails(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  }, [selectedAppId, searchType]);

  const migrate = () => {
    if (
      selectedProjectId === undefined ||
      !selectedAppId ||
      selectedProjectId === undefined
    )
      return;

    setLoading(true);
    dmpClient?.storageApi
      .getStorageApiMigrate(selectedAppId, selectedProjectId, false)
      .then((res) => {
        setMigrationJob(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      })
      .finally(() => setLoading(false));
  };

  const migrateApply = () => {
    if (!selectedAppId || selectedProjectId === undefined) return;

    dmpClient?.storageApi
      .getStorageApiMigrate(selectedAppId, selectedProjectId, true)
      .then((res) => {
        setMigrationJob(res);
        setTimeout(() => refreshMigrationJob(res.jobId), 100);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  const refreshMigrationJob = (jobId: string, toastId?: Id) => {
    if (!selectedAppId || selectedProjectId === undefined || !migrationJob)
      return;

    dmpClient?.storageApi
      .getStorageApiGetMigrationJob(selectedAppId, selectedProjectId, jobId)
      .then((res) => {
        setMigrationJob(res);

        // keep refreshing until it's complete
        if (res.progressPercentage !== 100) {
          if (toastId === undefined) {
            toastId = toast("Migration in Progress", {
              progress:
                res.progressPercentage == 0
                  ? 1 / 100
                  : res.progressPercentage / 100,
            });
          } else {
            toast.update(toastId, {
              progress:
                res.progressPercentage == 0
                  ? 1 / 100
                  : res.progressPercentage / 100,
            });
          }

          setTimeout(() => refreshMigrationJob(jobId, toastId), 1000);
        } else if (res.progressPercentage === 100) {
          if (toastId !== undefined) {
            toast.update(toastId, {
              progress: 1,
              type: toast.TYPE.SUCCESS,
              render: "Migration complete!",
            });
          }

          dmpClient?.storageApi
            .getStorageApiGetFilesSummary(selectedAppId, selectedProjectId)
            .then((res) => {
              setSummary(res);
            })
            .catch((err) => {
              console.log(err);
              toast.error(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  const deleteAllFiles = (type: MessagesStorageStorageFileType) => {
    if (!selectedAppId || selectedProjectId === undefined) return;

    dmpClient?.storageApi
      .getStorageApiDeleteAll(selectedAppId, selectedProjectId, type)
      .then(() => {
        toast.info("Files will be deleted in background.");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  const deleteUnusedFiles = (type: MessagesStorageStorageFileType) => {
    if (!selectedAppId || selectedProjectId === undefined) return;

    dmpClient?.storageApi
      .getStorageApiDeleteUnused(selectedAppId, selectedProjectId, type)
      .then(() => {
        toast.info("Files will be deleted in background.");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  //Object.values(MessagesStorageStorageFileType).find(x => (x as string).toLowerCase() == t.type?.toString().toLowerCase())  as MessagesStorageStorageFileType
  const getEnumType = (x: string): MessagesStorageStorageFileType => {
    switch (x) {
      case "template":
        return MessagesStorageStorageFileType.Template;
      case "inApp":
        return MessagesStorageStorageFileType.InApp;
      case "modelling":
        return MessagesStorageStorageFileType.Modelling;
      case "result":
        return MessagesStorageStorageFileType.Result;
      case "other":
        return MessagesStorageStorageFileType.Other;
      case "bin":
        return MessagesStorageStorageFileType.Bin;
      default:
        return MessagesStorageStorageFileType.Unknown;
    }
  };

  /**
   * https://stackoverflow.com/a/14919494
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   *
   * @return Formatted string.
   */
  const humanFileSize = (
    bytes: number | undefined,
    si = true,
    dp = 1
  ): string => {
    if (bytes === undefined) return "";

    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  };

  return (
    <div className="ux-page manage">
      {!appId && projectId && (
        <aside>
          <div className="ux-filters primary">
            <div className="ux-filter">
              {selectedAppId && appList && (
                <Field
                  __typename="SelectField"
                  id="Select Application"
                  options={appList}
                  updateFilters={(x, opt) => {
                    setSelectedAppId(
                      opt?.value ? Number.parseInt(opt.value) : 0
                    );
                  }}
                  localSelection={appList.find(
                    (x) => x.value == selectedAppId.toString()
                  )}
                ></Field>
              )}
            </div>
          </div>
        </aside>
      )}

      <div className="panels">
        {migrationJob && (
          <>
            <div className="ux-panel">
              <div className="ux-tile">
                <header>
                  <span className="title">Migration files found</span>
                </header>
                <div>
                  <div className="ux-form">
                    <table className="migrationTable">
                      <thead>
                        <tr>
                          {!appId && projectId && <th align="left">Entity</th>}
                          <th align="left">Filename</th>
                          <th align="left">Content Type</th>
                          <th align="left">Type</th>
                          <th align="right">Size</th>
                          <th align="left">Old Name</th>
                          <th align="left">New Name</th>
                          <th align="left">Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {migrationJob.files.map((mfd) => (
                          <tr key={mfd.oldBlobName}>
                            {!appId && projectId && <td align="left">{mfd.entityName}</td>}
                            <td align="left">{mfd.filename}</td>
                            <td align="left">{mfd.contentType}</td>
                            <td align="left">{mfd.type}</td>
                            <td align="right">{humanFileSize(mfd.size)}</td>
                            <td align="left">{mfd.oldBlobName}</td>
                            <td align="left">{mfd.newBlobName}</td>
                            <td align="left">{mfd.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {migrationJob.progressPercentage === 0 && (
                      <Button
                        text="Confirm Migration"
                        onClick={() => migrateApply()}
                      />
                    )}
                    {migrationJob.progressPercentage > 0 &&
                      migrationJob.progressPercentage < 100 && (
                        <span className="progressMessage">
                          Migration in progress:{" "}
                          {migrationJob.progressPercentage}% complete
                        </span>
                      )}
                    {migrationJob.progressPercentage === 100 && (
                      <span className="progressMessage">
                        Migration complete.
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="ux-panel">
          <div className="tiles">
            <div className="ux-tile">
              <header>
                <span className="title">Manage project files</span>
              </header>
              <div>
                <div className="ux-form">
                  <Button
                    text="Migrate Files (once-off)"
                    onClick={() => migrate()}
                  />

                  <br />

                  <table>
                    <thead>
                      <tr>
                        <th align="left">Type</th>
                        <th align="right">Count</th>
                        <th align="right">Size</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {summary &&
                        summary.map((t) => (
                          <tr
                            className={
                              getEnumType(t.type.toString()) === searchType
                                ? "current"
                                : ""
                            }
                            key={t.type}
                          >
                            <td align="left">{t.type}</td>
                            <td align="right">{t.count}</td>
                            <td align="right">{humanFileSize(t.totalSize)}</td>
                            <td align="center">
                              {getEnumType(t.type.toString()) !== searchType ? (
                                <IconButton
                                  icon="search-outline"
                                  onClick={() =>
                                    setSearchType(
                                      getEnumType(t.type.toString())
                                    )
                                  }
                                />
                              ) : (
                                <IconButton
                                  icon="search-fill"
                                  onClick={() =>
                                    setSearchType(
                                      MessagesStorageStorageFileType.Unknown
                                    )
                                  }
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="ux-tile">
              <header>
                <span className="title">Existing files</span>
              </header>
              <div>
                <div className="ux-form">
                  {fileDetails && searchType ? (
                    <>
                      <table>
                        <thead>
                          <tr>
                            {searchType ===
                              MessagesStorageStorageFileType.Template && !appId && projectId && (
                              <th align="left">Entity</th>
                            )}
                            <th align="left">Type</th>
                            <th align="left">Filename</th>
                            <th align="left">Content</th>
                            <th align="right">Size</th>
                            {searchType ===
                              MessagesStorageStorageFileType.Template && (
                              <th align="right">Current</th>
                            )}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {fileDetails &&
                            fileDetails.map((fd, i) => (
                              <tr key={fd.filename + i.toString()}>
                                {searchType ===
                                  MessagesStorageStorageFileType.Template && !appId && projectId && (
                                  <td align="left">{fd.entityName}</td>
                                )}
                                <td align="left">{fd.type}</td>
                                <td align="left">{fd.filename}</td>
                                <td align="left">{fd.contentType}</td>
                                <td align="right">{humanFileSize(fd.size)}</td>
                                {searchType ===
                                  MessagesStorageStorageFileType.Template && (
                                  <td align="center">
                                    {fd.current && (
                                      <Icon
                                        icon="check-mark-fill"
                                        color="green"
                                      ></Icon>
                                    )}
                                  </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {fileDetails.length > 0 ? (
                        <>
                          <Button
                            text="Delete all files"
                            onClick={() => deleteAllFiles(searchType)}
                          />
                          {searchType ===
                            MessagesStorageStorageFileType.Template && (
                            <Button
                              text="Delete unused files"
                              onClick={() => deleteUnusedFiles(searchType)}
                            />
                          )}
                        </>
                      ) : (
                        <span>No files.</span>
                      )}
                    </>
                  ) : (
                    <>Please select a file type to the left.</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
