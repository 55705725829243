/*
 * DO NOT EDIT
 * This code is managed by the ARQ Apps Core Team
 */
import './Panel.scss';
import * as g from './Panel.generated';
import { Tile } from "../Tile";
import { memo, useState } from "react";
import { IconButton } from '@arq-apps/ui';


export interface PanelProps extends g.PanelFragment {
}

export const Panel = memo(function Panel(props: PanelProps) {
  const [ collapsed, setCollapsed ] = useState<boolean>(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  return (
    <div className="ux-panel">
      {(props.collapsible && props.title) && <div className="title">
        <IconButton
          icon="down-chevron-outline"
          toggleIcon="up-chevron-outline"
          onClick={ toggleCollapsed }
          toggleState={ collapsed }
        />
        { props.title }
      </div>}
      {!collapsed && <div className="tiles">
        { props?.tiles?.map((tile, tileIdx) => (
          <Tile {...tile} key={ `tile-${tileIdx}` } />
        )) }
      </div>}
    </div>
  );
});
