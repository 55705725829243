import './FAQ.scss';
import { SupportPaneSize } from '../App'
import { useEffect, useState } from 'react'
import { SearchBox } from '../SearchBox'
import { MessagesFaqFaq } from '../../dotnet-client'
import { useDotnetClient } from 'src/contexts/DotnetClientContext'
import { Loading } from '@arq-apps/ui'

interface FaqItemProps {
  item: MessagesFaqFaq,
  onClick: () => void,
  selected: boolean
}

const FaqItem = (props: FaqItemProps) => {
  const classes = [
		"faq-item",
		props.selected && "selected"
	].filter(Boolean).join(" ");

  return (
    <div className={classes} onClick={props.onClick} >
      <div className='faq-title'>{props.item.question}</div>
      <div className='faq-description'>{props.item.short ?? props.item.long}</div>
    </div>
  )
}

interface FaqProps {
	supportPaneSize: SupportPaneSize | undefined
	setSupportPaneSize: (size: SupportPaneSize | undefined) => void
	appId: number | null
}

const FAQ = ({ supportPaneSize, setSupportPaneSize, appId }: FaqProps) => {
  const [faq, setFaq] = useState<MessagesFaqFaq[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(0);
  const [searchString, setSearchString] = useState("");
  const { dmpClient } = useDotnetClient();

  useEffect(()=>{
    if (!appId) return
    if (!dmpClient) return

    setLoading(true)
    dmpClient.faqApi.getFaqApiGetFaq(appId)
      .then(_faq => {
        setFaq(_faq)
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }, []);

  const filterOnSearchTerm = (question: MessagesFaqFaq) =>
		!searchString
		|| searchString === ""
		|| JSON.stringify(question).toLowerCase().includes(searchString.toLowerCase());

  const numberOfFiles = () => {
		if (!faq)
			return '';
		const total = faq.filter(filterOnSearchTerm).length ?? 0
		const string = total === 1 ? "question" : "questions"
    return `${total} ${string}`
	}

  const onSelect = (index: number) => {
    setSupportPaneSize(SupportPaneSize.Max)
    setSelected(index)
  }

  const classes = [
		"ux-faq-layout",
		supportPaneSize
	].filter(Boolean).join(" ");

  return (
    <div className={classes}>
			<div className="faq">
				<SearchBox searchString={searchString} setSearchString={setSearchString}/>
				<div className="file-count" key="file_count">{numberOfFiles()}</div>
        {loading && <Loading />}
        {!loading && faq.filter(filterOnSearchTerm).length === 0 && <Loading message='No FAQ questions'/>}
				{!loading && faq.filter(filterOnSearchTerm).length > 0 && faq.filter(filterOnSearchTerm).map((question, index) => 
          <FaqItem key={question.id} item={question} onClick={() => onSelect(index)} selected={index === selected} />
        )}
			</div>
      {supportPaneSize === SupportPaneSize.Max && !loading && faq.length > 0 &&
				<div className='faq-long'>
          <div className='faq-title'>{faq[selected].question}</div>
          <div className='faq-long-description'>{faq[selected].long ?? faq[selected].short}</div>
        </div>
			}
		</div>
  )
}

export default FAQ;