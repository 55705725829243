import "./Table.scss";
import * as g from "./Table.generated";
import { CSSProperties, useCallback, useMemo, useRef, useEffect } from "react";
import { Button, Icon } from "@arq-apps/ui";
import { Column } from "../Column";
import { useEditTable } from "./useEditTable";
import { range } from "@arq-apps/util";
import { FieldInput } from "@arq-apps/generated";
import { useContext, usePageFilterContext } from "src/hooks";
import { useApolloClient } from "@apollo/client";
import { LocalEditColumnFragment, LocalEditTableFragmentDoc } from "./useEditTable.generated";
import { useLandingContext } from "src/contexts/LandingContext"
import { useHierarchicalFilterContext } from "src/contexts/HierarchicalFilterContext/HierarchicalFilterContext"
import { usePage } from "src/hooks/usePage"

export type TableProps = {
  id: string,
  tileFilterInputs?: FieldInput[]
}

function useTable(tableId: string, tileFilterInputs?: FieldInput[], pageFilterInputs?: FieldInput[] | null) {
  const {appId, projectId} = useContext();
  const LandingContext = useLandingContext();
  const {hierarchicalFilterInputs} = useHierarchicalFilterContext();
  const {pageLoaded} = usePage();

  const [getTable, getTableData] = g.useTableLazyQuery();
  const {cache} = useApolloClient();

  const filters = useMemo(() => {
    const _pageFilterInputs = pageFilterInputs ?? [];
    const _tileFilterinputs = tileFilterInputs ?? [];
    const _hierarchicalFilterinputs = hierarchicalFilterInputs ?? [];
    return [..._tileFilterinputs, ..._pageFilterInputs, ..._hierarchicalFilterinputs];
  }, [tileFilterInputs, pageFilterInputs, hierarchicalFilterInputs]);

  const fetchTable = async() => {
    cache.evict({
      id: cache.identify({__typename: "Table", id: tableId})
    })
    getTable({
      fetchPolicy: "network-only",
      variables: {
        appId: appId === "landing" ? "main" : appId,
        projectId,
        tableId,
        filters,
        extraInputs: [
          {"name": "appId", "value": `${LandingContext.appId}`},
          {"name": "projectId", "value": `${LandingContext.projectId}`}
        ]
      },
    })
    .then(result => {
      const columns = result.data?.app?.tableById?.columns;
      resetTableCache(columns);
    })
  }

  const resetTableCache = (columns: LocalEditColumnFragment[] | null | undefined) => {
    if (!columns) {
      return
    }
    const tableIdentity = cache.identify({__typename: "Table", id: tableId})

    cache.updateFragment({
      id: tableIdentity,
      fragment: LocalEditTableFragmentDoc,
      fragmentName: "LocalEditTable",
      broadcast: true
    }, (data) => {
      const returnable = {
        ...data,
        // Note: this change will trigger `Table -> fields -> columns -> merge` TypePolicy
        columns: columns?.map((column: LocalEditColumnFragment) => {
          switch (column.__typename) {
            case "BooleanColumn":
              return {
                ...column,
                localBoolValues: column.localBoolValues?.map(() => null)
              }
            case "NumberColumn":
              return {
                ...column,
                localNumberValues: column.localNumberValues?.map(() => null)
              }
            case "TextColumn":
              return {
                ...column,
                localTextValues: column.localTextValues?.map(() => null)
              }
              case "DateColumn":
                return {
                  ...column,
                  localDateValues: column.localDateValues?.map(() => null)
                }              
            default:
              console.debug('reset column', column.__typename, column.id, column);
              console.error(`reset column does not support column type ${ column.__typename }`);
              throw Error(`reset column does not support column type ${ column.__typename }`);
          }
        }),
      }

      // console.log("return from cache reset", returnable)

      return returnable
    });
  }

  useEffect(() => {
    if (pageLoaded) {
      fetchTable();
    }
  }, [tableId, filters, pageLoaded]);

  return {
    table: getTableData.data?.app?.tableById,
    tableLoading: getTableData.loading
  }
}

export function Table(props: TableProps) {
  const { pageFilterInputs } = usePageFilterContext();
  const { table, tableLoading } = useTable(props.id, props.tileFilterInputs, pageFilterInputs);

  const dataRowIndex = useMemo(() => range(table?.dataRowCount ?? 0), [table?.dataRowCount]);

  const { localEditCell, submitTableChanges, resetTableChanges } = useEditTable(props.id, props.tileFilterInputs);

  const classes = ["ux-table"].filter(Boolean).join(" ");

  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // scroll to the leftmost column on mount
    if(tableRef.current) {
      tableRef.current.scrollLeft = 0;
    }
  }, []);

  const handleTableReset = useCallback(() => {
    console.debug("reset this table", table);
    if (table) {
      resetTableChanges(table);
    }
  }, [table?.columns]);

  const handleTableSubmission = () => {
    console.debug("submit this table", table);
    if (table) {
      submitTableChanges(table);
    }
  };

  // TODO @RM - there must be a better way to define which types are editable
  // possibly add "editable" with default "false" as a prop to all column types
  const tableEditable = useMemo(() => {
    return table?.columns?.some((column) => {
      return (column.__typename === "BooleanColumn"
      || column.__typename === "NumberColumn"
      || column.__typename === "TextColumn") 
      && column.editable === true});
  }, [table?.columns]);

  const StartpinnedColumns = useMemo(() => {
    return table?.columns?.filter((column) => column.pinStart === true && column.hide !== true)
  }, [table, table?.columns])

  const OtherColumns = useMemo(() => {
    return table?.columns?.filter((column) => column.pinStart !== true && column.hide !== true)
  }, [table, table?.columns])
  
  const ShowTotalsRow = useMemo(() => {
    return table?.columns?.some((column) => column.__typename == "NumberColumn" && column.showTotals) || false
  }, [table])
  
  
  const columnsContainer: CSSProperties = useMemo(
    () => ({
      display: "inline-grid",
      gridAutoFlow:  "column",
      gridTemplateRows: `repeat(1, auto)`,
      gridTemplateColumns: `repeat(2, auto)`,
      height: "fit-content", // `${table?.rowCount * 20}px`,
    }),
    []
  );

  const rowsContainer: CSSProperties = useMemo(
    () => ({
      display: "inline-grid",
      gridAutoFlow:  "row",
      gridTemplateRows: `repeat(2, auto)`,
      gridTemplateColumns: `repeat(1, auto)`,
      height: "fit-content", // `${table?.rowCount * 20}px`,
    }),
    []
  );
  
  const rowCount = useMemo(() => ((table?.dataRowCount || 0) + (table?.titleRowCount || 0) + (ShowTotalsRow ? 1 : 0)  ), [table?.rowCount]);

  const pinnedColumnsStyle: CSSProperties = useMemo(
    () => ({
      display: "inline-grid",
      gridAutoFlow: table?.transpose ? "row" : "column",
      gridTemplateRows: `repeat(${
        !table?.transpose ? rowCount : StartpinnedColumns?.length
      }, auto)`,
      gridTemplateColumns: `repeat(${
        !table?.transpose ? StartpinnedColumns?.length : rowCount
      }, auto)`,
      height: "fit-content", // `${table?.rowCount * 20}px`,
    }),
    [table?.transpose, table?.rowCount, StartpinnedColumns?.length]
  );

  const notpinnedStyle: CSSProperties = useMemo(
    () => ({
      display: "inline-grid",
      gridAutoFlow: table?.transpose ? "row" : "column",
      gridTemplateRows: `repeat(${
        !table?.transpose ? rowCount : OtherColumns?.length
      }, auto)`,
      gridTemplateColumns: `repeat(${
        !table?.transpose ? OtherColumns?.length : rowCount
      }, auto)`,
      height: "fit-content", // `${table?.rowCount * 20}px`,
    }),
    [table?.transpose, table?.rowCount, OtherColumns?.length]
  );

  const pinnedRowsStyle: CSSProperties = useMemo(
    () => ({
      display: "inline-grid",
      gridAutoFlow: table?.transpose ? "row" : "column",
      gridTemplateRows: `repeat(${
        !table?.transpose ? rowCount : StartpinnedColumns?.length
      }, auto)`,
      gridTemplateColumns: `repeat(${
        !table?.transpose ? StartpinnedColumns?.length : rowCount
      }, auto)`,
      height: "fit-content", // `${table?.rowCount * 20}px`,
    }),
    [table?.transpose, table?.rowCount, StartpinnedColumns?.length]
  );
  
  if (!tableLoading && (!table || table?.columns === undefined || table?.columns?.length === 0)) {
    return (
      <div className="invalidTable">
        <Icon icon='3by3grid-outline' />
        { table?.caption && <div className={ "caption" }>{ table?.caption }</div> }
      </div>
    );
  }
  
  if (tableLoading) {
  return (
    <div className="loadingTable">Loading...</div>
    )
  }

  const columnsScroller = [
    'columns-scroller',
    StartpinnedColumns?.length? '' : '-nopinned',
  ].filter(Boolean).join('');
    
  const rowsScroller = [
    'rows-scroller',
    StartpinnedColumns?.length? '' : '-nopinned',
  ].filter(Boolean).join('');

  return (
    <div className={ classes }>
      { table?.title && <div className={ "title" }>{ table?.title }</div> }
      { table?.caption && <div className={ "caption" }>{ table?.caption }</div> }
      { table?.description && (
        <div className={ "description" }>{ table?.description }</div>
      ) }
      { !table?.transpose ? 
        <div className="columns-container" style={ columnsContainer }>
          { !!StartpinnedColumns?.length && 
          <div className="columns" style={ pinnedColumnsStyle }>
            {StartpinnedColumns?.map((column, idx) => (
              <Column
                key={ column.id }
                { ...column }
                tableId={ props.id }
                titleRowCount={ table?.titleRowCount ?? 0}
                dataRowCount={ table?.dataRowCount ?? 0}
                footerRowCount={ table?.footerRowCount ?? 0}
                dataRowIndex={ dataRowIndex }
                onEditCell={ localEditCell }
                showTotals = {column.showTotals}
                showTotalsRow = { ShowTotalsRow }
              />
              )) }
          </div> }
          <div className={columnsScroller} style={ notpinnedStyle } ref={tableRef}>
            {OtherColumns?.map((column, idx) => (
              <Column
                key={ column.id }
                { ...column }
                tableId={ props.id }
                titleRowCount={ table?.titleRowCount ?? 0 }
                dataRowCount={ table?.dataRowCount ?? 0}
                footerRowCount={ table?.footerRowCount ?? 0}
                dataRowIndex={ dataRowIndex }
                onEditCell={ localEditCell }
                showTotals = {column.showTotals}
                showTotalsRow = { ShowTotalsRow }
              />
            )) }
          </div>
        </div>
        :
        <div className="rows-container" style={ rowsContainer }>
          { !!StartpinnedColumns?.length && 
          <div className="rows" style={ pinnedRowsStyle }>
            {StartpinnedColumns?.map((column, idx) => (
              <Column
                key={ column.id }
                { ...column }
                tableId={ props.id }
                titleRowCount={ table?.titleRowCount ?? 0}
                dataRowCount={ table?.dataRowCount ?? 0}
                footerRowCount={ table?.footerRowCount ?? 0}
                dataRowIndex={ dataRowIndex }
                onEditCell={ localEditCell }
                showTotals = {column.showTotals}
                showTotalsRow = { ShowTotalsRow }
              />
              )) }
          </div> }
          <div className={rowsScroller} style={ notpinnedStyle } ref={tableRef}>
            {OtherColumns?.map((column, idx) => (
              <Column
                key={ column.id }
                { ...column }
                tableId={ props.id }
                titleRowCount={ table?.titleRowCount ?? 0 }
                dataRowCount={ table?.dataRowCount ?? 0}
                footerRowCount={ table?.footerRowCount ?? 0}
                dataRowIndex={ dataRowIndex }
                onEditCell={ localEditCell }
                showTotals = {column.showTotals}
                showTotalsRow = { ShowTotalsRow }
              />
            )) }
          </div>
        </div>
        }

      {tableEditable && <div className="actions">
        <Button
          text={ 'Reset' }
          theme='white'
          onClick={ handleTableReset }
        />
        <Button
          text={ table?.submitButtonText ?? 'Submit' }
          onClick={ handleTableSubmission }
        />
      </div>}
    </div>
  );
}
