import './Landing.scss';
import { useEffect, useMemo, useState } from "react";
import { useContext } from 'src/hooks'
import { useLandingContext } from 'src/contexts/LandingContext'
import { useDotnetClient } from 'src/contexts/DotnetClientContext'
import { MessagesLandingMenuItem } from '@arq-apps/dotnet'

import { BreadCrumbs, Logo, SearchBox, TabMenu, UserWidget, ManageCard, ManagePage } from '@arq-apps/ux';
import { IconButton, Footer } from '@arq-apps/ui';

export function LandingApplication() {
  const [applicationMenu, setApplicationMenu] = useState<MessagesLandingMenuItem[]>([]);
  const [mainMenuIndex, setMainMenuIndex] = useState<number>(0);
  const [secondMenuIndex, setSecondMenuIndex] = useState<number>(0);
  const [searchString, setSearchString] = useState<string>("");
  const [cardsLoading, setCardsLoading] = useState<boolean>(false);
  const [applicationPageId, setApplicationPageId] = useState<string>();
  const [applicationPageName, setApplicationPageName] = useState<string>("");

  const { applicationName, updateLandingContext } = useLandingContext();
  const { updateContext } = useContext();
  const { dmpClient } = useDotnetClient();

  const fetchMenu = async () => {
    setCardsLoading(true)
    dmpClient?.landingApi.getLandingApiGetPlatformOperations(1, 0, "application")
    .then(result => {
      setApplicationMenu(result.items)
    })
    .catch(err => {
      console.warn(err)
    })
    .finally(() => setCardsLoading(false))
  }

  useEffect(() => {
    fetchMenu();
  }, [])

  const mainMenu = useMemo(() => {
    if (applicationMenu.length <= 0) {
      return []
    }
    return applicationMenu?.map(item => {
      return {
          key: item.name,
          title: item.name
      }
    })
  }, [applicationMenu]);

  const secondMenu = useMemo(() => {
    if (applicationMenu.length <= 0) {
      return []
    }
    return applicationMenu[mainMenuIndex].items.map(item => {
      return {
        key: item.name,
        title: item.name
      }
    })
  }, [applicationMenu, mainMenuIndex])

  return (
    <div className="landing">
      <div className='header'>
        <Logo />
        <TabMenu inHeader={true} items={mainMenu} selectedIndex={mainMenuIndex} onChange={setMainMenuIndex} />
        <UserWidget />
      </div>
      {applicationPageId ? 
        <BreadCrumbs
          title={`Application: ${applicationName}`}
          items={["Applications", applicationName ?? "Application", mainMenu[mainMenuIndex].title, secondMenu[secondMenuIndex].title, applicationPageName]}
          onBack={() => setApplicationPageId(undefined)}
          onHome={() => {
            setApplicationPageId(undefined)
            updateLandingContext({appKey: undefined, appId: undefined, projectId: undefined})
          }}
        /> :
        <div className='second-level-menu-container'>
          <div className="back-navigate">
            <IconButton icon="arrow-left-outline" hover-icon="arrow-left-fill" scale={2} onClick={() => updateLandingContext({appKey: undefined, appId: undefined, projectId: undefined})}/>
            <div>{"Application:"}</div>
            <div className="bold">{applicationName}</div>
          </div>
          <TabMenu items={secondMenu} selectedIndex={secondMenuIndex} onChange={setSecondMenuIndex} />
          <SearchBox searchString={searchString} setSearchString={setSearchString}/>
        </div>
      }
      {applicationPageId ? 
        <ManagePage managePageId={applicationPageId} /> :
        <div className='card-container'>
          {cardsLoading && <div>Loading...</div>}
          {!cardsLoading && applicationMenu.length > 0 && applicationMenu[mainMenuIndex].items[secondMenuIndex]?.items.map((item, index) => {
            return <ManageCard key={index} {...item} onClick={() => {
              updateContext({
                appId: "main",
                projectId: null,
                pageId: item.page_id
              })
              setApplicationPageName(item.name)
              setApplicationPageId(item.page_id ?? "main/home")
            }}/>
          })}
        </div>
      }
      <Footer />
    </div>
  );
};