import './Content.scss';
import * as g from "./Content.generated";
import { Form, Metric, Table, VegaLiteChart } from "@arq-apps/ux";
import { FieldInput } from '@arq-apps/generated';

type ContentProps = g.ContentFragment & {
  tileFilterInputs?: FieldInput[],
};

export function Content(props: ContentProps) {

  const classes = [
    "ux-content",
  ].filter(Boolean).join(" ");

  switch (props.__typename) {
    case "VegaLiteChart":
      return <VegaLiteChart { ...props } />;
    case "Form":
      return <Form { ...props } />;
    case "Metric":
      return <Metric { ...props } />;
    case "Table":
      return <Table { ...props } />;
    // TODO other content types...
  }

  return (
    <div className={ classes }>
      <div>{ props.__typename }:</div>
      {/* <div>{ props.id }</div> */}
    </div>
  );
}