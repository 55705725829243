import "./MultiCheckbox.scss";
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from "@arq-apps/ui";

interface MultiCheckboxProps {
	options: string[];
	selected: string[];
	onOptionClicked: (option: string) => void;
}

export const MultiCheckbox = (props: MultiCheckboxProps) => {

  return (
    <div className="ux-multiselect-checkbox">
			{props.options.map((option) => (
				<div className="check-item" key={ option } onClick={() => props.onOptionClicked(option)}>
					<Checkbox.Root checked={ props.selected.includes(option) }>
						<Checkbox.CheckboxIndicator>
							<CheckIcon />
						</Checkbox.CheckboxIndicator>
					</Checkbox.Root>
					<div className="label">{ option }</div>
				</div>
			))}
    </div>
  );
}

export default MultiCheckbox