import './Card.scss';
import { useState } from 'react'
import { MessagesLandingCard } from '@arq-apps/dotnet'
import { ENDPOINTS } from '@arq-apps/util';

interface ApplicationCardProps extends MessagesLandingCard {
  blurb?: string;
  email?: string;
  long_description?: string;
  hover?: boolean;
  onClick?: () => void;
}

export function ApplicationCard(props: ApplicationCardProps) {
  const [hover, setHover] = useState(false);
  const hoverFalse = () => setHover(false);
  const hoverTrue = () => setHover(true);

  const classes = [
    'ux-card', 'application-card',
    props.hover && 'hover',
    props.email && 'email',
  ].filter(Boolean).join(' ');

  if (props.email) {
    return (
      <div className={classes.replace('hover', '')} onClick={props.onClick}>
        <div className="image" style={{ backgroundImage: `url("${ENDPOINTS.DOTNET}/rest/support/getImage?imageKey=${props.image}")` }}/>
        <div className="content">
          <div className="title">{props.title}</div>
          <div className="description">{props.description}</div>
          <div className="description">{props.email}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes} onClick={props.onClick} onMouseEnter={props.hover ? hoverTrue : undefined} onMouseLeave={props.hover ? hoverFalse : undefined}>
      <div className="image" style={{ backgroundImage: `url("${ENDPOINTS.DOTNET}/rest/support/getImage?imageKey=${props.image}")` }}/>
      <div className="content">
        <div className="title">{props.title}</div>
        <div className="description">{props.subtitle}</div>
        {hover && <>
          <div className="divider"></div>
          <div className="long-description">{props.description}</div>
          <div className="blurb">{props.blurb}</div>
        </>}
      </div>
    </div>
  );
};