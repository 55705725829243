/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesDataAssetsTDataAssetActivity } from '../models/MessagesDataAssetsTDataAssetActivity';
import type { SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues } from '../models/SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DataAssetApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Gets the summary of data assets for a specific application and project.
     * @param applicationId The application ID.
     * @param projectId The project ID.
     * @returns MessagesDataAssetsTDataAssetActivity OK
     * @throws ApiError
     */
    public getDataAssetApiGetTemplateSummary(
        applicationId: number,
        projectId: number,
    ): CancelablePromise<Array<MessagesDataAssetsTDataAssetActivity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/data_asset/summary/{applicationId},{projectId}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
            },
        });
    }

    /**
     * Gets the activity log of a specific data asset.
     * @param applicationId The application ID.
     * @param projectId The project ID.
     * @param entityId The entity ID.
     * @param dataAssetId The data asset ID.
     * @returns MessagesDataAssetsTDataAssetActivity OK
     * @throws ApiError
     */
    public getDataAssetApiGetSpecificDataAssetSummary(
        applicationId: number,
        projectId: number,
        entityId: number,
        dataAssetId: number,
    ): CancelablePromise<Array<MessagesDataAssetsTDataAssetActivity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/data_asset/activity_log/{applicationId},{projectId},{entityId},{dataAssetId}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
                'entityId': entityId,
                'dataAssetId': dataAssetId,
            },
        });
    }

    /**
     * Updates the state of a data asset.
     * @param formData
     * @returns MessagesDataAssetsTDataAssetActivity OK
     * @throws ApiError
     */
    public postDataAssetApiPostTransitionUpdate(
        formData?: {
            formData?: Array<SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues>;
        },
    ): CancelablePromise<Array<MessagesDataAssetsTDataAssetActivity>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/data_asset/transition',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
