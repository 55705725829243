import "./MarketingPage.scss";
import { useEffect, useState } from "react";
import { useDotnetClient } from "src/contexts/DotnetClientContext"
import { MessagesLandingApplicationMarketingInfoCard } from "@arq-apps/dotnet"

export interface MarketingPageProps {
  id: number
}

export function MarketingPage(props: MarketingPageProps) {
  const [marketingPage, setMarketingPage] = useState<MessagesLandingApplicationMarketingInfoCard>();
  const [loading, setLoading] = useState<boolean>(false);

  const { dmpClient } = useDotnetClient();

  const fetchMarketingPage = async () => {
    setLoading(true)
     dmpClient?.landingApi.getLandingApiGetApplicationInfoExternal(props.id)
    .then(result => {
      setMarketingPage(result)
    })
    .catch(err => {
      console.warn(err)
    })
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchMarketingPage()
  }, [])

  return (
    <div className="marketing-page">
      <div className="main-panel">
        <div className="title">{marketingPage?.name}</div>
        <div className="subtitle">{marketingPage?.description}</div>
        <div className="main-panel-image" style={{backgroundImage: `url("${ marketingPage?.image }")`}}>
          <div className="content">
            <div className="description-title">{marketingPage?.problem_statement}</div>
            <div className="description">{marketingPage?.key_questions}</div>
          </div>
        </div>
      </div>

      <div className="panels">
        <div className="panel">
          <div className="title">{"How this app tackles the problem statement"}</div>
          <div className="blurb">{marketingPage?.solution_description}</div>
        </div>

        <div className="panel">
          <div className="title">{"Benefits that differentiate this app"}</div>
          {marketingPage?.differentiators && <div className="items">
            {marketingPage?.differentiators.map((dif, index) => 
              <div className="item" key={index}>
                <div className="item-image" style={{backgroundImage: `url("${ dif.image }")`}} />
                <div className="item-description">{dif.description}</div>
              </div>
            )}
          </div>}
        </div>

        <div className="panel">
          <div className="title">{"Get in touch with our app leads"}</div>
          {marketingPage?.application_leads && <div className="contacts">
            {marketingPage?.application_leads.map((contact, index) => 
              <div className="contact" key={index}>
                <div className="contact-image" style={{backgroundImage: `url("${ contact.image }")`}}/>
                <div className="contact-details">
                  <div className="name">{contact.name}</div>
                  <div className="detail">{contact.designation}</div>
                  <div className="detail">{contact.contact_detail}</div>
                </div>
              </div>
            )}
          </div>}
        </div>
      </div>
    </div>
  )
}
