import { MessagesLandingApplicationCard, MessagesLandingApplicationMarketingCard, MessagesLandingApplicationProjectCard, MessagesLandingPlatformMarketingCard, MessagesLandingProjectCard } from "@arq-apps/dotnet"
import { TabMenuItem } from "src/ux/TabMenu/TabMenu"

export const sortMethod = (
  itemA: MessagesLandingApplicationMarketingCard | MessagesLandingPlatformMarketingCard,
  itemB: MessagesLandingApplicationMarketingCard | MessagesLandingPlatformMarketingCard
) => {
  if (itemA.card.groupings[0].sort_order && itemB.card.groupings[0].sort_order) {
    return itemA.card.groupings[0]?.sort_order - itemB.card.groupings[0]?.sort_order
  } else {
    return 0
  }
}

export const searchFilterGenerator = (searchString: string) => {
  return (item: MessagesLandingApplicationMarketingCard | MessagesLandingPlatformMarketingCard | MessagesLandingApplicationProjectCard | MessagesLandingApplicationCard | MessagesLandingProjectCard) => {
    if (searchString && searchString !== "") {
      return JSON.stringify(item).includes(searchString)
    }
    return true
  }
}

export function CardsToMenu(responseBody: MessagesLandingApplicationProjectCard[] | MessagesLandingApplicationCard[] | MessagesLandingProjectCard[]): React.SetStateAction<TabMenuItem[]> {
  const groups: string[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const groupsSortOrder: any = {};
  responseBody.forEach(app => {
    app.card?.groupings?.forEach(grp => {
      if (grp.sort_order && grp.sort_order >= 0) {
        groups.push(grp.value);
        groupsSortOrder[grp.value] = grp.sort_order;
      }
    });
  });
  const groupsUniq = groups.filter((val, idx) => groups.indexOf(val) === idx);
  const groupsSorted = groupsUniq.sort((a, b) => groupsSortOrder[a] - groupsSortOrder[b]);

  return groupsSorted.map((x) => { return { title: x, key: x } as TabMenuItem; });
}