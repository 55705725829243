import "./DataAssets.scss"
import { Fragment, useCallback, useEffect, useState } from "react"
import { useContext } from "src/hooks"
import { useDotnetClient } from "src/contexts/DotnetClientContext"
import { MessagesDataAssetsTDataAssetActivity } from "@arq-apps/dotnet"
import { DataAssetLog, Collapsible, MultiCheckbox, SearchBox, SupportPaneSize, DataAssetSummary } from "@arq-apps/ux"
import { ErrorBoundary, Select } from "@arq-apps/ui"

const TAB_MENU = [
	"All",
	"Uploaded",
	"Final",
	"None"
]

interface DataAssetsProps {
	supportPaneSize: SupportPaneSize | undefined
	setSupportPaneSize: (size: SupportPaneSize | undefined) => void
	appId: number | null
}

const DataAssets = ({ supportPaneSize, setSupportPaneSize, appId }: DataAssetsProps) => {
	const { projectId } = useContext();
  	const {dmpClient} = useDotnetClient();
	const [loading, setLoading] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>();
	const [selectedTab, setSelectedTab] = useState<string>(TAB_MENU[0]);
	const [summary, setSummary] = useState<MessagesDataAssetsTDataAssetActivity[]>();
	const [entityTypes, setEntityTypes] = useState<Map<string, string[]>>();
	const [entityGroups, setEntityGroups] = useState<string[]>();
	const [selectedEntityType, setSelectedEntityType] = useState<string>();
	const [selectedEntities, setSelectedEntities] = useState<string[]>();
	const [selectedEntityId, setSelectedEntityId] = useState<number>();
	const [selectedTemplateId, setSelectedTemplateId] = useState<number>();

	const fetchSummary = async () => {
    // TODO - Error check for appId and projectId here
    setLoading(true);
    dmpClient?.dataAssetApi.getDataAssetApiGetTemplateSummary(appId ?? 0, projectId ?? 0)
      .then(result => {
        setSummary(result)
        deriveEntityTypes(result)
        const entitys = result.map(t => t.entity.entity_type.name);
				setEntityGroups(entitys.filter((x, i) => entitys?.indexOf(x) === i));
      })
      .catch(err => {
        console.warn(err)
      })
      .finally(() => setLoading(false))
	}

	useEffect(() => {
		fetchSummary();
	}, [])

	const deriveEntityTypes = (templates: MessagesDataAssetsTDataAssetActivity[]) => {
		if (!templates || templates.length === 0) { return }
		const derivedEntityTypes = new Map<string, string[]>();
		templates?.forEach((templateItem) => {
			const entityType = templateItem.entity.entity_type.name;
			if (derivedEntityTypes.has(entityType)) {
				const derivedEntities = derivedEntityTypes.get(entityType)
				if (!derivedEntities?.includes(templateItem.entity.name)) {
					derivedEntities?.push(templateItem.entity.name)
				}
				derivedEntityTypes.set(entityType, derivedEntities ?? [])
			} else {
				derivedEntityTypes.set(entityType, [templateItem.entity.name])
			}
		})

		setEntityTypes(derivedEntityTypes);

		const firstEntityType = derivedEntityTypes.entries().next().value
		setSelectedEntityType(firstEntityType[0])
		setSelectedEntities(firstEntityType[1])
	}

	const toggleEntitySelected = useCallback((option: string) => {
		setSelectedEntities(prev => {
			const currentSelections = prev ?? [];
			const filtered = currentSelections.filter(
				(it) => it !== option
			);

			return filtered.length < currentSelections.length
				? // option was filtered out
				filtered
				: // option must be added
				[...filtered, option]
		})
	}, []);

	const filterOnSelectedEntities = (template: MessagesDataAssetsTDataAssetActivity) =>
		supportPaneSize === SupportPaneSize.Min
		|| (selectedEntities && !!template.entity && selectedEntities?.includes(template.entity.name));

	const filterOnSelectedTab = (template: MessagesDataAssetsTDataAssetActivity) =>
		selectedTab === "All"
		|| template.state === selectedTab;

	const filterOnSearchTerm = (template: MessagesDataAssetsTDataAssetActivity) =>
		!searchString
		|| searchString === ""
		|| JSON.stringify(template).toLowerCase().includes(searchString.toLowerCase());

	const onSelectTemplate = (entityId: number, templateId: number, entityType: string) => {
		setSelectedEntityId(entityId);
		setSelectedTemplateId(templateId);

		if (supportPaneSize === SupportPaneSize.Min) {
			setSelectedEntityType(entityType);
			setSelectedEntities(entityTypes?.get(entityType));
		}

		setSupportPaneSize(SupportPaneSize.Max);
	}

	const newLastActivity = (activity: MessagesDataAssetsTDataAssetActivity) => {
		if (!summary)
			return;
		const oldSummary = summary;
		const found = oldSummary?.findIndex(x => x.data_asset.id === selectedTemplateId && x.entity.id === selectedEntityId);
		if (found !== -1) {
			oldSummary[found] = activity;
			setSummary([...oldSummary]);
		}

	}

	const numberOfFiles = () => {
		if (!summary)
			return '';

		const summaryFiltered = summary?.filter(filterOnSelectedEntities);
		const total = summaryFiltered.length ?? 0
		const string = total === 1 ? "file" : "files"

		if (selectedTab === "All")
			return `${total} ${string}`
		else {
			const filtered = summaryFiltered.filter(filterOnSelectedTab).length ?? 0;
			return `${filtered}/${total} ${string}`
		}
	}

	const classes = [
		"ux-templates-layout",
		supportPaneSize
	].filter(Boolean).join(" ");

	return (
		<div className={classes} key="pane1">
			<ErrorBoundary>

				{supportPaneSize === SupportPaneSize.Max &&
					<div className="entity-template-selector">
						<Select
							options={Array.from(entityTypes?.keys() ?? [])}
							selected={selectedEntityType}
							onOptionSelected={(value) => {
								setSelectedEntityType(value)
								setSelectedEntities(entityTypes?.get(value))
							}}
							placeholder={"Entity Type"}
						/>
						{selectedEntityType && <MultiCheckbox
							options={entityTypes?.get(selectedEntityType) ?? []}
							selected={selectedEntities ?? []}
							onOptionClicked={toggleEntitySelected}
						/>}
					</div>}

				<div className="ux-templates" key="pane2">
					<div className="tabs">
						{TAB_MENU.map(menuItem =>
							<Fragment key={menuItem}>
								<div
									className={`tab ${selectedTab === menuItem ? "selected" : ""}`}
									onClick={() => setSelectedTab(menuItem)}
									title={menuItem}>
									{menuItem}
								</div>
								<div>|</div>
							</Fragment>
						)}
					</div>
					<SearchBox searchString={searchString} setSearchString={setSearchString} key="searchTemplates" />
					<div className="file-count" key="file_count">{numberOfFiles()}</div>
					{entityTypes && entityGroups && (
						supportPaneSize === SupportPaneSize.Min ?
							entityGroups.map((entityTypeKey) => {
								return (
									<ErrorBoundary key={entityTypeKey}>
										<Collapsible title={entityTypeKey} defaultCollapsed={false} key={entityTypeKey}>
											{summary?.filter(x => x.entity.entity_type.name === entityTypeKey)
												.filter(filterOnSelectedEntities)
												.filter(filterOnSelectedTab)
												.filter(filterOnSearchTerm).map((template) => {
													return (
														<DataAssetSummary
															{...template}
															selected={template.data_asset.id === selectedTemplateId && template.entity.id === selectedEntityId}
															onClick={onSelectTemplate}
															appId={appId}
															key={`${template.data_asset.id}-${template.entity.id}`}
														/>
													);
												}
												)
											}
										</Collapsible>
									</ErrorBoundary>
								);
							}
							)
							:
							<ErrorBoundary key="eb2">
								{summary?.filter(filterOnSelectedEntities)
									.filter(filterOnSelectedTab)
									.filter(filterOnSearchTerm).map((template) => {
										return (
											<DataAssetSummary
												{...template}
												selected={template.data_asset.id === selectedTemplateId && template.entity.id === selectedEntityId}
												onClick={onSelectTemplate}
												appId={appId}
												key={`${template.data_asset.id}-${template.entity.id}`}
											/>
										);
									}
									)}
							</ErrorBoundary>
					)}
					{loading && !summary && <div>Loading...</div>}
					{!loading && !summary && <div>No templates for user</div>}
				</div>
				{supportPaneSize === SupportPaneSize.Max &&
					<ErrorBoundary key="eb3">
						<DataAssetLog appId={appId} entityId={selectedEntityId} dataAssetId={selectedTemplateId} newLastActivity={newLastActivity} key="pane3" />
					</ErrorBoundary>
				}
			</ErrorBoundary>
		</div>
	)
}

export default DataAssets;