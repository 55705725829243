import "./Logo.scss";
import { AppLink, NavLinkTo } from "../Link";
import { NavLink } from "@arq-apps/generated";

export interface LogoProps {
  title?: string;
  onClick?: () => void;
}

export function Logo(props: LogoProps) {
  const logoTitle = props.title || "Data Modelling Platform";

  const homeLink: NavLink = {
    __typename: "NavLink",
    pageId: "landing",
    appId: undefined,
    projectId: undefined,
  };

  const homeTo: NavLinkTo = {
    pathname: "/landing",
    search: "",
    active: false,
    childActive: false,
  };

  return (
    <div className="logo">
      <div className="image">
        <AppLink className="brand-logo" {...homeLink} to={homeTo}>
          <img
            alt="Data Modelling Platform"
            src="/assets/logos/pwc-logo.svg"
			onClick={() => props.onClick && props.onClick()}
          ></img>
        </AppLink>
      </div>
      <div className="name">{logoTitle}</div>
    </div>
  );
}
