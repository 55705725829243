
export const PWC_ID_SCOPES = [
  'openid',
  'address',
  'aduidscope',
  'businessunit',
  'cloudEmail',
  'costcenter',
  'country',
  'countrycode',
  'email',
  'employeeNumber',
  'employeetype',
  'GlobalGradeCode',
  'groups',
  'isMemberOf',
  'jobtitle',
  'locality',
  'LOS',
  'organization',
  'phone',
  'phone_number',
  'postalcode',
  'preferredLanguage',
  'preferredMail',
  'profile',
  'PwCassignedofficecode',
  'pwcGlobalGradeAbbreviatedName',
  'PwClos',
  'pwcPartyID',
  'PwCPPI',
  'PwCStaffClass',
  'pwcWorkerID',
  'role',
  'splitgroups',
  'staffnumber',
  'stateorprovince',
  'street',
  'uid',
  'upwcjobtitle',
  'windowsaccountname',
];

export class PwCIDConfig {

  readonly redirectUrl: string;
  readonly scopes: string[];
  readonly scopesJoined: string;

  constructor(
    readonly host: string,
    readonly providerUrl: string,
    readonly clientId: string,
    readonly debug: boolean = false,
    readonly production: boolean = false,
    // readonly redirectUrl: string = `${host}/oauth`,
  ) {
    this.redirectUrl = `${host}/oauth`;
    this.scopes = PWC_ID_SCOPES;
    this.scopesJoined = this.scopes.join(' ');
  }

  authUrl(postLoginRedirectUrl?: string | undefined): string {
    const state = postLoginRedirectUrl; // `state` input parameter is preserved in authentication response
    return `${this.providerUrl}?`
      + `response_type=code`
      + `&scope=${this.scopesJoined}`
      + `&client_id=${this.clientId}`
      + `&redirect_uri=${this.redirectUrl}`
      + (state!! ? `&state=${state}` : '')
    ;
  }

  redirectBrowserToAuthUrl(postLoginRedirectUrl?: string | undefined): void {
    window.location.href = this.authUrl(postLoginRedirectUrl);
  }

  static forHost(host: string): PwCIDConfig {
    // TODO config should be provided by backend (public resources)
    switch (host) {
      case 'https://datamodellingplatform.africa.pwc.com':
        return new PwCIDConfig(
          host,
          'https://login.pwc.com/openam/oauth2/authorize',
          'urn:datamodellingplatform.africa.pwc.com',
          false,
          true,
        );
      case 'https://apps.dev.arq.pwcglb.com':
        return new PwCIDConfig(
          host,
          'https://login-stg.pwc.com/openam/oauth2/authorize',
          'urn:apps.dev.arq.pwcglb.com',
          true,
          true,
        );
      case 'https://eu.datamodellingplatform.africa.pwcglb.com':
      case 'https://next.eu.datamodellingplatform.africa.pwcglb.com':
      case 'https://prev.eu.datamodellingplatform.africa.pwcglb.com':
      case 'https://stag.eu.datamodellingplatform.africa.pwcglb.com':
      case 'https://dev.eu.datamodellingplatform.africa.pwcglb.com':
        return new PwCIDConfig(
          host,
          'https://login.pwc.com/openam/oauth2/authorize',
          'urn:eu.datamodellingplatform.africa.pwcglb.com',
          true,
          true,
        );
      case 'https://eu.datamodellingplatform.africa.pwc.com':
      case 'https://next.eu.datamodellingplatform.africa.pwc.com':
      case 'https://prev.eu.datamodellingplatform.africa.pwc.com':
      case 'https://stag.eu.datamodellingplatform.africa.pwc.com':
      case 'https://dev.eu.datamodellingplatform.africa.pwc.com':
        return new PwCIDConfig(
          host,
          'https://login.pwc.com/openam/oauth2/authorize',
          'urn:eu.datamodellingplatform.africa.pwc.com',
          false,
          true,
        );
      case 'https://za.datamodellingplatform.africa.pwcglb.com':
      case 'https://next.za.datamodellingplatform.africa.pwcglb.com':
      case 'https://prev.za.datamodellingplatform.africa.pwcglb.com':
      case 'https://stag.za.datamodellingplatform.africa.pwcglb.com':
      case 'https://dev.za.datamodellingplatform.africa.pwcglb.com':
        return new PwCIDConfig(
          host,
          'https://login.pwc.com/openam/oauth2/authorize',
          'urn:za.datamodellingplatform.africa.pwcglb.com',
          true,
          true,
        );
      case 'https://za.datamodellingplatform.africa.pwc.com':
      case 'https://next.za.datamodellingplatform.africa.pwc.com':
      case 'https://prev.za.datamodellingplatform.africa.pwc.com':
      case 'https://stag.za.datamodellingplatform.africa.pwc.com':
      case 'https://dev.za.datamodellingplatform.africa.pwc.com':
        return new PwCIDConfig(
          host,
          'https://login.pwc.com/openam/oauth2/authorize',
          'urn:za.datamodellingplatform.africa.pwc.com',
          false,
          true,
        );
      default:
        console.warn('using fallback openid config', host);
        return new PwCIDConfig(
          host,
          // 'https://login-stg.pwc.com/openam/oauth2/authorize',
          // 'urn:apps.dev.arq.pwcglb.com',
          'https://login.pwc.com/openam/oauth2/authorize',
          'urn:za.datamodellingplatform.africa.pwcglb.com',
          true,
          false,
        );
    }
  }

}

const host = window.location.origin;
export const PWC_ID_CONFIG: PwCIDConfig = PwCIDConfig.forHost(host);
