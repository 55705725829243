import { MouseEvent, useCallback } from "react";
import "./IconButton.scss";
import { IconName } from "../Theme";
import { Icon } from "../Icon";

// TODO memo() to configure as pure with shallow comparisons
//  https://reactjs.org/docs/react-api.html#reactmemo

/* eslint-disable-next-line */
export interface IconButtonProps {
  onClick?: (event: MouseEvent<HTMLElement>) => void,
  icon: IconName,
  hoverIcon?: IconName,
  toggleState?: boolean,
  toggleIcon?: IconName,
  toggleHoverIcon?: IconName,
  disableFill?: boolean,
  focusable?: boolean,
  scale?: number,
  disabled?: boolean,
}

export function IconButton(props: IconButtonProps) {

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    if (props.onClick) {
      props.onClick(event);
    }
    event.preventDefault();
  }, [props.onClick]);

  const classes = [
    'icon-button',
    props.disableFill ? '' : 'icon-button-fill',
  ].filter(Boolean).join(' ');

  return (
    props.focusable ? (
      <button className={classes} type="button" onClick={handleClick} disabled={props.disabled}>
        <Icon icon={props.toggleState ? (props.toggleIcon ?? props.icon) : props.icon}
          hoverIcon={props.toggleState ? (props.toggleHoverIcon ?? props.hoverIcon) : props.hoverIcon}
          scale={props.scale ?? 1}
        />
      </button>
    ) : (
      <div className={classes} onClick={handleClick}>
        <Icon icon={props.toggleState ? (props.toggleIcon ?? props.icon) : props.icon}
          hoverIcon={props.toggleState ? (props.toggleHoverIcon ?? props.hoverIcon) : props.hoverIcon}
          scale={props.scale ?? 1}
        />
      </div>
    )
  );
}

export default IconButton;
