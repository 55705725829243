import './Page.scss';
import { memo, useEffect } from "react";
import { AppLink } from "../Link";
import { Centered } from "../Centered";
import { usePage } from "../../hooks/usePage";
import { Panel } from "../Panel";
import { QuickLink } from "../QuickLink";
import { QuickLinksFragment } from "../QuickLink/QuickLink.generated";
import { Filters } from '../Filters';
import { useFooterHeight, usePageFilterContext } from 'src/hooks';
import { useHierarchicalFilterContext } from 'src/contexts/HierarchicalFilterContext/HierarchicalFilterContext'
import HierarchicalFilter from '../HierarchicalFilter/HierarchicalFilter'

export interface PageProps {
  managePage?: boolean
}

export const Page = memo(function Page(props: PageProps) {
  const {pageLoading, page} = usePage();
  const {primaryPageFilters, updatePrimaryPageFilters, selectClear, multiSelect, multiSelectAll, multiClearAll} = usePageFilterContext();
  const {hierarchicalFilters} = useHierarchicalFilterContext();
  const footerHeight = useFooterHeight();
  const headerHeight = 43;
  
  const classes = [
    'ux-page',
    props.managePage && "manage"
  ].filter(Boolean).join(' ');

  if (pageLoading) {
    return (
      <Centered>
        <span>Loading... please wait</span>
      </Centered>
    );
  }

  if (!page) {
    return (
      <Centered>
        <span>Page not available</span>
      </Centered>
    );
  }

  return (
    <div className={classes} style={!props.managePage ? {
      height: `calc(100vh - ${footerHeight}px - ${headerHeight}px)`,
      backgroundImage: `url("${ page.background?.imageUrl }")`
    } : {}}>
      {/*Page: { pageId } (app: { appId })*/ }
      {((hierarchicalFilters && hierarchicalFilters.length > 0)
       || (primaryPageFilters && primaryPageFilters?.length > 0 )) ?
        <aside className="topbar">
          {hierarchicalFilters && hierarchicalFilters.length > 0 ?
           <div className="hierarchical-filter-panel">
            {hierarchicalFilters.map((filter) => <HierarchicalFilter key={filter.id} {...filter}/>)}
          </div>
          :
          <div></div>
          }
          {primaryPageFilters && primaryPageFilters?.length > 0 &&
            <Filters 
              topbarFilter={true}
              filters={primaryPageFilters ?? []} 
              updateFilters={updatePrimaryPageFilters}
              primaryFilters={true}
              selectClear={selectClear}
              multiSelect={multiSelect}
              multiClearAll={multiClearAll}
              multiSelectAll={multiSelectAll}
            />
          }
        </aside>
        :
        <aside></aside>
      }
      <div className="scrollable-context">
        <div className="page-content">
          <div>
            { page.banner && (
              <div className="banner-wrapper">
                { page.banner.title && (
                  <div className="banner-title">
                    { page.banner.title }
                  </div>
                ) }
                { page.banner.subtitle && (
                  <div className="banner-subtitle">
                    { page.banner.subtitle }
                  </div>
                ) }
              </div>
            ) }
            { page?.title ? (
              <div className="title-wrapper">
                <div className="title">
                  { page.title }
                </div>
              </div>
            ) : (<div></div>) }
            { page.link && (
              <div className="page-link">
                <AppLink { ...page.link }>
                  { page.link.label }
                </AppLink>
              </div>
            ) }
          </div>
          <div className="panels">
            { page?.panels?.map((panel, panelIdx) => (
              <Panel key={ `panel-${ panelIdx }` } { ...panel } />
            )) }
          </div>
          {/* TODO @RM - rework page layout for this empty div */}
          <div></div>
          <div>
            { page.quickLinks?.links && (
              <QuickLinks { ...page.quickLinks } />
            ) }
          </div>
        </div>
      </div>
    </div>
  );
});


function QuickLinks(props: QuickLinksFragment) {
  if (!props.links) {
    return <></>;
  }
  // TODO use radix scroll-area. Adds keyboard, accessibility, scrollbar, etc
  return (
    <div className="ux-quick-links">
      { props.links.map(ql =>
        // TODO what is the correct 'key' to use here?
        <QuickLink key={ ql.title } {...ql} />
      ) }
    </div>
  );
}