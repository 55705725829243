import './Menu.scss';
import { AppLink } from "../Link";
import { useCallback, useState } from "react";
import { IconButton } from '@arq-apps/ui';
import { MessagesLandingMenu, MessagesLandingMenuItem } from 'src/dotnet-client'
import { NavLink } from '@arq-apps/generated'
import { useLandingContext } from 'src/contexts/LandingContext'

export type MenuProps = MessagesLandingMenu;

export function Menu(props: MenuProps) {
  const classes = [
    "ux-menu",
  ].filter(Boolean).join(" ");

  return (
    <div className={ classes }>
      { props.items?.map((it, index) => (
        <MenuItem key={index} { ...it } />
      )) }
    </div>
  );
}

interface MenuItemProps extends MessagesLandingMenuItem {
  level?: number,
  activeParent?: boolean,
}

function MenuItem(props: MenuItemProps) {
  const level = props.level ?? 1;  // expect 1/2/3
  const [active, setActive] = useState<boolean>(false);
  const [activeChild, setActiveChild] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const {appId, appKey, projectId} = useLandingContext();

  const toggleActive = useCallback((active: boolean, activeChild: boolean) => {
    setActive(active);
    setActiveChild(activeChild);
  }, []);

  const link: NavLink = {
    __typename: "NavLink",
    label: props.name,
    pageId: props.page_id,
    appId: appKey,
    projectId: projectId,
    setParams: null,
    toggleParams: null
  };

  return (
    <>
      <AppLink
        { ...link }
        onActiveStateChange={ toggleActive }
        className={ `ux-menu-item level-${ level } ${props.activeParent ? 'active-parent' : ''}` }
      >
        { link.label }
        { (props.items && props.items?.length > 0 && level === 1) && 
          <IconButton 
            icon={active || activeChild || expanded ? "up-chevron-outline" : "down-chevron-outline"}
            disableFill 
            onClick={() => {
                setExpanded(!expanded)
            }}
          /> 
        }
      </AppLink>
      { (active || activeChild || expanded) && (
        <>
          { props.items?.map((it, index) => (
            <MenuItem key={index} { ...it } activeParent={active || activeChild || props.activeParent} level={ level + 1 }/>
          )) }
        </>
      ) }
    </>
  );
}
