import { Button } from "@arq-apps/ui"
import { useEditableMenu } from "src/contexts/EditableMenuContext"
import { AddEditMenuItem } from "src/ux/Menu/AddEditMenuItem"
import { EditableMenu } from "src/ux/Menu/EditableMenu"
import { PreconfigureMenu } from "./PreconfigureMenu"

// using excessive divs and classnames so that the look and feel stays consistent if graphql pages change
export function MaintainMenu() {
  const {postMenu} = useEditableMenu();

  return (
    <div className="ux-page manage">
      <div className="panels">
        <div className="ux-panel">
          <div className="tiles">
            <div className="ux-tile">
              <div className="ux-form">
                <PreconfigureMenu />
              </div>
            </div>
          </div>
        </div>
        <div className="ux-panel">
          <div className="tiles">
            <div className="ux-tile">
              <div className="ux-form">
                <EditableMenu />
              </div>
            </div>
            <div className="ux-tile">
              <div className="ux-form">
                <AddEditMenuItem />
              </div>
            </div>
          </div>
        </div>
        <div className="ux-panel">
          <div className="tiles">
            <div className="ux-tile">
              <div className="ux-form">
                <div>
                  <Button text='Upload menu' onClick={postMenu} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}