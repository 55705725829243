import { useLayoutEffect, useState } from "react"

export function useFooterHeight() {
  const DEFAULT_HEIGHT = 75;
  const [height, setHeight] = useState<number>();
  useLayoutEffect(() => {
    function updateHeight() {
      setHeight(document.getElementById("ui-footer")?.offsetHeight);
    }
    window.addEventListener('resize', updateHeight);
    updateHeight();
    return () => window.removeEventListener('resize', updateHeight);
  }, []);
  return (height ?? DEFAULT_HEIGHT) + 1
}