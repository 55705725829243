import "./index.scss";
import { createRoot } from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import { Root } from "@arq-apps/ux";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { apolloClient } from "./apollo/client";
import { LandingControl } from "./ui";
import { DotnetClientProvider } from "./contexts/DotnetClientContext"
import { LandingContextProvider } from "./contexts/LandingContext"
import { BrandVariants, createLightTheme, FluentProvider, Theme } from "@fluentui/react-components"
import {SubscriptionControl} from "./ui/SubscriptionControl/SubscriptionControl";
import { ToastContainer } from "react-toastify"
import { ImageControl } from "./ui/ImageControl/ImageControl"

const container = document.getElementById('root')!;
const root = createRoot(container);

const pwcOrangeVariants: BrandVariants = { 
  10: "#0A0100",
  20: "#310803",
  30: "#520205",
  40: "#690006",
  50: "#7F0005",
  60: "#950903",
  70: "#A81B01",
  80: "#B92E00",
  90: "#C94000",
  100: "#D75306",
  110: "#E36715",
  120: "#ED7B28",
  130: "#F58F3D",
  140: "#FCA456",
  150: "#FFB975",
  160: "#FFCE9C"
};

const pwcLightTheme: Theme = {
   ...createLightTheme(pwcOrangeVariants), 
   colorBrandBackground: pwcOrangeVariants[100],
   colorBrandBackgroundInvertedSelected: pwcOrangeVariants[140],
   fontFamilyBase: "unset",
   lineHeightBase300: "unset",
  //  colorNeutralBackground1: "unset"
  //   colorBrandForeground1: pwcOrangeVariants[110],
  //   colorBrandForeground2: pwcOrangeVariants[130]
};

root.render(
  <ApolloProvider client={ apolloClient }>
    <BrowserRouter>
      {/* <Root /> */}
      <DotnetClientProvider>
        <LandingContextProvider>
          <FluentProvider theme={pwcLightTheme}>
            <div className="ux-root">
              {/* <Root /> */}
              <Routes>
                <Route path="/landing" Component={LandingControl}/>
                <Route path="/subscriptions" Component={SubscriptionControl} />
                <Route path="/images" Component={ImageControl} />
                <Route 
                  path="*"
                  Component={Root}
                />
              </Routes>
            </div>
            <ToastContainer />
          </FluentProvider>
        </LandingContextProvider>
      </DotnetClientProvider>
    </BrowserRouter>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
