import './ContextPane.scss';
import { useFooterHeight } from "../../hooks";
import { Menu } from "../Menu";
import SupportMenu from '../SupportMenu/SupportMenu';
import { useLandingContext } from 'src/contexts/LandingContext';
import { useEffect, useState } from 'react';
import { useDotnetClient } from 'src/contexts/DotnetClientContext';
import { MessagesLandingMenu } from 'src/dotnet-client';

export interface ContextPaneProps {
  setSupport: (key: string) => void;
}

export function ContextPane(props: ContextPaneProps) {
  const {dmpClient} = useDotnetClient();
  const {appId, projectId, projectName, projectOrg} = useLandingContext();
  const [menu, setMenu] = useState<MessagesLandingMenu>();


  useEffect(() => {
    dmpClient?.landingApi.getLandingApiGetPlatformOperations(appId, projectId, "menu")
      .then(result => setMenu(result))
      .catch(err => console.warn(err))
  }, [appId])
  
  const classes = [
    "ux-context-pane",
  ].filter(Boolean).join(" ");

  const footerHeight = useFooterHeight();
  const headerHeight = 43;

  return (
    <div className={ classes } style={{height: `calc(100vh - ${footerHeight}px - ${headerHeight}px)`}}>
      <header className="header">
        <div className="project-org">{ projectOrg }</div>
        <div className="project-name">{ projectName }</div>
      </header>
      <div className="hr"></div>
      <section className="menu">
        { menu && <Menu {...menu} /> }
      </section>
      <div className="hr"></div>
      <SupportMenu setSupport={props.setSupport}/>
    </div>
  );
}
