/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesLandingApplicationMarketingCard } from '../models/MessagesLandingApplicationMarketingCard';
import type { MessagesStorageFileRequirements } from '../models/MessagesStorageFileRequirements';
import type { MessagesStorageStorageDetailItem } from '../models/MessagesStorageStorageDetailItem';
import type { MessagesStorageStorageFileType } from '../models/MessagesStorageStorageFileType';
import type { MessagesStorageStorageList } from '../models/MessagesStorageStorageList';
import type { MessagesStorageStorageMigrationJob } from '../models/MessagesStorageStorageMigrationJob';
import type { MessagesStorageStorageSummaryItem } from '../models/MessagesStorageStorageSummaryItem';
import type { MessagesStorageStorageTransfer } from '../models/MessagesStorageStorageTransfer';
import type { SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues } from '../models/SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues';
import type { SystemNetHttpHttpResponseMessage } from '../models/SystemNetHttpHttpResponseMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StorageApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param applicationId
     * @param templateId
     * @returns MessagesStorageFileRequirements OK
     * @throws ApiError
     */
    public postStorageApiStorageFileRequirements(
        applicationId?: number,
        templateId?: number,
    ): CancelablePromise<MessagesStorageFileRequirements> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/storage/fileRequirements',
            query: {
                'applicationId': applicationId,
                'templateId': templateId,
            },
        });
    }

    /**
     * @param formData
     * @returns MessagesStorageStorageTransfer OK
     * @throws ApiError
     */
    public postStorageApiUploadFile(
        formData?: {
            formData?: Array<SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues>;
        },
    ): CancelablePromise<MessagesStorageStorageTransfer> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/storage/upload_file',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param blobName
     * @param applicationId
     * @param projectId
     * @param fileType
     * @returns SystemNetHttpHttpResponseMessage OK
     * @throws ApiError
     */
    public getStorageApiDownloadFile(
        blobName: string,
        applicationId: number,
        projectId: number,
        fileType: MessagesStorageStorageFileType,
    ): CancelablePromise<SystemNetHttpHttpResponseMessage> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/storage/download_file/{blobName},{applicationId},{projectId},{fileType}',
            path: {
                'blobName': blobName,
                'applicationId': applicationId,
                'projectId': projectId,
                'fileType': fileType,
            },
        });
    }

    /**
     * @param applicationId
     * @param projectId
     * @returns MessagesLandingApplicationMarketingCard OK
     * @throws ApiError
     */
    public getStorageApiListFiles(
        applicationId: number,
        projectId: number,
    ): CancelablePromise<Array<MessagesLandingApplicationMarketingCard>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/storage/list_files/{applicationId},{projectId}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
            },
        });
    }

    /**
     * Get a summary of storage/files, including counts per type
     * @param applicationId
     * @param projectId
     * @returns MessagesStorageStorageSummaryItem OK
     * @throws ApiError
     */
    public getStorageApiGetFilesSummary(
        applicationId: number,
        projectId: number,
    ): CancelablePromise<Array<MessagesStorageStorageSummaryItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/storage/summary/{applicationId},{projectId}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
            },
        });
    }

    /**
     * Get a detailed list of files for a type, including filename, size, current indicator
     * @param applicationId
     * @param projectId
     * @param type
     * @returns MessagesStorageStorageDetailItem OK
     * @throws ApiError
     */
    public getStorageApiGetFilesDetails(
        applicationId: number,
        projectId: number,
        type: MessagesStorageStorageFileType,
    ): CancelablePromise<Array<MessagesStorageStorageDetailItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/storage/details/{applicationId},{projectId},{type}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
                'type': type,
            },
        });
    }

    /**
     * Deletes all files of a particular type (for project/application)
     * @param applicationId
     * @param projectId
     * @param type
     * @returns MessagesStorageStorageList OK
     * @throws ApiError
     */
    public getStorageApiDeleteAll(
        applicationId: number,
        projectId: number,
        type: MessagesStorageStorageFileType,
    ): CancelablePromise<Array<MessagesStorageStorageList>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/storage/delete-all/{applicationId},{projectId},{type}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
                'type': type,
            },
        });
    }

    /**
     * Deletes all "unused" files of a particular type (for project/application)
     * eg. old template uploads
     * @param applicationId
     * @param projectId
     * @param type
     * @returns MessagesStorageStorageList OK
     * @throws ApiError
     */
    public getStorageApiDeleteUnused(
        applicationId: number,
        projectId: number,
        type: MessagesStorageStorageFileType,
    ): CancelablePromise<Array<MessagesStorageStorageList>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/storage/delete-unused/{applicationId},{projectId},{type}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
                'type': type,
            },
        });
    }

    /**
     * Migrates files for a project/application from the old storage format to the new storage format
     * Old: All files as blobs in a single container
     * New: Files structured in "folders"
     * @param applicationId
     * @param projectId
     * @param apply With this set to false, no action will be performed, only old files enumerated to see if everything can be identified/migrated.
     * @returns MessagesStorageStorageMigrationJob OK
     * @throws ApiError
     */
    public getStorageApiMigrate(
        applicationId: number,
        projectId: number,
        apply: boolean,
    ): CancelablePromise<MessagesStorageStorageMigrationJob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/storage/migrate/{applicationId},{projectId},{apply}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
                'apply': apply,
            },
        });
    }

    /**
     * Gets details of job busy being applied
     * @param applicationId
     * @param projectId
     * @param jobId
     * @returns MessagesStorageStorageMigrationJob OK
     * @throws ApiError
     */
    public getStorageApiGetMigrationJob(
        applicationId: number,
        projectId: number,
        jobId: string,
    ): CancelablePromise<MessagesStorageStorageMigrationJob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/storage/migrate/{applicationId},{projectId},{jobId}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
                'jobId': jobId,
            },
        });
    }

}
