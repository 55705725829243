import './Select.scss';
import * as S from '@radix-ui/react-select';
import { Icon } from "@arq-apps/ui";
import { SelectFieldFragment } from "../../ui/Field/Field.generated";
import { SelectOption } from "@arq-apps/generated";
import { useCallback } from "react";

function ChevronUpIcon() {
  return <Icon icon="up-chevron-outline"/>;
}

function ChevronDownIcon() {
  return <Icon icon="down-chevron-outline" />;
}

export interface SelectProps<E> extends SelectFieldFragment {
  onOptionSelected: (id: string, option: SelectOption | null, extra: E | null) => void,
  extra?: E,
}

export function Select<E>(props: SelectProps<E>) {
  // https://www.radix-ui.com/docs/primitives/components/select
  
  const selectValue = useCallback((value: string) => {
    const option = props.options?.find(it => it.value === value) ?? null;
    props.onOptionSelected(props.id, option, props.extra ?? null);
  }, [props.id, props.options, props.extra]);

  const SELECT_NONE = "__select-none__";

  return (
    <div className="ux-select">
      <S.Root onValueChange={ selectValue } value={ props.localSelection?.value ?? props.selection?.value ?? SELECT_NONE}>
          <S.Trigger aria-label="Select" >
            <S.SelectValue placeholder={ props.label } />
            <S.SelectIcon>
              <ChevronDownIcon />
            </S.SelectIcon>
          </S.Trigger>
          <S.Portal className="ux-select-portal">
            <S.Content position="popper">
              {/* <S.SelectScrollUpButton>
                <ChevronUpIcon />
              </S.SelectScrollUpButton> */}
              <S.Viewport>
                <S.SelectItem value={ SELECT_NONE } className="hidden">
                  <S.SelectItemText>
                    { props.label }
                  </S.SelectItemText>
                </S.SelectItem>
                { props.options?.map(it => (
                  <S.SelectItem key={ it.value } value={ it.value } >
                    <S.SelectItemText>
                      { it.label }
                    </S.SelectItemText>
                  </S.SelectItem>
                )) }
              </S.Viewport>
              {/* <S.SelectScrollDownButton>
                <ChevronDownIcon/>
              </S.SelectScrollDownButton> */}
            </S.Content>
          </S.Portal>
      </S.Root>
    </div>
  );
}
