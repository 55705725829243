import { Logo, UserWidget } from "@arq-apps/ux";
import { Footer } from "@arq-apps/ui";
import { useDotnetClient } from "src/contexts/DotnetClientContext"
import { useEffect, useMemo, useState } from "react"
import { MessagesSupportImageItem } from "@arq-apps/dotnet"
import { DataGridComponent } from "../DataGrid/DataGridComponent"
import { TableCellLayout, TableColumnDefinition, createTableColumn } from "@fluentui/react-components";

type LabelValueCell = {
  label: string;
  value: string;
}

export type ImageItem = {
  filename: LabelValueCell
}

export const ImageControl = () => {
  const { dmpClient } = useDotnetClient();
  const [images, setImages] = useState<MessagesSupportImageItem[]>([]);

  const fetchImages = async () => {
    dmpClient?.supportApi.getSupportApiListImages()
      .then((result) => {
        setImages(result)
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchImages()
  }, [])

  const items = useMemo(() => {
    return images.map((image) => {
      return {
        filename: {label: image.filename, value: image.filename},
      }
    })
  }, [images])

  const columns: TableColumnDefinition<ImageItem>[] = [
    createTableColumn<ImageItem>({
      columnId: "filename",
      compare: (a, b) => {
        return a.filename.label.localeCompare(b.filename.label);
      },
      renderHeaderCell: () => {
        return "Filename";
      },
      renderCell: (item) => {
        return item.filename.label
      },
    })
  ];

  return (
    <div className="subscriptions">
      <div className="header">
        <Logo title="Image library"/> 
        <UserWidget />
      </div>
     
      <div style={{padding: "20px", backgroundColor: "#F3F3F3", overflowY: "scroll"}}>
        <DataGridComponent 
          items={items}
          columns={columns}
        />
      </div>
      <Footer />
    </div>
  )
}