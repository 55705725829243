import './SearchBox.scss';
import { Icon } from "@arq-apps/ui";


export interface SearchBoxProps {
  searchString: string | undefined;
  setSearchString: (searchString: string) => void;
}

export const SearchBox: React.FC<SearchBoxProps> = ({searchString, setSearchString}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  }

  return (
    <div className="search-box">
      <Icon icon="search-outline" scale={1.1}/>
      <input className="search-string"
        value={searchString}
        onChange={handleChange}
        placeholder={"Search"}
      />
    </div>
  );
}