import { gql } from "@apollo/client";
import * as types from "@arq-apps/generated";

export const DEFAULT_CONTEXT: types.Context = {
  __typename: "Context",
  // token: null,
  appId: "",
  pageId: "",
  projectId: null,
  supportPageId: null
};

export const contextSchema = gql`

  extend type Query {  # TODO extend App / Platform rather?
    localContext: Context! @client
  }

  type Context {
    #token: String,
    appId: ID!,
    pageId: ID!,
    projectId: Int,
    supportPageId: ID
  }

`;

export const contextTypePolicies: types.StrictTypedTypePolicies = {
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
  // TODO specify cache keys OR empty lists if singletons
  Query: {
    fields: {
      localContext: {
        keyArgs: false,
        read(cached: Partial<types.Context> = DEFAULT_CONTEXT) {
          return cached;
        },
        merge(
          cached: Partial<types.Context> = DEFAULT_CONTEXT,
          incoming: Partial<types.Context> = {},
          { mergeObjects },
        ) {
          console.debug('localContext::merge', cached, incoming);
          // if (incoming && incoming.token) {
          //   sessionStorage.setItem("access", incoming.token);
          // }
          return mergeObjects(cached, incoming);
        }
      },
      app: {
        merge(
          cached,
          incoming,
          { mergeObjects },
        ) {
          return mergeObjects(cached, incoming);
        }
      }
    },
  },
  App: {
    merge(
      cached,
      incoming,
      { mergeObjects },
    ) {
      return mergeObjects(cached, incoming);
    }
  }
}

//TODO @RM - understand this :)
