import { gql } from "@apollo/client";
import * as types from "@arq-apps/generated";

export const DEFAULT_SESSION: types.Session = {
  __typename: "Session",
  token: null,
  expiresAt: null,
};

export const sessionSchema = gql`

  extend type Query {
    localSession: Session! @client
  }

  type Session {
    token: String,
    expiresAt: Int,
  }

`;

interface Claims {
  sub?: number,
  iat?: number,
  exp?: number,
}

export const sessionTypePolicies: types.StrictTypedTypePolicies = {
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
  // TODO specify cache keys OR empty lists if singletons
  Query: {
    fields: {
      localSession: {
        keyArgs: false,
        read(_) {
          const token = sessionStorage.getItem("token");
          try {
            const claimsStr = token
              ? atob(token.split(".")[1])
              : ""
            ;
            const { sub, iat, exp }: Claims = JSON.parse(claimsStr);
            return {
              token: token,
              expiresAt: exp ?? null,
            };
          } catch (error) { }
          return {...DEFAULT_SESSION};
        },
      }
    },
  },
}
