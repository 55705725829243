import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
export type Content_Chart_Fragment = { __typename?: 'Chart' };

export type Content_DataTemplate_Fragment = { __typename?: 'DataTemplate' };

export type Content_Form_Fragment = { __typename?: 'Form', id: string };

export type Content_Metric_Fragment = { __typename?: 'Metric', id: string };

export type Content_Table_Fragment = { __typename?: 'Table', id: string };

export type Content_VegaLiteChart_Fragment = { __typename?: 'VegaLiteChart', id: string };

export type ContentFragment = Content_Chart_Fragment | Content_DataTemplate_Fragment | Content_Form_Fragment | Content_Metric_Fragment | Content_Table_Fragment | Content_VegaLiteChart_Fragment;

export const ContentFragmentDoc = gql`
    fragment Content on Content {
  ... on VegaLiteChart {
    id
  }
  ... on Form {
    id
  }
  ... on Metric {
    id
  }
  ... on Table {
    id
  }
}
    `;