import './Input.scss';
import { IconButton } from "@arq-apps/ui";
import {
  ChangeEvent, CSSProperties,
  MouseEvent,
  useCallback,
  useEffect, useMemo,
  useRef,
  useState,
} from "react";

export interface InputProps {
  id: string,
  label?: string;
  value: string;
  onChange: (id: string, value: string, error: string | null) => void;
  onBlur?: () => void;
  validateInput: (value: string | null) => void,
  height?: number;
  debounceDelay?: number,
  // styling
  align?: 'start' | 'center' | 'end',
  formattedValue?: string;
}

export function Input(props: InputProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState(props.value);
  const [error, setError] = useState<string | null>(null);
  const [focused, setFocused] = useState<boolean>(false);
  
  const onFocus = () => setFocused(true);
  const onBlur = () => {
    setTimeout(() => { // in a timeout because user might click the clear button then the onBlur of the input is fired before the onClick of the button then the button goes away
      props.onBlur && props.onBlur();
      setFocused(false);
    }, 100);
  }
  // return <input type="text" onFocus={onFocus} onBlur={onBlur} />

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  let changeTimeout:NodeJS.Timeout;

  const changeValue = useCallback((value: string) => {
    try {
      props.validateInput(value);
      setValue(value);

      clearTimeout(changeTimeout);
      changeTimeout = setTimeout(() => {
        try {
          props.onChange(props.id, value, null);
        } catch (e) {
            //     // setError(e ? `${e}` : 'invalid input');
        }
      }, 400)

      setError(null);  // clear error
    } catch (e) {
      // value change aborted due to validation error; no need to report/show
      // setError(e ? `${e}` : 'invalid input');
    }
  }, []);

  const handleChangeValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    //console.debug("Input::handleChangeValue");
    changeValue(event.target.value);
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleClearValue = useCallback((event: MouseEvent<HTMLElement>) => {
    props.onChange(props.id, "", null);
    setValue("");
    event.preventDefault();
    event.stopPropagation();
  }, []);

  // const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
  //   switch (event.key) {
  //     case "Enter":
  //       // event.stopPropagation();
  //       event.preventDefault(); // default executes handleDeleteValue (button)
  //       break;
  //   }
  // }, []);

  const classes = useMemo(() => [
    "ux-input",
    error ? "error" : "",
  ].filter(Boolean).join(" "), [error]);

  const inputStyle: CSSProperties = {
    textAlign: props.align ?? 'start',
  }

  return (
    <div className={ classes }>
      { props.align && props.align === 'end' && (
        <IconButton
          icon="circle-delete-fill"
          focusable={ false }
          onClick={ handleClearValue }
        />
      ) }
      <input
        ref={ inputRef }
        value={ !focused && props.formattedValue ? props.formattedValue : value }
        onChange={ handleChangeValue }
        placeholder={ props.label }
        style={ inputStyle }
        onFocus={onFocus} 
        onBlur={onBlur}
      />
      { (!props.align || props.align !== 'end') && (
        <IconButton
          icon="circle-delete-fill"
          focusable={ false }
          onClick={ handleClearValue }
        />
      ) }
    </div>
  );
}
