import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HomePageIdQueryVariables = Types.Exact<{
  appId: Types.Scalars['ID'];
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type HomePageIdQuery = { __typename?: 'Query', platform?: { __typename?: 'Platform', homePageId?: string | null } | null };


export const HomePageIdDocument = gql`
    query HomePageId($appId: ID!, $projectId: Int) {
  platform(appId: $appId, projectId: $projectId) {
    homePageId
  }
}
    `;

/**
 * __useHomePageIdQuery__
 *
 * To run a query within a React component, call `useHomePageIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageIdQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useHomePageIdQuery(baseOptions: Apollo.QueryHookOptions<HomePageIdQuery, HomePageIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomePageIdQuery, HomePageIdQueryVariables>(HomePageIdDocument, options);
      }
export function useHomePageIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePageIdQuery, HomePageIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomePageIdQuery, HomePageIdQueryVariables>(HomePageIdDocument, options);
        }
export type HomePageIdQueryHookResult = ReturnType<typeof useHomePageIdQuery>;
export type HomePageIdLazyQueryHookResult = ReturnType<typeof useHomePageIdLazyQuery>;
export type HomePageIdQueryResult = Apollo.QueryResult<HomePageIdQuery, HomePageIdQueryVariables>;