import { useMemo, useState } from "react"
import "./DataGridComponent.scss";
import {
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableColumnDefinition,
  Input,
  Field,
  InputOnChangeData,
  SearchBox,
} from "@fluentui/react-components";
import { OrganisationItem } from "../SubscriptionControl/SubscriptionControl";
import { ImageItem } from "../ImageControl/ImageControl";

interface DataGridProps {
  items: any[]
  columns: TableColumnDefinition<OrganisationItem>[] | TableColumnDefinition<ImageItem>[]
}

export const DataGridComponent = (props: DataGridProps) => {
  const [search, setSearch] = useState<string>("")

  const filteredItems = useMemo(() => {
    return props.items.filter(item => JSON.stringify(item).toLowerCase().includes(search.toLowerCase()))
  }, [search])

  const getRowId = (item: OrganisationItem | ImageItem) => {
    if (item.hasOwnProperty("name")) return (item as OrganisationItem).name.label
    if (item.hasOwnProperty("filename")) return (item as ImageItem).filename.label
    return "no row id"
  }

  return (
    <div>
      <div style={{backgroundColor: "white", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px"}}>
        <div>{filteredItems.length} items</div>
        <SearchBox value={search} onChange={(e, data) => setSearch(data.value)} />
      </div>
      <DataGrid
        items={filteredItems}
        columns={props.columns}
        sortable
        selectionMode="multiselect"
        getRowId={getRowId}
        focusMode="composite"
        style={{backgroundColor: "white"}}
      >
        <DataGridHeader>
          <DataGridRow
            selectionCell={{
              checkboxIndicator: { "aria-label": "Select all rows" },
            }}
          >
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<OrganisationItem | ImageItem>>
          {({ item, rowId }) => (
            <DataGridRow<OrganisationItem | ImageItem>
              key={rowId}
              selectionCell={{
                checkboxIndicator: { "aria-label": "Select row" },
              }}
            >
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </div>
  )
}

export default DataGrid;