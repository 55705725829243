import { gql } from "@apollo/client";

export const fieldsSchema = gql`

  extend interface FieldI {
    "True if the local value differs from the latest server value"
    localEdited: Boolean @client  # required for all field types
  }

  extend type TextField {
    localEdited: Boolean @client
    "The local/client-edited text value"
    localText: String @client
  }

  extend type BooleanField {
    localEdited: Boolean @client
    "The local/client-edited boolean value"
    localState: Boolean @client
  }

  extend type NumberField {
    localEdited: Boolean @client
    "The local/client-edited number value"
    localValue: Float @client
  }

  extend type NumberRangeField {
    localEdited: Boolean @client
    "The local/client-edited range value"
    localRange: Range @client
  }

  #extend type UploadFileField {
  #  # localEdited: False,
  #}

  extend type UploadFileField {
    "Always false"
    localEdited: Boolean @client
  }

  extend type DownloadFileField {
    "Always false"
    localEdited: Boolean @client
  }

  extend type SelectField {
    localEdited: Boolean @client
    localSelection: SelectOption @client
  }

  extend type MultiSelectField {
    localEdited: Boolean @client
    localSelections: [SelectOption!] @client
  }

  extend type DateField {
    localEdited: Boolean @client
    localDate: String @client
  }

  extend type DateTimeField {
    localEdited: Boolean @client
    localDateTime: String @client
  }

`;
