import './Filters.scss';
import { FilterFragment } from "../Filter/Filter.generated";
import { Filter } from "../Filter";
import { SelectOption } from '@arq-apps/generated';

export interface FilterProps {
  filters: FilterFragment[],
  primaryFilters: boolean,
  updateFilters: (id: string, option: SelectOption | null | undefined) => void,
  selectClear: (id: string) => void,
  multiSelect: (id: string, options: SelectOption[] | null | undefined) => void,
  multiClearAll: (id: string) => void,
  multiSelectAll: (id: string) => void,
  topbarFilter?: boolean,
}

export function Filters(props: FilterProps) {
  const classes = ["ux-filters", 
    props.primaryFilters && "primary",
    props.topbarFilter && "topbarFilter"
  ].filter(Boolean).join(" ");

  return (
    <div className={classes}>
      { props.filters.map(filter => (
        <Filter key={ filter.id } {...filter}
          topbarFilter={props.topbarFilter}
          updateFilters={props.updateFilters} 
          primaryFilter={props.primaryFilters} 
          selectClear={props.selectClear}
          multiSelect={props.multiSelect}
          multiClearAll={props.multiClearAll}
          multiSelectAll={props.multiSelectAll}
        />
      )) }
    </div>
  );
}
