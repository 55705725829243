/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesTemplatesTTemplateActivity } from '../models/MessagesTemplatesTTemplateActivity';
import type { SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues } from '../models/SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TemplatesApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param applicationId
     * @param projectId
     * @returns MessagesTemplatesTTemplateActivity OK
     * @throws ApiError
     */
    public getTemplatesApiGetTemplateSummary(
        applicationId: number,
        projectId: number,
    ): CancelablePromise<Array<MessagesTemplatesTTemplateActivity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/templates_summary/{applicationId},{projectId}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
            },
        });
    }

    /**
     * @param applicationId
     * @param projectId
     * @param entityId
     * @param templateId
     * @returns MessagesTemplatesTTemplateActivity OK
     * @throws ApiError
     */
    public getTemplatesApiGetSpecificTemplateSummary(
        applicationId: number,
        projectId: number,
        entityId: number,
        templateId: number,
    ): CancelablePromise<Array<MessagesTemplatesTTemplateActivity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/templates_activity_log/{applicationId},{projectId},{entityId},{templateId}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
                'entityId': entityId,
                'templateId': templateId,
            },
        });
    }

    /**
     * @param formData
     * @returns MessagesTemplatesTTemplateActivity OK
     * @throws ApiError
     */
    public postTemplatesApiPostTransitionUpdate(
        formData?: {
            formData?: Array<SystemCollectionsGenericKeyValuePairOfSystemStringMicrosoftExtensionsPrimitivesStringValues>;
        },
    ): CancelablePromise<Array<MessagesTemplatesTTemplateActivity>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/templates_activity_log_transition_template',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
