import "./TabMenu.scss";

export interface TabMenuItem {
  key: string
  title: string
}

interface TabMenuProps {
  items: TabMenuItem[]
  selectedIndex: number
  onChange: (index: number) => void
  inHeader?: boolean
}

export const TabMenu = (props: TabMenuProps) => {
  const className = props.inHeader ? "tab-menu tab-menu-header" : "tab-menu";

  return (
    <div className={className}>
      {props.items.map((item, index) => 
        <div
          className={`tab-menu-item ${index === props.selectedIndex && "selected"}`}
          key={item.key}
          onClick={() => {
            props.onChange(index);
          }}
        >
          <span>{item.title}</span>
        </div>
      )}
    </div>
  )
}