import './Toggle.scss';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from "@arq-apps/ui";
import { useCallback } from "react";

export interface ToggleProps<T> {
  id: string,
  label: string | null,
  labelled?: boolean,
  editable?: boolean,
  state: boolean | null | undefined,
  onChange: (id: string, state: boolean, extra: T) => void
  onChangeExtra: T,  // extra properties passed back to parent on change events
}

export function Toggle<T>(props: ToggleProps<T>) {
  const editable = props.editable ?? true;  // editable by default
  const label = props.label && props.label.length > 0 ? props.label : undefined;

  const onChange = useCallback((checked: Checkbox.CheckedState) => {
    const state: boolean = checked === "indeterminate" ? false : (checked ?? false);
    props.onChange(props.id, state, props.onChangeExtra);
  }, []);

  return (
    <div className={"ux-toggle"}>
      <Checkbox.Root
        id={ props.id }
        checked={ props.state || false }
        disabled={ !editable }
        onCheckedChange={ onChange }
      >
        <Checkbox.CheckboxIndicator>
          <CheckIcon />
        </Checkbox.CheckboxIndicator>
      </Checkbox.Root>
      { props.labelled && label && (
        <label htmlFor={ props.id }>
          { props.label }
          {/*Accept terms and conditions*/}
        </label>
      ) }
    </div>
  );
}
