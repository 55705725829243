/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagesSupportMenuSupportMenuItem } from '../models/MessagesSupportMenuSupportMenuItem';
import type { MessagesUiAccessPageItem } from '../models/MessagesUiAccessPageItem';
import type { MessagesUiApplication } from '../models/MessagesUiApplication';
import type { MessagesUiApplicationMenu } from '../models/MessagesUiApplicationMenu';
import type { MessagesUiCascadingFilters } from '../models/MessagesUiCascadingFilters';
import type { MessagesUiRoleItem } from '../models/MessagesUiRoleItem';
import type { WebRequestsMenuRequest } from '../models/WebRequestsMenuRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UiApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get a menu for the app/user
     * @param applicationId
     * @param projectId
     * @returns MessagesSupportMenuSupportMenuItem OK
     * @throws ApiError
     */
    public getUiApiGetSupportMenu(
        applicationId?: number,
        projectId?: number,
    ): CancelablePromise<Array<MessagesSupportMenuSupportMenuItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/ui/support-menu',
            query: {
                'applicationId': applicationId,
                'projectId': projectId,
            },
        });
    }

    /**
     * Get pages of an app
     * @param applicationId
     * @returns MessagesUiAccessPageItem OK
     * @throws ApiError
     */
    public getUiApiGetPages(
        applicationId: number,
    ): CancelablePromise<Array<MessagesUiAccessPageItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/ui/pages/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
        });
    }

    /**
     * Get roles of an app
     * @param applicationId
     * @returns MessagesUiRoleItem OK
     * @throws ApiError
     */
    public getUiApiGetRoles(
        applicationId: number,
    ): CancelablePromise<Array<MessagesUiRoleItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/ui/roles/{applicationId}',
            path: {
                'applicationId': applicationId,
            },
        });
    }

    /**
     * Gets the config menu of an app
     * @param applicationId
     * @param grouping
     * @returns MessagesUiApplicationMenu OK
     * @throws ApiError
     */
    public getUiApiGetMenu(
        applicationId: number,
        grouping: string,
    ): CancelablePromise<MessagesUiApplicationMenu> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/ui/menu/{applicationId}/{grouping}',
            path: {
                'applicationId': applicationId,
                'grouping': grouping,
            },
        });
    }

    /**
     * Updates the config menu of an app
     * @param applicationId
     * @param grouping
     * @param requestBody
     * @returns boolean OK
     * @throws ApiError
     */
    public postUiApiUpdateMenu(
        applicationId: number,
        grouping: string,
        requestBody?: WebRequestsMenuRequest,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/ui/menu/{applicationId}/{grouping}',
            path: {
                'applicationId': applicationId,
                'grouping': grouping,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Gets the cascading filters
     * @param applicationId
     * @param projectId
     * @param filterGroup
     * @returns MessagesUiCascadingFilters OK
     * @throws ApiError
     */
    public getUiApiGetFilterGroup(
        applicationId: number,
        projectId: number,
        filterGroup: string,
    ): CancelablePromise<MessagesUiCascadingFilters> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/ui/filter-group/{applicationId}/{projectId}/{filterGroup}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
                'filterGroup': filterGroup,
            },
        });
    }

    /**
     * Sets the cascading filters
     * @param applicationId
     * @param projectId
     * @param filterGroup
     * @param requestBody JSON string of filter group
     * @returns any OK
     * @throws ApiError
     */
    public postUiApiGetFilterGroup(
        applicationId: number,
        projectId: number,
        filterGroup: string,
        requestBody?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/ui/filter-group/{applicationId}/{projectId}/{filterGroup}',
            path: {
                'applicationId': applicationId,
                'projectId': projectId,
                'filterGroup': filterGroup,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Gets the applications related to a project
     * @param projectId
     * @returns MessagesUiApplication OK
     * @throws ApiError
     */
    public getUiApiGetApplicationsByProject(
        projectId: number,
    ): CancelablePromise<Array<MessagesUiApplication>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rest/ui/applications/{projectId}',
            path: {
                'projectId': projectId,
            },
        });
    }

}
