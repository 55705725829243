import { useEffect, useState } from 'react'
import { useDotnetClient } from 'src/contexts/DotnetClientContext'
import { useLandingContext } from 'src/contexts/LandingContext'
import { MessagesSupportMenuSupportMenuItem } from 'src/dotnet-client'
import { useContext } from 'src/hooks'

interface SupportMenuProps {
  setSupport: (key: string) => void;
}

export function SupportMenu(props: SupportMenuProps) {
  const {dmpClient} = useDotnetClient();
  const {appId, projectId} = useLandingContext();
  const [supportMenu, setSupportMenu] = useState<MessagesSupportMenuSupportMenuItem[]>([]);
  

  useEffect(() => {
    dmpClient?.uiApi.getUiApiGetSupportMenu(appId, projectId)
      .then(result => setSupportMenu(result))
  }, [])

  return (
    <section className="menu">
      <span className="title"></span>
      <div className="ux-menu">
        {supportMenu.map((item) =>
          <div key={item.key} className="ux-menu-item" onClick={() => props.setSupport(item.key ?? "")}>{item.label}</div>
        )}
        {/* Leaving these commented out as they could be useful if you don't have the right permissions to see an item but still want to debug/develop */}
        {/* <div className="ux-menu-item" onClick={() => props.setSupport("data-assets")}>Data Assets</div> */}
        {/* <div className="ux-menu-item" onClick={() => props.setSupport("glossary")}>Glossary</div> */}
        {/* <div className="ux-menu-item" onClick={() => props.setSupport("faq")}>FAQ</div> */}
        {/* <div className="ux-menu-item" onClick={() => props.setSupport("data-templates")}>Data template</div> */}
      </div>
    </section>
  )
}

export default SupportMenu;