import "./SupportPane.scss";
import { IconButton } from "@arq-apps/ui";
import Templates from "../Templates/Templates";
import DataAssets from "../DataAssets/DataAssets";
import { SupportPaneSize } from "../App";
import { useFooterHeight } from "src/hooks";
import Glossary from "../Glossary/Glossary";
import ErrorBoundary from "src/ui/ErrorBoundary/ErrorBoundary"
import FAQ from "../FAQ/FAQ"

interface SupportPaneProps {
  supportPaneKey: string | undefined;
  supportPaneSize: SupportPaneSize | undefined;
  setSupportPaneSize: (size: SupportPaneSize | undefined) => void;
  appId: number | null;
}

function SupportPane({supportPaneKey, supportPaneSize, setSupportPaneSize, appId}: SupportPaneProps) {
  const renderSupport = () => {
    switch (supportPaneKey) {
      case "data-assets":
        return <DataAssets supportPaneSize={supportPaneSize} setSupportPaneSize={setSupportPaneSize} appId={appId}/>      
      case "data-templates":
        return <Templates supportPaneSize={supportPaneSize} setSupportPaneSize={setSupportPaneSize} appId={appId}/>
      case "glossary":
        return <Glossary supportPaneSize={supportPaneSize} setSupportPaneSize={setSupportPaneSize} appId={appId}/>
      case "faq":
        return <FAQ supportPaneSize={supportPaneSize} setSupportPaneSize={setSupportPaneSize} appId={appId}/>
      default:
        return <div>Support Pane Key not supported</div>
    }
  }

  const renderTitle = () => {
    switch (supportPaneKey) {
      case "data-assets":
        return "Data Assets"      
      case "data-templates":
        return "Templates"
      case "glossary":
        return "Glossary"
      case "faq":
        return "FAQ"
      default:
        return "Support"
    }
  }

  const maximiseMinimise = () => {
    if (supportPaneSize === SupportPaneSize.Max) {
      setSupportPaneSize(SupportPaneSize.Min)
    }
    if (supportPaneSize === SupportPaneSize.Min) {
      setSupportPaneSize(SupportPaneSize.Max)
    }
  }

  const footerHeight = useFooterHeight();
  const headerHeight = 43;

  return (
    <ErrorBoundary>
      <div className="ux-support-pane" style={{height: `calc(100vh - ${footerHeight}px - ${headerHeight}px)`}}>
        <div className="support-pane-header">
          <div className="title">{renderTitle()}</div>
          <div className="action-container">
            <IconButton icon={supportPaneSize === SupportPaneSize.Max ? "minimize-outline" : "expand-outline"} onClick={maximiseMinimise}/>
            <IconButton icon="close-outline" onClick={() => setSupportPaneSize(undefined)}/>
          </div>
        </div>
        {renderSupport()}
      </div>
    </ErrorBoundary>
  );
}

export default SupportPane;
