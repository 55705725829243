/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ActivityLoggingApiService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @deprecated
     * Logs that a user is logged into the DMP application. No params as the token is already in the headers.
     * @returns any OK
     * @throws ApiError
     */
    public postActivityLoggingApiLogLogin(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/login',
        });
    }

    /**
     * Logs that a user access an application and accepted the terms and conditions and privacy policies.
     * @param appId Application ID
     * @param popup Indicator if the acceptance was explicit (ie there was a popup and checkboxes etc)
     * @returns any OK
     * @throws ApiError
     */
    public postActivityLoggingApiLogAppAccess(
        appId?: number,
        popup?: boolean,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rest/app-access',
            query: {
                'appId': appId,
                'popup': popup,
            },
        });
    }

}
