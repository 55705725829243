// import "./Column.scss";
import * as g from "./Column.generated";
import { BaseColumnProps } from "./BaseColumn";
import { LinkColumn } from "./LinkColumn";
import { memo, useCallback, useState } from "react";
import { Input } from "../Input";
import { EditCellEvent } from "../Table/useEditTable";
import { Select } from "../Select/Select";
import { SelectOption } from "@arq-apps/generated";

export interface TextColumnProps extends g.TextColumnFragment, BaseColumnProps {
  className?: string,
}

export function TextColumn(props: TextColumnProps) {
  const classes = [
    props.className,
    'text',
    props.editable ? 'editable' : '',
    props.align ? props.align : '',
  ].filter(Boolean).join(' ');

  if (props.isStorageBlob) {
    return <LinkColumn { ...props } />;
  }
  
  const footerClassName = ["footer-totals"].filter(Boolean).join('');

  if (!props.editable)
  {
    return (
      <div className={ classes }>
      <div className="title">{ props.title?.at(0) ?? props.id }</div>
      { props.dataRowIndex?.map((row) => (
        <div key={row} title={props.textValues?.at(row) ?? ""}>{props.textValues?.at(row) ?? ""}</div>
      )) }
      {props.showTotalsRow && <div className={footerClassName}></div> }
    </div>
    );
  }

  const validateInput = useCallback((value: string | null) => {
    // TODO apply TextColumn validation rules
  }, []);

  const options: SelectOption[] | null = props.options?.map(opt => ({
    __typename: "SelectOption",
    value: opt ?? "-",
    label: opt ?? "-",
  })) || null;

  // const selection = options[1];

  const onOptionSelected = useCallback((
    id: string,
    option: SelectOption | null,
    extra: number | null,
  ) => {
    // console.log('option selected', id, option);
    if (extra === null) {
      console.error('invalid extra: ', extra);
      return;
    }
    props.onEditCell({
      __typename: "TextColumn",
      columnId: props.id,
      dataRowIdx: extra,  // dataRowIdx passed as extra
      value: option?.value ?? "",
    });
  }, [props.id]);

 
  return (
    <div className={ classes }>
      <div className="title">{ props.title?.at(0) ?? props.id }</div>
      { props.dataRowIndex?.map((row) => (
        options ? (
          <Select
            id={ props.id }
            key={ `${ props.id }/${ row }` }
            label={ props.title?.at(0) }
            // TODO @RM - clean up the passing of SelectOption OR Build SelectColumn
            selection={props.textValues.at(row) ? 
              {
                value: props.textValues.at(row) ?? "",
                label: props.textValues.at(row) ?? "",
              } :
              null
            }
            localSelection={props.localTextValues?.at(row) ? 
              {
                value: props.localTextValues.at(row) ?? "",
                label: props.localTextValues.at(row) ?? "",
              } :
              null
            }
            options={ options }
            onOptionSelected={ onOptionSelected }
            extra={ row }
          />
        ) : (
          <TextCell
            key={ `${ props.id }/${ row }` }
            columnId={ props.id }
            editable={ props.editable ?? false }
            rowIdx={ row }
            value={ props.textValues?.at(row) ?? "" }
            localValue={ props.localTextValues?.at(row) ?? undefined }
            onChange={ props.onEditCell }
            validateInput={ validateInput }
          />
        )
      )) }
      {props.showTotalsRow && <div className={footerClassName}></div> }
    </div>
  );
}




interface TextCellProps {
  columnId: string,
  rowIdx: number,
  value: string,
  localValue?: string,
  editable: boolean,
  onChange: (event: EditCellEvent) => void,
  validateInput: (value: string | null) => void,
}

const TextCell = memo(function TextCell(props: TextCellProps) {
  const [focused, setFocused] = useState(false);
  
  const onChange = useCallback((id: string, value: string, error: string | null) => {
    props.onChange({
      __typename: "TextColumn",
      columnId: props.columnId,
      dataRowIdx: props.rowIdx,
      value,
    });
  }, [props.columnId, props.rowIdx]);

  const value = props.localValue ?? props.value;

  const handleClick = useCallback(() => {
    setFocused(true);
    }, []
  );

  const handleBlur = useCallback(() => {
    setFocused(false);
    }, []
  );


  if (!props.editable) {
    return <div>{ value }</div>;
  }

  if (props.editable && !focused) {
    return <div onClick={handleClick}>{ value }</div>;
  }
  
  return (
    <Input
      id={ "" }
      value={ value }
      height={ 21 }
      onChange={ onChange }
      onBlur={handleBlur}
      validateInput={ props.validateInput }
    />
  );
});
